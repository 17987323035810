import React, { useEffect, useState } from "react";
import { getFile } from "../../firebase/storage";
import { toast } from "react-toastify";
import "./Courses.css";
import { PriceBox, Prices } from "../../components/about/Prices";
import { LatestWork } from "../../components/LatestWork";
import { translation } from "../../utils/translation";

const RefreshmentEnglishCourse: React.FC = () => {
  const [img1, setImg1] = useState<string>("");
  const [img2, setImg2] = useState<string>("");
  const [img3, setImg3] = useState<string>("");
  const [img4, setImg4] = useState<string>("");
  const [img5, setImg5] = useState<string>("");

  useEffect(() => {
    getFile("courses/19.png").then((res) => {
      if (!res.success) {
        toast.error("couldn't load an image");
        return;
      }
      setImg1(res?.message);
    });

    getFile("courses/Ploy.png").then((res) => {
      if (!res.success) {
        toast.error("couldn't load an image");
        return;
      }
      setImg2(res?.message);
    });
    getFile("courses/onlineConference.jpg").then((res) => {
      if (!res.success) {
        toast.error("couldn't load an image");
        return;
      }
      setImg3(res?.message);
    });
    getFile("courses/grammar.jpg").then((res) => {
      if (!res.success) {
        toast.error("couldn't load an image");
        return;
      }
      setImg4(res?.message);
    });

    getFile("courses/4.png").then((res) => {
      if (!res.success) {
        toast.error("couldn't load an image");
        return;
      }
      setImg5(res?.message);
    });
  }, []);
  return (
    <div className="course-container">
      <section className="section-main">
        <div className="div-text">
          <h1>{translation.tr_refreshment_english.en}</h1>
          <h2>{translation.tr_overview.en}</h2>
          <p>{translation.tr_the_refreshment_english.en}</p>
        </div>
        <div className="div-img-main">
          <img src={img1} alt="Refreshment Course Main Image" />
        </div>
      </section>

      <section className="section-main">
        <div className="div-img-main div-secondary-img ">
          <img src={img2} alt="Refreshment Course Secondary Image" />
        </div>
        <div className="div-text">
          <h2>{translation.tr_who_is_it.en}</h2>
          <ul>
            <li>{translation.tr_professionals_looking_to.en}</li>
            <li>{translation.tr_individuals_preparing_for.en}</li>
            <li>{translation.tr_students_returning_to.en}</li>
            <li>{translation.tr_anyone_who_wants_brush.en}</li>
          </ul>
        </div>
      </section>

      <section className="section-main section-secondary">
        <div className="div-text">
          <h2>{translation.tr_key_features.en}</h2>

          <ul>
            <li>
              <strong>{translation.tr_customized_lessons.en}:</strong>{" "}
              {translation.tr_each_lesson_is.en}
            </li>
            <li>
              <strong>{translation.tr_practical_topics.en}:</strong>{" "}
              {translation.tr_the_course_focuses.en}:
              <ul>
                <li>{translation.tr_professional_communication_emails.en}</li>
                <li>{translation.tr_travel_and_daily.en}</li>
                <li>{translation.tr_academic_writing_and.en}</li>
              </ul>
            </li>
            <li>
              <strong>{translation.tr_skill_integration.en}:</strong>{" "}
              {translation.tr_each_session_integrates.en}
            </li>
            <li>
              <strong>{translation.tr_flexible_scheduling.en}:</strong>{" "}
              {translation.tr_choose_class_timings.en}
            </li>
            <li>
              <strong>Interactive Sessions:</strong>
              {translation.tr_engage_in_role_playing.en}
            </li>
          </ul>
        </div>

        <div className="div-img-main div-secondary-img ">
          <img src={img3} alt="Refreshment Course Secondary Image" />
        </div>
      </section>

      <section className="section-main ">
        <div className="div-img-main div-secondary-img ">
          <img src={img4} alt="Refreshment Course Secondary Image" />
        </div>
        <div className="div-text">
          <h2>{translation.tr_what_youll_learn.en}</h2>
          <ul>
            <li>{translation.tr_refresh_and_refine.en}</li>
            <li>{translation.tr_expand_your_vocabulary.en}</li>
            <li>{translation.tr_enhance_fluency_in.en}</li>
            <li>{translation.tr_build_confidence_for.en}</li>
            <li>{translation.tr_master_clear_and.en}</li>
          </ul>
        </div>
      </section>

      <section className="section-main ">
        <div className="div-text">
          <h2>{translation.tr_why_choose_the.en}</h2>
          <ul>
            <li>{translation.tr_personalized_teaching_methods.en}</li>
            <li>{translation.tr_interactive_and_fun.en}</li>
            <li>{translation.tr_expert_instructors_with.en}</li>
            <li>{translation.tr_a_focus_on.en}</li>
          </ul>
        </div>
        <div className="div-img-main div-fifth-img ">
          <img src={img5} alt="Refreshment Course Secondary Image" />
        </div>
      </section>

      <div className="prices-container">
        <h1>{translation.tr_custom_courses.en}</h1>
        <div className="price-box-grid">
          <PriceBox
            price={"3,500"}
            hours={10}
            color="#545454"
            pointers={[
              translation.tr_1_hour_free.en,
              translation.tr_pay_on_salary.en,
              translation.tr_free_digital_book.en,
              translation.tr_postpone_up_to.en,
            ]}
          />

          <PriceBox
            price={"6,500"}
            hours={20}
            color="#6257e3"
            pointers={[
              translation.tr_1_hour_free.en,
              translation.tr_pay_on_salary.en,
              translation.tr_2_payment_installments.en,
              translation.tr_postpone_up_to.en,
            ]}
          />

          <PriceBox
            price={"12,000"}
            hours={40}
            color="#a359a0"
            pointers={[
              translation.tr_1_hour_free.en,
              translation.tr_pay_on_salary.en,
              translation.tr_postpone_up_to.en,
              translation.tr_2_payment_installments.en,
            ]}
          />

          <PriceBox
            price={"22,500"}
            hours={100}
            color="#c6b79b"
            pointers={[
              translation.tr_1_hour_free.en,
              translation.tr_pay_on_salary.en,
              translation.tr_free_digital_book.en,
              translation.tr_3_payment_installments.en,
            ]}
          />
        </div>
      </div>

      <section className="section-main section-1-line">
        <div className="div-text">
          <h2>{translation.tr_take_the_next.en}</h2>
          <p>{translation.tr_join_the_refreshment.en}</p>
          <p>
            <strong>{translation.tr_contact_us_now.en}</strong>
          </p>
        </div>
      </section>

      <LatestWork title={translation.tr_more_courses.en} />
    </div>
  );
};

export default RefreshmentEnglishCourse;
