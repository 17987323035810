interface TranslationObject {
  en: string;
  th: string;
}

interface Translation {
  tr_english: TranslationObject;
  tr_guiding_students_to: TranslationObject;
  tr_fluency_with_a: TranslationObject;
  tr_7_years: TranslationObject;
  tr_exam_preperation: TranslationObject;
  tr_english_for_daily: TranslationObject;
  tr_business_english_essentials: TranslationObject;
  tr_conversational_english_practice: TranslationObject;
  tr_reading_phonics: TranslationObject;
  tr_it: TranslationObject;
  tr_from_scratch_to: TranslationObject;
  tr_popular_english_lessons: TranslationObject;
  tr_refreshment_english: TranslationObject;
  tr_perfect_for_those: TranslationObject;
  tr_practice_language_that: TranslationObject;
  tr_english_for_young: TranslationObject;
  tr_fun_foundational_english: TranslationObject;
  tr_english_for_teenagers: TranslationObject;
  tr_engaging_relevant_conversations: TranslationObject;
  tr_grammar_essentials: TranslationObject;
  tr_strengthen_your_english: TranslationObject;
  tr_freshen_up_your: TranslationObject;
  tr_play_wordle: TranslationObject;
  tr_popular_it_lessons: TranslationObject;
  tr_game_design_with: TranslationObject;
  tr_learn_to_create: TranslationObject;
  tr_robotics_for_kids: TranslationObject;
  tr_dive_into_the: TranslationObject;
  tr_python_for_teenagers: TranslationObject;
  tr_designed_for_teens: TranslationObject;
  tr_miss_bow: TranslationObject;
  tr_regional_manager_pharmaceutical: TranslationObject;
  tr_david_has_been_my_teacher: TranslationObject;
  tr_david_has_been_fantastic: TranslationObject;
  tr_miss_ploy: TranslationObject;
  tr_tv_show_host: TranslationObject;
  tr_working_with_david: TranslationObject;
  tr_miss_kwan: TranslationObject;
  tr_business_owner: TranslationObject;
  tr_david_guided_me: TranslationObject;
  tr_mr_brook_and: TranslationObject;
  tr_david_prepared_us: TranslationObject;
  tr_nodd: TranslationObject;
  tr_teacher_david_has: TranslationObject;
  tr_mr_frank: TranslationObject;
  tr_lawyer: TranslationObject;
  tr_davids_refreshment_courses: TranslationObject;
  tr_miss_bam: TranslationObject;
  tr_nodds_mother: TranslationObject;
  tr_pugan: TranslationObject;
  tr_teacher_david_is: TranslationObject;
  tr_khim: TranslationObject;
  tr_student: TranslationObject;
  tr_teacher_davids_lessons: TranslationObject;
  tr_what_people_say: TranslationObject;
  tr_popular_courses: TranslationObject;
  tr_1_hour_free: TranslationObject;
  tr_pay_on_salary: TranslationObject;
  tr_postpone_up_to: TranslationObject;
  tr_free_digital_book: TranslationObject;
  tr_looking_for_something: TranslationObject;
  tr_get_a_quote: TranslationObject;
  tr_about: TranslationObject;
  tr_welcome_im_teacher: TranslationObject;
  tr_what_i_do: TranslationObject;
  tr_i_specialize_in: TranslationObject;
  tr_young_learners: TranslationObject;
  tr_building_strong_foundations: TranslationObject;
  tr_teenagers: TranslationObject;
  tr_guiding_them_to: TranslationObject;
  tr_professionals: TranslationObject;
  tr_helping_business_leaders: TranslationObject;
  tr_global_aspirants: TranslationObject;
  tr_preparing_students_for: TranslationObject;
  tr_creating_a_need: TranslationObject;
  tr_i_design_lessons: TranslationObject;
  tr_custom_materials_and: TranslationObject;
  tr_i_develop_personalized: TranslationObject;
  tr_bilingual_support: TranslationObject;
  tr_for_thai_speaking_learners: TranslationObject;
  tr_child_centered_learning: TranslationObject;
  tr_for_young_learners: TranslationObject;
  tr_integrating_technology: TranslationObject;
  tr_i_incorporate_modern: TranslationObject;
  tr_cultural_connection: TranslationObject;
  tr_my_lessons_link: TranslationObject;
  tr_supportive_coaching: TranslationObject;
  tr_i_provide_clear: TranslationObject;
  tr_my_philosophy: TranslationObject;
  tr_get_students_talking: TranslationObject;
  tr_my_teaching_style: TranslationObject;
  tr_i_believe_learning: TranslationObject;
  tr_monthly_subscriptions: TranslationObject;
  tr_all_classes_are: TranslationObject;
  tr_2_hours: TranslationObject;
  tr_one_time_payment: TranslationObject;
  tr_1_student: TranslationObject;
  tr_4_hours: TranslationObject;
  tr_up_to_2: TranslationObject;
  tr_6_hours: TranslationObject;
  tr_2_payment_installments: TranslationObject;
  tr_up_to_3: TranslationObject;
  tr_8_hours: TranslationObject;
  tr_3_payment_installments: TranslationObject;
  tr_up_to_4: TranslationObject;
  tr_10_hours: TranslationObject;
  tr_up_to_5: TranslationObject;
  tr_15_hours: TranslationObject;
  tr_up_to_6: TranslationObject;
  tr_2_dedicated_teachers: TranslationObject;
  tr_20_hours: TranslationObject;
  tr_up_to_7: TranslationObject;
  tr_4_dedicated_teachers: TranslationObject;
  tr_25_hours: TranslationObject;
  tr_4_payment_installments: TranslationObject;
  tr_up_to_15: TranslationObject;
  tr_5_dedicated_teachers: TranslationObject;
  tr_popular_private_courses: TranslationObject;
  tr_years_of_teaching: TranslationObject;
  tr_online_private_students: TranslationObject;
  tr_hours_of_online: TranslationObject;
  tr_professionals_coached: TranslationObject;
  tr_teaching_materials: TranslationObject;
  tr_contact: TranslationObject;
  tr_get_in_touch: TranslationObject;
  tr_linkedin: TranslationObject;
  tr_instagram: TranslationObject;
  tr_facebook: TranslationObject;
  tr_line: TranslationObject;
  tr_send_me_an: TranslationObject;
  tr_name: TranslationObject;
  tr_email: TranslationObject;
  tr_message: TranslationObject;
  tr_overview: TranslationObject;
  tr_the_refreshment_english: TranslationObject;
  tr_key_features: TranslationObject;
  tr_who_is_it: TranslationObject;
  tr_professionals_looking_to: TranslationObject;
  tr_individuals_preparing_for: TranslationObject;
  tr_students_returning_to: TranslationObject;

  tr_customized_lessons: TranslationObject;
  tr_each_lesson_is: TranslationObject;
  tr_practical_topics: TranslationObject;
  tr_the_course_focuses: TranslationObject;
  tr_professional_communication_emails: TranslationObject;
  tr_travel_and_daily: TranslationObject;
  tr_academic_writing_and: TranslationObject;
  tr_skill_integration: TranslationObject;
  tr_each_session_integrates: TranslationObject;
  tr_flexible_scheduling: TranslationObject;
  tr_choose_class_timings: TranslationObject;
  tr_engage_in_role_playing: TranslationObject;
  tr_what_youll_learn: TranslationObject;
  tr_refresh_and_refine: TranslationObject;
  tr_expand_your_vocabulary: TranslationObject;
  tr_enhance_fluency_in: TranslationObject;
  tr_build_confidence_for: TranslationObject;
  tr_master_clear_and: TranslationObject;
  tr_why_choose_the: TranslationObject;
  tr_personalized_teaching_methods: TranslationObject;
  tr_interactive_and_fun: TranslationObject;
  tr_expert_instructors_with: TranslationObject;
  tr_a_focus_on: TranslationObject;
  tr_custom_courses: TranslationObject;
  tr_take_the_next: TranslationObject;
  tr_join_the_refreshment: TranslationObject;
  tr_contact_us_now: TranslationObject;
  tr_beginners_looking_to: TranslationObject;
  tr_intermediate_learners_wanting: TranslationObject;
  tr_travelers_preparing_for: TranslationObject;
  tr_the_course_emphasizes: TranslationObject;
  tr_shopping_and_handling: TranslationObject;
  tr_ordering_food_at: TranslationObject;
  tr_introducing_yourself_and: TranslationObject;
  tr_making_appointments_or: TranslationObject;
  tr_asking_for_directions: TranslationObject;
  tr_role_playing_dialogues_and: TranslationObject;
  tr_pronunciation_practice: TranslationObject;

  tr_tailored_content_to: TranslationObject;
  tr_improve_conversational_fluency: TranslationObject;
  tr_expand_vocabulary_for: TranslationObject;
  tr_understand_and_use: TranslationObject;
  tr_build_confidence_to: TranslationObject;
  tr_learn_cultural_tips: TranslationObject;
  tr_learn_english_thats: TranslationObject;
  tr_build_confidence_with: TranslationObject;
  tr_focus_on_improving: TranslationObject;

  tr_why_choose_english: TranslationObject;

  tr_the_business_english: TranslationObject;
  tr_professionals_aiming_to: TranslationObject;
  tr_job_seekers_preparing: TranslationObject;
  tr_managers_and_team: TranslationObject;
  tr_entrepreneurs_seeking_to: TranslationObject;
  tr_professional_scenarios: TranslationObject;
  tr_practice_business_related_tasks: TranslationObject;
  tr_delivering_effective_presentations: TranslationObject;
  tr_participating_in_meetings: TranslationObject;
  tr_negotiating_deals_and: TranslationObject;
  tr_writing_professional_emails: TranslationObject;
  tr_networking_and_building: TranslationObject;
  tr_vocabulary_expansion: TranslationObject;
  tr_learn_industry_specific_vocabulary: TranslationObject;
  tr_role_playing_activities: TranslationObject;
  tr_simulate_real_workplace: TranslationObject;
  tr_personalized_curriculum: TranslationObject;
  tr_content_tailored_to: TranslationObject;
  tr_master_professional_communication: TranslationObject;
  tr_build_confidence_in: TranslationObject;
  tr_learn_to_write: TranslationObject;
  tr_develop_strategies_for: TranslationObject;
  tr_why_choose_business: TranslationObject;
  tr_focus_on_practical: TranslationObject;
  tr_learn_from_interactive: TranslationObject;
  tr_children_aged_4: TranslationObject;
  tr_young_learners_starting: TranslationObject;
  tr_kids_needing_extra: TranslationObject;
  tr_parents_looking_to: TranslationObject;
  tr_interactive_learning: TranslationObject;
  tr_lessons_include_storytelling: TranslationObject;
  tr_phonics_and_vocabulary: TranslationObject;
  tr_focus_on_building: TranslationObject;
  tr_creative_activities: TranslationObject;
  tr_use_art_music: TranslationObject;
  tr_tailored_curriculum: TranslationObject;
  tr_lessons_are_adapted: TranslationObject;
  tr_parent_involvement: TranslationObject;
  tr_regular_progress_updates: TranslationObject;
  tr_basic_conversational_english: TranslationObject;
  tr_improved_pronunciation_and: TranslationObject;
  tr_foundational_grammar_and: TranslationObject;
  tr_reading_and_comprehension: TranslationObject;
  tr_creative_expression_in: TranslationObject;
  tr_fun_and_engaging: TranslationObject;
  tr_boosts_confidence_and: TranslationObject;
  tr_interactive_and_creative: TranslationObject;
  tr_flexible_learning_options: TranslationObject;
  tr_regular_updates_and: TranslationObject;
  tr_help_your_child: TranslationObject;
  tr_teenagers_aged_13: TranslationObject;
  tr_students_looking_to: TranslationObject;
  tr_teens_preparing_for: TranslationObject;
  tr_young_learners_wanting: TranslationObject;
  tr_practical_focus: TranslationObject;
  tr_lessons_designed_around: TranslationObject;
  tr_academic_skills: TranslationObject;
  tr_strengthens_grammar_vocabulary: TranslationObject;
  tr_interactive_sessions: TranslationObject;
  tr_engaging_discussions_debates: TranslationObject;
  tr_individualized_learning: TranslationObject;
  tr_curriculum_tailored_to: TranslationObject;
  tr_creative_writing: TranslationObject;
  tr_focus_on_essays: TranslationObject;
  tr_fluent_and_confident: TranslationObject;
  tr_enhanced_grammar_and: TranslationObject;
  tr_critical_thinking_and: TranslationObject;
  tr_advanced_writing_techniques: TranslationObject;
  tr_effective_communication_for: TranslationObject;
  tr_prepares_teens_for: TranslationObject;
  tr_builds_confidence_in: TranslationObject;
  tr_focus_on_academic: TranslationObject;
  tr_interactive_and_engaging: TranslationObject;
  tr_flexible_scheduling_to: TranslationObject;
  tr_equip_your_teenager: TranslationObject;
  tr_grammar_is_the: TranslationObject;
  tr_students_of_all: TranslationObject;
  tr_adults_aiming_to: TranslationObject;
  tr_language_learners_preparing: TranslationObject;
  tr_comprehensive_coverage: TranslationObject;
  tr_lessons_on_tenses: TranslationObject;
  tr_practical_application: TranslationObject;
  tr_exercises_and_examples: TranslationObject;
  tr_error_correction: TranslationObject;
  tr_focused_feedback_on: TranslationObject;
  tr_activities_and_quizzes: TranslationObject;
  tr_flexible_pace: TranslationObject;
  tr_tailored_lessons_to: TranslationObject;
  tr_proper_sentence_construction: TranslationObject;
  tr_mastery_of_verb: TranslationObject;
  tr_accurate_use_of: TranslationObject;
  tr_understanding_and_avoiding: TranslationObject;
  tr_confidence_in_speaking: TranslationObject;
  tr_strengthens_your_foundation: TranslationObject;
  tr_helps_you_sound: TranslationObject;
  tr_improves_accuracy_in: TranslationObject;
  tr_offers_engaging_lessons: TranslationObject;
  tr_tailored_to_your: TranslationObject;
  tr_take_charge_of: TranslationObject;
  tr_enroll_today_and: TranslationObject;
  tr_contact_us_today: TranslationObject;
  tr_anyone_who_wants_brush: TranslationObject;
  tr_the_english_for_daily: TranslationObject;
  tr_anyone_who_wants_feel: TranslationObject;
  tr_gain_confidence_in_speaking: TranslationObject;
  tr_get_personalized_feedback_improvement: TranslationObject;
  tr_enroll_in_the_daily: TranslationObject;
  tr_gain_confidence_in_networking: TranslationObject;
  tr_get_personalized_feedback_skills: TranslationObject;
  tr_gain_confidence_in_professional: TranslationObject;
  tr_enroll_in_the_business: TranslationObject;
  tr_the_english_for_young: TranslationObject;
  tr_the_english_for_teenagers: TranslationObject;
  tr_why_choose_english_teenagers: TranslationObject;
  tr_anyone_who_wants_refine: TranslationObject;
  tr_python: TranslationObject;
  tr_robotics: TranslationObject;
  tr_web_design: TranslationObject;
  tr_roblox_game_development: TranslationObject;
  tr_learning_tools_for: TranslationObject;
  tr_free_trial: TranslationObject;
  tr_hours: TranslationObject;
  tr_more_courses: TranslationObject;
  tr_why_choose_english_young: TranslationObject;
  tr_why_choose_grammar: TranslationObject;
  tr_for_over: TranslationObject;
  tr_send_email: TranslationObject;
  tr_seven_years: TranslationObject;
  tr_scratch: TranslationObject;
}
export const translation: Translation = {
  tr_english: {
    en: "English",
    th: "ภาษาอังกฤษ",
  },
  tr_seven_years: {
    en: "7 years",
    th: "7 ปี",
  },
  tr_guiding_students_to: {
    en: "Guiding students to",
    th: "คอยแนะนำนักเรียน",
  },
  tr_fluency_with_a: {
    en: "fluency with a proven track record of success over",
    th: "ความคล่องแคล่วพร้อมประวัติความสำเร็จที่พิสูจน์แล้ว",
  },
  tr_7_years: {
    en: "For over <b>7 years </b>, I’ve been passionate about empowering\n          students of all ages to achieve their goals through personalized and\n          engaging education. My teaching journey has taken me across Cambodia\n          and Thailand, where I’ve taught <b>English</b>, <b>Programming</b> ,\n          and <b>Robotics</b>. Along the way, I’ve helped students build\n          confidence, develop essential skills, and open doors to new\n          opportunities.",
    th: "เป็นเวลากว่า <b>7 ปี </b> ที่ฉันหลงใหลในการเพิ่มศักยภาพ\n          นักเรียนทุกวัยเพื่อให้บรรลุเป้าหมายผ่านทางส่วนบุคคลและ\n          การศึกษาที่มีส่วนร่วม เส้นทางการสอนของฉันพาฉันไปทั่วกัมพูชา\n          และประเทศไทยที่ฉันสอน <b>ภาษาอังกฤษ</b> <b>การเขียนโปรแกรม</b>\n          และ<b>วิทยาการหุ่นยนต์</b> ระหว่างทาง ฉันได้ช่วยนักเรียนสร้าง\n          ความมั่นใจ พัฒนาทักษะที่จำเป็น และเปิดประตูสู่สิ่งใหม่ๆ\n          โอกาส.",
  },
  tr_exam_preperation: {
    en: "Exam Preperation",
    th: "การเตรียมสอบ",
  },
  tr_english_for_daily: {
    en: "English for Daily Life",
    th: "ภาษาอังกฤษเพื่อชีวิตประจำวัน",
  },
  tr_business_english_essentials: {
    en: "Business English Essentials",
    th: "สิ่งจำเป็นสำหรับภาษาอังกฤษธุรกิจ",
  },
  tr_conversational_english_practice: {
    en: "Conversational English Practice",
    th: "การฝึกสนทนาภาษาอังกฤษ",
  },
  tr_reading_phonics: {
    en: "Reading & Phonics for Young Learners",
    th: "การอ่านและการออกเสียงสำหรับผู้เรียนรุ่นเยาว์",
  },
  tr_it: {
    en: "IT",
    th: "มัน",
  },
  tr_from_scratch_to: {
    en: "From Scratch to Python, empowering young learners with essential IT skills.",
    th: "จาก Scratch ไปจนถึง Python เสริมศักยภาพผู้เรียนรุ่นเยาว์ด้วยทักษะไอทีที่จำเป็น",
  },
  tr_popular_english_lessons: {
    en: "Popular English Lessons",
    th: "บทเรียนภาษาอังกฤษยอดนิยม",
  },
  tr_refreshment_english: {
    en: "Refreshment English",
    th: "ภาษาอังกฤษเพื่อความสดชื่น",
  },
  tr_perfect_for_those: {
    en: "Perfect for those who’ve studied English but need a boost. Refresh your vocabulary, polish speaking skills, and regain fluency.",
    th: "เหมาะสำหรับผู้ที่เคยเรียนภาษาอังกฤษแต่ต้องการความช่วยเหลือเพิ่มเติม รีเฟรชคำศัพท์ ทักษะการพูดโปแลนด์ และฟื้นความคล่องแคล่ว",
  },
  tr_practice_language_that: {
    en: "Practice language that is relevant to your work context, so you can use what you learn in real work situations.",
    th: "ฝึกฝนภาษาที่เกี่ยวข้องกับบริบทในการทำงานของคุณ เพื่อให้คุณสามารถใช้สิ่งที่คุณเรียนรู้ในสถานการณ์การทำงานจริง",
  },
  tr_english_for_young: {
    en: "English for Young Learners",
    th: "ภาษาอังกฤษสำหรับผู้เรียนรุ่นเยาว์",
  },
  tr_fun_foundational_english: {
    en: "Fun, foundational English lessons using various tools to build core language skills through stories, activities, and exercises.",
    th: "บทเรียนภาษาอังกฤษขั้นพื้นฐานที่สนุกสนานโดยใช้เครื่องมือต่างๆ เพื่อสร้างทักษะภาษาหลักผ่านเรื่องราว กิจกรรม และแบบฝึกหัด",
  },
  tr_english_for_teenagers: {
    en: "English for Teenagers",
    th: "ภาษาอังกฤษสำหรับวัยรุ่น",
  },
  tr_engaging_relevant_conversations: {
    en: "Engaging, relevant conversations using Oxford Discover Futures to boost confidence and critical thinking for real-world English skills.",
    th: "บทสนทนาที่เกี่ยวข้องและมีส่วนร่วมโดยใช้ Oxford Discover Futures เพื่อเพิ่มความมั่นใจและการคิดอย่างมีวิจารณญาณสำหรับทักษะภาษาอังกฤษในโลกแห่งความเป็นจริง",
  },
  tr_grammar_essentials: {
    en: "Grammar Essentials",
    th: "สิ่งจำเป็นด้านไวยากรณ์",
  },
  tr_strengthen_your_english: {
    en: "Strengthen your English from the ground up with this focused course. Learn essential grammar rules, fix common mistakes, and gain confidence in writing.",
    th: "เสริมสร้างภาษาอังกฤษของคุณตั้งแต่พื้นฐานด้วยหลักสูตรที่มุ่งเน้นนี้ เรียนรู้กฎไวยากรณ์ที่สำคัญ แก้ไขข้อผิดพลาดทั่วไป และเพิ่มความมั่นใจในการเขียน",
  },
  tr_freshen_up_your: {
    en: "Freshen up Your Vocabulary",
    th: "เติมความสดชื่นให้กับคำศัพท์ของคุณ",
  },
  tr_play_wordle: {
    en: "Play Wordle",
    th: "เล่นเวิร์ด",
  },
  tr_popular_it_lessons: {
    en: "Popular IT Lessons",
    th: "บทเรียนไอทียอดนิยม",
  },
  tr_game_design_with: {
    en: "Game Design with Scratch",
    th: "การออกแบบเกมด้วย Scratch",
  },
  tr_learn_to_create: {
    en: "Learn to create your own games using Scratch! Perfect for beginners, this course teaches coding basics through fun, interactive projects.",
    th: "เรียนรู้การสร้างเกมของคุณเองโดยใช้ Scratch! เหมาะสำหรับผู้เริ่มต้น หลักสูตรนี้สอนพื้นฐานการเขียนโค้ดผ่านโปรเจ็กต์เชิงโต้ตอบที่สนุกสนาน",
  },
  tr_robotics_for_kids: {
    en: "Robotics for Kids",
    th: "หุ่นยนต์สำหรับเด็ก",
  },
  tr_dive_into_the: {
    en: "Dive into the world of robotics with hands-on projects! Kids will learn to design, build, and program robots, sparking their curiosity in STEM while having fun.",
    th: "ดำดิ่งสู่โลกแห่งหุ่นยนต์ด้วยโปรเจ็กต์ภาคปฏิบัติ! เด็กๆ จะได้เรียนรู้การออกแบบ สร้าง และเขียนโปรแกรมหุ่นยนต์ เพื่อจุดประกายความอยากรู้อยากเห็นใน STEM ในขณะที่สนุกสนาน",
  },
  tr_python_for_teenagers: {
    en: "Python for Teenagers",
    th: "Python สำหรับวัยรุ่น",
  },
  tr_designed_for_teens: {
    en: "Designed for teens, this course covers essential coding concepts through engaging projects, preparing them for more advanced challenges.",
    th: "หลักสูตรนี้ออกแบบมาสำหรับวัยรุ่น โดยครอบคลุมแนวคิดการเขียนโค้ดที่จำเป็นผ่านโปรเจ็กต์ที่น่าสนใจ เพื่อเตรียมพร้อมสำหรับความท้าทายขั้นสูงยิ่งขึ้น",
  },
  tr_miss_bow: {
    en: "Miss Bow",
    th: "คุณโบว์",
  },
  tr_regional_manager_pharmaceutical: {
    en: "Regional Manager, Pharmaceutical Trials Company",
    th: "ผู้จัดการประจำภูมิภาค บริษัท Pharmaceutical Trials",
  },
  tr_david_has_been_my_teacher: {
    en: "David has been my English teacher for over three years. His lessons are practical and focused on improving my professional communication. I feel much more confident speaking with international colleagues thanks to his support.",
    th: "เดวิดเป็นครูสอนภาษาอังกฤษของฉันมานานกว่าสามปี บทเรียนของเขาใช้ได้จริงและมุ่งเน้นไปที่การปรับปรุงการสื่อสารทางวิชาชีพของฉัน ฉันรู้สึกมั่นใจมากขึ้นในการพูดคุยกับเพื่อนร่วมงานต่างชาติด้วยการสนับสนุนของเขา",
  },
  tr_miss_ploy: {
    en: "Miss Ploy",
    th: "คุณพลอย",
  },
  tr_tv_show_host: {
    en: "TV Show Host",
    th: "พิธีกรรายการทีวี",
  },
  tr_working_with_david: {
    en: "Working with David has been a game-changer for my English. He helped me refine my speaking skills, making me more polished and confident during high-stakes interviews like those with Miss Universe candidates.",
    th: "การร่วมงานกับเดวิดเป็นตัวเปลี่ยนเกมภาษาอังกฤษของฉัน เขาช่วยฉันปรับปรุงทักษะการพูดของฉัน ทำให้ฉันขัดเกลาและมั่นใจมากขึ้นในระหว่างการสัมภาษณ์ที่มีเดิมพันสูงเหมือนกับการสัมภาษณ์ผู้เข้าประกวดมิสยูนิเวิร์ส",
  },
  tr_miss_kwan: {
    en: "Miss Kwan",
    th: "นางสาวขวัญ",
  },
  tr_business_owner: {
    en: "Business Owner",
    th: "เจ้าของธุรกิจ",
  },
  tr_david_guided_me: {
    en: "David guided me through the process of selecting the right classes for my studies in Singapore and continues to support me as I navigate my coursework. His expertise and encouragement have been invaluable.",
    th: "เดวิดแนะนำฉันตลอดกระบวนการเลือกชั้นเรียนที่เหมาะสมสำหรับการเรียนในสิงคโปร์ และยังคงสนับสนุนฉันต่อไปในขณะที่ฉันสำรวจรายวิชาของฉัน ความเชี่ยวชาญและการให้กำลังใจของเขามีค่าอันล้ำค่า",
  },
  tr_mr_brook_and: {
    en: "Mr Brook and Miss Nee",
    th: "คุณบรู๊คและคุณนี",
  },
  tr_david_prepared_us: {
    en: "David prepared us for our move to New Zealand, helping us improve our English skills and cultural understanding. Thanks to his practical lessons, we felt ready to communicate and adapt in a new country.",
    th: "เดวิดเตรียมเราให้พร้อมสำหรับการย้ายไปนิวซีแลนด์ โดยช่วยให้เราพัฒนาทักษะภาษาอังกฤษและความเข้าใจในวัฒนธรรม ด้วยบทเรียนเชิงปฏิบัติของเขา เราจึงรู้สึกว่าพร้อมที่จะสื่อสารและปรับตัวในประเทศใหม่",
  },
  tr_nodd: {
    en: "Nodd",
    th: "พยักหน้า",
  },
  tr_teacher_david_has: {
    en: "Teacher David has been my teacher for the past 5 years, and I’ve learned so much! He makes every lesson fun and exciting. I really like how he explains things clearly and helps me improve step by step.",
    th: "ครูเดวิดเป็นครูของฉันมา 5 ปีแล้ว และฉันได้เรียนรู้มากมาย! เขาทำให้ทุกบทเรียนสนุกและน่าตื่นเต้น ฉันชอบที่เขาอธิบายสิ่งต่าง ๆ อย่างชัดเจนและช่วยฉันปรับปรุงทีละขั้นตอน",
  },
  tr_mr_frank: {
    en: "Mr Frank",
    th: "นายแฟรงค์",
  },
  tr_lawyer: {
    en: "Lawyer",
    th: "ทนายความ",
  },
  tr_davids_refreshment_courses: {
    en: "David's refreshment courses have been perfect for keeping my conversational English sharp. As a lawyer, I need clarity and confidence in communication, and his lessons always deliver.",
    th: "หลักสูตรเติมความสดชื่นของ David ช่วยให้การสนทนาภาษาอังกฤษของฉันเฉียบแหลมได้อย่างสมบูรณ์แบบ ในฐานะทนายความ ฉันต้องการความชัดเจนและความมั่นใจในการสื่อสาร และบทเรียนของเขาก็ส่งมอบได้เสมอ",
  },
  tr_miss_bam: {
    en: "Miss Bam",
    th: "คุณแบม",
  },
  tr_nodds_mother: {
    en: "Nodd's mother",
    th: "แม่ของพยักหน้า",
  },
  tr_david_has_been_fantastic: {
    en: "David has been a fantastic teacher for my daughter, Nodd. His dedication and patience over the years have really helped Nodd grow, not just in language skills but also in confidence. We’re so grateful for his guidance.",
    th: "เดวิดเป็นครูที่ยอดเยี่ยมสำหรับลูกสาวของฉัน น็อดด์ ความทุ่มเทและความอดทนของเขาตลอดหลายปีที่ผ่านมาช่วยให้ Nodd เติบโตขึ้นจริงๆ ไม่ใช่แค่ทักษะทางภาษาเท่านั้น แต่ยังรวมถึงความมั่นใจด้วย เรารู้สึกขอบคุณมากสำหรับคำแนะนำของเขา",
  },
  tr_pugan: {
    en: "Pugan",
    th: "พูแกน",
  },
  tr_teacher_david_is: {
    en: "Teacher David is super kind and fun! I’ve been learning with him for a year, and I love the games and stories he uses to help me learn English.",
    th: "ครูเดวิดใจดีและสนุกสุด ๆ ! ฉันเรียนกับเขามาหนึ่งปีแล้ว และฉันชอบเกมและเรื่องราวที่เขาใช้เพื่อช่วยฉันเรียนภาษาอังกฤษ",
  },
  tr_khim: {
    en: "Khim",
    th: "คิม",
  },
  tr_student: {
    en: "Student",
    th: "นักเรียน",
  },
  tr_teacher_davids_lessons: {
    en: "Teacher David’s lessons are always engaging and helpful. He explains everything so well, and I’ve seen great improvement in my English. I even invited my friend to join because I enjoy learning with him so much!",
    th: "บทเรียนของครูเดวิดมีส่วนร่วมและช่วยเหลือเสมอ เขาอธิบายทุกอย่างได้ดีมาก และฉันก็เห็นการพัฒนาภาษาอังกฤษของฉันดีขึ้นมาก ฉันยังชวนเพื่อนมาเข้าร่วมด้วยเพราะฉันสนุกกับการเรียนกับเขามาก!",
  },
  tr_what_people_say: {
    en: "What People Say About Teacher David",
    th: "สิ่งที่ผู้คนพูดถึงเกี่ยวกับครูเดวิด",
  },
  tr_popular_courses: {
    en: "Popular Courses",
    th: "หลักสูตรยอดนิยม",
  },
  tr_1_hour_free: {
    en: "1 hour free trial",
    th: "ทดลองใช้ฟรี 1 ชั่วโมง",
  },
  tr_pay_on_salary: {
    en: "Pay on Salary",
    th: "จ่ายเงินเดือน",
  },
  tr_postpone_up_to: {
    en: "Postpone up to 3 times",
    th: "เลื่อนได้ถึง 3 ครั้ง",
  },
  tr_free_digital_book: {
    en: "Free Digital Book",
    th: "หนังสือดิจิทัลฟรี",
  },
  tr_looking_for_something: {
    en: "Looking for something else?",
    th: "กำลังมองหาสิ่งอื่นอยู่ใช่ไหม?",
  },
  tr_get_a_quote: {
    en: "Get a Quote",
    th: "รับใบเสนอราคา",
  },
  tr_about: {
    en: "about",
    th: "เกี่ยวกับ.",
  },
  tr_welcome_im_teacher: {
    en: "Welcome! I’m Teacher David.",
    th: "ยินดีต้อนรับ! ฉันชื่อครูเดวิด",
  },
  tr_what_i_do: {
    en: "What I Do",
    th: "สิ่งที่ฉันทำ",
  },
  tr_i_specialize_in: {
    en: "I specialize in teaching English language skills to a wide variety of students",
    th: "ฉันเชี่ยวชาญในการสอนทักษะภาษาอังกฤษให้กับนักเรียนที่หลากหลาย",
  },
  tr_young_learners: {
    en: "Young Learners",
    th: "ผู้เรียนรุ่นเยาว์",
  },
  tr_building_strong_foundations: {
    en: "Building strong foundations through fun, interactive methods.",
    th: "สร้างรากฐานที่แข็งแกร่งด้วยวิธีการที่สนุกสนานและโต้ตอบได้",
  },
  tr_teenagers: {
    en: "Teenagers",
    th: "วัยรุ่น",
  },
  tr_guiding_them_to: {
    en: "Guiding them to excel academically and socially in English.",
    th: "ชี้แนะให้พวกเขาเก่งทั้งในด้านวิชาการและสังคมในด้านภาษาอังกฤษ",
  },
  tr_professionals: {
    en: "Professionals",
    th: "ผู้เชี่ยวชาญ",
  },
  tr_helping_business_leaders: {
    en: "Helping business leaders, managers, and employees improve communication and succeed in international environments.",
    th: "ช่วยให้ผู้นำธุรกิจ ผู้จัดการ และพนักงานปรับปรุงการสื่อสารและประสบความสำเร็จในสภาพแวดล้อมระหว่างประเทศ",
  },
  tr_global_aspirants: {
    en: "Global Aspirants",
    th: "ผู้ปรารถนาระดับโลก",
  },
  tr_preparing_students_for: {
    en: "Preparing students for life-changing opportunities, from studying abroad to clearing English proficiency exams.",
    th: "การเตรียมนักเรียนให้พร้อมสำหรับโอกาสที่เปลี่ยนแปลงชีวิต ตั้งแต่การเรียนในต่างประเทศไปจนถึงการสอบวัดความรู้ภาษาอังกฤษ",
  },
  tr_creating_a_need: {
    en: "Creating a Need to Communicate",
    th: "การสร้างความต้องการในการสื่อสาร",
  },
  tr_i_design_lessons: {
    en: "I design lessons that encourage students to actively use English, focusing on real-world scenarios and meaningful interactions.",
    th: "ฉันออกแบบบทเรียนที่ส่งเสริมให้นักเรียนใช้ภาษาอังกฤษอย่างกระตือรือร้น โดยเน้นไปที่สถานการณ์จริงและการโต้ตอบที่มีความหมาย",
  },
  tr_custom_materials_and: {
    en: "Custom Materials and Methods",
    th: "วัสดุและวิธีการที่กำหนดเอง",
  },
  tr_i_develop_personalized: {
    en: "I develop personalized materials and adapt my teaching style to suit each student’s goals, pace, and learning preferences.",
    th: "ฉันพัฒนาสื่อการเรียนการสอนที่เหมาะกับแต่ละบุคคลและปรับรูปแบบการสอนให้เหมาะกับเป้าหมาย จังหวะ และความชอบในการเรียนรู้ของนักเรียนแต่ละคน",
  },
  tr_bilingual_support: {
    en: "Bilingual Support",
    th: "การสนับสนุนสองภาษา",
  },
  tr_for_thai_speaking_learners: {
    en: "For Thai-speaking learners, I offer bilingual assistance to build confidence and bridge language gaps.",
    th: "สำหรับผู้เรียนที่พูดภาษาไทย ฉันให้ความช่วยเหลือแบบสองภาษาเพื่อสร้างความมั่นใจและลดช่องว่างทางภาษา",
  },
  tr_child_centered_learning: {
    en: "Child-Centered Learning",
    th: "การเรียนรู้ที่เน้นเด็กเป็นศูนย์กลาง",
  },
  tr_for_young_learners: {
    en: "For young learners, I prioritize building a strong foundation in vocabulary, listening, and speaking over traditional grammar and reading-heavy methods.",
    th: "สำหรับผู้เรียนรุ่นเยาว์ ฉันให้ความสำคัญกับการสร้างรากฐานที่แข็งแกร่งในด้านคำศัพท์ การฟัง และการพูด มากกว่าไวยากรณ์แบบดั้งเดิมและวิธีการอ่านที่หนักหน่วง",
  },
  tr_integrating_technology: {
    en: "Integrating Technology",
    th: "การบูรณาการเทคโนโลยี",
  },
  tr_i_incorporate_modern: {
    en: "I incorporate modern tools and resources to make lessons interactive, engaging, and effective.",
    th: "ฉันรวมเครื่องมือและแหล่งข้อมูลที่ทันสมัยเพื่อทำให้บทเรียนมีการโต้ตอบ น่าสนใจ และมีประสิทธิภาพ",
  },
  tr_cultural_connection: {
    en: "Cultural Connection",
    th: "การเชื่อมต่อทางวัฒนธรรม",
  },
  tr_my_lessons_link: {
    en: "My lessons link language to culture, offering deeper understanding and practical context.",
    th: "บทเรียนของฉันเชื่อมโยงภาษาเข้ากับวัฒนธรรม ทำให้มีความเข้าใจที่ลึกซึ้งยิ่งขึ้นและบริบทที่เป็นประโยชน์",
  },
  tr_supportive_coaching: {
    en: "Supportive Coaching",
    th: "การฝึกสอนแบบสนับสนุน",
  },
  tr_i_provide_clear: {
    en: "I provide clear explanations, constructive feedback, and ongoing encouragement to keep students motivated and progressing.",
    th: "ฉันให้คำอธิบายที่ชัดเจน ข้อเสนอแนะที่สร้างสรรค์ และให้กำลังใจอย่างต่อเนื่องเพื่อให้นักเรียนมีแรงบันดาลใจและก้าวหน้า",
  },
  tr_my_philosophy: {
    en: "My Philosophy",
    th: "ปรัชญาของฉัน",
  },
  tr_get_students_talking: {
    en: '"Get students talking — the rest can wait."',
    th: "ให้นักเรียนพูด ที่เหลือรอได้",
  },
  tr_my_teaching_style: {
    en: "My Teaching Style",
    th: "รูปแบบการสอนของฉัน",
  },
  tr_i_believe_learning: {
    en: "I believe learning should be engaging, practical, and tailored to each student’s needs. My teaching style focuses on creating an environment where students feel the <b>need</b> to speak, making language  natural part of their daily lives.",
    th: "ฉันเชื่อว่าการเรียนรู้ควรมีส่วนร่วม นำไปใช้ได้จริง และปรับให้เหมาะกับความต้องการของนักเรียนแต่ละคน รูปแบบการสอนของฉันมุ่งเน้นไปที่การสร้างสภาพแวดล้อมที่นักเรียนรู้สึกว่า <b>จำเป็น</b> ที่จะพูด ทำให้ภาษาเป็นส่วนหนึ่งของชีวิตประจำวันของพวกเขา",
  },
  tr_monthly_subscriptions: {
    en: "Monthly Subscriptions",
    th: "การสมัครสมาชิกรายเดือน",
  },
  tr_all_classes_are: {
    en: "All Classes are private lessons. No AI, or Video Recordings involved.",
    th: "ชั้นเรียนทั้งหมดเป็นบทเรียนส่วนตัว นักเรียนแบ่งเฉพาะค่าเล่าเรียนเท่านั้น",
  },
  tr_2_hours: {
    en: "2 hours / week",
    th: "2 ชั่วโมง/สัปดาห์",
  },
  tr_one_time_payment: {
    en: "One-Time Payment",
    th: "การชำระเงินแบบครั้งเดียว",
  },
  tr_1_student: {
    en: "1 student",
    th: "นักเรียน 1 คน",
  },
  tr_4_hours: {
    en: "4 hours / week",
    th: "4 ชั่วโมง/สัปดาห์",
  },
  tr_up_to_2: {
    en: "Up to 2  students",
    th: "นักเรียนได้ถึง 2 คน",
  },
  tr_6_hours: {
    en: "6 hours / week + 1 hr",
    th: "6 ชั่วโมง/สัปดาห์ + 1 ชม",
  },
  tr_2_payment_installments: {
    en: "2 payment installments",
    th: "ผ่อนชำระ 2 งวด",
  },
  tr_up_to_3: {
    en: "Up to 3 students",
    th: "นักเรียนได้ถึง 3 คน",
  },
  tr_8_hours: {
    en: "8 hours / week + 1 hr",
    th: "8 ชั่วโมง/สัปดาห์ + 1 ชม",
  },
  tr_3_payment_installments: {
    en: "3 payment installments",
    th: "ผ่อนชำระ 3 งวด",
  },
  tr_up_to_4: {
    en: "Up to 4 students",
    th: "นักเรียนได้ถึง 4 คน",
  },
  tr_10_hours: {
    en: "10 hours / week + 2 hr",
    th: "10 ชั่วโมง/สัปดาห์ + 2 ชม",
  },
  tr_up_to_5: {
    en: "Up to 5 students",
    th: "นักเรียนได้ถึง 5 คน",
  },
  tr_15_hours: {
    en: "15 hours / week + 3 hr",
    th: "15 ชั่วโมง/สัปดาห์ + 3 ชม",
  },
  tr_up_to_6: {
    en: "Up to 6 students",
    th: "นักเรียนได้ถึง 6 คน",
  },
  tr_2_dedicated_teachers: {
    en: "2 dedicated teachers",
    th: "ครูผู้อุทิศตน 2 คน",
  },
  tr_20_hours: {
    en: "20 hours / week + 3 hr",
    th: "20 ชั่วโมง/สัปดาห์ + 3 ชม",
  },
  tr_up_to_7: {
    en: "Up to 7 students",
    th: "นักเรียนมากถึง 7 คน",
  },
  tr_4_dedicated_teachers: {
    en: "4 dedicated teachers",
    th: "ครูผู้อุทิศตน 4 คน",
  },
  tr_25_hours: {
    en: "25 hours / week + 4 hr",
    th: "25 ชั่วโมง/สัปดาห์ + 4 ชม",
  },
  tr_4_payment_installments: {
    en: "4 payment installments",
    th: "ผ่อนชำระ 4 งวด",
  },
  tr_up_to_15: {
    en: "Up to 15 students",
    th: "นักเรียนมากถึง 15 คน",
  },
  tr_5_dedicated_teachers: {
    en: "5 dedicated teachers",
    th: "ครูผู้อุทิศตน 5 คน",
  },
  tr_popular_private_courses: {
    en: "Popular Private Courses",
    th: "หลักสูตรส่วนตัวยอดนิยม",
  },
  tr_years_of_teaching: {
    en: "Years of teaching experience",
    th: "ประสบการณ์การสอนหลายปี",
  },
  tr_online_private_students: {
    en: "Online Private Students",
    th: "นักเรียนเอกชนออนไลน์",
  },
  tr_hours_of_online: {
    en: "Hours of Online Private Lessons",
    th: "ชั่วโมงของบทเรียนส่วนตัวออนไลน์",
  },
  tr_professionals_coached: {
    en: "Professionals Coached",
    th: "ผู้เชี่ยวชาญฝึกสอน",
  },
  tr_teaching_materials: {
    en: "Teaching Materials",
    th: "สื่อการสอน",
  },
  tr_contact: {
    en: "contact",
    th: "ติดต่อ.",
  },
  tr_get_in_touch: {
    en: "Get in touch with me via social media or email.",
    th: "ติดต่อฉันผ่านโซเชียลมีเดียหรืออีเมล",
  },
  tr_linkedin: {
    en: "LinkedIn",
    th: "ลิงค์อิน",
  },
  tr_instagram: {
    en: "Instagram",
    th: "อินสตาแกรม",
  },
  tr_facebook: {
    en: "Facebook",
    th: "เฟสบุ๊ค",
  },
  tr_line: {
    en: "Line",
    th: "เส้น",
  },
  tr_send_me_an: {
    en: "Send me an email",
    th: "ส่งอีเมลถึงฉัน",
  },
  tr_name: {
    en: "Name",
    th: "ชื่อ",
  },
  tr_email: {
    en: "Email",
    th: "อีเมล",
  },
  tr_message: {
    en: "Message",
    th: "ข้อความ",
  },
  tr_overview: {
    en: "Overview",
    th: "ภาพรวม",
  },
  tr_the_refreshment_english: {
    en: "The Refreshment English Course is designed for individuals who already have a foundation in English but seek to enhance their skills, refresh their knowledge, and regain confidence in everyday communication. Whether you’ve been out of practice or simply want to polish your abilities, this course is tailored to meet your needs in a structured yet flexible way.",
    th: "หลักสูตร Refreshment English ได้รับการออกแบบมาสำหรับบุคคลที่มีพื้นฐานภาษาอังกฤษอยู่แล้ว แต่พยายามพัฒนาทักษะ ฟื้นฟูความรู้ และฟื้นความมั่นใจในการสื่อสารในชีวิตประจำวัน ไม่ว่าคุณจะไม่ได้ฝึกฝนหรือเพียงต้องการขัดเกลาความสามารถ หลักสูตรนี้ได้รับการออกแบบมาเพื่อตอบสนองความต้องการของคุณในรูปแบบที่มีโครงสร้างแต่มีความยืดหยุ่น",
  },
  tr_key_features: {
    en: "Key Features",
    th: "คุณสมบัติที่สำคัญ",
  },
  tr_who_is_it: {
    en: "Who is it for?",
    th: "มันมีไว้สำหรับใคร?",
  },
  tr_professionals_looking_to: {
    en: "Professionals looking to improve their workplace communication.",
    th: "ผู้เชี่ยวชาญที่ต้องการปรับปรุงการสื่อสารในที่ทำงาน",
  },
  tr_individuals_preparing_for: {
    en: "Individuals preparing for international travel or relocation.",
    th: "บุคคลที่เตรียมตัวเดินทางระหว่างประเทศหรือย้ายที่อยู่",
  },
  tr_students_returning_to: {
    en: "Students returning to academic studies that require English proficiency",
    th: "นักเรียนที่กลับมาเรียนวิชาการที่ต้องใช้ภาษาอังกฤษ",
  },
  tr_anyone_who_wants_brush: {
    en: "Anyone who wants to brush up on grammar, vocabulary, and conversational skills.",
    th: "ใครก็ตามที่ต้องการทบทวนทักษะด้านไวยากรณ์ คำศัพท์ และการสนทนา",
  },
  tr_customized_lessons: {
    en: "Customized Lessons",
    th: "บทเรียนที่กำหนดเอง",
  },
  tr_each_lesson_is: {
    en: "Each lesson is tailored to suit your current skill level and goals, ensuring a personalized learning experience.",
    th: "แต่ละบทเรียนได้รับการปรับแต่งให้เหมาะสมกับระดับทักษะและเป้าหมายในปัจจุบันของคุณ เพื่อให้มั่นใจว่าจะได้รับประสบการณ์การเรียนรู้ส่วนบุคคล",
  },
  tr_practical_topics: {
    en: "Practical Topics",
    th: "หัวข้อการปฏิบัติ",
  },
  tr_the_course_focuses: {
    en: "The course focuses on real-life scenarios, such as",
    th: "หลักสูตรนี้เน้นไปที่สถานการณ์ในชีวิตจริง เช่น",
  },
  tr_professional_communication_emails: {
    en: "Professional communication (emails, presentations, meetings).",
    th: "การสื่อสารอย่างมืออาชีพ (อีเมล การนำเสนอ การประชุม)",
  },
  tr_travel_and_daily: {
    en: "Travel and daily interactions (asking for directions, dining out, small talk).",
    th: "การเดินทางและการมีปฏิสัมพันธ์ในชีวิตประจำวัน (การถามเส้นทาง การรับประทานอาหารนอกบ้าน การพูดคุยเล็กๆ น้อยๆ)",
  },
  tr_academic_writing_and: {
    en: "Academic writing and speaking.",
    th: "การเขียนและการพูดเชิงวิชาการ",
  },
  tr_skill_integration: {
    en: "Skill Integration",
    th: "การบูรณาการทักษะ",
  },
  tr_each_session_integrates: {
    en: "Each session integrates speaking, listening, reading, and writing activities to reinforce your skills holistically.",
    th: "แต่ละเซสชั่นผสมผสานกิจกรรมการพูด การฟัง การอ่าน และการเขียนเพื่อเสริมทักษะของคุณแบบองค์รวม",
  },
  tr_flexible_scheduling: {
    en: "Flexible Scheduling",
    th: "การจัดตารางเวลาที่ยืดหยุ่น",
  },
  tr_choose_class_timings: {
    en: "Choose class timings that fit your busy lifestyle, with options for both fixed and dynamic schedules.",
    th: "เลือกกำหนดเวลาเรียนที่เหมาะกับไลฟ์สไตล์ที่ยุ่งวุ่นวายของคุณ โดยมีตัวเลือกสำหรับทั้งกำหนดการแบบตายตัวและแบบไดนามิก",
  },
  tr_engage_in_role_playing: {
    en: "Engage in role-playing, group discussions, and interactive exercises to make learning enjoyable and practical.",
    th: "มีส่วนร่วมในการแสดงบทบาทสมมติ การอภิปรายกลุ่ม และแบบฝึกหัดเชิงโต้ตอบเพื่อให้การเรียนรู้สนุกสนานและนำไปใช้ได้จริง",
  },
  tr_what_youll_learn: {
    en: "What You’ll Learn",
    th: "สิ่งที่คุณจะได้เรียนรู้",
  },
  tr_refresh_and_refine: {
    en: "Refresh and refine your grammar skills.",
    th: "รีเฟรชและปรับปรุงทักษะไวยากรณ์ของคุณ",
  },
  tr_expand_your_vocabulary: {
    en: "Expand your vocabulary with relevant and modern expressions.",
    th: "ขยายคำศัพท์ของคุณด้วยสำนวนที่เกี่ยวข้องและทันสมัย",
  },
  tr_enhance_fluency_in: {
    en: "Enhance fluency in speaking and listening.",
    th: "เพิ่มความคล่องแคล่วในการพูดและการฟัง",
  },
  tr_build_confidence_for: {
    en: "Build confidence for public speaking, interviews, and social interactions.",
    th: "สร้างความมั่นใจในการพูดในที่สาธารณะ การสัมภาษณ์ และปฏิสัมพันธ์ทางสังคม",
  },
  tr_master_clear_and: {
    en: "Master clear and effective written communication.",
    th: "เชี่ยวชาญการสื่อสารที่เป็นลายลักษณ์อักษรที่ชัดเจนและมีประสิทธิภาพ",
  },
  tr_why_choose_the: {
    en: "Why Choose the Refreshment English Course?",
    th: "เหตุใดจึงเลือกหลักสูตรภาษาอังกฤษเพื่อความสดชื่น",
  },
  tr_personalized_teaching_methods: {
    en: "Personalized teaching methods to target specific areas of improvement.",
    th: "วิธีการสอนแบบเฉพาะบุคคลเพื่อกำหนดเป้าหมายการพัฒนาเฉพาะด้าน",
  },
  tr_interactive_and_fun: {
    en: "Interactive and fun learning environment to make progress feel effortless.",
    th: "สภาพแวดล้อมการเรียนรู้แบบโต้ตอบและสนุกสนานเพื่อทำให้ความคืบหน้าเป็นเรื่องง่าย",
  },
  tr_expert_instructors_with: {
    en: "Expert instructors with years of experience in teaching English to non-native speakers.",
    th: "อาจารย์ผู้สอนผู้เชี่ยวชาญที่มีประสบการณ์หลายปีในการสอนภาษาอังกฤษให้กับผู้ที่ไม่ใช่เจ้าของภาษา",
  },
  tr_a_focus_on: {
    en: "A focus on building confidence and real-world application.",
    th: "มุ่งเน้นที่การสร้างความมั่นใจและการประยุกต์ใช้ในโลกแห่งความเป็นจริง",
  },
  tr_custom_courses: {
    en: "Custom Courses",
    th: "หลักสูตรที่กำหนดเอง",
  },
  tr_take_the_next: {
    en: "Take the Next Step",
    th: "ทำตามขั้นตอนต่อไป",
  },
  tr_join_the_refreshment: {
    en: "Join the Refreshment English Course today and regain your edge in English communication. Whether for personal growth, career advancement, or preparing for a new adventure, this course will equip you with the skills you need to thrive.",
    th: "เข้าร่วมหลักสูตร Refreshment English วันนี้และเพิ่มความได้เปรียบในการสื่อสารภาษาอังกฤษ ไม่ว่าจะเป็นการเติบโตส่วนบุคคล ความก้าวหน้าในอาชีพ หรือการเตรียมพร้อมสำหรับการผจญภัยครั้งใหม่ หลักสูตรนี้จะช่วยให้คุณมีทักษะที่จำเป็นเพื่อการเติบโต",
  },
  tr_contact_us_now: {
    en: "Contact us now to book a free trial class!",
    th: "ติดต่อเราตอนนี้เพื่อจองคลาสทดลองเรียนฟรี!",
  },
  tr_the_english_for_daily: {
    en: "The English for Daily Life course is perfect for individuals who want to enhance their communication skills for everyday situations. From casual conversations to handling day-to-day tasks confidently, this course ensures you are equipped to use English comfortably in real-life scenarios.",
    th: "หลักสูตรภาษาอังกฤษเพื่อชีวิตประจำวันเหมาะสำหรับบุคคลที่ต้องการพัฒนาทักษะการสื่อสารในสถานการณ์ประจำวัน ตั้งแต่การสนทนาแบบสบายๆ ไปจนถึงการจัดการงานในแต่ละวันอย่างมั่นใจ หลักสูตรนี้ช่วยให้คุณมั่นใจได้ว่าคุณจะพร้อมที่จะใช้ภาษาอังกฤษอย่างสะดวกสบายในสถานการณ์ในชีวิตจริง",
  },
  tr_beginners_looking_to: {
    en: "Beginners looking to establish practical English skills.",
    th: "ผู้เริ่มต้นที่ต้องการสร้างทักษะภาษาอังกฤษเชิงปฏิบัติ",
  },
  tr_intermediate_learners_wanting: {
    en: "Intermediate learners wanting to boost their fluency in daily contexts.",
    th: "ผู้เรียนระดับกลางที่ต้องการเพิ่มความคล่องแคล่วในบริบทรายวัน",
  },
  tr_travelers_preparing_for: {
    en: "Travelers preparing for international trips or relocation.",
    th: "นักท่องเที่ยวที่เตรียมตัวเดินทางระหว่างประเทศหรือย้ายที่อยู่",
  },
  tr_anyone_who_wants_feel: {
    en: "Anyone who wants to feel more confident using English in casual and functional settings.",
    th: "ใครก็ตามที่ต้องการรู้สึกมั่นใจมากขึ้นในการใช้ภาษาอังกฤษในสภาพแวดล้อมที่เป็นกันเองและมีประโยชน์ใช้สอย",
  },
  tr_the_course_emphasizes: {
    en: "The course emphasizes real-world scenarios like",
    th: "หลักสูตรนี้เน้นถึงสถานการณ์ในโลกแห่งความเป็นจริง เช่น",
  },
  tr_shopping_and_handling: {
    en: "Shopping and handling transactions.",
    th: "การซื้อและการจัดการธุรกรรม",
  },
  tr_ordering_food_at: {
    en: "Ordering food at restaurants or cafes.",
    th: "การสั่งอาหารที่ร้านอาหารหรือร้านกาแฟ",
  },
  tr_introducing_yourself_and: {
    en: "Introducing yourself and meeting new people.",
    th: "การแนะนำตัวเองและพบปะผู้คนใหม่ๆ",
  },
  tr_making_appointments_or: {
    en: "Making appointments or reservations.",
    th: "การนัดหมายหรือการจอง",
  },
  tr_asking_for_directions: {
    en: "Asking for directions and transportation-related conversations.",
    th: "การถามเส้นทางและการสนทนาที่เกี่ยวข้องกับการคมนาคม",
  },
  tr_role_playing_dialogues_and: {
    en: "Role-playing, dialogues, and interactive activities simulate real-life situations.",
    th: "การสวมบทบาท บทสนทนา และกิจกรรมเชิงโต้ตอบจำลองสถานการณ์ในชีวิตจริง",
  },
  tr_pronunciation_practice: {
    en: "Pronunciation Practice",
    th: "การฝึกออกเสียง",
  },
  tr_gain_confidence_in_speaking: {
    en: "Gain confidence in speaking clearly and understanding native speakers.",
    th: "เพิ่มความมั่นใจในการพูดอย่างชัดเจนและเข้าใจเจ้าของภาษา",
  },
  tr_tailored_content_to: {
    en: "Tailored content to match your skill level and personal objectives.",
    th: "เนื้อหาที่ได้รับการปรับแต่งให้ตรงกับระดับทักษะและวัตถุประสงค์ส่วนตัวของคุณ",
  },
  tr_improve_conversational_fluency: {
    en: "Improve conversational fluency for everyday interactions.",
    th: "ปรับปรุงความคล่องแคล่วในการสนทนาสำหรับการโต้ตอบในชีวิตประจำวัน",
  },
  tr_expand_vocabulary_for: {
    en: "Expand vocabulary for common situations like shopping, dining, and traveling.",
    th: "ขยายคำศัพท์สำหรับสถานการณ์ทั่วไป เช่น การช็อปปิ้ง การรับประทานอาหาร และการเดินทาง",
  },
  tr_understand_and_use: {
    en: "Understand and use common expressions and idioms.",
    th: "ทำความเข้าใจและใช้สำนวนและสำนวนทั่วไป",
  },
  tr_build_confidence_to: {
    en: "Build confidence to engage with native and non-native English speakers.",
    th: "สร้างความมั่นใจในการมีส่วนร่วมกับเจ้าของภาษาและไม่ใช่เจ้าของภาษา",
  },
  tr_learn_cultural_tips: {
    en: "Learn cultural tips for more effective communication.",
    th: "เรียนรู้เคล็ดลับทางวัฒนธรรมเพื่อการสื่อสารที่มีประสิทธิภาพมากขึ้น",
  },
  tr_learn_english_thats: {
    en: "Learn English that’s immediately applicable in real-life situations.",
    th: "เรียนภาษาอังกฤษที่นำไปใช้ในสถานการณ์จริงได้ทันที",
  },
  tr_build_confidence_with: {
    en: "Build confidence with engaging and interactive lessons.",
    th: "สร้างความมั่นใจด้วยบทเรียนเชิงโต้ตอบและมีส่วนร่วม",
  },
  tr_focus_on_improving: {
    en: "Focus on improving speaking and listening skills for practical communication.",
    th: "มุ่งเน้นการพัฒนาทักษะการพูดและการฟังเพื่อการสื่อสารเชิงปฏิบัติ",
  },
  tr_get_personalized_feedback_improvement: {
    en: "Get personalized feedback to ensure consistent improvement.",
    th: "รับคำติชมส่วนบุคคลเพื่อให้แน่ใจว่ามีการปรับปรุงอย่างต่อเนื่อง",
  },
  tr_why_choose_english: {
    en: "Why Choose English for Daily Life?",
    th: "ทำไมถึงเลือกภาษาอังกฤษในชีวิตประจำวัน?",
  },
  tr_enroll_in_the_daily: {
    en: "Enroll in the English for Daily Life course today and start using English with confidence in your everyday activities. Whether you’re a beginner or looking to refine your skills, this course will help you achieve your communication goals.",
    th: "สมัครเรียนหลักสูตรภาษาอังกฤษเพื่อชีวิตประจำวันวันนี้และเริ่มใช้ภาษาอังกฤษอย่างมั่นใจในกิจกรรมประจำวันของคุณ ไม่ว่าคุณจะเป็นมือใหม่หรือต้องการฝึกฝนทักษะ หลักสูตรนี้จะช่วยให้คุณบรรลุเป้าหมายการสื่อสาร",
  },
  tr_the_business_english: {
    en: "The Business English course is tailored for professionals looking to enhance their communication skills in corporate and professional environments. Whether you’re preparing for meetings, delivering presentations, or writing emails, this course will equip you with the tools to communicate effectively and confidently in the workplace.",
    th: "หลักสูตรภาษาอังกฤษธุรกิจได้รับการออกแบบมาสำหรับผู้ประกอบอาชีพที่ต้องการพัฒนาทักษะการสื่อสารในสภาพแวดล้อมขององค์กรและในสายอาชีพ ไม่ว่าคุณจะเตรียมการประชุม นำเสนอผลงาน หรือเขียนอีเมล หลักสูตรนี้จะจัดเตรียมเครื่องมือในการสื่อสารอย่างมีประสิทธิภาพและมั่นใจในที่ทำงาน",
  },
  tr_professionals_aiming_to: {
    en: "Professionals aiming to improve their workplace communication.",
    th: "ผู้เชี่ยวชาญที่มุ่งหวังที่จะปรับปรุงการสื่อสารในที่ทำงานของตน",
  },
  tr_job_seekers_preparing: {
    en: "Job seekers preparing for interviews or enhancing their resumes.",
    th: "ผู้หางานกำลังเตรียมตัวสัมภาษณ์หรือปรับปรุงเรซูเม่ของตน",
  },
  tr_managers_and_team: {
    en: "Managers and team leaders needing better presentation and negotiation skills.",
    th: "ผู้จัดการและผู้นำทีมที่ต้องการทักษะการนำเสนอและการเจรจาที่ดีขึ้น",
  },
  tr_entrepreneurs_seeking_to: {
    en: "Entrepreneurs seeking to communicate effectively with international clients and partners.",
    th: "ผู้ประกอบการที่ต้องการสื่อสารอย่างมีประสิทธิภาพกับลูกค้าและหุ้นส่วนต่างประเทศ",
  },
  tr_professional_scenarios: {
    en: "Professional Scenarios",
    th: "สถานการณ์ระดับมืออาชีพ",
  },
  tr_practice_business_related_tasks: {
    en: "Practice business-related tasks like",
    th: "ฝึกฝนงานที่เกี่ยวข้องกับธุรกิจเช่น",
  },
  tr_delivering_effective_presentations: {
    en: "Delivering effective presentations.",
    th: "การนำเสนอผลงานที่มีประสิทธิภาพ",
  },
  tr_participating_in_meetings: {
    en: "Participating in meetings and discussions.",
    th: "การมีส่วนร่วมในการประชุมและการอภิปราย",
  },
  tr_negotiating_deals_and: {
    en: "Negotiating deals and resolving conflicts.",
    th: "การเจรจาข้อตกลงและการแก้ไขข้อขัดแย้ง",
  },
  tr_writing_professional_emails: {
    en: "Writing professional emails and reports.",
    th: "การเขียนอีเมลและรายงานอย่างมืออาชีพ",
  },
  tr_networking_and_building: {
    en: "Networking and building professional relationships.",
    th: "การสร้างเครือข่ายและการสร้างความสัมพันธ์ทางวิชาชีพ",
  },
  tr_vocabulary_expansion: {
    en: "Vocabulary Expansion",
    th: "การขยายคำศัพท์",
  },
  tr_learn_industry_specific_vocabulary: {
    en: "Learn industry-specific vocabulary and professional jargon.",
    th: "เรียนรู้คำศัพท์เฉพาะอุตสาหกรรมและศัพท์เฉพาะทางวิชาชีพ",
  },
  tr_role_playing_activities: {
    en: "Role-Playing Activities",
    th: "กิจกรรมเล่นตามบทบาท",
  },
  tr_simulate_real_workplace: {
    en: "Simulate real workplace situations for hands-on practice.",
    th: "จำลองสถานการณ์ในที่ทำงานจริงเพื่อการฝึกฝนจริง",
  },
  tr_personalized_curriculum: {
    en: "Personalized Curriculum",
    th: "หลักสูตรส่วนบุคคล",
  },
  tr_content_tailored_to: {
    en: "Content tailored to your industry and role.",
    th: "เนื้อหาที่ปรับให้เหมาะกับอุตสาหกรรมและบทบาทของคุณ",
  },
  tr_master_professional_communication: {
    en: "Master professional communication skills for the workplace.",
    th: "ทักษะการสื่อสารระดับมืออาชีพสำหรับสถานที่ทำงาน",
  },
  tr_build_confidence_in: {
    en: "Build confidence in giving presentations and participating in meetings.",
    th: "สร้างความมั่นใจในการนำเสนอและเข้าร่วมการประชุม",
  },
  tr_learn_to_write: {
    en: "Learn to write clear and concise business documents.",
    th: "เรียนรู้การเขียนเอกสารทางธุรกิจที่ชัดเจนและรัดกุม",
  },
  tr_develop_strategies_for: {
    en: "Develop strategies for effective cross-cultural communication.",
    th: "พัฒนากลยุทธ์สำหรับการสื่อสารข้ามวัฒนธรรมที่มีประสิทธิภาพ",
  },
  tr_gain_confidence_in_networking: {
    en: "Gain confidence in networking and professional interactions.",
    th: "เพิ่มความมั่นใจในการสร้างเครือข่ายและการมีปฏิสัมพันธ์อย่างมืออาชีพ",
  },
  tr_why_choose_business: {
    en: "Why Choose Business English?",
    th: "ทำไมถึงเลือกภาษาอังกฤษธุรกิจ?",
  },
  tr_focus_on_practical: {
    en: "Focus on practical communication skills that are immediately applicable in the workplace.",
    th: "มุ่งเน้นไปที่ทักษะการสื่อสารเชิงปฏิบัติที่สามารถนำไปใช้ได้ทันทีในที่ทำงาน",
  },
  tr_get_personalized_feedback_skills: {
    en: "Get personalized feedback to refine your skills.",
    th: "รับคำติชมส่วนตัวเพื่อปรับแต่งทักษะของคุณ",
  },
  tr_gain_confidence_in_professional: {
    en: "Gain confidence in professional settings like meetings, presentations, and negotiations.",
    th: "เพิ่มความมั่นใจในสภาพแวดล้อมแบบมืออาชีพ เช่น การประชุม การนำเสนอ และการเจรจา",
  },
  tr_learn_from_interactive: {
    en: "Learn from interactive and engaging lessons tailored to your needs.",
    th: "เรียนรู้จากบทเรียนแบบโต้ตอบและมีส่วนร่วมที่เหมาะกับความต้องการของคุณ",
  },
  tr_enroll_in_the_business: {
    en: "Enroll in the Business English course today and start using English with confidence in your everyday activities. Whether you’re a beginner or looking to refine your skills, this course will help you achieve your communication goals.",
    th: "ลงทะเบียนเรียนหลักสูตรภาษาอังกฤษธุรกิจวันนี้และเริ่มใช้ภาษาอังกฤษอย่างมั่นใจในกิจกรรมประจำวันของคุณ ไม่ว่าคุณจะเป็นมือใหม่หรือต้องการฝึกฝนทักษะ หลักสูตรนี้จะช่วยให้คุณบรรลุเป้าหมายการสื่อสาร",
  },
  tr_the_english_for_young: {
    en: "The English for Young Learners course is designed to make language learning fun, engaging, and effective for children. Through interactive lessons, games, and creative activities, we help young learners build a strong foundation in English, focusing on listening, speaking, reading, and writing skills.",
    th: "หลักสูตรภาษาอังกฤษสำหรับผู้เรียนรุ่นเยาว์ได้รับการออกแบบมาเพื่อทำให้การเรียนภาษาเป็นเรื่องสนุก มีส่วนร่วม และมีประสิทธิภาพสำหรับเด็ก เราช่วยให้ผู้เรียนรุ่นเยาว์สร้างรากฐานที่แข็งแกร่งในภาษาอังกฤษผ่านบทเรียนแบบโต้ตอบ เกม และกิจกรรมสร้างสรรค์ โดยมุ่งเน้นที่ทักษะการฟัง การพูด การอ่าน และการเขียน",
  },
  tr_children_aged_4: {
    en: "Children aged 4 to 12 years old.",
    th: "เด็กอายุ 4 ถึง 12 ปี",
  },
  tr_young_learners_starting: {
    en: "Young learners starting their journey in English.",
    th: "ผู้เรียนรุ่นเยาว์เริ่มต้นการเดินทางด้วยภาษาอังกฤษ",
  },
  tr_kids_needing_extra: {
    en: "Kids needing extra support with school English assignments.",
    th: "เด็กๆ ต้องการความช่วยเหลือเป็นพิเศษเกี่ยวกับงานภาษาอังกฤษของโรงเรียน",
  },
  tr_parents_looking_to: {
    en: "Parents looking to boost their child's confidence in English.",
    th: "ผู้ปกครองที่ต้องการเพิ่มความมั่นใจในภาษาอังกฤษของบุตรหลาน",
  },
  tr_interactive_learning: {
    en: "Interactive Learning",
    th: "การเรียนรู้แบบโต้ตอบ",
  },
  tr_lessons_include_storytelling: {
    en: "Lessons include storytelling, songs, games, and activities to keep children engaged and excited.",
    th: "บทเรียนประกอบด้วยการเล่าเรื่อง เพลง เกม และกิจกรรมเพื่อให้เด็กๆ มีส่วนร่วมและตื่นเต้น",
  },
  tr_phonics_and_vocabulary: {
    en: "Phonics and Vocabulary",
    th: "โฟนิคส์และคำศัพท์",
  },
  tr_focus_on_building: {
    en: "Focus on building a strong base in pronunciation, spelling, and vocabulary.",
    th: "มุ่งเน้นที่การสร้างฐานที่แข็งแกร่งในการออกเสียง การสะกดคำ และคำศัพท์",
  },
  tr_creative_activities: {
    en: "Creative Activities",
    th: "กิจกรรมสร้างสรรค์",
  },
  tr_use_art_music: {
    en: "Use art, music, and role-playing to make language learning enjoyable.",
    th: "ใช้ศิลปะ ดนตรี และการแสดงบทบาทสมมติเพื่อทำให้การเรียนภาษาเป็นเรื่องสนุกสนาน",
  },
  tr_tailored_curriculum: {
    en: "Tailored Curriculum",
    th: "หลักสูตรที่ปรับให้เหมาะสม",
  },
  tr_lessons_are_adapted: {
    en: "Lessons are adapted to each child’s age, skill level, and learning pace.",
    th: "บทเรียนได้รับการปรับให้เหมาะกับอายุ ระดับทักษะ และจังหวะการเรียนรู้ของเด็กแต่ละคน",
  },
  tr_parent_involvement: {
    en: "Parent Involvement",
    th: "การมีส่วนร่วมของผู้ปกครอง",
  },
  tr_regular_progress_updates: {
    en: "Regular progress updates and tips to support learning at home.",
    th: "การอัปเดตความคืบหน้าและเคล็ดลับเป็นประจำเพื่อสนับสนุนการเรียนรู้ที่บ้าน",
  },
  tr_basic_conversational_english: {
    en: "Basic conversational English for everyday interactions.",
    th: "บทสนทนาภาษาอังกฤษขั้นพื้นฐานเพื่อการโต้ตอบในชีวิตประจำวัน",
  },
  tr_improved_pronunciation_and: {
    en: "Improved pronunciation and confidence in speaking.",
    th: "ปรับปรุงการออกเสียงและความมั่นใจในการพูด",
  },
  tr_foundational_grammar_and: {
    en: "Foundational grammar and sentence-building skills.",
    th: "ไวยากรณ์พื้นฐานและทักษะการสร้างประโยค",
  },
  tr_reading_and_comprehension: {
    en: "Reading and comprehension skills through engaging stories.",
    th: "ทักษะการอ่านและทำความเข้าใจผ่านเรื่องราวที่น่าสนใจ",
  },
  tr_creative_expression_in: {
    en: "Creative expression in writing simple sentences and paragraphs.",
    th: "การแสดงออกอย่างสร้างสรรค์ในการเขียนประโยคและย่อหน้าง่ายๆ",
  },
  tr_fun_and_engaging: {
    en: "Fun and engaging lessons tailored to young learners.",
    th: "บทเรียนที่สนุกสนานและน่าดึงดูดซึ่งออกแบบมาเพื่อผู้เรียนรุ่นเยาว์",
  },
  tr_boosts_confidence_and: {
    en: "Boosts confidence and builds a love for the English language.",
    th: "เพิ่มความมั่นใจและสร้างความรักในภาษาอังกฤษ",
  },
  tr_interactive_and_creative: {
    en: "Interactive and creative methods ensure maximum engagement.",
    th: "วิธีการเชิงโต้ตอบและสร้างสรรค์ช่วยให้เกิดการมีส่วนร่วมสูงสุด",
  },
  tr_flexible_learning_options: {
    en: "Flexible learning options to suit your child’s needs.",
    th: "ตัวเลือกการเรียนรู้ที่ยืดหยุ่นเพื่อให้เหมาะกับความต้องการของบุตรหลานของคุณ",
  },
  tr_regular_updates_and: {
    en: "Regular updates and feedback for parents to track progress.",
    th: "การอัปเดตและข้อเสนอแนะเป็นประจำสำหรับผู้ปกครองเพื่อติดตามความคืบหน้า",
  },
  tr_help_your_child: {
    en: "Help your child shine in English! Enroll in the English for Young Learners course today and set them up for success in school and beyond. With engaging lessons and a nurturing approach, we make learning fun and effective.",
    th: "ช่วยให้ลูกของคุณโดดเด่นเป็นภาษาอังกฤษ! ลงทะเบียนหลักสูตรภาษาอังกฤษสำหรับผู้เรียนรุ่นเยาว์วันนี้ และเตรียมพร้อมสำหรับความสำเร็จในโรงเรียนและที่อื่นๆ ด้วยบทเรียนที่น่าดึงดูดและแนวทางการดูแล เราทำให้การเรียนรู้สนุกและมีประสิทธิภาพ",
  },
  tr_the_english_for_teenagers: {
    en: "The English for Teenagers course is designed to empower young learners with the language skills they need to succeed in school, social settings, and future careers. With a focus on building confidence, critical thinking, and effective communication, this course helps teenagers transition into advanced English users while staying engaged and motivated.",
    th: "หลักสูตรภาษาอังกฤษสำหรับวัยรุ่นได้รับการออกแบบมาเพื่อเพิ่มศักยภาพให้กับผู้เรียนรุ่นเยาว์ด้วยทักษะภาษาที่จำเป็นต่อการประสบความสำเร็จในโรงเรียน สภาพแวดล้อมทางสังคม และอาชีพในอนาคต หลักสูตรนี้มุ่งเน้นที่การสร้างความมั่นใจ การคิดเชิงวิพากษ์ และการสื่อสารที่มีประสิทธิภาพ ช่วยให้วัยรุ่นเปลี่ยนไปใช้ภาษาอังกฤษขั้นสูงในขณะที่ยังคงมีส่วนร่วมและมีแรงบันดาลใจ",
  },
  tr_teenagers_aged_13: {
    en: "Teenagers aged 13 to 18 years old.",
    th: "วัยรุ่นอายุ 13 ถึง 18 ปี",
  },
  tr_students_looking_to: {
    en: "Students looking to enhance their academic English skills.",
    th: "นักเรียนที่ต้องการพัฒนาทักษะภาษาอังกฤษเชิงวิชาการ",
  },
  tr_teens_preparing_for: {
    en: "Teens preparing for exams, interviews, or study abroad programs.",
    th: "วัยรุ่นที่กำลังเตรียมตัวสอบ สัมภาษณ์ หรือศึกษาต่อต่างประเทศ",
  },
  tr_young_learners_wanting: {
    en: "Young learners wanting to improve their conversational and written English.",
    th: "ผู้เรียนรุ่นเยาว์ต้องการพัฒนาการสนทนาและการเขียนภาษาอังกฤษ",
  },
  tr_practical_focus: {
    en: "Practical Focus",
    th: "มุ่งเน้นการปฏิบัติ",
  },
  tr_lessons_designed_around: {
    en: "Lessons designed around real-world scenarios, including school, social media, and future career skills.",
    th: "บทเรียนที่ออกแบบมาเพื่อสถานการณ์ในโลกแห่งความเป็นจริง รวมถึงโรงเรียน โซเชียลมีเดีย และทักษะอาชีพในอนาคต",
  },
  tr_academic_skills: {
    en: "Academic Skills",
    th: "ทักษะทางวิชาการ",
  },
  tr_strengthens_grammar_vocabulary: {
    en: "Strengthens grammar, vocabulary, and reading comprehension for school success.",
    th: "เสริมสร้างไวยากรณ์ คำศัพท์ และความเข้าใจในการอ่านเพื่อความสำเร็จของโรงเรียน",
  },
  tr_interactive_sessions: {
    en: "Interactive Sessions",
    th: "เซสชันแบบโต้ตอบ",
  },
  tr_engaging_discussions_debates: {
    en: "Engaging discussions, debates, and collaborative projects to build confidence.",
    th: "การมีส่วนร่วมอภิปราย อภิปราย และโครงการความร่วมมือเพื่อสร้างความมั่นใจ",
  },
  tr_individualized_learning: {
    en: "Individualized Learning",
    th: "การเรียนรู้รายบุคคล",
  },
  tr_curriculum_tailored_to: {
    en: "Curriculum tailored to each teenager’s level, goals, and areas of improvement.",
    th: "หลักสูตรที่ปรับให้เหมาะกับระดับ เป้าหมาย และด้านที่ต้องปรับปรุงของวัยรุ่นแต่ละคน",
  },
  tr_creative_writing: {
    en: "Creative Writing",
    th: "การเขียนเชิงสร้างสรรค์",
  },
  tr_focus_on_essays: {
    en: "Focus on essays, stories, and academic writing techniques.",
    th: "เน้นการเขียนเรียงความ เรื่องราว และเทคนิคการเขียนเชิงวิชาการ",
  },
  tr_fluent_and_confident: {
    en: "Fluent and confident conversational English.",
    th: "สนทนาภาษาอังกฤษได้อย่างคล่องแคล่วและมั่นใจ",
  },
  tr_enhanced_grammar_and: {
    en: "Enhanced grammar and sentence construction skills.",
    th: "พัฒนาทักษะไวยากรณ์และการสร้างประโยค",
  },
  tr_critical_thinking_and: {
    en: "Critical thinking and analytical skills through reading comprehension.",
    th: "การคิดอย่างมีวิจารณญาณและทักษะการวิเคราะห์ผ่านการอ่านเพื่อความเข้าใจ",
  },
  tr_advanced_writing_techniques: {
    en: "Advanced writing techniques for essays and reports.",
    th: "เทคนิคการเขียนเรียงความและรายงานขั้นสูง",
  },
  tr_effective_communication_for: {
    en: "Effective communication for interviews, group projects, and presentations.",
    th: "การสื่อสารที่มีประสิทธิภาพสำหรับการสัมภาษณ์ โครงการกลุ่ม และการนำเสนอ",
  },
  tr_why_choose_english_teenagers: {
    en: "Why Choose English for Teenagers?",
    th: "ทำไมต้องเลือกภาษาอังกฤษสำหรับวัยรุ่น?",
  },
  tr_prepares_teens_for: {
    en: "Prepares teens for real-life challenges like interviews and exams.",
    th: "เตรียมวัยรุ่นให้พร้อมรับมือกับความท้าทายในชีวิตจริง เช่น การสัมภาษณ์และการสอบ",
  },
  tr_builds_confidence_in: {
    en: "Builds confidence in both speaking and writing English.",
    th: "สร้างความมั่นใจทั้งในการพูดและการเขียนภาษาอังกฤษ",
  },
  tr_focus_on_academic: {
    en: "Focus on academic and conversational English for well-rounded skills.",
    th: "มุ่งเน้นภาษาอังกฤษเชิงวิชาการและการสนทนาเพื่อทักษะรอบรู้",
  },
  tr_interactive_and_engaging: {
    en: "Interactive and engaging lessons tailored for teenage learners.",
    th: "บทเรียนเชิงโต้ตอบและน่าดึงดูดที่ออกแบบมาสำหรับผู้เรียนวัยรุ่น",
  },
  tr_flexible_scheduling_to: {
    en: "Flexible scheduling to fit busy school and extracurricular calendars.",
    th: "การจัดตารางเวลาที่ยืดหยุ่นเพื่อให้เหมาะกับโรงเรียนที่ยุ่งวุ่นวายและปฏิทินนอกหลักสูตร",
  },
  tr_equip_your_teenager: {
    en: "Equip your teenager with the skills they need to excel in English! The English for Teenagers course is the perfect stepping stone to academic and social success. Whether preparing for exams, planning to study abroad, or boosting their confidence, this course has them covered.",
    th: "เตรียมทักษะที่จำเป็นสำหรับวัยรุ่นของคุณเพื่อเก่งภาษาอังกฤษ! หลักสูตรภาษาอังกฤษสำหรับวัยรุ่นเป็นก้าวที่สมบูรณ์แบบสู่ความสำเร็จด้านวิชาการและสังคม ไม่ว่าจะเตรียมตัวสอบ วางแผนไปเรียนต่อต่างประเทศ หรือเพิ่มความมั่นใจ หลักสูตรนี้ก็ครอบคลุม",
  },
  tr_grammar_is_the: {
    en: "Grammar is the backbone of any language, and mastering it is key to effective communication. The Grammar Essentials course is designed to help learners strengthen their foundation in English grammar, making it easier to write clearly, speak confidently, and understand the language better. Whether you're a beginner or someone looking to polish your grammar skills, this course has something for everyone.",
    th: "ไวยากรณ์เป็นหัวใจสำคัญของทุกภาษา และการฝึกฝนให้เชี่ยวชาญเป็นกุญแจสำคัญในการสื่อสารที่มีประสิทธิภาพ หลักสูตร Grammar Essentials ได้รับการออกแบบมาเพื่อช่วยให้ผู้เรียนมีพื้นฐานไวยากรณ์ภาษาอังกฤษที่แข็งแกร่งขึ้น ทำให้เขียนได้ชัดเจน พูดอย่างมั่นใจ และเข้าใจภาษาได้ดีขึ้น ไม่ว่าคุณจะเป็นมือใหม่หรือผู้ที่ต้องการขัดเกลาทักษะไวยากรณ์ หลักสูตรนี้มีทุกสิ่งสำหรับทุกคน",
  },
  tr_students_of_all: {
    en: "Students of all ages who struggle with English grammar.",
    th: "นักเรียนทุกวัยที่ประสบปัญหากับไวยากรณ์ภาษาอังกฤษ",
  },
  tr_adults_aiming_to: {
    en: "Adults aiming to improve their professional communication.",
    th: "ผู้ใหญ่ที่มีเป้าหมายที่จะพัฒนาการสื่อสารทางวิชาชีพของตน",
  },
  tr_language_learners_preparing: {
    en: "Language learners preparing for exams like IELTS, TOEFL, or ADFELPS.",
    th: "ผู้เรียนภาษาที่กำลังเตรียมตัวสอบเช่น IELTS, TOEFL หรือ ADFELPS",
  },
  tr_anyone_who_wants_refine: {
    en: "Anyone who wants to refine their grammar for speaking and writing.",
    th: "ใครก็ตามที่ต้องการปรับแต่งไวยากรณ์ในการพูดและการเขียน",
  },
  tr_comprehensive_coverage: {
    en: "Comprehensive Coverage",
    th: "ความคุ้มครองที่ครอบคลุม",
  },
  tr_lessons_on_tenses: {
    en: "Lessons on tenses, sentence structures, parts of speech, and punctuation.",
    th: "บทเรียนเรื่องกาล โครงสร้างประโยค ส่วนของคำพูด และเครื่องหมายวรรคตอน",
  },
  tr_practical_application: {
    en: "Practical Application",
    th: "การประยุกต์ใช้ในทางปฏิบัติ",
  },
  tr_exercises_and_examples: {
    en: "Exercises and examples to apply grammar rules in real-life situations.",
    th: "แบบฝึกหัดและตัวอย่างการใช้กฎไวยากรณ์ในสถานการณ์จริง",
  },
  tr_error_correction: {
    en: "Error Correction",
    th: "การแก้ไขข้อผิดพลาด",
  },
  tr_focused_feedback_on: {
    en: "Focused feedback on common mistakes and how to avoid them.",
    th: "เน้นความคิดเห็นเกี่ยวกับข้อผิดพลาดทั่วไปและวิธีหลีกเลี่ยง",
  },
  tr_activities_and_quizzes: {
    en: "Activities and quizzes to make grammar learning engaging and fun.",
    th: "กิจกรรมและแบบทดสอบเพื่อให้การเรียนไวยากรณ์มีส่วนร่วมและสนุกสนาน",
  },
  tr_flexible_pace: {
    en: "Flexible Pace",
    th: "ก้าวที่ยืดหยุ่น",
  },
  tr_tailored_lessons_to: {
    en: "Tailored lessons to fit your learning speed and goals.",
    th: "บทเรียนที่ปรับแต่งให้เหมาะกับความเร็วและเป้าหมายการเรียนรู้ของคุณ",
  },
  tr_proper_sentence_construction: {
    en: "Proper sentence construction for clear communication.",
    th: "การสร้างประโยคที่เหมาะสมเพื่อการสื่อสารที่ชัดเจน",
  },
  tr_mastery_of_verb: {
    en: "Mastery of verb tenses and how to use them correctly.",
    th: "การเรียนรู้คำกริยากาลและวิธีใช้อย่างถูกต้อง",
  },
  tr_accurate_use_of: {
    en: "Accurate use of articles, prepositions, and conjunctions.",
    th: "การใช้คำนำหน้า คำบุพบท และคำสันธานอย่างถูกต้อง",
  },
  tr_understanding_and_avoiding: {
    en: "Understanding and avoiding common grammar pitfalls.",
    th: "ทำความเข้าใจและหลีกเลี่ยงข้อผิดพลาดทางไวยากรณ์ทั่วไป",
  },
  tr_confidence_in_speaking: {
    en: "Confidence in speaking and writing English with proper grammar.",
    th: "มั่นใจในการพูดและเขียนภาษาอังกฤษด้วยไวยากรณ์ที่ถูกต้อง",
  },
  tr_strengthens_your_foundation: {
    en: "Strengthens your foundation for all areas of English learning.",
    th: "เสริมสร้างรากฐานของคุณสำหรับการเรียนรู้ภาษาอังกฤษทุกด้าน",
  },
  tr_helps_you_sound: {
    en: "Helps you sound more professional and articulate.",
    th: "ช่วยให้คุณฟังดูเป็นมืออาชีพและชัดเจนมากขึ้น",
  },
  tr_improves_accuracy_in: {
    en: "Improves accuracy in exams, presentations, and daily communication.",
    th: "ปรับปรุงความแม่นยำในการสอบ การนำเสนอ และการสื่อสารรายวัน",
  },
  tr_offers_engaging_lessons: {
    en: "Offers engaging lessons with practical applications.",
    th: "เสนอบทเรียนที่น่าสนใจพร้อมการนำไปปฏิบัติจริง",
  },
  tr_tailored_to_your: {
    en: "Tailored to your current level and future goals.",
    th: "ปรับให้เหมาะกับระดับปัจจุบันและเป้าหมายในอนาคตของคุณ",
  },
  tr_take_charge_of: {
    en: "Take charge of your English journey by mastering the rules of grammar. With the Grammar Essentials course, you'll gain the skills and confidence to use English correctly and effectively in any setting.",
    th: "ดูแลการเดินทางภาษาอังกฤษของคุณโดยการเรียนรู้กฎไวยากรณ์ ด้วยหลักสูตร Grammar Essentials คุณจะได้รับทักษะและความมั่นใจในการใช้ภาษาอังกฤษอย่างถูกต้องและมีประสิทธิภาพในทุกสภาพแวดล้อม",
  },
  tr_enroll_today_and: {
    en: "Enroll today and start building a solid foundation in English grammar!",
    th: "ลงทะเบียนวันนี้และเริ่มสร้างรากฐานที่แข็งแกร่งในไวยากรณ์ภาษาอังกฤษ!",
  },
  tr_contact_us_today: {
    en: "Contact us today to enroll and get your teenager started on their journey to English excellence!",
    th: "ติดต่อเราวันนี้เพื่อลงทะเบียนและให้วัยรุ่นของคุณเริ่มต้นการเดินทางสู่ความเป็นเลิศทางภาษาอังกฤษ!",
  },
  tr_python: {
    en: "Python",
    th: "หลาม",
  },
  tr_robotics: {
    en: "Robotics",
    th: "วิทยาการหุ่นยนต์",
  },
  tr_web_design: {
    en: "Web Design",
    th: "การออกแบบเว็บไซต์",
  },
  tr_roblox_game_development: {
    en: "Roblox Game Development",
    th: "การพัฒนาเกม Roblox",
  },
  tr_learning_tools_for: {
    en: "Learning tools for every situation. Build confidence in everyday conversations, from ordering food to making new friends!",
    th: "เครื่องมือการเรียนรู้สำหรับทุกสถานการณ์ สร้างความมั่นใจในการสนทนาในชีวิตประจำวัน ตั้งแต่การสั่งอาหารไปจนถึงการได้รู้จักเพื่อนใหม่!",
  },
  tr_free_trial: {
    en: "Free Trial",
    th: "ทดลองใช้ฟรี",
  },
  tr_hours: {
    en: "hours",
    th: "ชั่วโมง",
  },
  tr_more_courses: {
    en: "More Courses",
    th: "หลักสูตรเพิ่มเติม",
  },
  tr_why_choose_english_young: {
    en: "Why Choose English for Young Learners?",
    th: "เหตุใดจึงเลือกภาษาอังกฤษสำหรับผู้เรียนรุ่นเยาว์?",
  },
  tr_why_choose_grammar: {
    en: "Why Choose Grammar Essentials",
    th: "เหตุใดจึงเลือกสิ่งจำเป็นด้านไวยากรณ์",
  },
  tr_for_over: {
    en: "For over",
    th: "กว่า",
  },
  tr_send_email: {
    en: "Send Email",
    th: "ส่งอีเมล์",
  },
  tr_scratch: {
    en: "Scratch",
    th: "Scratch",
  },
};
