export const challengeNames = [
  "Word Warriors Showdown",
  "Battle of the Brains",
  "The Wordsmith Challenge",
  "Lexicon Legends",
  "Vocab Voyage",
  "Dictionary Duel",
  "Alpha Achievers",
  "Spelling Sprint",
  "Phonics Frenzy",
  "Ultimate Word Race",
  "Puzzle Masters Arena",
  "The Riddle Relay",
  "Mind Meld Match",
  "Letter League Showdown",
  "Brain Boost Battle",
  "Scramble Scuffle",
  "Think Fast Tournament",
  "Grammar Gladiators",
  "Word Jumble Journey",
  "Rapid Word Rally",
  "Word Wizard Showoff",
  "Epic Word War",
  "Team Letter Clash",
  "Word Hunter's Arena",
  "Fast Fingers Faceoff",
  "Unlock the Word Zone",
  "Challenge of the Letters",
  "The Ultimate Spelling Showdown",
  "Smartypants Challenge",
  "Mind Sharpener Mission",
  "Crack the Code Showdown",
  "Letter Quest Marathon",
  "Vocab Titans Clash",
  "Conquer the Dictionary",
  "The Brain Blitz",
  "Spelling Challenge Supreme",
  "Letter Storm Tournament",
  "Word Blitz League",
  "Speedy Spellers Race",
  "The Ultimate Vocab Show",
  "Alpha Mastermind Challenge",
  "Word Wonder Faceoff",
  "Letter Detectives Showdown",
  "Daily Word Challenge",
  "Cerebral Word Race",
  "Quick Letter Thinkers",
  "Brainy Spellers Duel",
  "Word Wizards Unite",
  "Lexicon Crushers",
  "The Ultimate Think Fast",
  "Battle of Word Ninjas",
  "Word Connect Clash",
  "Team Spelling Frenzy",
  "Puzzle Mastermind Arena",
  "Wordy Victory League",
  "Champions of Letters",
  "Head-to-Head Word Battle",
  "Spelling Sprint Faceoff",
  "Unlock the Lexicon",
  "Alpha Rush Challenge",
  "Brain Buster Blitz",
  "Word Zone Arena",
  "Fast Track Spelling",
  "The Word Explorer Quest",
  "Master Spellers League",
  "Rapid-Fire Letters",
  "The Big Vocab Showdown",
  "Letter Genius Challenge",
  "Puzzle Pros Showdown",
  "Race Against Words",
  "Challenge the Letters",
  "The Mega Word Clash",
  "WordPlay Masters",
  "Match of the Minds",
  "Word Duel Masters",
  "Alpha Sprint Faceoff",
  "SpellBound Challenge",
  "Vocab Masters Unite",
  "Team Word Warriors",
  "The Thinking Fast League",
  "The Letter Detectives",
  "Challenge WordStorm",
  "Puzzle Race to Victory",
  "The Alpha Rush Show",
  "Letters vs Minds",
  "Super Spellers Arena",
  "Word Busters Faceoff",
  "Riddle Warriors Arena",
  "Daily Word Sprint",
  "Letters to the Finish",
  "Speed Spellers Showdown",
  "The Word Brain Challenge",
  "Mindstorm Word Duel",
  "Spelling Wiz Faceoff",
  "Wordy Riddle Challenge",
  "Alpha Arena Showdown",
  "Quick Word Battle",
  "The Brainstorm Relay",
  "Word Clash Frenzy",
  "The Spelling Circuit",
  "Mind Sprint Arena",
  "Letter Stars Challenge",
  "Lexicon Knockout",
  "Challenge of Champions",
  "Race to the Word Zone",
  "WordIQ Masters",
  "Brainstorm Spelling Clash",
  "The Word Wizard Tournament",
  "Letter Showdown Express",
  "Epic Alpha Battle",
  "Word Seekers Marathon",
  "Daily Letter Challenge",
  "Lexi-Warriors Clash",
  "Word Craze Arena",
  "Dictionary Masters League",
  "Head-to-Head Lexicon",
  "Spelling Supreme Duel",
  "Brainstorm Word Hunt",
  "Rapid Word Genius",
  "The Word Tornado",
  "Letter Runners Showdown",
  "Word Race Ultimate",
  "Mindrush Challenge",
  "Vocab Master Blitz",
  "The Spelling Test Faceoff",
  "Word Puzzle Legends",
  "Champion Word Hunters",
  "Brain Speed Duel",
  "Puzzle Storm Challenge",
  "Alpha Champs Battle",
  "The Word Craze Race",
  "Letters Master Relay",
  "Smart Word Clash",
  "The Epic Letter Hunt",
  "Quick Minds Challenge",
  "Wordplay Blitz League",
  "Spelling Knights Tournament",
  "Alpha Zone Champions",
  "The Mind Words Showdown",
  "Super Word Challenge",
  "Vocab Knights Arena",
  "The Puzzle Champions League",
  "Brain Spellers Supreme",
  "Letters League Duel",
  "The Spelling Genius Show",
  "Word Game Masters",
  "Fast Fingers Challenge",
  "Ultimate Brainstorm Race",
  "Wordstorm Duel Arena",
  "Smart Lexicon Showdown",
  "Brain Blitz Spelling",
  "Team Word Craze Faceoff",
  "The Champion Spelling Show",
  "Word Connect Challenge",
  "Puzzle League Masters",
  "Letter Master Faceoff",
  "Ultimate Alpha Challenge",
  "Super Spelling Frenzy",
  "Daily Challenge Showdown",
  "Team Wordstorm Clash",
  "Letter Rush Battle",
  "Brain Rush Puzzle",
  "Word Finders Race",
  "Ultimate WordIQ Challenge",
  "Spelling Titans Showdown",
  "Team Brain Battle",
  "Alpha Blitz Race",
  "Quick Vocab Clash",
  "WordStorm Knockout",
  "Speedy Letters Faceoff",
  "Puzzle Knights Challenge",
  "Mind Race Champions",
  "The Letter Genius Arena",
  "Vocab Warriors Showdown",
  "Brain Spellers Blitz",
  "The Alpha Masters Clash",
  "Word Wizards Unite",
  "Head-to-Head Alpha Race",
  "Mind Race Marathon",
  "The Spelling Brainstorm",
  "Puzzle Clash Legends",
  "Champion Vocab Masters",
  "The Fast Word Race",
  "Alpha Duel Showdown",
  "Challenge Word Relay",
  "Mindzone Puzzle Clash",
  "Rapid Word Relay Race",
  "The Champion Lexicon Clash",
  "Letter Zone Battle",
  "Ultimate Word Sprint",
  "BrainStorm Letter Challenge",
  "Vocab League Race",
  "Spelling Victory Arena",
  "Team Word Finders",
  "Puzzle Showdown League",
  "Speed Words Arena",
  "The Word Genius Race",
  "Alpha Stars Showdown",
  "Ultimate Challenge Blitz",
  "Letter Seekers Show",
  "The Brain Champs Duel",
  "Smart Word Faceoff",
  "WordStorm Blitz Race",
  "Epic Letter Rush Showdown",
  "Alpha Clash of Minds",
  "Spelling Masters Marathon",
  "Word League Challenge",
  "Lexicon Challenge Blitz",
  "Shadow Peak",
  "Echo Drift",
  "Nova Zone",
  "Frost Byte",
  "Iron Crest",
  "Lunar Echo",
  "Phantom Rise",
  "Solar Core",
  "Zenith Arc",
  "Vortex Edge",
  "Inferno Wave",
  "Pixel Surge",
  "Alpha Flame",
  "Void Spark",
  "Gravity Well",
  "Cyber Realm",
  "Star Forge",
  "Crimson Rush",
  "Zero Flux",
  "Apex Flow",
  "Nimbus Fall",
  "Radiant Tide",
  "Pulse Nova",
  "Quantum Shade",
  "Ether Stream",
  "Frost Nova",
  "Titan Wing",
  "Phantom Drift",
  "Iron Veil",
  "Starlight Run",
  "Echo Void",
  "Celestial Rise",
  "Neon Haze",
  "Omega Pulse",
  "Silver Drift",
  "Blaze Shard",
  "Nova Loop",
  "Pulse Rift",
  "Ether Fall",
  "Lunar Storm",
  "Obsidian Tide",
  "Prism Core",
  "Solar Flare",
  "Shadow Arc",
  "Crimson Sky",
  "Nebula Strike",
  "Frostbound",
  "Ember Peak",
  "Rift Walk",
  "Zenith Horizon",
  "Storm Forge",
  "Velocity Core",
  "Drift Ember",
  "Glitch Flow",
  "Pulse Verge",
  "Sky Break",
  "Nightfall Edge",
  "Dusk Rift",
  "Radiant Rush",
  "Starbound",
  "Inferno Trail",
  "Cinder Haze",
  "Moonstone Edge",
  "Echo Surge",
  "Nebula Zone",
  "Specter Drift",
  "Blaze Tide",
  "Solar Wing",
  "Titan Surge",
  "Void Bound",
  "Chaos Loop",
  "Ironfall",
  "Celestia Core",
  "Rift Spark",
  "Prism Strike",
  "Twilight Drift",
  "Horizon Blaze",
  "Aurora Edge",
  "Zen Spark",
  "Skybound Nova",
  "Phantom Haze",
  "Aether Wing",
  "Shadow Rift",
  "Flux Wave",
  "Spectral Drift",
  "Pulse Shade",
  "Blaze Core",
  "Lunar Verge",
  "Starlight Fall",
  "Solar Tide",
  "Crimson Drift",
  "Void Nova",
  "Night Spark",
  "Zenith Drift",
  "Chaos Verge",
  "Nimbus Pulse",
  "Rift Walker",
  "Stormfall",
  "Obsidian Wing",
  "Neon Rush",
  "Ether Nova",
  "Gravity Spark",
  "Apex Tide",
  "Radiant Echo",
  "Celestial Rift",
  "Vortex Loop",
  "Shadow Verge",
  "Twilight Core",
  "Prism Nova",
  "Nightcore",
  "Stellar Pulse",
  "Frostflare",
  "Starfall Drift",
  "Pulse Arc",
  "Zen Flow",
  "Inferno Drift",
  "Lunar Beam",
  "Cinder Wave",
  "Ember Verge",
  "Vapor Core",
  "Rift Horizon",
  "Haze Drift",
  "Celestia Tide",
  "Void Spark",
  "Nebula Verge",
  "Solar Haze",
  "Titan Spark",
  "Frostveil",
  "Blaze Verge",
  "Aurora Drift",
  "Dusk Horizon",
  "Ember Surge",
  "Specter Core",
  "Obsidian Shade",
  "Chaos Ember",
  "Zenith Echo",
  "Nova Crest",
  "Shadow Pulse",
  "Storm Arc",
  "Iron Rift",
  "Starlight Verge",
  "Echo Beam",
  "Nimbus Verge",
  "Solar Drift",
  "Twilight Spark",
  "Horizon Nova",
  "Celestia Crest",
  "Moonlight Verge",
  "Radiant Blaze",
  "Vapor Tide",
  "Zen Arc",
  "Cinder Rift",
  "Gravity Verge",
  "Ether Rush",
  "Aether Core",
  "Chaos Bound",
  "Nightfall Horizon",
  "Drift Storm",
  "Prism Pulse",
  "Frost Wing",
  "Celestial Shade",
  "Oblivion Nova",
  "Twilight Crest",
  "Radiant Arc",
  "Shadow Core",
  "Sky Spark",
  "Nova Rise",
  "Zero Drift",
  "Ember Storm",
  "Apex Surge",
  "Moonfall Echo",
  "Neon Verge",
  "Flux Horizon",
  "Void Arc",
  "Zenith Core",
  "Celestial Drift",
  "Nightforge",
  "Titan Rift",
  "Star Haze",
  "Solar Rush",
  "Nebula Storm",
  "Horizon Edge",
  "Drift Core",
  "Lunar Spark",
  "Dusk Drift",
  "Phantom Loop",
  "Vapor Surge",
  "Zenith Wave",
  "Frost Spark",
  "Cinder Core",
  "Chaos Nova",
  "Storm Pulse",
  "Gravity Beam",
  "Prism Arc",
  "Void Drift",
  "Iron Rush",
  "Blaze Crest",
  "Echo Nova",
  "Aurora Pulse",
  "Celestial Verge",
  "Shadow Rise",
  "Frost Nova",
  "Titan Arc",
  "Nimbus Drift",
  "Chaos Pulse",
  "Nightfall Verge",
  "Zenith Shade",
  "Starcrest",
  "Rift Horizon",
  "Blaze Wave",
  "Prism Rift",
  "Solar Edge",
  "Void Storm",
  "Twilight Echo",
  "Horizon Spark",
  "Cinder Drift",
  "Frost Arc",
  "Zen Ember",
  "Specter Tide",
  "Aether Rush",
  "Gravity Rift",
  "Dusk Haze",
  "Stellar Drift",
  "Vapor Edge",
  "Lunar Surge",
  "Radiant Rift",
  "Zenith Nova",
  "Aetherion",
  "Virelia",
  "Quorvian",
  "Syranth",
  "Velmora",
  "Dracoth",
  "Thrynn",
  "Eryndor",
  "Zephyrae",
  "Morbane",
  "Xylaris",
  "Solivane",
  "Nexora",
  "Vexalis",
  "Cindrelis",
  "Lumithra",
  "Zorathis",
  "Arcanis",
  "Caelora",
  "Oblivis",
  "Drelith",
  "Vorinth",
  "Azryelle",
  "Erythrae",
  "Orphalia",
  "Thalryn",
  "Vornyx",
  "Astheris",
  "Phovian",
  "Cyllora",
  "Morithis",
  "Zephyris",
  "Nythera",
  "Axionis",
  "Venlyra",
  "Kyrilith",
  "Lorindel",
  "Exyntha",
  "Sarithis",
  "Falorix",
  "Dymora",
  "Kelbrin",
  "Vyranth",
  "Lyranth",
  "Tysoran",
  "Brivana",
  "Aurenis",
  "Xarith",
  "Vynther",
  "Ylvaris",
  "Eldrith",
  "Quylora",
  "Halyth",
  "Nemoris",
  "Tavryss",
  "Kovara",
  "Velothra",
  "Fyralis",
  "Orynth",
  "Corynth",
  "Phyrexis",
  "Myrranth",
  "Varionis",
  "Thaloris",
  "Lunareth",
  "Axeroth",
  "Vyrora",
  "Draxelis",
  "Zephoria",
  "Cynthora",
  "Illoria",
  "Jarethis",
  "Thyris",
  "Velanthis",
  "Ryveris",
  "Xorneth",
  "Elyndra",
  "Valtheris",
  "Lyorin",
  "Nexaris",
  "Tavrynn",
  "Aerivyn",
  "Drivane",
  "Vexthra",
  "Sorynth",
  "Illuvan",
  "Morvayne",
  "Zyrantha",
  "Velmyra",
  "Xylvora",
  "Dralen",
  "Quivara",
  "Kelythra",
  "Thavros",
  "Zorentis",
  "Syralia",
  "Brynelle",
  "Tarynth",
  "Cyranth",
  "Phyreth",
  "Morynth",
  "Varenthis",
  "Olyndra",
  "Draythe",
  "Calvoryn",
  "Xelorith",
  "Thalyra",
  "Nyxaris",
  "Vyrannis",
  "Quorinth",
  "Bryllith",
  "Syrisca",
  "Orynthia",
  "Aerentis",
  "Myranthra",
  "Vyrintha",
  "Thalvyn",
  "Zyralis",
  "Corvith",
  "Erylia",
  "Brithis",
  "Tyvalis",
  "Xandrya",
  "Dracora",
  "Korynthia",
  "Vathra",
  "Lorveth",
  "Zephylen",
  "Nyxoria",
  "Illivor",
  "Falmyra",
  "Drynthor",
  "Calythea",
  "Morallis",
  "Zynthar",
  "Solaryth",
  "Orithis",
  "Vethryn",
  "Lunthera",
  "Mylaris",
  "Tavrisca",
  "Exoria",
  "Caelris",
  "Thyvalis",
  "Coryndel",
  "Xyvaris",
  "Nylaris",
  "Brythis",
  "Lorynth",
  "Kavora",
  "Sorynna",
  "Valryss",
  "Aurenith",
  "Thryvara",
  "Velrynn",
  "Quoralis",
  "Zorvane",
  "Dravenis",
  "Erynnth",
  "Vorthis",
  "Axelynn",
  "Falvryn",
  "Solvyn",
  "Coryllis",
  "Xelvoryn",
  "Dralyra",
  "Aerorys",
  "Thyrenis",
  "Volaris",
  "Kovaryn",
  "Mylarra",
  "Zephrynn",
  "Illarix",
  "Nytheris",
  "Phovryn",
  "Tyrantha",
  "Vorynth",
  "Caelorys",
  "Zyralith",
  "Exovryn",
  "Morvyn",
  "Kelvrynn",
  "Valrith",
  "Corythra",
  "Lorynna",
  "Aerenthis",
  "Bryvoryn",
  "Tavryllis",
  "Lunthra",
  "Draloria",
  "Thavryn",
  "Elystra",
  "Velvyn",
  "Zynthora",
  "Korynnth",
  "Myvalis",
  "Vyrentis",
  "Calvryn",
  "Xylthra",
  "Orynthis",
  "Tharvyn",
  "Solrath",
  "Zephynis",
  "Bryvyris",
  "Varentis",
  "Lorythra",
  "Quorith",
  "Nyxentis",
  "Phyrisca",
  "Falryss",
  "Thyraxia",
  "Velryth",
  "Xanoryn",
  "Solmora",
  "Coryvyn",
  "Voranth",
  "Zephoria",
  "Morandra",
  "Zynthis",
  "Aurenthis",
  "Lunryss",
  "Exythera",
  "Exurbia",
  "Cortex",
  "Technophile",
];

export const bots = {
  difficultyLevels: [
    {
      title: "Beginner Bunny",
      delayRange: { min: 60, max: 90 },
      description: "Extremely forgiving and great for new learners.",
    },
    {
      title: "Casual Cat",
      delayRange: { min: 45, max: 60 },
      description: "Balanced difficulty for casual players.",
    },
    {
      title: "Steady Squirrel",
      delayRange: { min: 30, max: 45 },
      description: "A steady challenge for improving players.",
    },
    {
      title: "Quick Fox",
      delayRange: { min: 20, max: 30 },
      description: "Fast and competitive level for skilled players.",
    },
    {
      title: "Master Owl",
      delayRange: { min: 10, max: 20 },
      description: "Elite challenge for wordle masters.",
    },
    {
      title: "Lightning Cheetah",
      delayRange: { min: 5, max: 10 },
      description: "A near-impossible level for the fastest minds.",
    },
  ],
  botNamesByDifficulty: {
    "Beginner Bunny": [
      "Mia",
      "Max",
      "Ben",
      "Lily",
      "Emma",
      "Noah",
      "Ava",
      "Jack",
      "Ella",
      "Sam",
    ],
    "Casual Cat": [
      "Chloe",
      "Leo",
      "Sophie",
      "Ethan",
      "Zoe",
      "Ryan",
      "Nora",
      "Alex",
      "Bella",
      "Liam",
    ],
    "Steady Squirrel": [
      "Oliver",
      "Sarah",
      "Daniel",
      "Clara",
      "Grace",
      "Henry",
      "Ruby",
      "Adam",
      "Emily",
      "Owen",
    ],
    "Quick Fox": [
      "Lucas",
      "Julia",
      "Nathan",
      "Iris",
      "Caleb",
      "Jade",
      "Felix",
      "Isla",
      "Gavin",
      "Maya",
    ],
    "Master Owl": [
      "Oliver",
      "Eleanor",
      "Sebastian",
      "Amelia",
      "Julian",
      "Evelyn",
      "Theodore",
      "Charlotte",
      "Isaac",
      "Victoria",
    ],
    "Lightning Cheetah": [
      "Axel",
      "Zara",
      "Damon",
      "Ivy",
      "Nico",
      "Sienna",
      "Jace",
      "Aurora",
      "Blake",
      "Luna",
    ],
  },
};
