export const db = [
  {
    word: "assistant",
    definition: "someone who helps another person in their job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "boss",
    definition: "a person who is in charge of other people at work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "chief Executive Officer",
    definition: "the person who is in charge of a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "colleague",
    definition: "someone that you work with",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "director",
    definition: "a senior person who is in charge of part of a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "employee",
    definition: "a person who is paid to work for sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "engineering",
    definition:
      "using scientific knowledge to help design and build machines, equipment, roads, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "friendly",
    definition: "helpful and kind",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "head",
    definition:
      "a person who is in charge of an organization or a group of people",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "introduce",
    definition:
      "to tell a person's name to sb who has not met the person before",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "journey",
    definition: "travelling from one place to another",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "manager",
    definition: "a person who is in charge of a group of people at work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "managing director",
    definition: "the person who is in charge of a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "personal assistant",
    definition: "a person whose job is to help sb with their work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "receptionist",
    definition:
      "a person who deals with people visiting or telephoning an office building or a hotel",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "sales rep",
    definition:
      "a person whose job is to visit different businesses to sell a company's products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "team leader",
    definition: "a person in charge of a team of people at work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "technician",
    definition:
      "a person whose job is to keep special equipment or machinery in good condition",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "technology",
    definition: "scientific knowledge, which is used in practical ways",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "website",
    definition:
      "a place on the Internet where a company or person puts information",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 1",
  },
  {
    word: "abroad",
    definition: "to or in other countries, not your home country",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "automobile",
    definition: "a car",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "book",
    definition:
      "to arrange with a company to have a plane ticket, hotel room, restaurant table, etc. on a particular date",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "buy",
    definition: "to get sth by paying money for it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "chain",
    definition:
      "a group of shops / stores, cinemas, or hotels owned by the same company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "confirm",
    definition: "to say that an arrangement is definite",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "customer",
    definition: "someone who buys sth from a business or shop / store",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "delivery",
    definition: "taking goods to the person who has bought them",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "develop",
    definition: "to produce a new product and make it successful",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "electronics",
    definition:
      "the use of electronic technology in business and in products such as CD players, games, televisions, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "employ",
    definition: "to give a job to sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "equipment",
    definition: "the things that are needed for doing sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "export",
    definition: "to sell and send goods to another country",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "financial services",
    definition:
      "services connected with money, for example insurance and banking",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "flexible",
    definition:
      "able to change when necessary, to suit new conditions or situations",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "hospitality",
    definition:
      "the business of providing food, drink, rooms, etc. for customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "industry",
    definition:
      "the people and activities that produce a particular thing, or provide a particular service",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "job fair",
    definition:
      "an event where people who are looking for a new job can meet companies that are looking for new employees",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "million",
    definition: "the number 1,000,000",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "office supplies",
    definition:
      "things such as paper and pens that are needed for work in an office",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "order",
    definition: "to ask for goods to be supplied or a service to be provided",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "pharmaceuticals",
    definition: "drugs or medicines",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "produce",
    definition: "to make things to be sold",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "provide",
    definition: "to offer goods or a service",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "real estate",
    definition: "the business of selling land or buildings",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "recruitment",
    definition: "to find new people to join a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "repeat",
    definition: "to say sth again",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "sell",
    definition: "to give sth to sb for money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "service",
    definition: "things that a business does for its customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "slowly",
    definition: "not quickly; at a slow speed",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "software",
    definition: "programs used to operate a computer",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "staff",
    definition: "the people who work in an organization",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "stock",
    definition: "the goods that are available for sale",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "transport",
    definition:
      "a system for carrying people or goods from one place to another",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 2",
  },
  {
    word: "area",
    definition: "part of a place, town, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "call sb back",
    definition:
      "to telephone sb again or to telephone someone who telephoned you earlier",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "canteen",
    definition:
      "a place where food and drink are served in a factory, an office building, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "conference",
    definition:
      "a large meeting of people with the same work or interests, usually lasting for a few days",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "consumer product",
    definition: "a product bought and used by individual customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "contact number",
    definition: "a telephone number that you can call to talk to someone",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "continent",
    definition:
      "one of the large land masses of the earth such as Europe, Asia, or Africa",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "destination",
    definition: "a place to which someone or something is going or being sent",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "distribution centre",
    definition:
      "a warehouse (large building for storing things) that receives goods from factories and sends them to shops/stores or customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "exhibition",
    definition:
      "a collection of things, for example products produced by different companies, that are shown to the public",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "factory",
    definition: "a building where goods are made",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "global",
    definition: "covering or affecting the whole world",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "head office",
    definition: "the main office of a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "international",
    definition: "connected with two or more countries",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "leave a message",
    definition:
      "to leave a written or spoken piece of information for someone when you cannot speak to them yourself",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "leisure",
    definition:
      "time that is spent doing what you enjoy when you are not working",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "location",
    definition: "a place where something is or happens",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "market",
    definition:
      "a particular area, country, or section of the population that might buy goods",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "nearby",
    definition: "a short distance from someone or something",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "region",
    definition: "a large area of land",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "sales office",
    definition: "the part of a company that sells the company's products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "take a message",
    definition:
      "to write down a piece of information to give to another person for someone who could not speak to them directly",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "technical centre",
    definition: "the part of a company that helps customers use their machines",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "unlike",
    definition:
      "used to say how someone or something is different from another person or thing",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "workplace",
    definition: "an office, factory, etc. where people work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },
  {
    word: "worldwide",
    definition: "in or to all parts of the world",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 3",
  },

  {
    word: "always",
    definition: "all the time; on every occasion",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "battery",
    definition:
      "a device that you put inside something such as a camera or clock that produces the electricity to make it work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "button",
    definition: "a small part of a machine that you press to make it work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "click on",
    definition:
      "to choose something on a computer screen by pressing one of the buttons on a mouse",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "connect",
    definition: "to join a computer to the Internet or a computer network",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "electronic device",
    definition:
      "a piece of equipment such as a mobile phone or computer, that has many small electrical parts",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "enter",
    definition:
      "to put information, such as a name or numbers, into a computer",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "key in",
    definition: "to type information such as a name or numbers into a computer",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "landing",
    definition: "the return down to the ground of an aircraft after a flight",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "laptop",
    definition:
      "a small computer that can work with a battery and be easily carried",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "log on",
    definition: "to start using a computer",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "microphone",
    definition:
      "a device that is used for recording sounds or for making your voice louder when you are speaking to an audience",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "mobile phone",
    definition:
      "[BrE] a telephone without wires, that you can carry with you and use anywhere",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "never",
    definition: "at no time; not on any occasion",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "often",
    definition: "many times",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "password",
    definition:
      "a series of letters or numbers that you must type into a computer before you can use it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "photocopier",
    definition: "a machine that copies documents by photographing them",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "power points",
    definition:
      "[BrE] places in a wall where you can plug in electrical equipment",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "press",
    definition: "to push part of a device in order to make it work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "printer",
    definition: "a machine for printing words onto paper, from a computer file",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "put in",
    definition:
      "to put something such as a coin or card into a space in a machine",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "rarely",
    definition: "not often",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "recharge",
    definition: "to fill a battery with electrical power",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "record",
    definition:
      "to make a copy of sound or images so that you can listen to or watch it again",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "screen",
    definition:
      "the flat surface of a computer or television, on which you see images or information",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "sometimes",
    definition: "occasionally, but not all of the time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "start menu",
    definition: "a list on a computer screen of programs you can work with",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "switch off",
    definition:
      "to turn a machine, light, etc. off by pressing a button or switch",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "switch on",
    definition:
      "to turn a machine, light, etc. on by pressing a button or switch",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "take-off",
    definition:
      "the action of leaving the ground at the beginning of a flight by an aircraft",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "ticket machine",
    definition: "a machine that prints tickets for trains, buses, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "username",
    definition:
      "the name you choose in order to be allowed to use a computer program or system",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "usually",
    definition: "most of the time; on most occasions",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "warehouse",
    definition: "a building where large quantities of goods are stored",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "wireless",
    definition: "not using wires or cables",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 4",
  },
  {
    word: "apologize",
    definition: "to say that you are sorry for sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "apply for a job",
    definition: "to make a formal request for a job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "appointment",
    definition: "a formal arrangement to meet or visit sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "attach",
    definition: "to send a copy of a document with an email",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "attachment",
    definition: "a copy of a document that is sent with an email",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "bill",
    definition: "document showing how much they owe for goods or services",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "branding",
    definition:
      "the use of a name, logo (= symbol), and design for a company or group of products, so that people will recognize them",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "business card",
    definition:
      "a small card printed with sb's name, job, and details of the company they work for",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "correspondence",
    definition: "letters that a person or company receives or sends",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },

  {
    word: "delivery note",
    definition: "a form that you sign when goods or documents are delivered",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "folder",
    definition: "a place for storing documents or computer files",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "forward",
    definition: "to send or pass goods or information to sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "hard copy",
    definition: "information from a computer that has been printed out",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "invoice",
    definition:
      "a list of goods that have been sold or work that has been done, showing what sb must pay",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "item",
    definition: "one single thing that can be bought",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "meeting",
    definition:
      "an occasion when people meet together to discuss or decide sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "mistake",
    definition: "something that is not correct, or produces the wrong result",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "order form",
    definition: "a document that is used for ordering goods",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "paperwork",
    definition:
      "the written work that is part of a job, such as filling in forms or writing letters",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "presentation",
    definition:
      "a meeting at which a product, idea, or piece of work is shown to a group of people",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "print",
    definition: "to produce letters, pictures, etc. on paper using a machine",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "receipt",
    definition:
      "a document that shows that goods or services have been paid for",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "receive",
    definition: "to get something which sb has sent to you",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "save",
    definition:
      "put information on a computer or on a disk so the information is not lost",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "scan",
    definition:
      "to copy a document using a special machine and save it as a computer file",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "send",
    definition: "to make a letter, package, or email go to a place",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "task",
    definition: "a piece of work that sb has to do",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 5",
  },
  {
    word: "boring",
    definition: "uninteresting",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "comfortable",
    definition: "pleasant and relaxing to wear, sit in, spend time in, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "course",
    definition: "one of the separate parts of a meal",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "delicious",
    definition: "very pleasant to eat",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "dessert",
    definition: "sweet food eaten at the end of a meal",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "dinner",
    definition: "the main meal of the day, usually eaten in the evening",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "dish",
    definition: "a type of food prepared as part of a meal",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "fine",
    definition: "fairly good or acceptable",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "lunch",
    definition: "a meal eaten in the middle of the day",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "manufacturer",
    definition: "a company that produces goods in large quantities",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "meal",
    definition:
      "a time when people sit down to eat food, for example breakfast, lunch, or dinner",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "meet",
    definition: "to be in the same place as sb and talk to them",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "menu",
    definition: "a list of the food that is available at a restaurant",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "nice",
    definition: "pleasant or enjoyable",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "soft drink",
    definition: "a drink that does not contain alcohol",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "sparkling water",
    definition: "water with bubbles of gas in it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "still water",
    definition: "water without bubbles of gas in it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "supplier",
    definition: "a company or person that supplies goods to companies",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "terrible",
    definition: "very bad or unpleasant",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "textiles",
    definition: "cloth produced by weaving or knitting",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "tip",
    definition:
      "a small amount of extra money that you give to sb who helps you, for example a waiter in a restaurant",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "trade fair",
    definition: "an event where many companies show and sell their products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "waiter",
    definition:
      "a person who works in a restaurant bringing food and drinks to customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "waitress",
    definition:
      "a woman who works in a restaurant bringing food and drinks to customers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 6",
  },
  {
    word: "above",
    definition: "in a higher place or position than sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "be in charge of",
    definition: "to have control over and responsibility for sth / sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "be responsible for",
    definition: "to have sth as your job or duty",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "behind",
    definition: "in a position at the back of sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "below",
    definition: "in a lower place or position than sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "between",
    definition: "in a position in the middle of two other things",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "cafeteria",
    definition: "a self-service restaurant",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "check",
    definition:
      "to look at sth carefully to see if it is correct or good enough",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "control",
    definition:
      "to make sth work in a particular way or within particular limits",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "customer services",
    definition:
      "the part of a company that deals with customers questions and complaints",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "deal with",
    definition: "to perform a task or solve a problem",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "department",
    definition: "one section of a large company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "finance",
    definition: "the part of a company that deals with managing money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "human resources",
    definition:
      "the part of a company that deals with employing and training people",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "in front of",
    definition: "in a position further forward than sb or sth else",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "information Technology",
    definition: "the part of a company that deals with computers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "lift",
    definition:
      "[BrE] a machine that carries people up and down to different levels in a building",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "logistics",
    definition:
      "the work of planning and organizing the supply of materials, goods, and staff",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "manage",
    definition: "to be in charge of people's work in a business or a team",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "marketing",
    definition:
      "the part of a company that is responsible for presenting, advertising, and selling the company's products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "next to",
    definition: "in a position at the side of sb or sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "plan",
    definition:
      "to make arrangements or designs for what will be done in the future",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "production",
    definition: "the part of a company that produces goods",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "Research and Development",
    definition: "the part of a company that thinks of and plans new products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "reception",
    definition:
      "the area inside the entrance of an office building, where visitors go first when they arrive",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "responsibility",
    definition: "a duty to deal with or look after sb / sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "sales",
    definition:
      "the part of a company that is responsible for selling the company's products",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 7",
  },
  {
    word: "arrange a meeting",
    definition: "to plan or organize a time and place to meet up",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "busy",
    definition: "having a lot to do",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "careful",
    definition: "giving a lot of thought or attention to what you do",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "currently",
    definition: "at the present time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "employment",
    definition: "work that is done to earn money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "energetic",
    definition: "having a lot of energy and enthusiasm",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "experienced",
    definition: "having knowledge or skill in a particular job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "focused",
    definition: "with a clear and determined idea of what you want to do",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "free",
    definition: "not busy; available to do sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "imaginative",
    definition: "having new and exciting ideas",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "invest",
    definition:
      "to spend money on sth in order to make it better or more successful",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "knowledge",
    definition:
      "the information, understanding, and skills that you gain through education or experience",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "nowadays",
    definition: "at the present time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "patient",
    definition: "able to wait or accept difficulties without becoming angry",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "practical",
    definition: "good at dealing with real situations in a sensible way",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "qualification",
    definition: "an exam that you have passed",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "quality",
    definition: "a part of someone's character, especially sth good",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "recruit",
    definition: "to find new people to join a company",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "skill",
    definition: "a particular ability or type of ability",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "training course",
    definition: "a course where you learn skills that you need to do a job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 8",
  },
  {
    word: "advice",
    definition:
      "a suggestion about what sb should do, especially from an expert",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "cheap",
    definition: "costing little money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "comfort",
    definition:
      "a thing that makes it easier to relax and have a pleasant time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "competition",
    definition:
      "a situation in which companies compete with each other to try and get sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "competitive",
    definition:
      "as good as or better than others that you are competing against",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "competitor",
    definition: "a company that is competing against others in business",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "discount",
    definition: "an amount of money that is taken off the usual cost of sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "expensive",
    definition: "costing a lot of money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "facilities",
    definition:
      "buildings, services, equipment, etc. that are provided for a purpose",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "fast",
    definition: "moving or happening quickly",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "high quality",
    definition: "of a good standard compared to others",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "low price",
    definition: "costing little money",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "mail order",
    definition:
      "a system of ordering goods using the mail, telephone, or Internet and having them sent to you",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "major",
    definition: "very large or important",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "market segment",
    definition:
      "a group of possible customers who are similar in income, interests, etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "model",
    definition: "a particular design or type of product",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "slow",
    definition: "not moving or not happening quickly",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "storage space",
    definition: "places where things can be kept until they are needed",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "up-to-date",
    definition: "very modern",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },
  {
    word: "wide choice",
    definition: "including a large number of different things",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 9",
  },

  {
    word: "amazing",
    definition: "very surprising or interesting",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "attend meetings",
    definition: "to go to meet your colleagues",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "average",
    definition: "normal or typical",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "complain",
    definition: "to say that you are not happy or not satisfied about sb / sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "component",
    definition: "one of several parts of sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "develop ideas",
    definition:
      "to think of new things, products, etc. and make them successful",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "disappointing",
    definition: "not as good as you hoped or expected",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "excellent",
    definition: "very good",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "face-to-face",
    definition:
      "involving people who are together in the same place and looking at each other",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "fantastic",
    definition: "extremely good",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "figures",
    definition: "numbers that show different things",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "find solutions",
    definition: "to think of ways of solving a problem",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "make decisions",
    definition: "to decide what you will do or what should be done",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "opinion",
    definition: "your feelings or thoughts about sb / sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "overtime",
    definition: "extra time that you spend working at your job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "permanent",
    definition: "existing all the time or for a long time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "popular",
    definition: "liked by a lot of people",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "production line",
    definition:
      "a line of workers and machines in a factory that make, put together, and check the parts of a product",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "salary",
    definition: "money that employees get monthly for doing their job",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "solve problems",
    definition:
      "to find ways of dealing with difficult things or things that go wrong",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "surprising",
    definition:
      "making you feel surprised, because you did not expect something",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "teamwork",
    definition: "the activity of working well together as a team",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "temporary",
    definition: "existing only for a short period of time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "turnover",
    definition:
      "the rate at which employees leave a company and are replaced by other people",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 10",
  },
  {
    word: "aisle seat",
    definition: "a seat in a plane that is next to the walkway",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "board",
    definition: "to get on a plane",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "boarding card",
    definition: "a card that you show before you get on a plane",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "business class",
    definition:
      "a type of seat on a plane that offers more comfort and service for business travellers",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "cancel",
    definition: "to decide that sth that has been arranged will not happen",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "change",
    definition:
      "small amounts of money that you get back when you have given too much for sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "check-in",
    definition:
      "the place in an airport where you go before your flight, to show your ticket and give in your bags",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "credit card",
    definition:
      "a small plastic card that you can use to buy goods and services and pay for them later",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "delay",
    definition:
      "a period of time when you have to wait because sth is slow or late",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "economy class",
    definition:
      "the cheapest type of seat on a plane, that offers the lowest level of comfort and service",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "exchange rate",
    definition:
      "the amount of a currency (= money used in a country) that you get in exchange for a unit of another currency",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "expiry date",
    definition:
      "the date after which a passport, credit card, agreement etc. is no longer valid",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "gate",
    definition:
      "a way out of an airport through which passengers go to get on their plane",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "hand luggage",
    definition: "small bags that you can keep with you on an aircraft",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "miss a flight",
    definition: "to arrive too late for a plane",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "pack",
    definition:
      "to put clothes, etc. into a bag in preparation for a trip away from home",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "passport control",
    definition:
      "the place in an airport or at a border where passports are checked",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "queue",
    definition: "[BrE] a line of people who are waiting for sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "reservation",
    definition: "an arrangement for a room in a hotel to be kept for you",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "room service",
    definition:
      "a service provided in a hotel, allowing guests to order food and drink to be brought to their rooms",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "security",
    definition: "protection against attack or danger",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "sightseeing",
    definition: "visiting interesting buildings or places as a tourist",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "stressful",
    definition: "causing worry or anxiety",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "terminal",
    definition: "a building at an airport where passengers arrive and leave",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "tour guide",
    definition:
      "a person who takes tourists around an interesting building or place",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "vacancy",
    definition: "a room that is empty in a hotel",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "wake-up call",
    definition: "a telephone call from a hotel reception to wake you up",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "window seat",
    definition: "a seat on a plane that is next to the window",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 11",
  },
  {
    word: "aim",
    definition: "the thing that sb plans or wants to do",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "annual leave",
    definition: "the time off work that sb is allowed each year",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "autumn",
    definition:
      "the season of the year after summer, when leaves fall from the trees",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "busy period",
    definition:
      "a time when there is a lot of activity because a lot of people want to do sth, buy sth etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "database",
    definition:
      "a computer record that contains a lot of organized information",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "deadline",
    definition: "a point in time by which sth must be done",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "event",
    definition:
      "a thing that happens, especially an important planned occasion",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "expect",
    definition: "to believe that sth will happen based on what you know",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "launch",
    definition:
      "an event at which a new product is made available for the first time",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "long weekend",
    definition:
      "a weekend that includes an extra day or two of holiday on Friday or Monday",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "packaging",
    definition: "materials used to wrap or protect a product when it is sold",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "predict",
    definition: "to say what will happen based on what you know",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "public holiday",
    definition: "a day when most shops, businesses, and schools close",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "quiet period",
    definition:
      "a time when there is not much activity because not many people want to do sth, buy sth etc.",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "raw materials",
    definition: "basic materials that are used to make a profit",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "reminder",
    definition: "a letter or note asking sb to remember to do sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "schedule",
    definition: "a plan of the work you need to do and when you need to do it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "shelf",
    definition:
      "a flat board fixed to the wall or as part of a cupboard, to put things on",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "ship",
    definition: "to send goods to sb",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "spring",
    definition:
      "the season of the year after winter, when plants and flowers start to grow again",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "summarize",
    definition: "to make a short statement giving the main points of sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "summer",
    definition:
      "the season of the year after spring, when it is hottest and people often have their main holiday",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "tight deadline",
    definition:
      "a date by which sth must be done that does not allow you much time to complete it",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "time off",
    definition: "time when you do not have to be at work",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "timetable",
    definition: "a list which shows when particular events will happen",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "update",
    definition: "to add the most recent information to sth",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },
  {
    word: "winter",
    definition:
      "the season of the year after autumn, when it is coldest and the days are shortest",
    book: "Business Result",
    level: "elementary",
    unit: "Unit 12",
  },

  {
    word: "annual",
    definition: "relating to a period of one year",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "based in",
    definition: "working or living in a particular place",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "commercial",
    definition:
      "connected with buying and selling goods and services; connected with carrying goods or passengers who pay",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "community",
    definition:
      "all the people who live in a particular area, country, etc. when they are talked about as a group",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "competitor",
    definition:
      "a person or an organization that tries to be better than others, especially in business",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "convenience food",
    definition:
      "food that you buy frozen or in a box that you can prepare and cook very quickly and easily",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "employ",
    definition: "to give sb a job to do for payment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "employee",
    definition: "a person who is paid to work for sb",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "environment",
    definition: "the natural world in which people, animals, and plants live",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "exporter",
    definition:
      "a person, company, or country that exports goods (sells them to another country)",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "guide",
    definition:
      "a book that gives information about a place for travellers or tourists",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "head office",
    definition: "the main office of a company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "lock",
    definition:
      "a device that keeps sth such as a door, lid, etc. closed, usually needing a key to open it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "manufacture",
    definition: "to make goods in large quantities, using machinery",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "mechanism",
    definition: "a set of moving parts in a machine that performs a task",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "offer",
    definition: "to make sth available or to provide the opportunity for sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "operate",
    definition: "to work in a particular way or from a particular place",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "produce",
    definition: "to make things to be sold, especially in large quantities",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "product",
    definition: "something that is produced or grown to be sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "provide services",
    definition: "to offer facilities for the public to use",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "recycled packaging",
    definition:
      "materials used to wrap goods that have already been used once, in order to reduce waste and harm to the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "retail",
    definition: "the selling of goods to the public, usually through shops",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "sales",
    definition: "the number of items sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "security system",
    definition:
      "things that protect a building or place against crime, danger, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "sell",
    definition: "to give sth to sb in exchange for money",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "signature",
    definition: "your name as you usually write it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "specialize in",
    definition:
      "to be an expert in a particular area of work, study, or business",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },
  {
    word: "subsidiary",
    definition:
      "a company that is owned or controlled by another larger company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 1",
  },

  {
    word: "abroad",
    definition: "to or in other countries, not your home country",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "automobile",
    definition: "a car",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "book",
    definition:
      "to arrange with a company to have a plane ticket, hotel room, restaurant table, etc. on a particular date",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "buy",
    definition: "to get something by paying money for it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "chain",
    definition:
      "a group of shops, stores, cinemas, or hotels owned by the same company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "confirm",
    definition: "to say that an arrangement is definite",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "customer",
    definition: "someone who buys something from a business or shop",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "delivery",
    definition: "taking goods to the person who has bought them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "develop",
    definition: "to produce a new product and make it successful",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "electronics",
    definition:
      "the use of electronic technology in business and in products such as CD players, games, televisions, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "employ",
    definition: "to give a job to someone",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "equipment",
    definition: "the things that are needed for doing something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "export",
    definition: "to sell and send goods to another country",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "financial services",
    definition:
      "services connected with money, for example, insurance and banking",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "flexible",
    definition:
      "able to change when necessary, to suit new conditions or situations",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "hospitality",
    definition:
      "the business of providing food, drink, rooms, etc. for customers",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "industry",
    definition:
      "the people and activities that produce a particular thing, or provide a particular service",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "job fair",
    definition:
      "an event where people who are looking for a new job can meet companies that are looking for new employees",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "million",
    definition: "the number 1,000,000",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "office supplies",
    definition:
      "things such as paper and pens that are needed for work in an office",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "order",
    definition: "to ask for goods to be supplied or a service to be provided",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "pharmaceuticals",
    definition: "drugs or medicines",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "produce",
    definition: "to make things to be sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "provide",
    definition: "to offer goods or a service",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "real estate",
    definition: "the business of selling land or buildings",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "recruitment",
    definition: "to find new people to join a company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "repeat",
    definition: "to say something again",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "sell",
    definition: "to give something to someone for money",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "service",
    definition: "things that a business does for its customers",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "slowly",
    definition: "not quickly; at a slow speed",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "software",
    definition: "programs used to operate a computer",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "staff",
    definition: "the people who work in an organization",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "stock",
    definition: "the goods that are available for sale",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "transport",
    definition:
      "a system for carrying people or goods from one place to another",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 2",
  },
  {
    word: "check",
    definition:
      "to examine sth to find out if it is correct, safe, or acceptable",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "create",
    definition: "to produce sth or make it happen",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "credit",
    definition: "an arrangement to have sth and pay for it later",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "customer service",
    definition:
      "the way in which a company treats its customers and answers their questions or complaints",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "defects",
    definition: "faults in sth or in the way it has been made",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "department",
    definition: "a section of a large organization",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "diagram",
    definition:
      "a simple drawing using lines to explain where sth is, how sth works, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "divided into",
    definition: "separated into parts",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "finance",
    definition:
      "the activity of managing money, especially by a commercial organization or a government",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "graph",
    definition:
      "a planned drawing, consisting of a line or lines, showing how two or more sets of numbers are related to each other",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "host",
    definition:
      "a person or organization that has invited guests to a place or event",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "human resources",
    definition:
      "the department in a company that deals with employing and training people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "in charge of",
    definition: "responsible for sth / sb or in control of sb / sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },

  {
    word: "logistics",
    definition:
      "the activity of planning and organizing the supply of materials, goods, and staff",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "maintain",
    definition:
      "to keep sth such as a machine or building in good condition by checking or repairing it regularly",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "marketing",
    definition:
      "the activity of presenting, advertising, and selling the company's products in the best possible way",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "national lottery",
    definition:
      "a game played in a particular country in which tickets with different numbers on them are sold and then numbers are chosen by chance to see who has won prizes",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "operations",
    definition:
      "the main activities that a business does to make money, for example selling goods or services or manufacturing products",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "organize",
    definition: "to arrange for sth to happen or to be provided",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "pie chart",
    definition:
      "a diagram consisting of a circle that is divided into sections to show the size of particular amounts in relation to the whole",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "purchasing",
    definition:
      "the activity of buying things that a company needs, such as materials, parts, equipment, services, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "quality control",
    definition:
      "a system of keeping high standards in products by planning carefully, checking, and making necessary improvements",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "report to",
    definition:
      "to have a particular manager who is responsible for your work and tells you what to do",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "research and development",
    definition:
      "the work of trying to find new products and processes or improving existing ones",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "responsible for",
    definition:
      "having the job or duty of doing sth or taking care of sb / sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "search engine",
    definition:
      "a computer program that searches the Internet for information, especially by looking for documents containing a particular word or group of words",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "security",
    definition: "the activities or processes involved in keeping a place safe",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "technical support",
    definition:
      "help with the use of machinery or equipment, especially things relating to computers",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "training",
    definition:
      "the process of teaching people the skills that they need to do a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "value",
    definition: "the quality of being useful or important",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "visitor",
    definition:
      "someone who is visiting a person or a place that is not their home, their usual place of work, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 3",
  },
  {
    word: "advertise",
    definition:
      "to tell people about a product or a service so that they will buy or use it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "attractive",
    definition: "pleasant to look at",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "brand",
    definition: "a type of product made by a particular company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "brand",
    definition:
      "to give a particular name, design, etc. to a product that you sell",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "design",
    definition:
      "the plan or arrangement of the different parts of a product, building, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "design",
    definition:
      "to decide how something will look, work, etc., especially by drawing plans or making models",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "development process",
    definition:
      "the series of stages by which something such as a new product is created",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "economical",
    definition: "providing good value in relation to the amount of money spent",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "entrepreneur",
    definition:
      "a person who makes money by starting a business, especially when it involves taking financial risks",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "exhibition",
    definition:
      "a collection of things, for example works of art, that are shown to the public",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "farmer",
    definition: "a person who owns or manages a farm",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "festival",
    definition:
      "a series of performances of music, plays, etc. over a period of days, usually taking place once a year in the same place and often outdoors",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "functional",
    definition: "practical and useful",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "funding",
    definition:
      "money for a particular purpose; the act of providing money for such a purpose",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "gadget",
    definition: "a small tool or device that does something useful",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "invest",
    definition:
      "to buy property, shares in a company, etc. in the hope of making a profit",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "launch",
    definition: "to make a product available to the public for the first time",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "maintenance",
    definition:
      "the act of keeping something in good condition by checking or repairing it regularly",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "market research",
    definition:
      "the work of collecting information about what people buy and why",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "original idea",
    definition:
      "idea at the beginning of a particular period, process, or activity",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "practical",
    definition: "useful or suitable",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "resident",
    definition:
      "a person who lives in a particular place or who has their home there",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "sponsor",
    definition:
      "a person or company that pays for or supports something, sometimes in return for advertising",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "stage",
    definition:
      "a period or state that something or somebody passes through while developing or making progress",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "stylish",
    definition: "fashionable; elegant and attractive",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "tent",
    definition:
      "a shelter made of a large sheet of strong cloth that is supported by poles and ropes fixed to the ground, and is used for camping",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "trials",
    definition:
      "tests of the quality or performance of something, especially before you make a final decision about them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "user-friendly",
    definition: "easy for people who are not experts to use or understand",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "well-designed",
    definition: "planned in a way that looks good and works well",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 4",
  },
  {
    word: "application form",
    definition:
      "a piece of paper printed with questions, etc., on which you make a formal request for something such as a job or position",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "apply for",
    definition:
      "to make a formal request, usually in writing, for something such as a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "availability",
    definition: "the fact that somebody is free to see or talk to people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "benefit",
    definition:
      "an advantage that you get from a company in addition to the money that you earn",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "bonus",
    definition:
      "an extra amount of money that is added to a payment, especially to someone's wages as a reward",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "candidate",
    definition: "a person who is applying for a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "career",
    definition: "the particular area that somebody works in",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "construction",
    definition:
      "the process or method of building or making something, especially roads, buildings, bridges, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },

  {
    word: "diverse",
    definition: "including many different kinds of people or things",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "employer",
    definition: "a person or company that pays people to work for them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "enclose",
    definition:
      "to put something in the same envelope, package, etc., as something else",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "fill in a form",
    definition: "to complete a form, etc., by writing information on it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "flexible hours",
    definition: "working hours that can be changed to suit somebody",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "humanitarian crisis",
    definition:
      "a bad situation that is causing great suffering for a group of people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "inform",
    definition:
      "to tell somebody about something, especially in an official way",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "interview",
    definition:
      "a formal meeting at which somebody is asked questions to see if they are suitable for a particular job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "maternity leave",
    definition:
      "a period of time when a woman is allowed to temporarily leave her job to have a baby",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "membership",
    definition:
      "the state of being a member of a group, a club, an organization, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "paid holiday",
    definition:
      "a period of time when you do not have to be at work, but are still paid",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "pension scheme",
    definition:
      "a system in which you, and usually your employer, pay money regularly into a fund while you are employed - you are then paid a regular amount of money when you retire",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "private healthcare",
    definition:
      "medical care that you pay for and is not provided free by the state",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "profit",
    definition:
      "money that a business makes, especially after paying the costs involved",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "recruitment",
    definition: "the process of finding new people to join an organization",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "referee",
    definition:
      "a person who gives information about your character and ability, usually in a letter, for example, when you are applying for a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "shortlisted",
    definition:
      "put on a list as one of the best people, chosen from all the people who applied for a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "subsidized childcare",
    definition:
      "care of children while parents are at work that is partly paid for, for example by a company or by the state",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "update",
    definition: "to add the most recent information to something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "vacancy",
    definition: "a job that is available for somebody to do",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "video clip",
    definition: "a short piece of film recorded on video",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 5",
  },
  {
    word: "balloon",
    definition:
      "a small bag made of very thin rubber that becomes larger and rounder when you fill it with air or gas; they are brightly coloured and used as decorations or toys",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "call centre",
    definition:
      "an office in which a large number of people work using telephones, for example taking customers' orders and answering questions",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "ceremony",
    definition: "a formal public occasion, for example to celebrate something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "conduct surveys",
    definition: "to carry out research on public opinion",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "consumer affairs",
    definition:
      "issues that affect consumers (people who buy a company's goods or use their services)",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "deal with complaints",
    definition:
      "to take action to solve problems that someone has with something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "discount",
    definition:
      "an amount of money that is taken off the usual cost of something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "dissatisfied",
    definition: "not happy or satisfied with somebody or something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "encourage customer loyalty",
    definition: "to make something better for customers so they come back",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "executive chair",
    definition: "a good-quality chair, suitable for senior people in an office",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "helpful",
    definition: "willing or able to help somebody",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "impossible",
    definition: "something that cannot be done",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "keep to your delivery dates",
    definition: "to deliver the product when you promised or agreed to",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "log on",
    definition:
      "to perform the actions that allow you to begin using a computer system",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "loyal",
    definition:
      "remaining faithful to somebody or something and supporting them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "loyalty card",
    definition:
      "a card given to customers by a company that rewards them for buying goods by giving points, which will allow them to pay less for goods in the future",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "meet the needs of the customer",
    definition: "to give the customer what they need",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "nominate",
    definition:
      "to formally suggest that somebody should be chosen for a prize, position, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "offer a personalized service",
    definition:
      "to provide a service that is designed or changed so that it is suitable for the needs of a particular person",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "petrol pump",
    definition:
      "a machine that is used to put petrol (liquid fuel) into a vehicle",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "receipt",
    definition:
      "a piece of paper that shows that goods or services have been paid for",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "refund",
    definition:
      "a sum of money that is paid back to you, for example because you paid too much or returned goods to a shop",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "reliable",
    definition:
      "something or someone that can be trusted to do things well or correctly",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "replacement",
    definition:
      "a thing that replaces something, especially because the first thing is old, broken, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "returns policy",
    definition:
      "the rules that a company has about how customers can return goods if they are not happy with them, and whether they get their money back",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "reward",
    definition:
      "a thing that you are given because you have done something good",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "satisfied",
    definition: "pleased because you got what you wanted",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "strategy",
    definition: "a plan that is used to achieve a particular purpose",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "unreliable",
    definition:
      "something or someone that cannot be trusted to work well or to do things the right way",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 6",
  },
  {
    word: "bill",
    definition:
      "a piece of paper that shows how much you owe sb for goods or services",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "board",
    definition: "to get on a ship, train, plane, bus, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "check-in",
    definition:
      "the place where you go first when you arrive at an airport, to hand in your bags, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "check in",
    definition:
      "to go to a desk in a hotel, an airport, etc. and tell an official there that you have arrived",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "check out",
    definition: "to pay your bill and leave a hotel, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "connection",
    definition:
      "a train, bus, or plane at a station or an airport that a passenger can take soon after getting off another in order to continue their journey",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "delay",
    definition:
      "a period of time when sb/sth has to wait because of a problem that makes sth slow or late",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "departure lounge",
    definition:
      "a place in an airport where you can sit and wait until your plane is ready to leave",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "departures",
    definition:
      "the part of an airport where passengers go who are leaving on a plane",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "double bed",
    definition: "a bed made for two people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "facilities",
    definition:
      "buildings, services, equipment, etc. that are provided for a particular purpose",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "flight",
    definition: "a journey made by air, especially in a plane",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "gate",
    definition:
      "the place in the airport where passengers go to get on their plane",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "key card",
    definition: "a plastic card that unlocks sth, especially a hotel room",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "luggage",
    definition:
      "bags, cases, etc. that contain sb's clothes and things when they are travelling",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "one-way ticket",
    definition:
      "a ticket that can be used for travelling to a place, but not back again",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "overseas",
    definition: "to, from, or in a foreign country",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "pack",
    definition:
      "to put clothes, etc. into a bag in preparation for a trip away from home",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "receptionist",
    definition:
      "a person whose job is to deal with people arriving at or telephoning a hotel, an office building, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "reservation",
    definition:
      "an arrangement for a seat on a plane or train, a room in a hotel, etc. to be kept for you",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "return ticket",
    definition:
      "a ticket that can be used for travelling to a place and back again",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "safe",
    definition:
      "a strong metal box or cupboard with a lock, used for storing valuable things",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "security pass",
    definition:
      "a piece of plastic or card that shows who sb is and that they have the right to be in a place, as a way of protecting the place against crime, attack, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "shuttle bus",
    definition: "a bus that travels regularly between two places",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "sign in",
    definition:
      "to write your name when you arrive at or leave an office, a club, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "single bed",
    definition: "a bed made for one person",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "terminal",
    definition:
      "a building or set of buildings at an airport where air passengers arrive and leave",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "valuables",
    definition:
      "things that are worth a lot of money, especially small personal things such as jewellery, cameras, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 7",
  },
  {
    word: "check",
    definition: "to find out if sth is correct or true",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "collect",
    definition: "to go somewhere in order to take sb / sth away",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "confirm",
    definition: "to state or show that sth is definite or official",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "deliver",
    definition:
      "to take goods, letters, etc. to the person or people they have been sent to",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "delivery",
    definition:
      "the act of taking goods, letters, etc. to the people they have been sent to",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "disappointed",
    definition:
      "sad because sth you hoped for has not happened or been as good, successful, etc. as you expected",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "enquiry",
    definition: "a request for information about sb / sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "global carrier",
    definition: "a company that transports goods all over the world",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "goods",
    definition: "things that are produced to be sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "in stock",
    definition: "available to be sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "initial",
    definition: "the first letter of a word, usually a person's name",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "invoice",
    definition:
      "a list showing what you must pay for goods that have been sold, work that has been done etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "logo",
    definition:
      "a printed design or symbol that a company uses as its special sign",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "make a complaint",
    definition: "to formally state that you are not satisfied",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "out of stock",
    definition: "not available to be sold",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "package",
    definition: "to put sth into a box, bag, etc. to be sold or transported",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "place an order",
    definition: "to make a request for a company to supply goods",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "process",
    definition: "to deal officially with a document, request, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "purchase",
    definition: "to buy sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "quote",
    definition:
      "to tell a customer how much money you will charge them for a job, service, or product",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "renegotiate",
    definition: "to discuss changes to the terms of an agreement",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "shipment",
    definition: "a load of goods that are sent from one place to another",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "track",
    definition: "to follow the movement, progress, or development of sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "warehouse",
    definition:
      "a building where large quantities of goods are stored, especially before they are sent to shops or customers",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 8",
  },
  {
    word: "ad",
    definition:
      "a notice, picture, or film telling people about a product, job, or service",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "advertisement",
    definition:
      "a notice, picture, or film telling people about a product, job, or service",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "advertising campaign",
    definition: "a series of advertisements for a product, service, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "attract customers",
    definition:
      "to make customers feel interested in sth or want to go somewhere",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "billboard",
    definition:
      "a large board on the outside of a building or at the side of the road, used for putting advertisements on",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "boost sales",
    definition: "to increase sales",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "click on a link",
    definition:
      "to choose an item on a computer screen by pressing one of the buttons on a mouse",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "direct mailing",
    definition:
      "the practice of sending advertisements directly to particular people through the post",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "download",
    definition:
      "to copy information from the Internet or from another computer onto your computer",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "enter a market",
    definition: "to start selling goods or services in a particular market",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "expand",
    definition: "to make sth become bigger in size, number, or importance",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "improve market share",
    definition:
      "to sell more of your products or services compared with other companies than you did in the past",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "non-refundable",
    definition:
      "that cannot be returned, for example if customers are not happy or change their minds",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "offer a discount",
    definition: "to take an amount of money off the usual cost of sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "online",
    definition: "done using the Internet",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "outdoor",
    definition: "happening or located outside rather than in a building",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "outdoors",
    definition: "outside rather than in a building",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "pastime",
    definition: "something that you enjoy doing when you are not working",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "preceding",
    definition: "coming or happening before sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "press ad",
    definition: "an advertisement put in a newspaper or magazine",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "promote a name",
    definition:
      "to make more people aware of the name of a product, company, etc., for example by advertising it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "range",
    definition: "a set of products of a particular type",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "relocation",
    definition:
      "the action of moving sth, especially a company or its workers, to a new place to work or operate",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "slot",
    definition:
      "a position, a time, or an opportunity for sb / sth, for example in a list, a programme of events or a series of broadcasts",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "target audience",
    definition:
      "the group of people that a TV programme, advertisement, etc. is aimed at",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "word-of-mouth",
    definition: "the process of people telling each other about sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 9",
  },
  {
    word: "affordable",
    definition:
      "that does not cost too much money, so that people can afford to do or buy it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "carbon emissions",
    definition:
      "carbon dioxide and carbon monoxide gases that are sent out into the air, harming the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "carpooling",
    definition:
      "the practice of travelling to work together in one car and sharing the cost between several people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "climate change",
    definition:
      "the change in the world's weather that is believed to be caused by human activity",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "commission",
    definition: "to officially ask sb to write, make, or carry out sth for you",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "convenient",
    definition: "useful, easy or quick to do; not causing problems",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "create",
    definition: "to make sth happen or exist",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "disposal",
    definition: "the act of getting rid of sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "ecological",
    definition: "connected with or concerned about the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "effective",
    definition: "producing a successful result",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "environmentally-friendly",
    definition: "not harming the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "good value for money",
    definition: "a good price for something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "hybrid",
    definition:
      "using a mixture of two or more types of fuel, for example a petrol engine and an electric motor, so that less harm is caused to the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "incentive",
    definition: "something that encourages you to do sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "initiative",
    definition:
      "a new plan for dealing with a particular problem or for achieving a particular purpose",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "insulated",
    definition:
      "protected with a material that prevents heat, sound, electricity, etc. from passing through",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "original",
    definition:
      "new and interesting in a way that is different from anything before",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "outsource",
    definition:
      "to arrange for sb outside a company to do work or provide goods for that company",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "popular",
    definition: "liked or enjoyed by a large number of people",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "postage",
    definition: "the cost of sending a package, letter, etc. by post",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "recycling",
    definition:
      "the practice of treating materials that have already been used so that they can be used again",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "renewable",
    definition:
      "that is replaced naturally or controlled carefully and can therefore be used without the risk of finishing it all",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "rubbish",
    definition:
      "things that you throw away because you no longer want or need them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "unusual",
    definition: "different from what is usual or normal",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "useful",
    definition: "that can help you to do or achieve what you want",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "vending machine",
    definition:
      "a machine from which you can buy snacks, drinks, etc. by putting coins into it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "waste",
    definition:
      "no longer needed for a particular process and therefore thrown away",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 10",
  },
  {
    word: "accept an invitation",
    definition: "to say 'yes' to an invitation or offer",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "anniversary",
    definition:
      "a date that is an exact number of years after the date of an important or special event",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "arrange a trip",
    definition: "to plan or organize a trip",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "book",
    definition:
      "to arrange with a hotel, restaurant, theatre, etc. to have a room, table, seat, etc. on a particular date",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "budget",
    definition:
      "the money that is available to a person or an organization to spend on a particular thing",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "celebrate",
    definition:
      "to show that a day or an event is important by doing sth special on it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "convention",
    definition:
      "a large meeting of the members of a profession, a political party, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "corporate entertainment",
    definition:
      "activities organized by companies to entertain their customers, business partners, or staff",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "dessert",
    definition: "sweet food eaten at the end of a meal",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "entertain clients",
    definition:
      "to invite customers to eat, drink, or enjoy amusing activities as your guests",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "event",
    definition: "a planned public or social occasion",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "financial loss",
    definition:
      "money that has been lost by an organization because sth cost more than it earned",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "guests",
    definition:
      "people that you have invited to an event that you are paying for",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "hold an event",
    definition:
      "to organize, and usually pay for, a public or social occasion such as a large meeting, dinner, party, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "hospitality",
    definition:
      "food, drink, or services that are provided by an organization for guests, customers, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "host company",
    definition: "a company that organizes an event to which guests are invited",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "pay rise",
    definition: "an increase in the amount of money you are paid for your work",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "pie",
    definition:
      "meat, vegetables, fish, or fruit baked in a dish with pastry on the bottom, sides and top",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "purpose",
    definition:
      "the reason for doing sth or the thing that it is supposed to achieve",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "reinforce a relationship",
    definition: "to make a relationship stronger or more successful",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "salmon",
    definition:
      "a large fish with silver skin and pink flesh that is used for food",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "seafood",
    definition: "fish and sea creatures that can be eaten",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "sue",
    definition:
      "to make a claim against sb in court about sth that they have done to harm you",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "venue",
    definition:
      "a place where people meet for an organized event, for example a concert, sporting event, or conference",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 11",
  },
  {
    word: "achieve a target",
    definition: "to succeed in reaching a particular goal",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "approximate",
    definition: "almost correct or accurate, but not completely so",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "approximation",
    definition:
      "an estimate of a number or an amount that is almost correct, but not exact",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "disappointing",
    definition: "not as good, successful, etc. as you had hoped",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "diversity",
    definition: "the quality of including a range of many people or things",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "dramatically",
    definition: "to a very great and surprising degree",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "durable",
    definition:
      "likely to last for a long time without breaking or getting weaker",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "encouraging",
    definition: "giving support, courage, or hope",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "environmental performance",
    definition:
      "how well a business performs in terms of carrying out its activities without harming the environment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "excellent",
    definition: "extremely good",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "lay off",
    definition:
      "to stop employing sb because there is not enough work for them to do",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "manage your costs",
    definition:
      "to use money, time, etc. in a sensible way so that it does not cost too much to do sth",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "market share",
    definition:
      "the amount of sales of a product that a company has, compared with total sales of that product",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "perform",
    definition: "to work or function well or badly",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "poor",
    definition: "not good; of a quality that is low or lower than expected",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "replace",
    definition:
      "to change sth that is old, damaged, etc. for a similar thing that is newer or better",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "reputation",
    definition:
      "the opinion that people have about what sb / sth is like, based on what has happened in the past",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "revenues",
    definition: "the money that an organization receives from its business",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "safety record",
    definition:
      "the facts about how an organization performs in relation to its products and activities being safe",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "satisfactory",
    definition: "good enough for a particular purpose",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "socially responsible",
    definition:
      "doing things in a way that shows concern for society and people in general",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "stock market",
    definition:
      "the business of buying and selling shares in companies and the place where this happens",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "workplace",
    definition:
      "the place where sb works, or the places where people work in general",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 12",
  },
  {
    word: "automobile",
    definition: "a car",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "component",
    definition: "one of several parts of which something is made",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "deteriorate",
    definition: "to become worse",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "economic development",
    definition:
      "the growth of the economy in a place so that it becomes bigger, more successful, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "energy crisis",
    definition:
      "a bad situation in which it is difficult or very expensive to get enough fuel to provide the power that homes and industries need",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "estimate",
    definition:
      "to form an idea of the cost, size, value, etc. of something, but without calculating it exactly",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "flexibility",
    definition:
      "the willingness or ability to change to suit new conditions or situations",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "forecast",
    definition:
      "to say what you think will happen in the future based on information that you have now",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "global demand",
    definition:
      "the desire or need of customers across the world for particular goods or services",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "improve",
    definition: "to make something better than before",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "microwave oven",
    definition:
      "a type of oven that cooks or heats food very quickly using electromagnetic waves",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "oil shortage",
    definition: "a situation when there is not enough oil",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "population growth",
    definition:
      "an increase in the size of the population (the number of people living in a place)",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "predict",
    definition: "to say that something will happen in the future",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "productivity",
    definition:
      "the rate at which a worker, a company, or a country produces goods, compared with how much time, work, and money is needed to produce them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "renewable energy",
    definition:
      "types of energy that are replaced naturally so they can be used without the risk of using them all",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "run out",
    definition: "to use up or finish a supply of something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "scheme",
    definition: "a plan or system for doing or organizing something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "teleworking",
    definition:
      "the practice of working from home, communicating with your office, customers, and others by telephone, email, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "trend",
    definition:
      "a general direction in which a situation is changing or developing",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "workforce",
    definition:
      "all the people who work for a particular company, organization, etc.",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "world supply",
    definition: "the amount of something that is available across the world",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 13",
  },
  {
    word: "agenda",
    definition: "list of items to be discussed at a meeting",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "allow time",
    definition:
      "to have or reserve a certain amount of time for doing something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "budget figures",
    definition:
      "details of the amount of money that is available to be spent in a particular way",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "confirmation",
    definition:
      "a statement, letter, etc. that shows that something is correct or definite",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "construction site",
    definition: "an area of land where something is being built",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "meet a deadline",
    definition: "to succeed in doing something by an agreed time",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "multi-task",
    definition: "to do several things at the same time",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "negotiate",
    definition: "to arrange or agree something by formal discussion",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "on time",
    definition: "by or within the time allowed",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "participant",
    definition: "a person who is taking part in an activity or event",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "plan a schedule",
    definition:
      "to make a plan listing everything that you have to do and when you must do each thing",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "save time",
    definition: "to avoid using too much time or more time than is necessary",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "spend time",
    definition: "to use time in a particular way",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "strike",
    definition:
      "a period of time when an organized group of employees of a company stops working because of a disagreement over pay or conditions",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "task",
    definition: "a piece of work that somebody has to do",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "transport",
    definition:
      "to take something/somebody from one place to another in a vehicle",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "waste time",
    definition: "to use more time than is necessary",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 14",
  },
  {
    word: "achieve a goal",
    definition: "to succeed in doing a particular thing",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "appraisal",
    definition:
      "a meeting in which an employee discusses with their manager how well they have been doing their job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "coach",
    definition:
      "a person who trains somebody to do something or gives lessons or advice",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "coaching",
    definition: "the work of training people or giving lessons or advice",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "develop skills",
    definition:
      "to start to have an ability or quality that becomes better and stronger",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "manufacturer",
    definition: "a company that produces goods in large quantities",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "motivate",
    definition:
      "to make somebody want to do something, especially something that involves hard work and effort",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "objective",
    definition: "something that you are trying to achieve",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "promotion prospects",
    definition:
      "the chances that somebody has of being promoted (moved to a more senior job)",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "set goals",
    definition: "to decide on things that you hope to achieve",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "take a step back",
    definition:
      "to think about a situation calmly, as if you are not involved in it yourself",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "underwear",
    definition:
      "clothes that you wear under other clothes and next to the skin",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "down-payment",
    definition:
      "a sum of money that is given as the first part of a larger payment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "enrol",
    definition: "to arrange for yourself to officially join a course of study",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "executive",
    definition:
      "a person who has an important job as a manager of a company or an organization",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "feedback",
    definition:
      "advice, criticism, or information about how good or useful something is",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "improve performance",
    definition: "to do something better than before",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "individual",
    definition: "connected with or designed for one person",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "interview panel",
    definition:
      "a group of people who are interviewing somebody, for example for a job",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 15",
  },
  {
    word: "ambition",
    definition: "something that you want to do or achieve very much",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "antisocial",
    definition: "wanting the company of others",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "career path",
    definition:
      "the particular way that somebody's career progresses, or a particular series of jobs that somebody has",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "career plan",
    definition: "a plan for how somebody will progress in their career",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "cause",
    definition: "an organization or idea that people support or fight for",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "celebrity",
    definition: "a famous person, especially one whose job is in entertainment",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "challenge",
    definition:
      "a new or difficult task that tests somebody's ability and skill",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "change direction",
    definition:
      "to start doing something completely different to before in your work or life",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "civil service",
    definition:
      "government departments in a country, except the armed forces, and the people who work for them",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "complete",
    definition: "to finish making or doing something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "concentrate on",
    definition:
      "to give all your attention to something and not think about anything else",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "consultant",
    definition:
      "a person who knows a lot about a particular subject and is employed to give advice about it",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "extreme",
    definition: "very great in degree",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "freelance",
    definition:
      "earning money by selling your work or services to several different organizations rather than being employed by one particular organization",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "give up",
    definition: "to stop doing or having something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "high-powered",
    definition: "important, with a lot of responsibility",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "hobby",
    definition: "an activity that you do for pleasure when you are not working",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "make a decision",
    definition: "to decide something",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "poverty",
    definition: "the state of being poor",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "qualification",
    definition:
      "an exam that you have passed or a course of study that you have successfully completed",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "run",
    definition: "to be in charge of a business or part of a business",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "strength",
    definition:
      "a quality or an ability that a person has that gives them an advantage",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "trauma",
    definition: "part of a hospital where people come who have injuries",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "weakness",
    definition:
      "a lack of a quality or ability that a person has, that can cause problems",
    book: "Business Result",
    level: "pre-intermediate",
    unit: "Unit 16",
  },
  {
    word: "abroad",
    definition: "to or in other countries; not in your home country",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "aim",
    definition:
      "something that you are trying to achieve; your purpose in doing sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "application",
    definition:
      "a formal written request for sth, such as money or permission to do sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "attendee",
    definition: "a person who goes to a place or an event such as a meeting",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "bureaucracy",
    definition:
      "the system of official rules and ways of doing things that an organization or government has, especially when these seem too complicated",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "challenging",
    definition: "difficult in a way that is interesting or satisfying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "conditions",
    definition:
      "the circumstances in which people work or do sth, for example the physical environment around them or the hours that they have to work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "consultant",
    definition:
      "a person who knows a lot about a particular subject and is employed to give advice about it to other people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "corruption",
    definition:
      "dishonest or illegal behaviour, especially by people in authority",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "demanding",
    definition: "needing a lot of effort, skill, hard work, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "depressing",
    definition: "making sb feel very unhappy or without hope",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "development",
    definition:
      "the process of becoming better or more advanced, or the process of creating sth new or more advanced",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "donation",
    definition: "the act of giving money, equipment, etc. to sb to help them",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "dull",
    definition: "not interesting or exciting; boring",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "estimate",
    definition:
      "to form an idea of the cost, size, value etc. of sth, without calculating it exactly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "event",
    definition: "a planned public or social occasion",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "fundraiser",
    definition: "a person who collects money for charity or an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "generate",
    definition: "to produce or create sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "glamorous",
    definition:
      "appearing to be attractive and exciting, and different from ordinary things or people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "grant",
    definition:
      "a sum of money that is given by the government or by another organization to be used for a particular purpose",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "impression",
    definition:
      "an opinion or idea that you get about sb / sth when you see or meet them",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "maintenance",
    definition:
      "the act of keeping sth in good condition by checking or repairing it regularly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "negotiation",
    definition:
      "the process of holding formal discussions in order to reach an agreement",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "networking",
    definition:
      "a system of meeting and talking to other people who may be useful or helpful to you in your work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "promotion",
    definition: "a move to a more important job in a company or organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "recognition",
    definition: "praise and rewards for the work that you do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "recruitment",
    definition:
      "the process of finding new people to join a company or an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "results",
    definition: "things that are achieved or produced successfully",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "rewarding",
    definition: "providing benefits such as a feeling of achievement",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "routine",
    definition:
      "done or happening as a normal part of a particular job, situation, or process",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "series",
    definition:
      "several events or things of a similar kind that happen one after the other",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "sponsor",
    definition:
      "a person or company that helps pay the costs of sth such as a special event or scientific project, usually in order to advertise their products",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "statement",
    definition:
      "something that you say or write that gives information or an opinion",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "stressful",
    definition: "causing a lot of anxiety and worry",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "target",
    definition: "a result that you try to achieve",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "varied",
    definition: "not staying the same, but changing often",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "variety",
    definition:
      "the quality of not staying the same or not doing the same things all the time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "worthwhile",
    definition:
      "interesting and enjoyable, especially because you feel you are doing sth important or useful to others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 1",
  },
  {
    word: "accomplished",
    definition: "very good or skilful at a particular thing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "advice",
    definition:
      "an opinion or a suggestion about what sb should do in a particular situation",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "allocate resources",
    definition:
      "to decide officially that money, labour, etc. will be used for a particular purpose",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "budget",
    definition:
      "an amount of money that is available to a person or organization to spend on sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "catch up",
    definition:
      "to spend extra time or do extra work because you have not done sth earlier, so that you finish everything you planned to finish",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "catering",
    definition:
      "the work of providing food and drinks for meetings or social events",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "charity",
    definition: "an organization whose aim is to help people in need",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "committed",
    definition:
      "believing strongly in sth and willing to give time and energy to it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "community",
    definition:
      "all the people who live in a particular place, for example the area near to where a company is based, when talked about as a group",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "crucial",
    definition: "very important",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "deadline",
    definition: "a point in time by which sth must be done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "decorating",
    definition:
      "the job of putting paint, wallpaper, etc. on the walls and ceilings inside a room or building",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "delegate tasks",
    definition: "to give particular pieces of your work to other people to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "develop",
    definition:
      "to think of or produce a new idea, product, etc. and make it successful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "environmentally-friendly",
    definition: "not harming the environment",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "equip",
    definition:
      "to supply sb / sth with things such as machines or materials that are needed for a particular purpose",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "essential",
    definition:
      "extremely important; completely necessary so that sth can be done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "experience",
    definition:
      "an event or sth that happens to you, that affects you in some way",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "fall behind schedule",
    definition:
      "to progress too slowly, so that things are not done by the time they were planned to be done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "finish ahead of schedule",
    definition: "to finish earlier than planned",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "get on with a job",
    definition: "to continue or make progress with sth you have to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "get updates",
    definition: "to get the most recent information about sth from sb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "invitation",
    definition:
      "a written or spoken request to sb to do sth, such as attend an event",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "issue",
    definition: "to give sth to sb, especially officially",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "leaflet",
    definition:
      "a printed sheet of paper or a few printed pages that are given free, in order to advertise or give information about sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "loan",
    definition:
      "money that a person or company borrows from an organization such as a bank",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "meet a deadline",
    definition:
      "to succeed in doing sth by the point in time when it was agreed it should be done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "objectives",
    definition: "things that you are trying to achieve",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "on track",
    definition:
      "happening in the right way to achieve a particular result at the time that was planned",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "organic",
    definition: "produced without using artificial chemicals",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "plastering",
    definition:
      "the activity of covering a wall, etc. with plaster (a substance that is put on walls and ceilings to give them a smooth, hard surface)",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "plumbing",
    definition:
      "the system of pipes, etc. that supply water to a building and take waste water away",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "predicted",
    definition: "expected to happen or stated by sb to be going to happen",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "profitable",
    definition: "that makes or is likely to make money",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "progress",
    definition: "development towards an improved or more advanced condition.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "raise awareness",
    definition:
      "to help people know that sth exists, to understand more about it, and to know that it is important",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "resources",
    definition:
      "a supply of sth that a country, an organization, or a person has and can use, especially to increase their wealth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "schedule",
    definition:
      "a plan that lists all the work that you have to do and when you must do each thing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "skill",
    definition:
      "a particular ability or type of ability, especially one that needs training or experience",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "staff",
    definition: "all the people who work for a company or organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "stationery",
    definition:
      "materials used for writing, such as paper, pens, and envelopes",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "succeed",
    definition: "to achieve what you were trying to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "sustainability",
    definition:
      "the ability to continue for a long time without harming the environment",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "teamwork",
    definition: "the activity of working together as a group to achieve sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "time-consuming",
    definition: "taking a lot of time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "timely",
    definition: "done or happening at the right time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "tracking",
    definition:
      "the activity of finding out where sth or sb is and when sth is happening",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "up-to-date",
    definition: "including the most recent information",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "volunteer",
    definition:
      "a person who does sth to help others, but does not get paid for it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "workload",
    definition:
      "the amount of work that has to be done by a person, a machine, or a system",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 2",
  },
  {
    word: "accommodation",
    definition: "a place to live or stay",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "assignment",
    definition:
      "a piece of work that sb is given to do, usually as part of their job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "bored",
    definition:
      "to have lost interest in it so that you do not want to do it, have it, etc. any more",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "boring",
    definition: "not interesting; making you feel tired and impatient",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "contact",
    definition:
      "a person that you know, especially sb who can be helpful to you in your work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "corridor",
    definition:
      "a long narrow passage in a building, with doors that open into rooms on either side",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "department",
    definition:
      "a section of a large organization such as a business, government, university, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "do exercise",
    definition:
      "to take part in physical activity in order to stay healthy or become stronger",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "do overtime",
    definition: "to work extra time in addition to your normal hours at work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "do work",
    definition: "to do your job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "emphasis",
    definition: "extra force or importance that is given to sth that you say",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "employee",
    definition: "a person who is paid to work for sb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "enjoyable",
    definition: "giving pleasure",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "excited",
    definition: "feeling or showing happiness and enthusiasm",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "exciting",
    definition: "causing great interest, happiness, or enthusiasm",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "exercise",
    definition:
      "physical activity done in order to stay healthy or become stronger",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "exhilarated",
    definition: "feeling very happy and excited",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "exhilarating",
    definition: "causing sb to feel very happy and excited",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "fascinating",
    definition: "extremely interesting and attractive",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "flexitime",
    definition:
      "a system in which employees work a particular number of hours each week or month, but can choose when they start and finish work each day",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "frightened",
    definition: "afraid; feeling fear",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "frightening",
    definition: "making you feel afraid; causing fear",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "hard work",
    definition:
      "work that needs or uses a lot of physical strength or mental effort",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "human resources",
    definition:
      "the department in a company that deals with employing and training people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "interested",
    definition:
      "feeling like you want to give your attention to sth because you enjoy finding out about it or doing it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "interesting",
    definition:
      "attracting your attention because of being special, exciting, or unusual",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "make progress",
    definition: "to improve or develop",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "make time",
    definition: "to find an occasion when time is available to do something.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "make useful contacts",
    definition: "to meet people who could be helpful to you in your business",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "novel",
    definition:
      "a book containing one long story, in which the characters and events are usually imaginary",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "overtime",
    definition:
      "extra time that you work in addition to your normal hours at work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "paid leave",
    definition:
      "a period of time when you are allowed to be away from work and you are paid as usual",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "plenty",
    definition: "a large amount; as much or as many as you need",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "politely",
    definition:
      "in a way that shows good manners and respect for the feelings of others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "progress",
    definition: "the process of improving or developing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "relaxing",
    definition: "helping you to rest and become less anxious",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "take holiday",
    definition:
      "to be away from work for a period of time with permission, in order to have a holiday/vacation",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "take paid leave",
    definition:
      "to be away from work for a period of time, for example for a holiday/vacation, when you have permission and are being paid as usual",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "take time off",
    definition: "to be away from work for a period of time with permission",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "time off",
    definition: "time when you are allowed to be away from work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "tired of",
    definition:
      "feeling that you have had enough of sb/sth because you no longer find them/it interesting or because they make you angry or unhappy",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "tiring",
    definition: "making you feel tired; causing tiredness",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "training",
    definition:
      "the process of learning the skills that you need to do a particular job or activity",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "uncomfortable",
    definition: "causing or feeling slight pain or physical discomfort.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "uncomfortable with",
    definition: "not feeling happy or confident about sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "workload",
    definition: "the amount of work that you have to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 3",
  },
  {
    word: "access",
    definition:
      "the chance or permission to use or have sth, for example, information or a service",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "account",
    definition:
      "an arrangement with a bank to keep your money there, which means you can pay money in and take it out when you need it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "accurate",
    definition: "correct and true in every detail",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "allow",
    definition:
      "to give permission to someone to do something or do not prevent them from doing it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "cashier",
    definition:
      "a person whose job is to receive and pay out money in a bank, shop / store, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "categorize",
    definition:
      "to put things or people into groups according to what type they are",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "choice",
    definition: "the number or range of different things from which to choose",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "combine",
    definition:
      "to put two or more different things, features, or qualities together or to do two or more things at the same time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "consumer",
    definition: "a person who buys goods or services for their own use",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "continuously",
    definition:
      "in a way that happens for a long period without interruption, or is repeated many times",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "convenient",
    definition: "useful or easy; not causing problems",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "cost-effective",
    definition:
      "giving the best possible profit or benefits in comparison with the money that is spent",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "customize",
    definition:
      "to make or change sth to suit the particular needs of one person",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "database",
    definition:
      "an organized set of data that is stored in a computer and can be looked at and used in various ways",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "deal",
    definition:
      "an agreement, especially in business, on a price and on other conditions for buying or doing sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "deliver",
    definition:
      "to take or supply sth to a particular person or people that it has been sent to",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "demand",
    definition: "to ask for sth very firmly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "efficient",
    definition: "doing sth well with no waste of time, money, or energy",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "encourage",
    definition:
      "to persuade sb to do sth by making it easier for them and making them believe it is a good thing to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "functionality",
    definition:
      "the range of functions that sth such as a computer, device, or system can perform",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "help",
    definition:
      "if you ____ sb to do sth, you make it easier or possible for them to do it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "immediate",
    definition: "happening or done without delay",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "incentive",
    definition:
      "something that is offered as a reward to encourage you to do sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "let",
    definition:
      "if you ___ sb do sth, you allow them to do it or do not try to stop it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "local",
    definition:
      "belonging to or connected with the particular place that you are talking about, or with the place where you live",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "make it easier",
    definition:
      "if you _____ __ _______ for sb to do sth, you do sth or give them sth that helps them to do it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "multiple",
    definition: "many in number; involving many different people or things",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "promotion",
    definition:
      "activities done in order to increase the sales of a product or service",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "queue",
    definition: "a line of people, cars, etc. waiting for sth or to do sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "relationship",
    definition:
      "the way in which two people or groups behave towards each other or deal with each other",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "relevant",
    definition:
      "closely connected with the subject you are discussing or the situation you are thinking about",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "relocate",
    definition: "to move or to move sb / sth to a new place to work or operate",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "review",
    definition:
      "a report in a newspaper or magazine, or on the Internet, television, or radio, in which sb gives their opinion of sth such as a book, play, website, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "screen",
    definition:
      "the flat surface at the front of a television or computer, on which you see pictures or information",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "secure",
    definition: "safe from danger; unable to be harmed or affected by sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "separately",
    definition: "not together; not at the same time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "site",
    definition:
      "a place where a building, town, etc. was, is, or will be located",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "store",
    definition: "to keep information or facts in a computer",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "suburb",
    definition:
      "an area where people live that is outside the centre of a city",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "time-saving",
    definition: "that reduces the amount of time needed for a particular task",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "tourist",
    definition:
      "a person who is visiting a place for pleasure and interest, or for education",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "user-friendly",
    definition: "easy for people to use or understand",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "verify",
    definition: "to check or prove that sth is true or accurate",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 4",
  },
  {
    word: "assess",
    definition:
      "to make a judgement about the nature, quality, or value of sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "assist",
    definition: "to help sb to do sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "attend",
    definition: "to be present at an event",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "based in",
    definition: "working or doing business in or from a particular place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "care",
    definition:
      "the process of providing sb with what they need and treating them well because they are important to you",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "check",
    definition: "to examine sth to see if it is correct, safe, or acceptable",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "comment",
    definition:
      "if you _______ on sth, you show that you have noticed it and give your opinion on it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "confirm",
    definition:
      "to say that an arrangement, an agreement, etc. is definite or official",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "current",
    definition: "happening or being used now, at the present time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "customer",
    definition:
      "a person or an organization that buys sth from a shop/store or business",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "discount",
    definition: "an amount of money that is taken off the usual cost of sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "ensure",
    definition: "to make sure that sth happens",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "equipment",
    definition:
      "the things such as machines and materials that are needed for a particular activity",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "evaluate",
    definition:
      "to form an opinion of the amount, value, or quality of sth after thinking about it carefully",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "expect",
    definition: "to think or believe that sth will happen",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "expectations",
    definition:
      "a belief about the way sth should happen or how sb should behave",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "external",
    definition:
      "happening outside an organization, or involving people who come from outside an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "instructions",
    definition: "detailed information on how to do or use sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "internal",
    definition:
      "involving or concerning people who are part of an organization, rather than people from outside it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "issue",
    definition: "a problem or worry that sb has with sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "layout",
    definition:
      "the way in which the parts of sth such as the equipment in an office, the land in a garden, or the parts of the page of a book are arranged",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "monitor",
    definition:
      "to watch and check sth over a period of time in order to see how it develops",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "provide",
    definition: "to give sth to sb or make it available for them to use",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "receive",
    definition: "to get or accept sth that is sent or given to you",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "recently",
    definition: "not long ago",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "refurbish",
    definition:
      "to clean and decorate a room, building, etc. in order to make it more attractive, more useful, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "rehabilitation",
    definition:
      "the process of returning to a normal life after having been very ill/sick or injured",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "require",
    definition: "to need or want sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "requirements",
    definition: "the things that you need or want",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "satisfaction",
    definition:
      "the good feeling that you have when you have achieved or got what you wanted",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "service",
    definition:
      "the work of doing sth for customers, or a business whose work involves doing sth for customers rather than producing goods",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "specific",
    definition: "detailed and exact",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "support",
    definition:
      "help that is given to sb such as a customer, for example, help with using a product",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "tailor",
    definition:
      "to make or adapt sth for a particular purpose, a particular person, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "treadmill",
    definition:
      "an exercise machine that has a moving surface that you can walk or run on while remaining in the same place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "unique",
    definition: "being the only one of its kind",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 5",
  },
  {
    word: "admit",
    definition: "to agree, often unwillingly, that sth is true",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "assemble",
    definition:
      "to fit together all the separate parts of sth, for example, a piece of furniture",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "bunny suit",
    definition:
      "a loose piece of clothing like a shirt and trousers/pants in one piece, usually worn over other clothing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "chamber of commerce",
    definition:
      "a group of local business people who work together to help business and trade in a particular town",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "check in",
    definition:
      "to go to a desk at an airport and tell an official there that you have arrived for your flight",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "conference",
    definition:
      "a large official meeting, usually lasting for a few days, at which people with the same work or interests come together",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "delay",
    definition:
      "a period of time when sb/sth has to wait because of a problem that makes sth slow or late",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "delegate",
    definition:
      "a person who is chosen to represent a group of people and vote and make decisions for them, for example, at a conference",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "drop off",
    definition:
      "to take sb somewhere in your car, usually on the way to another place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "dust",
    definition:
      "the fine powder of dirt that forms in buildings, on furniture, floors, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "eat out",
    definition: "to have a meal in a restaurant, rather than at home",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "enemy",
    definition: "something that harms sth or prevents it from being successful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "entertainment",
    definition:
      "things that people watch and listen to in order to have fun and relax",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "equivalent",
    definition:
      "something that is equal in value, amount, meaning, importance, etc. to sth else",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "etiquette",
    definition:
      "formal rules of correct or polite behaviour in society or among members of a particular profession",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "excursion",
    definition:
      "a short journey made for pleasure, especially one that has been organized for a group of people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "exhibition",
    definition:
      "a collection of things, for example, products or works of art, that are shown to the public",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "facilities",
    definition:
      "services, equipment, etc. that are provided so that sb can do a particular thing, for example in a hotel",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "freshen up",
    definition: "to wash and make yourself look clean and tidy",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "go out",
    definition:
      "to leave your house or the place where you are staying to go to a social event",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "guest",
    definition:
      "a person that you have invited to your house or to a particular event, organization, meeting, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "hospitality",
    definition: "friendly and generous behaviour towards guests",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "jewellery",
    definition:
      "objects such as rings and necklaces that people wear as decoration",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "look around",
    definition:
      "to visit a place or building, walking around it to see what is there",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "meet up with",
    definition: "to meet sb, especially by arrangement",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "nightlife",
    definition: "entertainment that is available in the evening and at night",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "off-limits",
    definition: "where people are not allowed to go",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "outcome",
    definition: "the result or effect of an action or event",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "pick up",
    definition:
      "to go somewhere in your car and collect sb who is waiting for you",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "pleasure",
    definition:
      "the activity of enjoying yourself, especially in contrast to working",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "prohibition",
    definition: "a rule or statement that says sth cannot be done or used",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "show around",
    definition:
      "to be a guide for sb when they visit a place for the first time to show them what is interesting",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "sightseeing",
    definition:
      "the activity of visiting interesting buildings and places as a tourist",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "souvenir",
    definition:
      "a thing that you buy when you travel, and give as a gift or keep to remind yourself of a place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "speciality",
    definition:
      "[BrE] a type of food or product that a restaurant or place is famous for",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "subordinate",
    definition:
      "a person who has a position with less authority and power than sb else in an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "synthetic",
    definition:
      "artificial; made by combining chemical substances rather than being produced naturally by plants or animals",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "tour",
    definition:
      "an act of walking around a building, organization, town, etc. in order to visit it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "uncommon",
    definition: "unusual or rare",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "venue",
    definition:
      "a place where people meet for an event, especially a sports or musical event",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "visit",
    definition: "to go to a place in order to spend time there and see it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "vow",
    definition: "a serious promise",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "whistle",
    definition:
      "a clear, high-pitched sound made by forcing breath through a small hole between partly closed lips, or between one's teeth.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 6",
  },
  {
    word: "advantage",
    definition: "a quality of sth that makes it better or more useful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "announce",
    definition:
      "to tell people sth officially, especially about a decision, plans, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "aware",
    definition:
      "if you are ______ of sth, you know that it exists and is important",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "breach",
    definition:
      "a failure to do sth that must be done, or an action that breaks a rule or agreement",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "deter",
    definition:
      "to make sb decide not to do sth or continue doing sth, especially by making them understand the difficulties and unpleasant results of their actions",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "disadvantage",
    definition:
      "something that causes problems and tends to stop sb / sth from succeeding or making progress",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "fraud",
    definition:
      "the crime of cheating sb in order to get money or goods illegally",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "gain access",
    definition:
      "to manage to reach or enter a place, or to use or see sth such as information on a computer",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "identity theft",
    definition:
      "using sb else's name and personal information in order to obtain credit cards and other goods or to take money out of the person's bank accounts",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "illegal",
    definition: "not allowed by the law",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "lift",
    definition:
      "[BrE] a machine that carries people or goods up and down to different levels in a building",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "load",
    definition: "to put a large quantity of things or people onto or into sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "measure",
    definition:
      "an official action that is done in order to achieve a particular aim",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "monitor",
    definition:
      "to watch sth over a period of time in order to check that a particular thing does not happen, or check how sth develops",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "network",
    definition:
      "number of computers and other devices that are connected together so that equipment and information can be shared",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "notify",
    definition: "to formally or officially tell sb about sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "pass",
    definition:
      "an official document or ticket that shows that you have the right to enter or leave a place, to travel on a bus or train, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "password",
    definition:
      "a secret word or series of letters or numbers that you need to know in order to be allowed into a place, or that you must type into a computer or computer system in order to be able to use it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "procedure",
    definition: "a way of doing sth, especially the usual or correct way",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "review",
    definition:
      "to carefully examine or consider sth again, especially so that you can decide if it is necessary to make changes",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "safeguard",
    definition:
      "to protect sth / sb from loss, harm, or damage; to keep sth / sb safe",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "security breach",
    definition:
      "an action that means that sth that is normally protected is no longer secure",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "security camera",
    definition:
      "a camera that is used to film what happens in or around a building, in order to protect the building from attack, crime, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "security pass",
    definition:
      "an official document or card that shows that you have the right to enter or leave a building, an office, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "security procedures",
    definition:
      "ways of doing things and rules that must be followed in order to protect a building, organization, etc. against attack, danger, or crime",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "security staff",
    definition:
      "people in an organization who are responsible for protecting it against attack, danger, or crime",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "swipe",
    definition:
      "to pass a plastic card, such as a credit card, through a special machine that is able to read the information that is stored on it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "transaction",
    definition:
      "a piece of business that is done between people, especially an act of buying or selling",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "unauthorized access",
    definition:
      "a situation in which sb enters a place, or uses or sees sth such as information on a computer, when they do not have permission or the right to do this",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 7",
  },
  {
    word: "blame",
    definition: "to think or say that sb / sth is responsible for sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "candidate",
    definition:
      "a person who is applying for a job or who is considered suitable or likely to get a job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "chart",
    definition:
      "a page or sheet of information in the form of diagrams, lists of figures, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "common ground",
    definition: "similar ideas, interests, or knowledge",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "complementary skills",
    definition:
      "abilities that are different, but together form a useful combination",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "conduct business",
    definition:
      "to take part in the activity of making, buying, selling, or supplying goods or services for money",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "continent",
    definition:
      "one of the large land masses of the earth such as Europe, Asia, or Africa",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "detail",
    definition:
      "the small facts or features of sth, that may not be easy to notice",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "form alliances",
    definition:
      "to make an agreement with other people or groups to work together in order to achieve sth that you all want",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "freelancer",
    definition:
      "a person who earns money by selling their work or services to several different organizations, rather than being employed by one particular organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "join forces",
    definition: "to work together in order to achieve a shared aim",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "joint venture",
    definition:
      "a project that is being worked on by two or more people or groups",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "merge",
    definition:
      "to combine, or make two or more organizations combine to form a single organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "mutual benefit",
    definition:
      "an advantage or useful result that helps both the people or groups involved in sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "prediction",
    definition: "a statement that says what you think will happen",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "premises",
    definition: "the building and the land around it that a business uses",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "response",
    definition: "a spoken or written answer",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "shared goals",
    definition:
      "similar things that two or more people or groups all want to achieve",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "suspicion",
    definition: "the feeling that you cannot trust sb / sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "take responsibility",
    definition:
      "to accept a duty to deal with sth, so that you may be blamed if sth goes wrong",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "team player",
    definition:
      "a person who is good at working as a member of a team, usually in their job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "work closely",
    definition:
      "to spend a lot of time working with another person or with other people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "worrying",
    definition: "that makes you worry",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 8",
  },
  {
    word: "appropriate",
    definition:
      "suitable, acceptable, or correct for the particular circumstances",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "average",
    definition:
      "typical or normal; calculated by adding several amounts together, finding a total, and dividing the total by the number of amounts",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "bill",
    definition:
      "a document that shows how much you owe sb for goods or services",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "breathtaking",
    definition: "very impressive or exciting",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "challenge",
    definition: "a new or difficult task that tests sb's ability and skill",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "client",
    definition:
      "a person who buys goods or uses services provided by a company, a professional person, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "component",
    definition: "one of several parts from which sth is made",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "container",
    definition:
      "a large metal or wooden box of a standard size in which goods are packed so that they can easily be transported by ship, train, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "courier",
    definition:
      "a person or company whose job is to take packages or important papers somewhere",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "demand",
    definition:
      "the desire or need of customers for goods or services which they want to buy or use",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "dispatch",
    definition: "to send a letter, package, or message somewhere",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "distribute",
    definition:
      "to send goods to shops / stores and businesses so that they can be sold",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "distributor",
    definition:
      "a person or company that supplies goods from the company that makes them to shops / stores, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "enormous",
    definition: "extremely large",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "home improvement",
    definition: "changes that are made to a house, that increase its value",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "in stock",
    definition: "available for sale",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "inventory",
    definition:
      "goods that a business has available for sale at a particular time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "keep track of",
    definition:
      "to have recent information about what is happening or where sb / sth is",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "manufacturer",
    definition: "a company or person that produces goods in large quantities",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "motherboard",
    definition: "the main board of a computer, containing all the circuits",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "out of stock",
    definition: "not ready or not available to be bought",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "performance",
    definition: "how well or badly sth works or is done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "raw material",
    definition: "a basic material that is used to make a product",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "retailer",
    definition: "a business or person that sells goods to the public",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "run low on",
    definition: "to have nearly used up or finished a supply of sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "run out of",
    definition: "to use up or finish a supply of sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "solution",
    definition:
      "a way of solving a problem or dealing with a difficult situation",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "stock up on",
    definition: "to buy a lot of sth so that you can use it later",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "streamlined",
    definition:
      "working well in a way that does not use any unnecessary money, time, or effort",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "supplier",
    definition:
      "a person or company that supplies goods or services to another company",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "supply chain",
    definition:
      "the series of processes involved in the production and supply of goods, from when they are first made, grown, etc. until they are bought or used",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "ultimately",
    definition: "in the end; finally",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "urgently",
    definition: "in a way that needs to be dealt with or happen immediately",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "warehouse",
    definition:
      "a building where large quantities of goods are stored, especially before they are sent to shops / stores to be sold",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 9",
  },
  {
    word: "amount",
    definition: "a sum of money",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "atmosphere",
    definition:
      "the feeling or mood that you have in a particular place or situation",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "autism",
    definition:
      "a mental condition in which a person finds it difficult to communicate or form relationships with others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "badly-equipped",
    definition:
      "not provided with the things that are needed for a particular purpose or activity",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "beautifully maintained",
    definition:
      "kept in a very good condition by being regularly checked and repaired",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "breast cancer",
    definition:
      "a form of the disease cancer (a serious disease in which cells grow in the body and kill normal body cells) that affects the breasts",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "canteen",
    definition:
      "a place where food and drink are served in a factory, a school, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "complain",
    definition:
      "to say that you are annoyed, unhappy, or not satisfied about sb / sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "complex",
    definition: "a group of buildings of a similar type together in one place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "co-operation",
    definition:
      "the fact of doing sth together or of working together towards a shared aim",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "cramped",
    definition: "not having enough space for the people in it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "crèche",
    definition:
      "a place where babies and small children are taken care of while their parents are working, studying, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "desire",
    definition: "a strong wish to have or do sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "extremely",
    definition: "to a very high degree",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "fairly",
    definition: "to some extent, but not very",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "initial",
    definition: "happening at the beginning; first",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "interactive",
    definition:
      "that involves people working together and having an influence on each other, or that involves information being passed in both directions between sth such as a computer and the person who uses it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "interior",
    definition:
      "the way that the inside of a building, room, or car is designed and decorated, including fabrics, carpets, paint, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "lid",
    definition:
      "a cover over a container that can be removed or opened by lifting or turning it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "locker",
    definition:
      "a small cupboard that can be locked, where you can leave your clothes, bags, etc. while you work, play a sport, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "maintained",
    definition:
      "kept in a good condition by being regularly checked and repaired",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "not exactly",
    definition:
      "not very; sometimes used when you are saying the opposite of what you mean",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "old-fashioned",
    definition: "not modern or fashionable",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "pretty",
    definition: "to quite a large extent",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "productivity",
    definition:
      "the rate at which a worker, a company, or a country produces goods, and the amount produced, compared with how much time, work, and money is needed to produce them",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "profit",
    definition:
      "the money that you make in business or by selling things, especially after paying the costs involved",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "quite",
    definition: "to some degree",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "really",
    definition: "very; used to emphasize an adjective or adverb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "recommendation",
    definition: "an official suggestion about the best thing to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "run down",
    definition: "in very bad condition; that has not been taken care of",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "spacious",
    definition: "large and with plenty of space for people to move around in",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "state-of-the-art",
    definition:
      "using the most modern or advanced techniques or methods; as good as it can be at the present time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "successful",
    definition: "having become popular and / or made a lot of money",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "up-to-date",
    definition: "new or recent",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "well-equipped",
    definition:
      "provided with all the things that could be needed for a particular purpose or activity",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 10",
  },
  {
    word: "argument",
    definition: "a discussion in which people disagree, often angrily",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "avoid confrontation",
    definition:
      "to prevent a situation in which there is angry disagreement between people or groups",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "backing",
    definition: "support or approval",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "brainstorm",
    definition:
      "to share thoughts about something as a group, in order to solve a problem or to create new ideas",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "branch",
    definition:
      "a local office or shop/store belonging to a large company or organization, especially a bank",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "carry out a decision",
    definition: "to take action based on something that has been decided",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "celebrate",
    definition:
      "to show that an event is important or that you are happy about it by doing something special",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "collaborative",
    definition:
      "involving, or done by several people or groups of people working together",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "competition",
    definition:
      "an event in which people compete with each other to find out who is the best at something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "complaint",
    definition:
      "a statement that someone makes saying that they are not satisfied",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "compromise",
    definition:
      "an agreement made between two people or groups in which each side gives up some of the things they want so that both sides are happy at the end",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "consensus",
    definition: "an opinion that all members of a group agree with",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "concession",
    definition:
      "something that you allow or do, or allow someone to have, in order to end an argument or make a situation less difficult",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "confrontation",
    definition:
      "a situation in which there is an angry disagreement between people or groups who have different opinions",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "consultation",
    definition:
      "the act of discussing something with someone or with a group of people before making a decision about it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "co-operative",
    definition:
      "a business or organization that is owned and run by the people involved, with the profits shared by them",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "democratic",
    definition:
      "based on the principle that all members have an equal right to be involved in running an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "dress code",
    definition: "rules about what clothes people should wear at work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "evaluate ideas",
    definition:
      "to form an opinion of the quality or value of ideas after thinking about them carefully",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "express an opinion",
    definition:
      "to show or make known your feelings or thoughts about something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "go for",
    definition: "to choose something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "have a say",
    definition:
      "to have the right to influence something by giving your opinion before a decision is made",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "hierarchy",
    definition:
      "a system in an organization in which people are organized into different levels of importance from highest to lowest",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "in favour",
    definition:
      "if you are __ _____ of someone or something, you support and agree with them/it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "independent",
    definition: "not part of a larger company or group of companies",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "instinct",
    definition:
      "a feeling that makes you do something or believe that something is true, even though it is not based on facts or reason",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "investigate",
    definition:
      "to find out information and facts about a subject or problem by study or research",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "leak",
    definition:
      "to give secret information to the public, for example, by telling a newspaper",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "magnet",
    definition:
      "a person, place, or thing that someone or something is attracted to",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "majority",
    definition: "the largest part of a group of people or things",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "make a suggestion",
    definition: "to mention an idea or a plan for someone else to think about",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "make up your mind",
    definition: "to decide something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "maximum",
    definition:
      "the greatest amount, size, speed, etc. that is possible, recorded, or allowed",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "option",
    definition: "something that you can choose to have or do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "panic",
    definition:
      "a situation in which people are made to feel very anxious, causing them to act quickly and without thinking carefully",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "patriotic",
    definition: "having or expressing a great love of your country",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "persuasive",
    definition: "able to persuade someone to do or believe something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "praise",
    definition:
      "to express your approval or admiration for someone or something",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "proposal",
    definition: "a suggestion or plan put forward",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "put forward",
    definition: "to suggest something, such as an idea, for discussion",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "reach a consensus",
    definition: "to find an opinion that all members of a group agree with",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "rejection",
    definition:
      "the act of not accepting, or refusing to accept, something or someone",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "rule",
    definition:
      "an official instruction that tells people what they must or must not do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "self-interest",
    definition:
      "the fact of only thinking about your own advantage or gain, rather than the good of others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "short-term",
    definition:
      "relating to a period of time that is near the present and not far in the future",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "standing order",
    definition: "an order for a payment or delivery to be made regularly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "suggest",
    definition: "to put forward a proposal, idea, or plan",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "suggestion",
    definition:
      "an idea, plan, or action put forward for others to consider or discuss",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "take into account",
    definition: "to consider something when making a decision or judgment",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "team-oriented",
    definition: "focused on the idea of working well with others in a group",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "under pressure",
    definition:
      "feeling stress because of having too many things to do in a limited amount of time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "voting",
    definition:
      "the act of giving your opinion on an issue, for example in an election",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 11",
  },
  {
    word: "a-ha moment",
    definition: "a moment when you suddenly understand or realize sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "asset",
    definition: "a thing or person that is valuable or useful to sb / sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "badge",
    definition:
      "a small piece of metal or plastic, with a design or words on it, that a person wears to show their name, the organization that they belong to, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "brainchild",
    definition: "an idea or invention of one person or a small group of people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "catalyst",
    definition: "a person or thing that causes a change",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "come up with",
    definition: "to find or produce an idea, an answer, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "concept",
    definition: "an idea or a principle",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "convince",
    definition: "to make sb believe that sth is true",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "dynamic",
    definition: "always changing and making progress",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "facilitator",
    definition:
      "a person who helps sb do sth or helps a process to take place by discussing problems, giving advice, etc. rather than telling people what to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "graduate",
    definition: "a person who has a university degree",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "income",
    definition:
      "the money that a person or organization earns, especially on a regular basis",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "independence",
    definition:
      "the freedom to organize your own life, make your own decisions, etc. without needing help from other people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "innovative",
    definition:
      "introducing or using new and original ideas, ways of doing sth, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "invention",
    definition:
      "a thing or an idea that has been produced or designed that did not exist before",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "joystick",
    definition:
      "a stick with a handle used to control sth such as a computer game or aircraft",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "mission statement",
    definition:
      "an official statement of the aims of a company or an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "obstacle",
    definition:
      "a situation, an event, etc. that makes it difficult for you to do or achieve sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "original",
    definition:
      "new and interesting in a way that is different from anything that has existed before",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "pension",
    definition:
      "an amount of money paid regularly by a government or company to sb who is considered to be too old or too ill / sick to work",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "prototype",
    definition:
      "the first design of sth from which other forms are copied or developed",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "reliable",
    definition:
      "that you can trust to do sth well or to do what is expected without changing or failing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "represent",
    definition:
      "to be a member of a group of people and act or speak on their behalf at an event, a meeting, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "retire",
    definition:
      "to stop doing your job, especially because you have reached a particular age",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "revolutionary",
    definition: "involving a great or complete change",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "roll",
    definition: "to move smoothly on wheels",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "simple",
    definition: "not complicated; easy to understand or do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "sophisticated",
    definition: "clever and complicated in the way that it works",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "steering wheel",
    definition:
      "the wheel that the driver turns to control the direction that a vehicle goes in",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "traditional",
    definition:
      "following older methods and ideas rather than modern or new ones",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },
  {
    word: "wheelchair",
    definition:
      "a special chair with wheels, used by people who cannot walk because of illness, an accident, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 12",
  },

  {
    word: "approve",
    definition: "to officially agree to a plan, request, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "benefit",
    definition: "to be useful to sb or improve their position in some way",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "break down",
    definition: "(of a machine or vehicle) to stop working because of a fault",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "bug",
    definition: "a fault, especially in a computer system or program",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "capital",
    definition:
      "an amount of money that is invested in or is used to start a business",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "damaged",
    definition: "harmed or spoiled",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "defect",
    definition:
      "a fault in sth or in the way it has been made which means that it is not perfect",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "defective",
    definition: "having been made with a fault or faults; not perfect",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "domestic appliance",
    definition:
      "a machine that is designed to do a particular thing in the home, such as preparing food, or cleaning",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "down",
    definition: "(of a computer or computer system) not working",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "failure",
    definition: "an occasion when sth does not work correctly or as expected",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "faulty",
    definition: "not perfect; not working or made correctly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "fix",
    definition: "to repair or correct sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "flaw",
    definition:
      "a mistake in sth that means that it is not correct or does not work correctly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "go wrong",
    definition:
      "to stop working correctly or to experience problems or difficulties",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "incident",
    definition: "something that happens, especially sth unusual or unpleasant",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "incompatible",
    definition:
      "two things that are of different types so that they cannot be used together. For example a USB port and an HDMI cable",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "lead",
    definition:
      "[BrE] a long piece of wire, usually covered in plastic, that is used to connect a piece of electrical equipment to a source of electricity",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "let down",
    definition: "to fail to help or support sb as they had hoped or expected",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "misunderstanding",
    definition:
      "a situation in which a comment, an instruction, etc. is not understood correctly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "out of order",
    definition: "(of a machine, etc. not working correctly",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "policy",
    definition:
      "a plan of action agreed or chosen by a business, an organization, or a political party",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "quality",
    definition:
      "the standard of sth when it is compared to other things like it, especially when this is a high standard",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "reboot",
    definition:
      "if you ______ a computer or it ______s, you switch it off and then start it again",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "relieve",
    definition: "to make a problem less serious",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "remotely",
    definition:
      "from a distance or from a different machine, using an electronic link",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "report",
    definition:
      "a spoken or written description of sth containing information that sb needs to have",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "unreliable",
    definition: "that cannot be trusted or depended on",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "workload",
    definition:
      "the amount of work that has to be done by a particular person or organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 13",
  },
  {
    word: "apologize",
    definition:
      "to say that you are sorry for doing sth wrong or causing a problem",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "apply",
    definition:
      "to make a formal request, usually in writing, for sth such as a job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "attention",
    definition: "the act of looking at or listening carefully to sb / sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "available",
    definition: "that you can get, buy, or find",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "basic procedure",
    definition: "the way that sth is done, in its simplest form",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "competitive",
    definition: "as good as or better than others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "competitor",
    definition: "a business, person, product etc. that competes against others",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "contract",
    definition:
      "an official written agreement, for example, that sb will do a particular job or provide a particular service",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "disciplinary procedures",
    definition:
      "an officially agreed way that people who break rules are punished in an organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "essentially",
    definition:
      "used when you are going to describe the basic or most important nature of sth / sb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "fare",
    definition: "the money that you pay to travel by plane, train, bus etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "feed into",
    definition: "to supply sth to or put sth into a machine",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "finally",
    definition: "used to introduce the last in a list of things",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "first of all",
    definition: "used to introduce the first in a list of things",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "fuel",
    definition:
      "any material that produces heat or power, usually when it is burnt",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },

  {
    word: "ink",
    definition: "coloured liquid for writing, drawing, and printing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "proceed",
    definition: "to continue doing sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "investor",
    definition:
      "a person or an organization that invests money (buys property or shares in a company or spends money on sth in the hope of making a profit)",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "make out of",
    definition:
      "to create or prepare sth by combining materials or putting parts together",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "once",
    definition: "as soon as; when",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "passenger",
    definition:
      "a person who is travelling in a plane, train, bus, car, or ship and who is not working on it or operating it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "pick up",
    definition: "to take hold of sth and lift it up",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "put in",
    definition: "to move sth into a place or position inside sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "refinery",
    definition:
      "a factory where a substance such as oil is refined (made pure)",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "research",
    definition:
      "careful study of a subject, especially in order to discover new facts or information about it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "revenue",
    definition:
      "the money that is received by a business, usually from selling goods or services",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "short notice",
    definition:
      "a small amount of time before sth happens, so that there is not much warning or opportunity for preparation",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "stage",
    definition: "a separate part that a process, etc. is divided into",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },
  {
    word: "take out",
    definition: "to remove sth from a particular thing or place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 14",
  },

  {
    word: "ambition",
    definition:
      "the desire or determination to be successful, rich, powerful, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "ambitious",
    definition: "determined to be successful, rich, powerful, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "annoying",
    definition: "making sb feel slightly angry",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "anticipate",
    definition:
      "to see what might happen in the future and take action to prepare for it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "appearance",
    definition: "the way that sb / sth looks on the outside",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "appraisal",
    definition:
      "a meeting between an employee and their manager to discuss the quality of the employee's work and to plan future tasks",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "assume",
    definition: "to think or accept that sth is true, but without having proof",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "audience",
    definition:
      "the group of people who have gathered to watch or listen to sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "award",
    definition: "a prize such as money, etc. for sth that sb has done",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "bring up to speed",
    definition:
      "to give sb the most recent and accurate information or knowledge",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "caring",
    definition: "kind, helpful, and showing that you care about other people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "confidence",
    definition: "a belief in your own ability to do things and be successful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "confident",
    definition:
      "feeling sure about your own ability to do things and be successful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "confused",
    definition:
      "unable to understand clearly what is happening or what sb is saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "creative",
    definition:
      "having the skill and imagination to produce sth new or create a work of art",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "creativity",
    definition:
      "the ability to use skill and imagination to produce sth new or create a work of art",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "dedicated",
    definition: "working hard at sth because it is very important to you",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "dedication",
    definition:
      "the hard work and effort that sb puts into an activity or purpose because they think it is important",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "dependability",
    definition: "the quality of being dependable",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "dependable",
    definition: "that can be relied on to do what you want or need",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "enthusiasm",
    definition: "the quality of being enthusiastic",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "enthusiastic",
    definition:
      "feeling or showing a lot of excitement and interest about sb / sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "factory",
    definition: "a building or group of buildings where goods are made",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "flexibility",
    definition: "the quality of being flexible",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "flexible",
    definition: "able to change to suit new conditions or situations",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "hardworking",
    definition: "putting a lot of effort into a job and doing it well",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "health",
    definition: "the condition of a person's body or mind",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "helpful",
    definition: "willing to help sb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "launch",
    definition: "to start an activity, especially an organized one",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "map",
    definition:
      "a drawing or plan of an area or of part of the earth's surface, showing things such as countries, towns, rivers, or streets",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "motivated",
    definition: "willing to do sth that involves hard work and effort",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "motivation",
    definition:
      "the quality of being willing to do sth that involves hard work and effort",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "nominate",
    definition:
      "to formally suggest that sb should be chosen for an important role, prize, position, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "patience",
    definition: "the quality of being patient",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "patient",
    definition:
      "able to wait for a long time or accept annoying behaviour or difficulties without becoming angry",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "punctual",
    definition:
      "arriving or doing sth at the arranged or correct time; not late",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "punctuality",
    definition: "the quality of being punctual",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "raise",
    definition:
      "an increase in the money you are paid for the work that you do; a rise",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "serious",
    definition:
      "that must be treated as important because it could be bad or dangerous",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "shaft",
    definition:
      "a metal bar that joins parts of a machine or an engine together, enabling power and movement to be passed from one part to another",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "slide",
    definition:
      "a small piece of film held in a frame that can be shown on a screen when you shine a light through it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "submit",
    definition:
      "to give a document, proposal, etc. to sb in authority so that they can consider it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "take on",
    definition: "to employ sb",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "take over",
    definition:
      "to start doing sb's job, or take control of an activity or organization",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "tension",
    definition:
      "a situation in which people disagree with each other and feel anger or dislike",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },
  {
    word: "timekeeping",
    definition: "the ability to arrive at work, etc. at the time expected",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 15",
  },

  {
    word: "absolute",
    definition:
      "total and complete; used to give emphasis to what you are saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "achievement",
    definition:
      "a thing that sb has done successfully, especially using their own effort and skill",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "amazing",
    definition: "very good, especially in an unexpected way",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "breakthrough",
    definition:
      "an important development that may lead to an agreement, achievement, or discovery",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "chaotic",
    definition: "in a state of complete confusion and lack of order",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "come across",
    definition: "to find sth by chance",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "complete",
    definition:
      "to the greatest degree possible; used to give emphasis to what you are saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "concern",
    definition: "a feeling of worry about a particular thing",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "cosmetic",
    definition:
      "a product you put on your face or body to make it more attractive",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "disaster",
    definition: "a complete failure",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "discover",
    definition:
      "to find sth, for example to find sth that was hidden or not known about, or find information about sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "endanger",
    definition:
      "to put sb / sth in a situation in which they could be harmed or damaged",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "expertise",
    definition:
      "expert knowledge or skill in a particular subject, activity, or job",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "find out about",
    definition:
      "to get some information about sth / sb by asking, reading, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "flop",
    definition: "something that is not at all successful",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "founded",
    definition:
      "when sth was started, such as an organization or an institution",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "gain",
    definition: "to obtain or win sth that you need or want",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "great",
    definition:
      "much more than average in degree or quantity; used to give emphasis to what you are saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "instant coffee",
    definition:
      "coffee in the form of a powder that can be made quickly and easily by adding hot water",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "know-how",
    definition: "knowledge of how to do sth and experience in doing it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "look for",
    definition: "to try to find sth",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "manage",
    definition: "if you _____ to do sth difficult, you succeed in doing it",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "moisturizer",
    definition: "a cream that is used to make your skin less dry",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "potential",
    definition: "that can develop into sth in the future; possible",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "rank",
    definition:
      "to give sb / sth a particular position on a scale according to quality, importance, success, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "real",
    definition: "genuine or true; used to give emphasis to what you are saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "search",
    definition:
      "to try to find sth, for example to look for information on the Internet",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "share",
    definition: "to tell other people your ideas, experiences, knowledge, etc.",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "shareholder",
    definition: "an owner of shares in a company or business",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "significant",
    definition: "large or important enough to have an effect",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "silk",
    definition:
      "a type of fine smooth cloth made from a fine thread produced by insects called silkworms",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "smuggle",
    definition: "to take goods secretly and illegally into or out of a place",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "spread",
    definition:
      "to affect or make sth affect, be known by, or used by more and more people",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "succeed in",
    definition:
      "if you ______ __ doing sth, you achieve sth that you have been trying to do",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "success",
    definition:
      "the fact that you have achieved sth that you want, or something that has achieved a good result",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "sweater",
    definition:
      "a knitted piece of clothing made of wool or cotton for the upper part of the body, with long sleeves",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "total",
    definition: "complete; used to give emphasis to what you are saying",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "triumph",
    definition: "a great success, achievement, or victory",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "turnover",
    definition:
      "the total amount of goods or services sold by a company during a particular period of time",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "verdict",
    definition:
      "a decision that you make or an opinion that you give about sth, after you have considered it carefully",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },
  {
    word: "waste of time",
    definition:
      "a situation in which it is not worth spending time on sth because it does not succeed or bring any good results",
    book: "Business Result",
    level: "intermediate",
    unit: "Unit 16",
  },

  {
    word: "acquaintance",
    definition: "a person that you know, but who is not a close friend",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "arrogant",
    definition: "behaving in a proud, unpleasant way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "banquet",
    definition:
      "a formal meal for a large number of people, usually for a special occasion, at which speeches are often made",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "bargain",
    definition:
      "to discuss prices, conditions, etc. with someone in order to reach an agreement that is acceptable",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "bound",
    definition:
      "if something is _ _ _ _ _  to happen, it is certain or very likely to happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "build a relationship",
    definition:
      "to create or develop a friendly way of working or dealing with someone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "cabinet",
    definition:
      "a piece of furniture with doors, drawers, and/or shelves, that is used for storing or showing things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "caterer",
    definition:
      "a person or company whose job is to provide food and drinks at a business meeting or for a special occasion",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "come across",
    definition: "to make a particular impression",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "complex",
    definition:
      "difficult to understand because of being made of many different things or parts",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "confident",
    definition:
      "feeling certain that something will happen in the way that you want or expect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "confirm",
    definition:
      "to state that something is definitely true or correct, or that an arrangement is definite or official",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "cover for",
    definition: "to do someone's work or duties while they are away",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "create an impression",
    definition:
      "to give people a particular feeling, idea, or opinion the first time they see something or meet someone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "creativity",
    definition:
      "the quality of being able to produce new ideas or works of art using skill and imagination",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "exhibition",
    definition:
      "a collection of things of a similar kind that are shown to the public",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "expensive",
    definition: "costing a lot of money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "extravagance",
    definition:
      "the act or habit of spending more money than you can afford or than is necessary",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "favourable",
    definition: "making people have a good opinion of someone or something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "forge",
    definition:
      "to create or develop something successful using a lot of effort",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "functional",
    definition: "practical and useful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "gain",
    definition: "to obtain or win something such as an advantage or benefit",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "glossy",
    definition: "printed on smooth, shiny, expensive paper",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "have a reputation",
    definition:
      "to be thought of in a particular way, because of what you have done in the past",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "ineffective",
    definition: "not achieving what you want to achieve; not having any effect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "innovation",
    definition:
      "the introduction of new things, ideas, or ways of doing something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "interpret",
    definition: "to translate one language into another as you hear it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "maternity leave",
    definition:
      "a period of time when a woman temporarily leaves her job to have a baby",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "modest",
    definition: "not very large, expensive, important, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "ostentatious",
    definition:
      "expensive or noticeable in a way that is intended to impress people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "phenomenon",
    definition: "a fact or an event in nature or society",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "portfolio",
    definition:
      "a collection of photographs, drawings, etc., that you use as an example of your work, especially when applying for a job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "premises",
    definition: "the building and the land around it that a business uses",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "principles",
    definition: "moral rules or strong beliefs that influence your actions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "professionalism",
    definition:
      "the high standard that you expect from a person who is well trained in a particular job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "project an image",
    definition:
      "to present someone or something to other people in a particular way, especially one that gives a good impression",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "proof reading",
    definition:
      "the process of checking a copy of printed material and correcting mistakes in it before it is published",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "provisionally",
    definition: "in a way that is not yet definite",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "rapport",
    definition:
      "a friendly relationship in which people understand each other well",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "real estate",
    definition: "property in the form of land or buildings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "recent",
    definition: "that happened or began only a short time ago",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "recommend",
    definition:
      "to tell someone that something is good or useful, or that someone would be suitable for a particular job, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "referral",
    definition:
      "the act of sending someone who needs professional help to a person or place that can provide it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "regarding",
    definition: "concerning someone or something; about someone or something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "represent",
    definition:
      "to be a member of a company or group and act or speak on their behalf",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "reputation",
    definition:
      "the opinion that people have about what someone or something is like, based on what has happened in the past",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "standardized",
    definition:
      "made to have the same features or qualities as other things of the same type",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "successful",
    definition:
      "achieving your aims or achieving success, for example by making a lot of money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "suspicious",
    definition:
      "feeling that someone has done something wrong, illegal, or dishonest, without having any proof",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "switch",
    definition: "to change from one thing to another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "synergy",
    definition:
      "a relationship in which two or more people or companies work together and achieve more energy, power, success, etc., than they could separately",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },

  {
    word: "take a dislike to",
    definition:
      "to feel that you do not like sb/sth, especially when this is sudden",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "text",
    definition:
      "a way of sending written messages using a mobile phone/cellphone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "tradition",
    definition:
      "a belief, custom, or way of doing sth that has existed for a long time among a particular group of people; a set of these beliefs or customs",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "trustworthy",
    definition: "that you can rely on to be good, honest, sincere, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "wary",
    definition:
      "careful when dealing with sb/sth because you think that there may be a danger or problem",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },
  {
    word: "word-of-mouth",
    definition: "spread news by people telling each other",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 1",
  },

  {
    word: "absenteeism",
    definition:
      "staying away from work, especially often and without good reasons",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "achievement",
    definition:
      "the act or process of doing something successfully, especially using your own effort and skill",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "acknowledged",
    definition:
      "accepted publicly as having a particular status or authority, or as having achieved something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "alignment",
    definition: "the correct relationship of things with each other",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "annual bonus",
    definition:
      "extra money that is added to someone’s wages once a year, as a reward",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "appreciation",
    definition:
      "the feeling of being grateful for something or of noticing someone’s good qualities, good work, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "attendance reward",
    definition:
      "something such as extra money that is given to someone because they have come to work regularly and not missed any days",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "autonomy",
    definition:
      "the ability to act and make decisions without being controlled by anyone else",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "better yourself",
    definition:
      "to improve your social position through education, a better job, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "boost staff morale",
    definition: "to increase the confidence and enthusiasm of staff",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "break the ice",
    definition:
      "to say or do something that helps people to feel relaxed and to start talking, especially at the beginning of a meeting, party, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "bump into",
    definition: "to meet someone by chance",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "commission",
    definition:
      "an amount of money that is paid to someone for selling goods and which increases with the amount of goods that are sold",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "company car",
    definition: "a car which is provided by the company that you work for",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "compensation plan",
    definition:
      "[AmE] everything that an employee receives from their employer including pay, benefits, and other rewards",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "dead-end job",
    definition: "a job with low wages and no hope of promotion or improvement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "development",
    definition:
      "the process of gradually improving by learning more, becoming more advanced, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "discount",
    definition:
      "an amount of money that is taken off the usual cost of something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "fare",
    definition: "the money that you pay to travel by bus, plane, taxi, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "feel valued",
    definition:
      "to feel that you and what you do are considered important by other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "filter through",
    definition: "to reach or become known by more people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "foster loyalty",
    definition:
      "to encourage people to feel faithful in their support of sth such as an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "fulfilment",
    definition:
      "a feeling of being happy and satisfied with what you are doing or have done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "generous",
    definition: "giving a large amount; giving freely",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "gossip",
    definition:
      "informal talk or stories about other people's private lives, that may be unkind or not true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "hire",
    definition: "person who has recently been given a job by a company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "horticulture",
    definition:
      "the study or practice of growing flowers, fruit, and vegetables",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "improve performance",
    definition: "to make people do sth better or work more effectively",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "incentive scheme",
    definition:
      "a plan or system for encouraging people to do sth or to work harder by rewarding them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "intervene",
    definition:
      "to become involved in a situation in order to improve or help it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "make the grade",
    definition: "to reach the necessary standard; to succeed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "misconception",
    definition: "a belief or an idea that is not based on correct information",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "monopolize",
    definition:
      "to have or take control of the largest part of sth so that other people are prevented from sharing it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "motivate",
    definition:
      "to make sb want to do sth, especially sth that involves hard work and effort",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "non-cash rewards",
    definition:
      "something other than money that you are given because you have done sth good, worked hard, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "non-contributory pension plan",
    definition:
      "a pension plan (= system of making regular payments into a fund for when a person retires) that is paid for by the employer and not the employee",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "on-time bonus",
    definition:
      "an extra amount of money that is added to sb's wages as a reward for regularly coming to work at the correct time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "perceived",
    definition: "understood or thought of in a particular way by people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "perk",
    definition:
      "something you receive as well as your wages for doing a particular job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "positive feedback",
    definition: "comments that sth or sb's work is good or useful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "praise",
    definition: "words that show approval of or admiration for sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "private medical insurance",
    definition:
      "an arrangement in which regular payments are made to a company who will pay for private medical care for sb if they are ill / sick",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "profit-sharing scheme",
    definition:
      "a system of dividing all or some of a company's profits among its employees",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "range",
    definition: "a set of products of a particular type",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "recognition",
    definition: "public praise and reward for sb's work or actions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "remark",
    definition:
      "something that you say or write which expresses an opinion, a thought, etc. about sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "remuneration package",
    definition: "the pay and other benefits that sb gets for doing their job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "satisfaction",
    definition:
      "the good feeling that you have when you have achieved sth or when things happen in the way that you want",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "staff discount",
    definition:
      "an amount of money that is taken off the usual cost of sth when it is sold to an employee of the company that makes or provides it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "staff retention",
    definition:
      "the action of keeping employees at the same company rather than losing them to other companies",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "subsidiary",
    definition:
      "a business company that is owned or controlled by another larger company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "time in lieu",
    definition:
      "time off that an employee is allowed to take in return for extra hours they have worked at other times",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "various",
    definition: "several different",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "vary",
    definition: "to change or be different according to the situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },
  {
    word: "voucher",
    definition:
      "a printed piece of paper that can be used instead of money to pay for sth, or that allows you to pay less than the usual price of sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 2",
  },

  {
    word: "accurate forecast",
    definition:
      "a correct statement about what will happen in the future, based on information that is available now",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "ahead of schedule",
    definition: "finished or done before the planned time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "allocate resources",
    definition:
      "to officially decide how money, equipment, staff etc. should be used",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "battery",
    definition:
      "a device that is placed inside a car engine, clock, mobile phone, etc. and that produces the electricity that makes it work",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "behind schedule",
    definition: "finished or done later than the planned time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "budget constraints",
    definition:
      "factors that limit or restrict the amount of money that can be spent on sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "capture",
    definition:
      "to succeed in getting control of sth that other people are also trying to control",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "check progress",
    definition:
      "to find out if sth is improving or developing, or getting nearer to being achieved or completed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "collate",
    definition:
      "to collect and arrange information from several different sources, or several documents or pieces of paper",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "compile",
    definition:
      "to produce a book, list, report, etc. by bringing together different items",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "handset",
    definition:
      "the part of a telephone that you hold close to your mouth and ear to speak into and listen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "keep track of spending",
    definition:
      "to always have recent information about how much money has been spent",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "lack of planning",
    definition:
      "a situation in which sb has not thought carefully enough in advance about what they are going to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "life span",
    definition: "the length of time that sth is likely to continue or function",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "maintain",
    definition: "to make sth continue at the same level, standard, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "make contingency plans",
    definition:
      "to make plans for what to do if a particular event happens or does not happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "make the launch date",
    definition:
      "to succeed in having a product ready to be made available or shown to the public by the planned date",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "man-hours",
    definition: "relating to the amount of work done by one person in one hour",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "miss the deadline",
    definition:
      "to fail to do sth, such as finish a piece of work, before the time by which it must be done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "out of control",
    definition: "impossible to manage or to control",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "over budget",
    definition:
      "costing more than the money that is available or was planned to spend",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "plumbing",
    definition:
      "the work of putting in and repairing the pipes and other systems that supply water to a building",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "press release",
    definition:
      "an official statement made to journalists by a large organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "prioritize tasks",
    definition:
      "to put the jobs you have to do in order of importance, so that you can deal with the most important first",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "resolve conflict",
    definition:
      "to deal with a disagreement or argument between people or groups",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "roofing",
    definition: "the work of building and repairing roofs",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "run out of money",
    definition: "to use up a supply of money so there is no more available",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "site",
    definition: "a place where a building is or will be situated",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "stay on track",
    definition:
      "to continue doing the right thing in order to achieve a particular result",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "tariff",
    definition:
      "a fixed price that is charged by a company for a particular service, for example for the use of a phone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "unrealistic budget",
    definition:
      "an amount of money that has been allowed for sth, that is not enough because it does not accept things as they really are",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "upfront planning",
    definition: "making plans for sth in advance, before you start",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "vital",
    definition: "necessary or essential in order for sth to succeed or exist",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "within budget",
    definition:
      "costing no more than the money that is available or was planned to spend",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 3",
  },
  {
    word: "appropriate",
    definition:
      "suitable, acceptable, or correct for the particular circumstances",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "bring about",
    definition: "to make sth happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "bring down",
    definition: "to reduce sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "canvas",
    definition:
      "a strong heavy rough material used for making tents, sails, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "carry out",
    definition: "to do and complete a task",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "cement",
    definition:
      "a grey powder that sets hard when it is mixed with water, used in building to stick bricks together and to make very hard surfaces",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "come up with",
    definition: "to find or produce an answer, a sum of money, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "commercially-viable proposition",
    definition:
      "a business idea that is good enough to be successful and make money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "concrete",
    definition:
      "a building material that is made by mixing together cement and water",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "contract",
    definition: "an official written agreement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "cutting-edge technology",
    definition:
      "the newest or most advanced technological machinery, equipment, or knowledge",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "delicious",
    definition: "having a very pleasant taste",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "demonstrate",
    definition: "to show sth clearly by actions or by giving proof",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "dung",
    definition: "solid waste from animals, especially from large ones",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "earthquake",
    definition: "a sudden, violent shaking of the earth's surface",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "enable",
    definition: "to make it possible for sb to do sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "enhanced",
    definition: "improved, better than before",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "fertilizer",
    definition:
      "a substance added to soil to make plants grow more successfully",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "get round",
    definition: "to deal with a problem successfully",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "impressive",
    definition:
      "making you feel admiration, because of being very good, skilful, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "inflatable",
    definition: "needing to be filled with air or gas before you use it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "innovative concept",
    definition: "an idea that is very new and has never been thought of before",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "inspire",
    definition:
      "to give sb the desire, confidence, or enthusiasm to do sth well",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "insulation",
    definition:
      "materials used to prevent heat, sound, electricity etc. from passing through sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "key feature",
    definition:
      "a part of a thing that is particularly important, interesting, or typical",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "major advantage",
    definition:
      "an important quality of sth that makes it better or more useful than sth else",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "patent",
    definition:
      "an official right to be the only person to make, use, or sell a product",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "pay off",
    definition: "to be successful and bring good results",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "pitch",
    definition:
      "talk or arguments used by a person trying to sell things or persuade people to do sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "policy",
    definition:
      "a plan of action agreed or chosen by a political party, a business, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "potential benefit",
    definition:
      "an advantage that sth may give you or a helpful and useful effect that it may have",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "practical solution",
    definition:
      "a sensible way of solving a problem, that is likely to be successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "Research and Development",
    definition:
      "the part of a business whose work is to find new products and processes or to improve existing ones",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "radical",
    definition: "new, different, and likely to have a great effect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "raise awareness",
    definition: "to help people know that sth exists and is important",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "refrigeration",
    definition: "the process of keeping food etc. cold so that it stays fresh",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "refugee",
    definition:
      "a person who has been forced to leave their country or home, because there is a war or for political, religious, or social reasons",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "remote",
    definition: "far away from places where other people live",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "revolutionary idea",
    definition:
      "an idea that is new and that involves a great or complete change",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "rigid",
    definition: "stiff and difficult to move or bend",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "sealed",
    definition: "closed tightly so that air, liquid, etc. cannot get in or out",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "set up",
    definition: "to start sth such as a business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "specification",
    definition:
      "a detailed description of how sth is, or should be, designed or made",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "state-of-the-art design",
    definition:
      "design that uses the most modern or advanced techniques or methods",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "sustainable",
    definition:
      "involving the use of natural products and energy in a way that does not harm the environment",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "take forward",
    definition: "to help sth to progress towards a good result",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "take up",
    definition: "to accept sth that is offered or available",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "tank",
    definition: "a large container for holding liquid or gas",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "technological breakthrough",
    definition:
      "an important development in technology that may lead to an achievement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "turbines",
    definition:
      "machines that receive power from a wheel that is turned by the pressure of water, air, or gas",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "unique",
    definition: "being the only one of its kind",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "vaccine",
    definition:
      "a substance that is put into the blood and that protects the body from a disease",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },
  {
    word: "vibration",
    definition: "a continuous shaking movement or feeling",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 4",
  },

  {
    word: "accounts",
    definition:
      "the part of a company that keeps written records of money that is owed to the business and money that has been paid by it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "agitated",
    definition: "showing in your behaviour that you are anxious and nervous",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "approval",
    definition:
      "agreement to or permission for sth, especially a plan or request",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "attentive",
    definition: "helpful and making sure that people have what they need",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "attitude",
    definition:
      "the way that you behave towards sb / sth that shows how you think and feel",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "blame",
    definition: "to think or say that sb / sth is responsible for sth bad",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "bulb",
    definition:
      "the glass part that fits into an electric lamp, etc. to give light when it is switched on",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "bulk",
    definition: "a large amount or quantity of sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "cashier",
    definition:
      "a person whose job is to receive and pay out money in a bank, shop / store, hotel, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "confectionery",
    definition: "sweets / candy, chocolate, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "count for",
    definition: "to be important or have an effect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "courteous",
    definition: "polite in a way that shows respect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "deducted",
    definition:
      "something such as money that is taken away from a total amount",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "discontinued",
    definition: "no longer being made",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "discourteous",
    definition: "not polite in a way that does not show respect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "dissatisfied",
    definition: "not happy or satisfied with sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "efficient",
    definition:
      "doing sth well and thoroughly with no waste of time, money, or energy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "encounter",
    definition:
      "an occasion on which two or more people meet and have to deal with each other, especially when this is unexpected",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "exceed customers expectations",
    definition: "to make sth better than customers hoped or expected",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "existing",
    definition: "happening, used, or in place at the present time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "existing customer",
    definition: "a person who is a customer at the present time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "find your feet",
    definition: "to become able to act independently and with confidence",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "get feedback",
    definition:
      "to get advice, criticism, or information about how good or useful sth is",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "high-quality",
    definition: "of a very good standard",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "literally",
    definition: "used to emphasize the truth of sth that may seem surprising",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "lose customers",
    definition: "to have customers taken away by another company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "loyal",
    definition:
      "remaining faithful to sb / sth and continuing to support them or it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "vibration",
    definition: "a continuous shaking movement or feeling",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "measure customer satisfaction",
    definition:
      "to find out how pleased customers are with a product or service",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "meet customers needs",
    definition: "to give customers what they want or need",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "negotiation",
    definition:
      "a formal discussion between people who are trying to reach an agreement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "originate",
    definition:
      "to happen or appear for the first time in a particular place or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "quibble",
    definition:
      "to argue or complain about a small matter or an unimportant detail",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "reassure",
    definition:
      "to say or do something that makes someone less frightened or worried",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "receipt",
    definition:
      "a piece of paper that shows that goods or services have been paid for",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "refund",
    definition:
      "to give someone their money back, especially because they have paid too much or because they are not satisfied with something they bought",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "repeat",
    definition: "happening or doing something again in the same way as before",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "responsive",
    definition: "reacting quickly and in a positive way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "rule of thumb",
    definition:
      "a practical method of doing or measuring something, usually based on past experience rather than on exact measurement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "satisfied",
    definition: "pleased because you got what you wanted",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "specifically",
    definition: "in a detailed and exact way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "stuff",
    definition:
      "(informal) used to refer in a general way to a group or type of things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "sub-standard",
    definition: "not as good as normal; not acceptable",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "superb",
    definition: "excellent; of very good quality",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "sympathize",
    definition:
      "to show that you understand and feel sorry about someone's problems",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "take notice",
    definition: "to pay attention to someone or something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "tills",
    definition:
      "machines used in shops, restaurants, etc. that have a drawer for keeping money in, and that show the amount of money received for each thing that is sold",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "tough",
    definition: "(informal) unfortunate, but impossible to change",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "turn away",
    definition: "to refuse to allow someone to enter a place",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "uncaring",
    definition:
      "not kind or helpful, and not sympathetic to other people's feelings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "unpublicized rule",
    definition:
      "a rule that is not official or written down, but that people know about and follow",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },
  {
    word: "upset",
    definition: "unhappy or annoyed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 5",
  },

  {
    word: "act responsibly",
    definition:
      "to behave in a way that shows you want to do the right thing and avoid harming people or things by your actions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "affiliated",
    definition:
      "closely connected to or controlled by a group or an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "bribery",
    definition:
      "the dishonest act of giving someone money to persuade them to help you or to do something bad",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "by-product",
    definition:
      "a substance that is produced during the process of making or destroying something else",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "cheer",
    definition:
      "a shout of joy, support, or praise, especially from a group of people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "claim",
    definition:
      "to say that something is true although it has not been proved and other people may not believe it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "combat",
    definition:
      "to stop something unpleasant or harmful from happening or from getting worse",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "comply with regulations",
    definition: "to obey the official rules about how something should be done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "contributor",
    definition: "something that helps to cause something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "corroded",
    definition: "damaged by the gradual action of chemicals",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "corruption",
    definition:
      "dishonest or illegal behaviour, especially of people in authority",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "counteract",
    definition:
      "to do something to reduce or prevent the bad or harmful effects of something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "credibility",
    definition:
      "the quality that someone or something has that makes people believe or trust them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "deception",
    definition:
      "the act of deliberately making someone believe something that is not true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "discreet",
    definition:
      "careful in what you say or do, in order to keep something secret or to avoid causing embarrassment or difficulty for someone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "discrimination",
    definition:
      "the practice of treating someone or a particular group in society less fairly than others",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "donate time",
    definition:
      "to spend some of your free time helping other people or helping an organization such as a charity",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "dye",
    definition:
      "to change the colour of something, especially by using a special liquid or substance",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "ethics",
    definition:
      "moral principles that control or influence a person's behaviour",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "ethos",
    definition:
      "moral ideas and attitudes that belong to a particular group or society",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "exotic",
    definition:
      "exciting and unusual because it is connected with foreign countries, especially tropical countries",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "exploit",
    definition:
      "to treat someone unfairly by making them work and not giving them much in return",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "fairness",
    definition:
      "the quality of treating people equally or in a way that is reasonable",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "feature",
    definition:
      "a special article or programme about someone or something in a newspaper, on television, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },

  {
    word: "generosity",
    definition:
      "the fact of being generous (= willing to give sb money, gifts, time, or kindness freely)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "greed",
    definition:
      "a strong desire for more wealth, possessions, power, etc. than a person needs",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "initiative",
    definition:
      "a new plan for dealing with a particular problem or for achieving a particular purpose",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "itinerary",
    definition:
      "a plan of a journey, including the route and the places that you visit",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "livelihood",
    definition: "a means of earning money in order to live",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "methane",
    definition:
      "a gas without colour or smell, that burns easily and is used as fuel",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "organic",
    definition:
      "(of food, farming methods, etc.) produced or practised without using artificial chemicals",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "paddling",
    definition:
      "moving in a small boat or canoe through water using a paddle (= a short pole with a flat wide part)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "pesticide",
    definition: "a chemical used for killing pests, especially insects",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "poverty",
    definition: "the state of being poor",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "prejudice",
    definition:
      "an unreasonable dislike of or preference for a person, group, custom, etc., especially when it is based on their race, religion, sex, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "pretend",
    definition: "to try to make people believe sth that is not true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "rare",
    definition: "not done, seen, happening, etc. very often",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "reduce the impact",
    definition:
      "to make sth have a less powerful effect on sb / sth, especially a bad effect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "reforestation",
    definition:
      "the act of planting new trees in an area where there used to be a forest",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "responsibility",
    definition:
      "the feeling that you have a duty to deal with or take care of sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "sample",
    definition: "to taste a particular food, usually for the first time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "share a strong commitment",
    definition:
      "to promise as a group that you will do sth, support sth, etc. because you strongly believe that it is important",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "source",
    definition: "to get sth from a particular place",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "spontaneous",
    definition: "not planned, but done because you suddenly want to do it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "stay true to principles",
    definition:
      "to continue to behave according to particular beliefs about what is right, even when situations change",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "take an active part in",
    definition:
      "to be involved in a situation in a way that involves doing things and making an effort",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "turtle",
    definition: "a large reptile with a hard round shell that lives in the sea",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "values",
    definition:
      "beliefs about what is right and wrong and what is important in life",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 6",
  },
  {
    word: "abstract",
    definition: "based on thoughts and ideas rather than on physical reality",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "actual",
    definition: "real or existing in fact",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "apparent",
    definition: "that seems to be real or true, but may not be",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "argue",
    definition:
      "to give reasons why you think that sth is right / wrong, true / not true, etc., especially to persuade people that you are right",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "associated",
    definition:
      "two things are connected because they happen together or one thing causes the other",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "cashflow",
    definition:
      "the movement of money into and out of a business as goods are bought and sold",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "compliment",
    definition:
      "to tell sb that you like or admire sth they have done, their appearance, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "concern",
    definition:
      "a strong interest in sb / sth and a desire to help or protect them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "consider all the options",
    definition:
      "to think about all the possible things you could do in a situation before choosing the best one",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "conventional",
    definition:
      "tending to follow what is done or considered acceptable by society in general",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "courtroom",
    definition: "a room in which trials or other legal cases are held",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "creative",
    definition: "having the ability to think of new things and ideas",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "decide between",
    definition: "to choose one of two things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "deficit",
    definition:
      "the amount by which money spent or owed is greater than money earned in a particular period of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "detached",
    definition: "not influenced by other people or by your own feelings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "determined",
    definition:
      "firm in your decisions, so you will not let anyone prevent you from doing what you want to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "emerge",
    definition: "to gradually start to exist, appear, or become known",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "emotion",
    definition: "a strong feeling such as love, fear, or anger",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "evidence",
    definition:
      "the facts, signs, or objects that make you believe that sth is true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "expenditure",
    definition:
      "the act of spending or using money, or an amount of money spent",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "expressive",
    definition: "showing or able to show your thoughts and feelings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "extrovert",
    definition:
      "a lively and confident person who enjoys being with other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "flexible",
    definition:
      "willing and able to change to suit different conditions or situations",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "focused",
    definition:
      "with very clear aims and your attention clearly directed towards what you want to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "freeze on recruitment",
    definition:
      "a policy by a company of not employing any new people for a period of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "get a different perspective",
    definition: "to change your attitude or way of thinking about sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "have confidence in",
    definition: "to feel sure that you are able to make sensible decisions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "impulsive",
    definition:
      "acting suddenly without thinking carefully about what might happen because of what you are doing",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "indecisive",
    definition: "unable to make decisions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "innate",
    definition: "that a person is born with and never loses",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "instinctive",
    definition: "based on instinct, not thought or training",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "introvert",
    definition:
      "a quiet person who is more interested in their own thoughts and feelings than in spending time with other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "intuition",
    definition:
      "the ability to know sth by using your feelings rather than considering the facts",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "judge",
    definition:
      "to form an opinion about how good sb / sth is, based on the information you have",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "lay-off",
    definition:
      "an act of making people unemployed because there is no more work for them to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "make up your mind",
    definition: "to decide",
    pronunciation: "merk sp 'mand",
    phrase: "to decide",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "methodical",
    definition: "doing things in a careful and logical way",
    pronunciation: "mo'Opdikl; AmE -'©a:d-",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "overheads",
    definition:
      "regular costs that you have when you are running a business or an organization, such as rent, electricity, wages, etc.",
    pronunciation: "'auvahedz; AmE 'ouvar-",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "pragmatic",
    definition:
      "solving problems in a practical and sensible way rather than by having fixed ideas or theories",
    pronunciation: "prag'matik",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "provider",
    definition:
      "a person or an organization that supplies sb with sth they need or want",
    pronunciation: "pro'vador)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "rational",
    definition: "based on reason rather than emotions",
    pronunciation: "rafanl",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "reject",
    definition: "to refuse to accept or consider sth",
    pronunciation: "ri'dgekt",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "rely on feelings",
    definition: "to use your emotions to help you decide what to do",
    pronunciation: "ri, lar on 'fi:z",
    phrase: "to use your emotions to help you decide what to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "self-contained",
    definition: "not needing or depending on other people",
    pronunciation: ",self kan'tend",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "sensor",
    definition:
      "someone who likes to be sensible and to make decisions by considering the facts rather than based on their feelings",
    pronunciation: "'sensar)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "stick with",
    definition: "to continue with sth or continue doing sth",
    pronunciation: "stik wiJ",
    phrase: "to continue with sth or continue doing sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "stock",
    definition:
      "a supply of goods that is available for sale in a shop / store",
    pronunciation: "stok; AmE sta:k",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "subsidy",
    definition:
      "money that is paid by a government or an organization to reduce the costs of services or of producing goods so that their prices can be kept low",
    pronunciation: "'sabsadi",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "tactful",
    definition:
      "careful not to say or do anything that will annoy or upset other people",
    pronunciation: "taktfl",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "thorough",
    definition:
      "doing things very carefully and with great attention to detail",
    pronunciation: "'Oare; AmE 'Osrou",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "thoughtful",
    definition:
      "showing careful thought, especially about other people's feelings",
    pronunciation: "'s:tfl",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "trouble-shooter",
    definition:
      "a person who helps to solve problems in a company or an organization",
    pronunciation: "trabl Ju:ta(r)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "trust your instincts",
    definition:
      "to have faith in your feelings about what to do, even when they may not be based on fact or reason",
    pronunciation: ",trast jor 'instinkts",
    phrase:
      "to have faith in your feelings about what to do, even when they may not be based on fact or reason",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "ultimately",
    definition: "in the end; finally",
    pronunciation: "'altimatli",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "unconventional",
    definition:
      "tending to behave or think in an unusual way rather than following what is done or considered acceptable by most people",
    pronunciation: "anken'venfanl",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },

  {
    word: "vertical",
    definition: "going straight up or down from a level surface",
    pronunciation: "'vetil; AmE 'vert-",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "weigh up information",
    definition:
      "to consider information carefully before forming an opinion about it",
    pronunciation: "wer ,p info'mefn; AmE -infor-",
    phrase:
      "to consider information carefully before forming an opinion about it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "wisdom",
    definition:
      "the ability to make sensible decisions and give good advice because of the experience and knowledge that you have",
    pronunciation: "wizdam",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 7",
  },
  {
    word: "achieve lower overheads",
    definition:
      "to succeed in reducing the regular costs that you have when you are running a business or an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "afford",
    definition: "have enough money to pay for.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "booming",
    definition: "rapidly growing or becoming more successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "buoyant",
    definition:
      "tending to increase or stay at a high level, usually showing financial success",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "business process outsourcing",
    definition:
      "the practice of giving the responsibility for running a particular system or service to people outside the company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "competitive edge",
    definition: "something that gives you an advantage over competitors",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "controversial",
    definition: "causing a lot of angry public discussion and disagreement",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "core activity",
    definition: "the main or most important things that an organization does",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "cost-cutting measures",
    definition:
      "things you do to try to reduce the amount of money spent by a business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "create jobs",
    definition: "to make work for more people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "cultural affinity",
    definition: "a feeling that you understand and like sb's culture",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "develop strategies",
    definition:
      "to think of plans that are intended to achieve a particular purpose",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "distinguish",
    definition: "to recognize how one thing is different from another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "establishment",
    definition:
      "the act of starting or creating sth that is meant to last for a long time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "expertise",
    definition:
      "expert knowledge or skill in a particular subject, activity, or job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "export growth",
    definition: "an increase in the number of goods sold to other countries",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "free up human resources",
    definition: "to make more people available to work on other things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "fuel by",
    definition: "to increase sth or make it stronger",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "get through",
    definition: "to succeed in finishing sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "graduate",
    definition: "someone who has a university degree",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "indirect employment",
    definition:
      "employment of people to work for a company by an independent business that provides their services under a contract",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "infrastructure",
    definition:
      "the basic systems and services that are necessary for a country or an organization to run smoothly, for example buildings, transport, and water and power supplies",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "job losses",
    definition: "the fact of people no longer having a job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "knock-on effect",
    definition: "an event caused by another event",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "legal",
    definition: "relating to the law",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "nickname",
    definition:
      "an informal, often humorous, name for a person or organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "offshore",
    definition: "happening or based in a different country",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "payroll",
    definition:
      "the activity of managing the payment of wages to everyone that works for a company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "productivity",
    definition:
      "rate at which a worker, a company, or a country produces goods, and the amount produced, compared with how much time, work, and money is needed to produce them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "protectionism",
    definition:
      "the principle or practice of protecting a country's own industry by taxing foreign goods",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "pursue",
    definition: "to do sth or try to achieve sth over a period of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "quality of life",
    definition: "how good a person's life is when compared with others",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "recruit",
    definition:
      "to find new people to join a company, an organization, the armed forces, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "repeal",
    definition: "to officially make a law no longer valid",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "sector",
    definition: "a part of an area of activity, especially of an economy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "shortfall",
    definition: "less of something than you need or expect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "skilled worker",
    definition: "a worker who has special abilities or training",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "spill",
    definition: "to make liquid flow over the edge of a container by accident",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "streamline",
    definition:
      "to make a system, an organization, etc. work better, especially in a way that saves money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "subsequently",
    definition: "afterwards; later; after sth else has happened",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "threefold",
    definition: "by three times as much",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "training facilities",
    definition:
      "buildings, services, equipment, etc. that are provided for the purpose of training staff",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "treble",
    definition: "to become three times as much or as many",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "volume of work",
    definition: "the amount of work that is done or has to be done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "work ethic",
    definition: "a moral belief that it is good to work hard",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 8",
  },
  {
    word: "ability",
    definition: "someone's level of skill or intelligence",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "abroad",
    definition: "in or to a foreign country",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "acquire",
    definition: "to gain or obtain sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "appoint",
    definition: "to choose sb for a job or position of responsibility",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "bureaucracy",
    definition:
      "official rules and ways of doing things that seem complicated or make things happen more slowly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "challenge",
    definition: "a new or difficult task that tests sb's ability and skill",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "change of direction",
    definition:
      "a change in sb's aims or the way in which their life is developing",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "coincide",
    definition: "to take place at the same time as another event",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "commodity",
    definition: "a product or a raw material that can be bought and sold",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "compromise",
    definition:
      "an agreement made between two people or groups in which each side gives up some of the things they want so that both sides are happy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "consultant",
    definition:
      "a person who knows a lot about a particular subject and is employed to give advice about it to other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "corporate culture",
    definition:
      "the values and ways of thinking that are typical of a large business organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "deployment",
    definition:
      "the fact of being moved to a particular place in order to be used there or to work there",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "expendable",
    definition: "that can be got rid of when no longer useful or needed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "get on",
    definition: "to be successful in your career",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "glass ceiling",
    definition:
      "the barrier created by people's attitudes that stops women, or other groups, from getting the most senior jobs in a company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "incompetent",
    definition:
      "not having the skill or ability to do your job or a task as it should be done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "invest",
    definition:
      "to spend money on sth in order to make it better or more successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "job mobility",
    definition: "the ability to move easily from one job to another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "key vacancy",
    definition:
      "a very important job in a company that is available for sb to have",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "laid off",
    definition:
      "told that you no longer have a job because there is not enough work for you to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "living expenses",
    definition: "money spent on things you need in everyday life",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "make redundant",
    definition:
      "to tell sb they no longer have a job because there is no work available for them in a company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "monotonous",
    definition: "never changing and therefore boring",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "native",
    definition:
      "a person born in a specified place or associated with a place by birth, whether subsequently resident there or not.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "no-no",
    definition:
      "a thing or a way of behaving that is not acceptable in a particular situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "opportunity for advancement",
    definition:
      "the possibility of making progress or reaching a higher position in your job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "opt",
    definition: "to choose to take a particular course of action",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "personal development",
    definition:
      "growth and improvement in a person's skills, experience, and knowledge",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "promote",
    definition: "to move sb to a higher rank or more senior job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "prospects",
    definition: "the chances of being successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "recurring",
    definition: "that happens again and again",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "red tape",
    definition:
      "official rules that seem more complicated than necessary and prevent things from being done quickly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "redeploy",
    definition: "to move sb / sth to a new position or job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "regret",
    definition: "to feel sorry about sth you have done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "relocation",
    definition:
      "the process of moving sb / sth to a new place to work or operate",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "replace",
    definition: "to take the place of sth / sb",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "resign",
    definition: "to officially announce that you are leaving your job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "resign from",
    definition: "to leave a job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "retire",
    definition: "to stop working when you reach a certain age",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "salary",
    definition: "the money you earn from your job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "set out",
    definition: "to start a journey or a new project",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "shift",
    definition:
      "a period of time during which a particular group of workers does a particular job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "skills gap",
    definition:
      "a difference between the skills that people have and the skills that are needed in a particular job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "subsidiary",
    definition: "a company that is owned by a larger company",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "take over",
    definition: "to get control of sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "task force",
    definition:
      "a group of people who are brought together to do a particular task",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "terminate",
    definition: "to end sth / sb's employment",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "uproot",
    definition: "to move sb or sth from one place to another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "voluntary",
    definition:
      "done because you choose to do it, not because you have to do it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 9",
  },
  {
    word: "absolutely",
    definition: "used to emphasize a negative or positive word that follows",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "bagel",
    definition: "a hard bread roll shaped like a ring",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "borrow",
    definition:
      "to take money from a person or bank and agree to pay it back to them at a later time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "bow to",
    definition: "to agree to do sth because other people want you to",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "branch out",
    definition:
      "to start to do an activity that you have not done before, especially in your work or business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "business angel",
    definition: "someone who invests their own money to help a new business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "business model",
    definition: "a plan for the way a business will operate to make money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "business plan",
    definition:
      "a written document stating how a business will get the money it needs and how it will make money",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "collapsible",
    definition:
      "that can be folded flat or made into a smaller shape that uses less space",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "commuter",
    definition:
      "a person who travels into a city to work each day, usually from quite far away",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "completely",
    definition:
      "in every possible way; used for emphasizing the word or phrase that follows",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "crunchy",
    definition: "firm and crisp and making a sound when you bite or crush it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "days leave",
    definition:
      "a day when you are allowed to be away from work for a holiday / vacation or for a special reason",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "differentiate",
    definition: "to show that things or people are not the same",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "drawback",
    definition: "disadvantage or problem that makes sth less attractive",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "extremely",
    definition:
      "to a very high degree; used to emphasize the adjective or adverb that follows",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "favour",
    definition: "[BrE] [AmE favor] a thing that you do to help sb",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "fee",
    definition:
      "an amount of money that you pay to join an organization, or to do sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "financial backing",
    definition:
      "help in the form of money that makes it possible for you to do sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "gap in the market",
    definition:
      "a business opportunity to make or sell sth that is not yet available",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "get somebody on board",
    definition: "to persuade sb to work with you or join you",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "go public",
    definition: "to start selling shares on the stock exchange",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "gradual",
    definition: "happening slowly over a long period; not sudden",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 10",
  },
  {
    word: "access",
    definition: "the opportunity or right to use sth or to see sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "according to",
    definition: "as stated or reported by sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "analyse",
    definition:
      "to examine the nature or structure of sth, especially by separating it into its parts, in order to understand or explain it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "chaos",
    definition: "a state of complete confusion and lack of order",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "collaborate",
    definition: "to work together with sb in order to produce or achieve sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "communication",
    definition:
      "methods of sending information, such as telephones, radio, computers, etc., or the activity of giving or sending information",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "connect",
    definition: "to join a computer to the Internet or a computer network",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "connection",
    definition: "the act of connecting or the state of being connected",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "consultation",
    definition:
      "a meeting or discussion with an expert, especially a doctor, to get advice or treatment",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "counterpart",
    definition:
      "someone that has the same position or function as sb / sth else in a different place or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "crowded",
    definition: "having a lot of people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "demo",
    definition: "an act of showing or explaining how sth works or is done",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "development",
    definition: "a new event or stage in sth, or a new and advanced product",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "devise",
    definition: "to invent sth new or a new way of doing sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "diagnosis",
    definition:
      "the act of discovering or identifying the exact cause of an illness or a problem",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "economy",
    definition:
      "the relationship between production, trade, and the supply of money in a particular country or region",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "excluded",
    definition: "prevented from taking part in sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "faint",
    definition: "that cannot be clearly heard or seen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "filtering",
    definition:
      "the process of removing sb / sth that you do not want from a large number of people or things using a special system, device, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "flag",
    definition:
      "to put a special mark next to information that you think is important",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "fleet",
    definition: "a group of ships",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "focus on",
    definition:
      "to give attention, effort, etc. to one particular subject, situation, or person rather than another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "hang up",
    definition:
      "to end a telephone conversation by putting the receiver down or switching the telephone off",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "have an impact",
    definition: "to have a powerful effect on sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "innovative",
    definition: "introducing or using new ideas, ways of doing sth, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "integrated",
    definition:
      "in which many different parts are closely connected and work successfully together",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "interact",
    definition: "to communicate with a person or machine",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "involved",
    definition: "taking part in sth or being connected with sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "landline",
    definition:
      "a telephone connection that uses wires carried on poles or under the ground, in contrast to a mobile phone / cellphone",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "microscope",
    definition:
      "an instrument used in scientific study for making very small things look larger so that you can examine them carefully",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "obligatory",
    definition: "that you must do because of the law, rules, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "participation",
    definition: "the act of taking part in an activity or event",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "procedure",
    definition: "a way of doing sth, especially the usual or correct way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "regardless",
    definition: "treating something as not important or not relevant",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "rotate",
    definition:
      "to regularly change a job or regularly change who does the job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "run over",
    definition: "to continue for longer than planned or agreed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "rural",
    definition: "connected with or in the countryside",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "seldom",
    definition: "not often; rarely",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "speculation",
    definition:
      "the act of discussing or thinking about what might happen in the future, without knowing all the facts",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "spread",
    definition:
      "to make sth affect, be known by, or used by more and more people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "subscribe",
    definition:
      "to pay an amount of money regularly in order to receive or use sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "surgeon",
    definition:
      "a doctor who is trained to perform surgery (= medical operations that involve cutting open a person's body)",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "tissue",
    definition:
      "a collection of cells that form the different parts of humans, animals, and plants",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "tool",
    definition: "a thing that helps you to do your job or to achieve sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "transferable",
    definition: "that can be moved from one place, person, or use to another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "tremendous",
    definition: "very great",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "urgent",
    definition: "that needs to be dealt with or happen immediately",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "volunteer",
    definition: "a person who does a job without being paid for it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "wider",
    definition: "more general; including more or many things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 11",
  },
  {
    word: "accept",
    definition: "to agree to or approve of sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "adapt",
    definition:
      "to change your behaviour in order to deal more successfully with a new situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "affect",
    definition: "to produce a change in sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "against",
    definition: "opposing or disagreeing with sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "ambivalent",
    definition: "having or showing both good and bad feelings about sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "antagonistic",
    definition: "showing or feeling opposition in an aggressive way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "anxious",
    definition: "feeling worried or nervous",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "apathy",
    definition:
      "the feeling of not being interested in or enthusiastic about anything",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "apprehensive",
    definition: "worried or frightened that sth unpleasant may happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "assure",
    definition:
      "to tell sb that sth is definitely true or is definitely going to happen, especially when they have doubts about it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "bottom-up",
    definition:
      "starting with or involving the people who have lower positions in an organization or their ideas",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "bring in",
    definition: "to introduce something such as a new law or policy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "committed",
    definition:
      "believing strongly in sth and willing to give time and energy to it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "concerned",
    definition: "worried and feeling concern about sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "considerably",
    definition: "much; a lot",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "count out",
    definition:
      "to decide that sth is not possible or definitely will not happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "critical",
    definition:
      "extremely important because a future situation will be affected by it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "crucial",
    definition: "extremely important, because it will affect other things",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "deal with",
    definition: "to solve a problem, perform a task, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "distraction",
    definition:
      "a thing that takes your attention away from what you are doing or thinking about",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "doubt",
    definition: "a feeling of being uncertain about sth or not believing sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "drop out",
    definition:
      "to leave sth or no longer take part in sth, especially before finishing it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "empowerment",
    definition:
      "the action of giving sb more control or power over their life or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "enthusiastic",
    definition:
      "feeling or showing a lot of excitement and interest about sb / sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "genuinely",
    definition: "sincerely and honestly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "get rid of",
    definition:
      "to make yourself free of sb / sth that is annoying you or that you do not want",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "hostile",
    definition: "very unfriendly or aggressive and ready to argue or fight",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "in favour",
    definition: "supporting or agreeing with sth / sb",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "keen",
    definition:
      "wanting to do sth or feeling enthusiastic about an activity or idea",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "loose ends",
    definition: "part of sth that has not been completely finished or resolved",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "majority",
    definition: "done, used etc. by the largest part of a group of people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "modify",
    definition: "to change sth slightly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "natural wastage",
    definition:
      "the process of reducing the number of people who are employed by an organization by not replacing people who leave their jobs",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "nervous",
    definition: "anxious about sth or afraid of sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "nominate",
    definition:
      "to formally suggest that sb should be chosen for an important role, prize, position, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "oppose",
    definition: "to disagree strongly with a plan or policy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "optimistic",
    definition: "expecting good things to happen or sth to be successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "pessimistic",
    definition: "expecting bad things to happen or sth not to be successful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "positive",
    definition:
      "thinking about what is good in a situation; feeling confident and sure that sth good will happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "put on",
    definition: "to provide sth specially",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "query",
    definition:
      "a question, especially one asking for information or expressing a doubt about sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "react",
    definition:
      "to behave in a particular way as a result of or in response to sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "rebellious",
    definition: "unwilling to obey rules or accept standards",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "receptive",
    definition: "willing to listen to or to accept new ideas or suggestions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "resentful",
    definition: "feeling bitter or angry about sth that you think is unfair",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "resist",
    definition: "to refuse to accept sth and try to stop it from happening",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "resistant",
    definition: "opposing sth and trying to stop it happening",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "rumoured",
    definition: "reported as a rumour and possibly not true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "run into",
    definition: "to experience difficulties, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "sabotage",
    definition:
      "to deliberately prevent sth from being successful or being achieved",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "sceptical",
    definition:
      "having doubts that a claim or statement is true or that sth will happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "take on",
    definition: "to agree to do or be responsible for sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "try out",
    definition:
      "to test or use sb / sth in order to see how good or effective they are",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "work out",
    definition: "to develop in a successful way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "worried",
    definition:
      "feeling unhappy because you are thinking about unpleasant things that have happened or that might happen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 12",
  },
  {
    word: "about",
    definition: "approximately",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "affordable",
    definition: "possible for someone to buy; not too expensive",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "approximately",
    definition:
      "used to show that sth is almost, but not completely, accurate or correct",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "blow up",
    definition: "to explode",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "childish",
    definition: "behaving in a stupid or silly way; immature",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "crash",
    definition: "to lose value or fall suddenly and quickly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "drop slightly",
    definition: "to become a little less or lower",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "entire",
    definition:
      "whole; used for emphasizing that you mean all or every part of something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "excuse",
    definition:
      "a reason, either true or invented, that you give to explain or defend your behaviour",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "exposure",
    definition: "the fact of being discussed, mentioned, or noticed by people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "fractionally",
    definition: "to a very small degree",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "front runner",
    definition: "the person or thing that seems most likely to win or succeed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "great deal",
    definition: "much; a lot",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "grow gradually",
    definition: "to slowly become more or bigger over time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "grow rapidly",
    definition: "to quickly become more or bigger",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "hepatitis",
    definition: "a serious disease of the liver",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "internal combustion engine",
    definition:
      "the type of engine found in most cars, that works by burning fuel inside it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "just over",
    definition: "slightly more than",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "just under",
    definition: "slightly less than",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "level off",
    definition:
      "to stay at a steady level of development or progress after a period of sharp rises or falls",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "little more than",
    definition: "a slightly larger number or amount than",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "marginally",
    definition: "by a very small amount",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "noticeable rise",
    definition: "an increase that is large enough to be easily noticed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "overriding",
    definition: "more important than anything else in a situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "plummet",
    definition: "to suddenly fall to a much lower amount or value",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "pop-up",
    definition:
      "a small window, usually containing an advertisement, that appears on your computer screen",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "predecessor",
    definition:
      "a thing, such as a machine, that has been followed or replaced by something else",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "revenue",
    definition: "money that an organization receives from its business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "ring tone",
    definition:
      "the sound or short tune played by a mobile phone when someone calls you",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "rise substantially",
    definition: "to increase by a large amount",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "rocket",
    definition: "to increase very quickly and suddenly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "roughly",
    definition: "approximately, but not exactly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "RSS feed",
    definition:
      "a link to a website that makes the most recent information appear on a user's computer screen every time the website is changed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "screensaver",
    definition:
      "an image on a computer screen that is displayed when the computer is not used for a particular length of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "shoot up",
    definition: "to suddenly increase to a much higher amount or value",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "significant drop",
    definition:
      "a fall in amount or value that is large enough to be important",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "significant increase",
    definition:
      "a rise in amount or value that is large enough to be important",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "slight fall",
    definition: "a small decrease in amount or value",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "slightly less than",
    definition: "a slightly smaller number or amount than",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "somewhere in the region of",
    definition: "approximately",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "standstill",
    definition: "a situation in which all activity or movement has stopped",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "stay the same",
    definition: "to remain at the same level, not rising or falling",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "substantial drop",
    definition: "a large fall in amount or value",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "threat",
    definition:
      "a person or thing that is likely to cause trouble, danger, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "transistor",
    definition:
      "a small electronic device used in computers, radios, televisions, etc. for controlling an electric current as it passes along a circuit",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "trends",
    definition:
      "general directions in which situations are changing or developing",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "vineyard",
    definition:
      "a piece of land where grapes are grown in order to produce wine.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 13",
  },
  {
    word: "accepting",
    definition: "willing to agree to, approve of, or believe something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "accumulation",
    definition:
      "the process or action of gradually getting more and more of something over a period of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "adjust to",
    definition:
      "to get used to a new situation by changing the way you behave and/or think",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "admit",
    definition: "to agree, often unwillingly, that something is true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "approach",
    definition:
      "a way of dealing with someone or something; a way of doing or thinking about something such as a problem or a task",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "assertiveness",
    definition:
      "the quality of expressing opinions or desires strongly and with confidence, so that people take notice",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "aware",
    definition: "knowing or realizing something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "caste",
    definition:
      "a social class into which someone is born and which they cannot leave",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "cautious",
    definition:
      "careful about what you say or do, in order to avoid danger or risk",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "collectivist",
    definition:
      "belonging to a system in which things such as businesses and goods are owned by groups or by all the people, rather than by individuals",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "commence",
    definition: "(formal) to begin",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "confront",
    definition:
      "to make someone face or deal with an unpleasant or difficult situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "confusing",
    definition: "difficult to understand; not clear",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "deal",
    definition: "a business agreement to buy or do something",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "disappointed",
    definition:
      "unhappy because something you hoped for has not happened or been as good, successful, etc. as you expected",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "egalitarian",
    definition:
      "based on the belief that everyone is equal and should have the same rights and opportunities",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "familiar with",
    definition: "knowing something well",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "formal",
    definition: "very correct and suitable for official or important occasions",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "hierarchical",
    definition:
      "based on a system in which people are organized into different levels of importance from highest to lowest",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "host",
    definition:
      "a country or place where a person is staying as a visitor or where an event is being held",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "individualistic",
    definition:
      "believing that individual people in society should have the right to make their own decisions and keep their own money and property",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "informed",
    definition:
      "having or showing a lot of knowledge about a particular subject or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "issue",
    definition:
      "an important topic that people are discussing or arguing about",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "liaise",
    definition:
      "to work closely with someone and exchange information with them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "liberal",
    definition:
      "wanting or allowing a lot of political, economic, or personal freedom",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "mentor",
    definition: "to advise and help someone with less experience than yourself",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "mortgage",
    definition:
      "a legal agreement by which a bank or similar organization lends you money to buy a house, etc., and you pay the money back over a particular number of years",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "neutrality",
    definition:
      "the state of not supporting any particular side in a disagreement, competition, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "norms",
    definition:
      "standards of behavior that are typical of or accepted within a particular group or society",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "offend",
    definition:
      "to make someone feel upset because of something you say or do that is rude or embarrassing",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "open",
    definition: "willing to listen to and think about new ideas",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "partition",
    definition:
      "a thin wall or screen that separates one part of a room from another",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "phrase",
    definition:
      "to say or write something in a particular way or using particular words",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "refreshment",
    definition: "food or drink",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "respectful",
    definition: "showing or feeling respect",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "sensitive",
    definition:
      "aware of and able to understand other people and their feelings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "strict",
    definition: "that must be obeyed exactly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "taboo",
    definition:
      "not done or talked about as people find it offensive or embarrassing",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "tolerant",
    definition:
      "able to accept what other people say or do even if you do not agree with it",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "twist",
    definition: "an unexpected change or development in a story or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "tyre",
    definition:
      "[BrE] [AmE tire] a thick rubber ring that fits around the edge of a wheel of a car, bicycle, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "unwind",
    definition:
      "to stop worrying or thinking about problems and start to relax",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 14",
  },
  {
    word: "address issues",
    definition:
      "to think about or discuss problems and decide how you are going to deal with them",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "agree objectives",
    definition:
      "to decide with other people what things you or they should try to achieve",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "appraisal",
    definition:
      "a meeting in which an employee discusses with their manager how well they have been doing their job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "assessment criteria",
    definition: "the standards by which someone or something is judged",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "base on",
    definition:
      "to use a fact, a situation, etc. as the point from which something can be developed",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "carry on",
    definition: "to continue",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "column",
    definition:
      "a part of a newspaper or magazine which appears regularly and deals with a particular subject or is written by a particular writer",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "come over as",
    definition: "to make a particular impression",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "concoction",
    definition:
      "a strange or unusual mixture of things, especially drinks or medicines",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "conduct an appraisal",
    definition:
      "to hold a meeting with an employee in order to discuss how well they have been doing their job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "confidential",
    definition:
      "meant to be kept secret and not told to or shared with other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "constraint",
    definition:
      "something that limits or restricts something, or your freedom to act",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "constructive feedback",
    definition:
      "criticism and opinions about someone's work that are useful and helpful, rather than being negative or without purpose",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "cope with",
    definition: "to deal successfully with something difficult",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "counselling",
    definition: "professional advice about a problem or subject",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "counter-productive",
    definition: "having the opposite effect to the one which was intended",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "customer base",
    definition: "all the people who buy or use a particular product or service",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "delegate",
    definition:
      "to give part of your work, power, or authority to someone in a lower position than you",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "delicatessen",
    definition:
      "a shop or store that sells cooked meats and cheeses, and special or unusual foods that come from other countries",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "development tool",
    definition:
      "something that helps someone to improve their skills and knowledge, so they can do their job more effectively",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "domineering",
    definition:
      "trying to control other people without considering their opinions or feelings",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "editorial",
    definition:
      "connected with the task of preparing something such as a newspaper or book to be published",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "end up with",
    definition:
      "to find that you have something or are in a particular situation at the end of a process",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "ensure",
    definition: "to make sure that something happens or is definite",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "exponentially",
    definition: "increasing at a faster and faster rate",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "express views",
    definition: "to say what your opinions are",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "get the sack",
    definition:
      "to be told by your employer that you can no longer continue working for them, usually because of something that you have done wrong",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "go through",
    definition: "to look at or consider something in detail",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "hand out",
    definition: "to give a number of things to the members of a group",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "monitor performance",
    definition:
      "to watch and check how well sb is doing their job over a period of time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "morale booster",
    definition:
      "something that increases the confidence and enthusiasm of a person or a group",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "move on",
    definition:
      "to start doing or discussing sth new, or to progress to sth new",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "muscle",
    definition:
      "body tissue that you contract and relax in order to move a particular part of the body",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "nutrition",
    definition:
      "the process of getting the food necessary in order to grow and be healthy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "peer rating",
    definition:
      "a judgement of how good, popular, effective, etc. sb is by people who have the same status",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "penny",
    definition: "a small amount of money. 1 one hundredth of a pound",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "performance management",
    definition:
      "the activity of checking and controlling how well people do their jobs in an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "proficient",
    definition: "able to do sth well because of training and practice",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "quit",
    definition: "to leave your job, school, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "raise",
    definition:
      "[esp AmE] [BrE rise] an increase in the money you are paid for the work you do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "rate objectives",
    definition:
      "to give a score showing how successful you think sb has been in achieving sth they wanted to achieve",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "recognize good performance",
    definition: "to do sth to show that you think sb has done a good job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "retail outlet",
    definition:
      "a place such as a shop / store that sells goods directly to the public",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "scholarship",
    definition:
      "an amount of money given to sb by an organization to help pay for their education",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "scrap",
    definition:
      "to cancel or get rid of sth that is no longer practical or useful",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "shelf life",
    definition:
      "the length of time that food, etc. can be kept before it is too old to be sold",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "short-staffed",
    definition: "having fewer members of staff than you need or usually have",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "speak your mind",
    definition: "to say exactly what you think, in a very direct way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "staff appraisal",
    definition:
      "a meeting in which a member of staff discusses with their manager how well they have been doing their job",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "take off",
    definition: "to become successful or popular quickly or suddenly",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "top-down",
    definition:
      "starting from or involving the people who have higher positions in an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "trigger",
    definition:
      "something that is the cause of a particular reaction or development",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "underestimate",
    definition:
      "to think or guess that the amount, cost, or size of sth is smaller than it really is",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "value judgement",
    definition:
      "a judgement about how good or important sth is, based on personal opinions rather than facts",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "van",
    definition:
      "a covered vehicle with no side windows in its back half, usually smaller than a lorry / truck, used for carrying goods or people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 15",
  },
  {
    word: "accompany",
    definition: "to travel or go somewhere with sb",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "appeal",
    definition: "to attract or interest sb",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "appreciate",
    definition: "to be grateful for sth; to welcome sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "book keeping",
    definition:
      "the work of keeping a record of the financial accounts of the business",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "broaden your horizons",
    definition: "to extend the limit of your knowledge or interests",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "career development",
    definition:
      "the process of gradually making progress in your career by learning more, taking more senior jobs, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "catalyst",
    definition: "a person or thing that causes a change",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "charity",
    definition:
      "an organization whose aim is to help people in need, rather than to make a profit",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "common practice",
    definition:
      "the way sth is often or usually done in a particular organization, profession, or situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "company policy",
    definition: "a plan of action agreed or chosen by an organization",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "contented",
    definition:
      "showing or feeling happiness or satisfaction, especially because your life is good",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "ex-pat",
    definition: "a person living in a country that is not their own",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "grateful",
    definition:
      "feeling or showing thanks for sth that you have or that sb has done for you",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "head off",
    definition: "to start moving away or developing in a new way",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "hesitate",
    definition: "to be slow to do or say sth because you feel uncertain",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "literacy",
    definition: "the ability to read and write",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "media exposure",
    definition:
      "the fact of being discussed or mentioned on television, in newspapers, etc.",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "misgivings",
    definition:
      "feelings of doubt or anxiety about what might happen, or about whether or not sth is the right thing to do",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "numeracy",
    definition:
      "a good basic knowledge of mathematics; the ability to understand and work with numbers",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "outlook",
    definition:
      "the attitude to life and the world of a particular person, group, or culture",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "pace of life",
    definition:
      "the speed at which sb does things, or the speed at which things happen and change in sb's life",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "perspective",
    definition:
      "a particular attitude towards sth; a way of thinking about sth",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "piece of advice",
    definition:
      "a suggestion about what sb should do in a particular situation",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "pilot",
    definition:
      "done on a small scale in order to see if sth is successful enough to do on a large scale",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "postpone",
    definition:
      "to arrange for an event, etc. to take place at a later time or date",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "precedent",
    definition:
      "an official action or decision that has happened in the past and that is seen as an example or a rule to be followed in a similar situation later",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "presumably",
    definition: "used to say that you think that sth is probably true",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "put off",
    definition: "to avoid doing sth until a later date or time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "recharged",
    definition:
      "with more strength and energy after resting or doing sth different for a time",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "renewed enthusiasm",
    definition:
      "a stronger feeling of excitement and interest in sth and a desire to become involved in it again",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "retention rate",
    definition: "the ability of a company to keep its staff",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "revitalize",
    definition: "to make sb/sth stronger, more active, or more healthy",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "sabbatical",
    definition:
      "a period of time when sb is allowed to stop their normal work in order to study or travel",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "scuba dive",
    definition:
      "to swim underwater using special breathing equipment consisting of a container of air which you carry on your back and a tube through which you breathe the air",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "soft skills",
    definition:
      "the abilities that sb has to communicate and work well with other people",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "strengthen",
    definition: "to make sth stronger",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },
  {
    word: "stressful",
    definition: "causing sb to feel worried and nervous",
    book: "Business Result",
    level: "upper-intermediate",
    unit: "Unit 16",
  },

  {
    word: "active",
    definition: "having a lot of energy and doing things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "announcement",
    definition: "a public or formal statement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "basketball game",
    definition:
      "a sport in which two teams of five players each try to score by shooting a ball through a hoop",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "brain",
    definition:
      "the organ inside your head that controls how you think, feel, and move",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "break",
    definition: "a short period of rest or time away from something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "carefully",
    definition: "with great attention or caution",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "checklist",
    definition: "a list of things to be checked or done",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "cruel",
    definition: "causing pain or suffering",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "daydream",
    definition:
      "a series of pleasant thoughts that distract you from what you are doing",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "dentists appointment",
    definition: "a scheduled meeting with a dentist",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "dentists office",
    definition: "the place where a dentist works and treats patients",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "essential",
    definition: "absolutely necessary or very important",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "exercise",
    definition: "physical activity that helps you stay healthy",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "factory",
    definition: "a building or group of buildings where products are made",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "find out",
    definition: "to discover or learn something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "find time",
    definition: "to make time available for something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "forum",
    definition: "a place, meeting, or medium for open discussion",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "free-time",
    definition: "time when you are not working or doing other obligations",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "function",
    definition: "the purpose or role that something has",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get busier",
    definition: "to become more busy",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get home",
    definition: "to arrive at your home",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get some exercise",
    definition: "to engage in physical activity for health",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get the bus",
    definition: "to board a bus",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get to bed early",
    definition: "to go to sleep early",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "get up",
    definition: "to rise from bed after sleeping",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "giraffe",
    definition: "a tall African animal with a very long neck and legs",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "happiness",
    definition: "the state of being happy",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "have time",
    definition: "to have enough time to do something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "history exam",
    definition: "a test or examination on historical topics",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "incredibly",
    definition: "in a way that is difficult to believe",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "make time",
    definition: "to find time to do something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "manage",
    definition: "to control or organize something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "math test",
    definition: "a test or examination of mathematical knowledge",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "mention",
    definition: "to briefly speak about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "multitasking",
    definition: "the ability to do several things at the same time",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "organization",
    definition: "the action of organizing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "organize",
    definition: "to arrange or put things in a particular order",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "piano lesson",
    definition: "a session where someone learns to play the piano",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "plan",
    definition: "a detailed proposal for doing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "precious",
    definition: "of great value or importance",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "properly",
    definition: "in a way that is correct or appropriate",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "relax",
    definition: "to become less tense or anxious",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "run out of time",
    definition: "to not have enough time to finish something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "save time",
    definition: "to use time in an efficient way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "school trip",
    definition: "a visit to a place as part of a school activity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "science project",
    definition: "a project or experiment that involves scientific principles",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "screen time",
    definition: "the amount of time spent looking at a screen",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "share time",
    definition: "to spend time together with others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "situation",
    definition: "a set of conditions or circumstances",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "skill",
    definition: "the ability to do something well",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "soccer practice",
    definition: "a session where soccer skills are trained and practiced",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "social media",
    definition:
      "websites and applications that allow users to create and share content",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "spend time",
    definition: "to use time doing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "subject",
    definition: "an area of knowledge or study",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "take time",
    definition: "to spend a certain amount of time doing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "talk",
    definition: "to speak or discuss something with someone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "task",
    definition: "a specific job or duty to be done",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "time management",
    definition:
      "the process of planning and organizing how to divide time between activities",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "tip",
    definition: "a small piece of advice or helpful information",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "tired",
    definition: "feeling the need to rest or sleep",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "to-do list",
    definition: "a list of tasks to be completed",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "traffic",
    definition: "vehicles moving along roads, often in large numbers",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "valuable",
    definition: "worth a lot, often in terms of money or importance",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "value",
    definition: "the importance or worth of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "waste time",
    definition: "to use time in a way that is unproductive",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 1",
  },
  {
    word: "account",
    definition: "a record of money or information kept by a bank or business",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "access",
    definition: "the ability to enter, approach, or use something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "activity",
    definition: "a task or action that is done as part of a process or goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "aim",
    definition: "a goal or purpose that one tries to achieve",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "app",
    definition: "a software application, typically for mobile devices",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "benefit",
    definition: "an advantage or positive outcome gained from something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "bring back a memory",
    definition: "to cause someone to remember something from the past",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "check",
    definition: "to examine or verify something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "click",
    definition: "to press a button on a device, especially a mouse or touchpad",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "colorful",
    definition: "having bright or varied colors",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "compare",
    definition:
      "to examine two or more things to see how they are similar or different",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "compete",
    definition: "to take part in a contest or challenge against others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "competition",
    definition: "an event or contest where people or teams try to win",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "competitor",
    definition: "a person or team that takes part in a competition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "condition",
    definition: "a particular state or situation",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "connect",
    definition: "to join or link together",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "connection",
    definition: "a link or relationship between two or more things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "copy",
    definition: "to duplicate or reproduce something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "costume",
    definition:
      "an outfit worn to represent a character or theme, especially for a performance or celebration",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "crew",
    definition:
      "a group of people working together, especially on a ship, plane, or film production",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "data",
    definition: "facts or information collected for analysis or reference",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "definition",
    definition: "a statement that explains the meaning of a word or phrase",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "digital",
    definition:
      "relating to or using technology that works with data expressed as digits",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "doubtful",
    definition: "uncertain or unsure about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "dramatic",
    definition: "having a great deal of emotion or impact",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "example",
    definition: "a representative or typical instance of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "excited",
    definition: "feeling strong enthusiasm or eagerness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "exercise",
    definition: "physical activity done to improve health or fitness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "experiment",
    definition: "a scientific procedure carried out to test a hypothesis",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "expert",
    definition:
      "a person with extensive knowledge or skill in a particular area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "forget",
    definition: "to fail to remember something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "forgetful",
    definition: "having difficulty remembering things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "however",
    definition:
      "used to introduce a statement that contrasts with or contradicts what has been said before",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "iceberg",
    definition:
      "a large mass of ice floating in the sea, with most of its volume submerged underwater",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "improve",
    definition: "to make something better or more effective",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "internet",
    definition: "a global system of interconnected computer networks",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "lifeboat",
    definition:
      "a small boat used for emergency situations, often to rescue people",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "look up",
    definition:
      "to search for information, typically in a reference book or online",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "memorable",
    definition: "worthy of being remembered",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "memorize",
    definition: "to commit something to memory",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "memory",
    definition: "the ability to remember information, experiences, or events",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "method",
    definition: "a planned or systematic way of doing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "nowadays",
    definition: "in the present time, as opposed to the past",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "online",
    definition: "connected to or available through the internet",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "opposite",
    definition: "completely different or contrary",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "passenger",
    definition: "a person who is traveling in a vehicle but is not driving it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "perfect",
    definition:
      "free from any flaws or mistakes, complete and complete in every way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "playlist",
    definition: "a list of songs or videos that are played in a specific order",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "post",
    definition:
      "to send or share information on a website or social media platform",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "practice",
    definition: "to repeatedly perform an activity or skill to improve it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "procession",
    definition:
      "a group of people or vehicles moving forward in an orderly manner",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "record",
    definition:
      "to capture or store information, often for later use or sharing",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "reliable",
    definition:
      "deserving trust or confidence due to being consistent and dependable",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "remember",
    definition: "to retain or recall information or past experiences",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "remind",
    definition: "to help someone recall something or bring something to mind",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "repeat",
    definition: "to say or do something again",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "research",
    definition:
      "the process of gathering information or studying a subject to discover facts",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "review",
    definition:
      "to look at or examine something again, often for improvement or understanding",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "scared",
    definition: "feeling afraid or frightened",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "shine",
    definition: "to emit light or brightness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "sink",
    definition: "to go down or submerge in a liquid or a surface",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "smartphone",
    definition:
      "a mobile phone with advanced features like internet access and apps",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "store",
    definition: "a place where goods or products are sold",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "strategy",
    definition: "a plan or method designed to achieve a particular goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "stressed",
    definition: "feeling pressure or anxiety due to challenges or demands",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "survey",
    definition:
      "an examination or collection of data, often in the form of questions or interviews",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "survivor",
    definition:
      "a person who has lived through a dangerous or challenging event",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "tasty",
    definition: "having a pleasant or delicious flavor",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "technique",
    definition: "a method or skill used to achieve a particular result",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "the cloud",
    definition:
      "a system for storing and accessing data over the internet, instead of on a local device",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "tragedy",
    definition:
      "an event causing great suffering or loss, often associated with sadness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "tragically",
    definition: "in a manner that is deeply sad or sorrowful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "translation",
    definition:
      "the process of converting text or speech from one language into another",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "unforgettable",
    definition:
      "impossible to forget because of being very memorable or significant",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "useful",
    definition: "helpful or beneficial for a particular purpose",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "virus",
    definition:
      "a harmful or malicious software program designed to damage or disrupt systems",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "write down",
    definition: "to record information on paper or a digital device",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 2",
  },
  {
    word: "artist",
    definition: "a person who creates art, especially as a profession",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "award",
    definition: "a prize or recognition given for an achievement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "behavior",
    definition: "the way in which one acts or conducts oneself",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "blog",
    definition:
      "a website or online journal where an individual or group shares content",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "brake",
    definition: "a device used for slowing or stopping a vehicle or machine",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "carefully",
    definition: "with attention to detail and caution",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "certificate",
    definition: "an official document attesting to a fact or qualification",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "clever",
    definition: "quick to understand, learn, or think of new ideas",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "come first",
    definition: "to finish in first place in a competition or race",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "comment",
    definition: "a verbal or written remark expressing an opinion or reaction",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "compare",
    definition:
      "to examine two or more things to identify similarities or differences",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "comparison website",
    definition:
      "a website that allows users to compare products, services, or prices",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "competition",
    definition:
      "an event or contest in which people or groups compete against each other",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "complain",
    definition: "to express dissatisfaction or annoyance about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "complaint",
    definition: "a statement or expression of dissatisfaction or unhappiness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "convenient",
    definition: "suitable or easy to use or access, without difficulty",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "crime",
    definition: "an act committed in violation of the law",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "cup",
    definition:
      "a trophy or award in the shape of a _ _ _, usually given for winning a competition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "delivery",
    definition:
      "the action of delivering goods or services to a customer or location",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "diet",
    definition: "the food and drink that a person or animal regularly consumes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "engaging",
    definition: "attractive or interesting enough to capture one's attention",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "equipment",
    definition:
      "the necessary items or tools used for a particular activity or purpose",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "exception",
    definition: "something that does not follow a rule or pattern",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "fair",
    definition: "treating people or situations equally and without bias",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "fairness",
    definition:
      "the quality of being just, equitable, and free from bias or favoritism",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "guarantee",
    definition:
      "a formal promise or assurance that something will meet a certain standard",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "judge",
    definition: "a person who makes decisions in a legal case or competition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "medal",
    definition:
      "a small metal disc awarded for an achievement, especially in a competition or contest",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "musician",
    definition:
      "a person who plays a musical instrument or is involved in music professionally",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "navigate",
    definition: "to plan and direct the course of a journey or activity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "opinion",
    definition: "a personal view or judgment formed about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "player",
    definition: "a person who participates in a game or sport",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "popularity contest",
    definition:
      "a competition in which people are judged based on how well-liked they are",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "positive",
    definition: "having a good or optimistic attitude or quality",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "preview",
    definition:
      "an early view or sample of something before it is fully available",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "prize money",
    definition: "the money awarded as a prize in a competition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "pros and cons",
    definition: "the positive and negative aspects of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "quality",
    definition: "the standard or level of excellence of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "reckon",
    definition: "to think or believe something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "recommend",
    definition: "to suggest something as being good or suitable",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "respond",
    definition: "to reply or react to something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "review",
    definition: "to examine or assess something in order to form an opinion",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "reviewer",
    definition: "a person who examines and gives their opinion on something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "reward",
    definition: "something given in recognition of achievement or effort",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "scroll down",
    definition: "to move down a webpage or document using a mouse or keyboard",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "similarity",
    definition: "the state of being similar or having common features",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "skillful",
    definition: "having the ability or expertise to do something well",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "sophisticated",
    definition: "having a high level of development or complexity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "special offer",
    definition: "a promotion or discount available for a limited time",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "speed",
    definition: "the rate at which something moves or operates",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "statistics",
    definition: "data collected and analyzed to provide information or insight",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "successful",
    definition: "having achieved a desired outcome or goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "talented",
    definition: "having a natural ability or skill in a particular area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "track",
    definition: "a path or route used for running, racing, or traveling",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "train",
    definition: "to teach or develop skills through practice or instruction",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "trophy",
    definition:
      "a prize or award, usually in the form of a cup or statue, given for victory",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "value",
    definition: "the importance or worth of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "winner",
    definition: "a person or team that wins a competition or contest",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 3",
  },
  {
    word: "achieve",
    definition: "to successfully complete or accomplish something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "admirer",
    definition: "a person who likes or respects someone or something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "advertise",
    definition: "to promote a product, service, or event to the public",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "antisocial",
    definition: "not wanting to be with or talk to other people",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "background",
    definition:
      "the details of a person's past or the circumstances surrounding an event",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "be part of",
    definition: "to be included in or involved with a group or activity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "become a member of",
    definition: "to join or become a part of a group, organization, or team",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "bold",
    definition: "showing courage, confidence, or a willingness to take risks",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "book",
    definition: "a set of written, printed, or blank pages fastened together",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "charity group",
    definition: "an organization that raises money to help others in need",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "check",
    definition: "to examine something in order to verify or ensure correctness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "chess board",
    definition: "the square board on which chess is played",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "chess piece",
    definition: "one of the individual pieces used in the game of chess",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "contact",
    definition: "to communicate with someone by phone, email, etc.",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "convention",
    definition:
      "a large meeting or conference, often for people with a shared interest",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "craziness",
    definition:
      "the state of being wildly excited, confused, or out of control",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "decorate",
    definition: "to make something more attractive by adding items or designs",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "fan club",
    definition:
      "a group of people who are passionate about and support a particular person or thing",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "figure out",
    definition: "to understand or find a solution to something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "find out",
    definition: "to discover or learn something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "follow",
    definition: "to go or move behind someone or something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "follower",
    definition:
      "a person who supports or admires a particular person, activity, or idea",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "gang",
    definition:
      "a group of people, often involved in criminal activities or sharing a common interest",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "get permission",
    definition: "to receive approval or consent for something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "gladiator",
    definition:
      "a person who fought in ancient Rome as a sport or entertainment",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "identity",
    definition:
      "the characteristics and qualities that make a person or thing unique",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "impolite",
    definition: "not showing good manners or respect for others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "improve",
    definition: "to make something better or more effective",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "irresponsible",
    definition: "not showing responsibility or care for one's actions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "join",
    definition: "to become a part of a group or activity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "look up",
    definition: "to search for information, often in a dictionary or online",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "merchandise",
    definition: "goods or products that are bought and sold",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "misbehave",
    definition: "to act in a way that is not proper or respectful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "organize",
    definition: "to arrange or order something in a systematic way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "passion",
    definition: "a strong feeling or enthusiasm for something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "peaceful",
    definition: "calm, quiet, and free from disturbance or conflict",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "plan",
    definition: "to decide on a course of action or a strategy",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "psychologist",
    definition:
      "a professional who studies and treats mental health and behavior",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "reserve",
    definition: "to book or set aside something for later use",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "scream",
    definition:
      "to make a loud, high-pitched sound, often as a reaction to fear or excitement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "send out",
    definition: "to distribute or send something to others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "set the mood",
    definition:
      "to create or influence the atmosphere or feeling of a situation",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "social media group",
    definition:
      "an online community or network of people sharing common interests on social platforms",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "sponsor",
    definition:
      "a person or organization that provides support, usually financial, for an event or activity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "spread",
    definition: "to distribute or share something across a larger area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "stand out",
    definition: "to be noticeably different or more noticeable than others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "striking",
    definition: "having a strong or unusual appearance that catches attention",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "support",
    definition: "to help, assist, or back up someone or something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "supporter",
    definition: "a person who helps or defends someone or something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "take turns",
    definition: "to alternate between people in an activity or task",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "team",
    definition: "a group of people working together to achieve a common goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "thoughtful",
    definition:
      "showing consideration for others, or thinking carefully about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "tournament",
    definition:
      "a competition or series of contests in which people or teams compete for a prize",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "unfortunately",
    definition: "used to express regret or disappointment about a situation",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "volunteer group",
    definition:
      "a group of people who offer their time and effort to help others without being paid",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "wish",
    definition: "to hope for something or desire something to happen",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "worthwhile",
    definition: "something that is worth the time, effort, or expense",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 4",
  },
  {
    word: "adaptable",
    definition: "able to adjust to new conditions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "amazing",
    definition: "causing great surprise or wonder",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "antibiotics",
    definition: "a medicine used to treat infections caused by bacteria",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "astronaut",
    definition: "a person trained to travel and work in space",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "bacteria",
    definition: "tiny organisms that can cause diseases",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "be scared of",
    definition: "to feel fear or anxiety about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "blood",
    definition:
      "the red liquid that circulates in the bodies of humans and animals",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "boiling",
    definition:
      "a state when a liquid reaches a high temperature and begins to bubble",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "breed",
    definition: "to produce offspring, usually by mating animals or plants",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "calm",
    definition: "not showing strong feelings or emotions; peaceful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "consequence",
    definition: "a result or effect of an action or decision",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "cooperative",
    definition: "working well with others to achieve a common goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "cultivation",
    definition:
      "the process of growing or developing something, usually crops or plants",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "danger",
    definition: "the possibility of harm or injury",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "dangerous",
    definition: "capable of causing harm or injury",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "deadly",
    definition: "likely to cause death",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "dormant",
    definition:
      "in a state of rest or inactivity, but capable of becoming active",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "earthquake",
    definition: "a sudden shaking or movement of the earth's surface",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "enormous",
    definition: "very large in size or amount",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "environment",
    definition:
      "the surroundings or conditions in which a person, animal, or plant lives",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "expedition",
    definition:
      "a journey or voyage undertaken for a specific purpose, often involving exploration",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "extinct",
    definition: "a species or thing that no longer exists",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "fascinating",
    definition: "extremely interesting or captivating",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "flexible",
    definition: "able to bend or change easily without breaking",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "freezing",
    definition:
      "extremely cold, often to the point of causing something to become solid",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "frog",
    definition:
      "a small, usually green animal that lives both in water and on land",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "fuel",
    definition:
      "a substance used to produce energy, such as gas, coal, or wood",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "get hurt",
    definition: "to suffer physical injury",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "get injured",
    definition: "to be harmed in an accident or attack",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "hardworking",
    definition: "putting in a lot of effort and dedication to work",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "harmful",
    definition: "causing damage or injury",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "have an accident",
    definition:
      "to experience an unexpected event that results in harm or damage",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "huge",
    definition: "extremely large in size, quantity, or degree",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "hurry",
    definition: "to do something quickly or move fast",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "icicles",
    definition:
      "long, pointed pieces of ice formed when water drips and freezes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "imaginative",
    definition: "having a creative and inventive mind",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "leaflet",
    definition:
      "a small printed sheet of paper, usually containing information",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "lightning",
    definition:
      "a flash of light in the sky caused by an electrical discharge during a storm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "nature conservation",
    definition:
      "the protection and preservation of the natural environment and wildlife",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "obvious",
    definition: "easily understood or clear to see",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "population",
    definition: "the total number of people or animals in a specific area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "positive",
    definition: "having a good or helpful effect",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "protect",
    definition: "to keep someone or something safe from harm or damage",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "purpose",
    definition: "the reason for which something is done or created",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "rainforest",
    definition: "a dense forest in a tropical area with high rainfall",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "recognize",
    definition: "to identify someone or something from previous experience",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "replace",
    definition: "to take the place of something or someone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "risky",
    definition: "involving the possibility of harm or danger",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "rollercoaster",
    definition:
      "a fast and exciting amusement park ride with steep inclines and drops",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "safe",
    definition: "free from danger or harm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "scary",
    definition: "causing fear or alarm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "shark",
    definition: "a large, predatory fish that lives in the ocean",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "shiny",
    definition: "having a smooth and bright surface that reflects light",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "shoot",
    definition: "to fire a weapon or propel something with force",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "smart",
    definition: "intelligent or able to think quickly and clearly",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "snakebite",
    definition: "an injury caused by a snake's venomous bite",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "species",
    definition:
      "a group of animals or plants that are similar and can breed together",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "surprise",
    definition: "an unexpected event or feeling that causes amazement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "survival",
    definition:
      "the act of continuing to live or exist, especially in difficult conditions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "threat",
    definition: "a danger or something that may cause harm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "tiny",
    definition: "extremely small in size",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "tough",
    definition: "strong enough to withstand difficult conditions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "tragic",
    definition: "causing sadness or sorrow, often because of death or disaster",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "wilderness",
    definition:
      "an uninhabited or uncultivated area, especially one that is wild and natural",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "worried",
    definition: "feeling anxious or concerned about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "wound",
    definition:
      "an injury to the body, typically involving a break in the skin",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 5",
  },
  {
    word: "avoid",
    definition: "to stay away from something or someone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "bird feeder",
    definition: "a container that holds food to attract birds",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "bottle top",
    definition: "the lid or cap of a bottle",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "broken",
    definition: "damaged or no longer in one piece",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "burn",
    definition: "to injure or damage by heat or fire",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "candle holder",
    definition: "a container or stand for holding a candle",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "cardboard",
    definition: "thick, stiff paper used for making boxes or packaging",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "careless",
    definition: "not paying attention or being cautious",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "closet",
    definition: "a small room or space for storing clothes and other items",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "colored paper",
    definition: "paper that has been dyed or tinted a different color",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "compost",
    definition:
      "organic material used to fertilize plants, often made from food scraps",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "container",
    definition: "an object for holding something, such as a box or bottle",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "damage",
    definition:
      "harm or injury that affects the appearance or function of something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "disposable",
    definition: "designed to be used once and then thrown away",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "drawers",
    definition:
      "a sliding storage compartment, usually part of a desk or cabinet",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "environment",
    definition: "the natural world or surroundings where organisms live",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "exchange",
    definition: "the act of giving one thing and receiving another in return",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "explore",
    definition:
      "to investigate or travel through an area to learn more about it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "field",
    definition: "an open area of land, often used for farming or sports",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "firewood",
    definition: "wood that is cut and used for burning in a fire",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "fragment",
    definition: "a small part or piece of something that has broken off",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "glue",
    definition: "a sticky substance used for sticking things together",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "go bad",
    definition: "to become spoiled, rotten, or unsafe to eat",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "independently",
    definition: "doing something without relying on others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 6",
  },
  {
    word: "moneyless",
    definition: "without money or resources",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "mountain of garbage",
    definition: "a very large amount of waste or trash",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "orbit",
    definition:
      "the curved path that an object follows as it moves around another object in space",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "packaging",
    definition: "material used to wrap or protect goods for transport or sale",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "piece of wood",
    definition: "a small section or portion of wood",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "pile",
    definition:
      "a stack or heap of things, typically arranged in a disorganized manner",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "planet",
    definition: "a large object in space that orbits a star, like Earth",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "pollute",
    definition:
      "to make something dirty or harmful, especially the environment",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "possess",
    definition: "to have or own something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "probably",
    definition: "used to say that something is likely to happen or be true",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "produce",
    definition: "to make or create something, especially in large quantities",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "recyclable",
    definition: "able to be processed and used again",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "recycle",
    definition: "to process used materials so that they can be used again",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "replace",
    definition: "to take the place of something or someone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "reuse",
    definition: "to use something again instead of throwing it away",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "rocket",
    definition: "a vehicle or device used to travel through space",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "rotten",
    definition: "decayed or spoiled, especially due to being old or wet",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "satellite",
    definition:
      "an object that orbits a planet or star, often used for communication or scientific purposes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "scissors",
    definition: "a tool used for cutting things, usually with two blades",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "seed",
    definition:
      "the small, hard part of a plant from which a new plant can grow",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "shelf",
    definition: "a flat surface used for storing or displaying things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "shocked",
    definition: "feeling surprised or disturbed by something unexpected",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "solar panel",
    definition: "a device that converts sunlight into electricity",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "solution",
    definition: "an answer to a problem or challenge",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "space junk",
    definition:
      "discarded, non-functional objects in space, such as old satellites or rocket parts",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "spray paint",
    definition: "paint that is sprayed from a can rather than being brushed on",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "stick",
    definition: "a thin, straight piece of wood or other material",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "string",
    definition: "a thin piece of thread or cord",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "stuff",
    definition:
      "a collection of things, often referring to personal items or belongings",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "surrounded",
    definition: "to be all around something or someone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "swap",
    definition: "to exchange one thing for another",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "tape",
    definition:
      "a long, narrow strip of material used for sticking things together",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "throw away",
    definition: "to dispose of something by putting it in the trash",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "tool",
    definition:
      "an instrument or device used to carry out a particular function",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "trailer",
    definition:
      "a large vehicle or container used for carrying goods or people",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "trash",
    definition: "discarded items, typically things that are no longer useful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "useful",
    definition: "providing help or serving a purpose",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "vase",
    definition: "a container used for holding flowers or decorative purposes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "waste",
    definition: "material that is no longer useful and is thrown away",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "wasteful",
    definition:
      "using more resources than necessary or throwing away useful materials",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "3D printer",
    definition:
      "a machine that creates three-dimensional objects by building them layer by layer from a digital model",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "activity",
    definition:
      "a task or action that someone does, usually for enjoyment or as part of work",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "artistic",
    definition: "having a natural ability to create art",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },

  {
    word: "blink",
    definition:
      "to quickly close and open your eyes, often as a reaction to something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "blood vessel",
    definition: "a tube that carries blood through the body",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "cell",
    definition:
      "the smallest unit of living organisms that can function on its own",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "clever",
    definition: "having or showing quick intelligence or skill",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "common sense",
    definition:
      "the ability to make good judgments based on simple, everyday understanding",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "communicate",
    definition: "to share or exchange information with others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "communication",
    definition:
      "the process of sharing information, thoughts, or ideas between people",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "communicative",
    definition: "willing to talk and share information with others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "connection",
    definition: "a relationship or link between things or people",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "creativity",
    definition: "the ability to produce original ideas or things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "development",
    definition: "the process of growth, improvement, or progress",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "discovery",
    definition: "the act of finding something new or previously unknown",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "dominant",
    definition: "more important, powerful, or noticeable than others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "electricity",
    definition: "a form of energy that powers devices like lights and machines",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "engineer",
    definition:
      "a person who designs or builds things, especially machines or structures",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "entertainment",
    definition: "activities that provide enjoyment or amusement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "fluently",
    definition: "able to speak or write a language smoothly and easily",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "genius",
    definition:
      "a person who is extremely intelligent or talented in a particular area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "hearing",
    definition: "the ability to perceive sound through the ears",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "imagination",
    definition:
      "the ability to create new ideas, images, or concepts in the mind",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "imaginative",
    definition: "having the ability to create new and original ideas",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "intelligence",
    definition: "the ability to learn, understand, and think about things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "intelligent",
    definition: "having a high level of mental ability",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "invent",
    definition: "to create something new that has never been made before",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "invention",
    definition: "something that has been created for the first time",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "inventor",
    definition: "a person who creates new things or ideas",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },

  {
    word: "logic",
    definition:
      "the process of reasoning to come to a conclusion or make decisions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "logical",
    definition: "based on clear, sound reasoning",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "maintenance",
    definition:
      "the process of keeping something in good condition or working order",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "mathematical",
    definition: "related to math or numbers",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "microscope",
    definition: "a scientific instrument used to look at very small things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "movement",
    definition: "the act or process of moving from one place to another",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "musical",
    definition: "relating to music or having a talent for making music",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "oxygen",
    definition:
      "a gas that is necessary for humans and animals to breathe and for plants to grow",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "preference",
    definition: "the feeling of liking one thing more than another",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "process",
    definition:
      "a series of actions or steps taken to achieve a particular result",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "prodigy",
    definition:
      "a young person who is very talented or intelligent for their age",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "robot",
    definition:
      "a machine that can perform tasks automatically, often controlled by a computer",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "satnav",
    definition:
      "a system that helps drivers navigate using satellites to show directions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "scientific",
    definition: "relating to science or the study of the natural world",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "sensible",
    definition: "having or showing good judgment or reason",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "skill",
    definition:
      "the ability to do something well, especially through practice or experience",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "skillful",
    definition: "having the ability and experience to do something well",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "speech",
    definition: "the ability to speak or the act of speaking",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "strength",
    definition: "the quality of being strong, or the power to resist or endure",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "transmit",
    definition: "to send or pass something from one place to another",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "voice recognition",
    definition: "technology that identifies and processes a person's voice",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "wisdom",
    definition:
      "the ability to make good decisions based on knowledge and experience",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "wise",
    definition: "having or showing good judgment or knowledge",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 7",
  },
  {
    word: "choice",
    definition:
      "the act of picking or deciding between two or more possibilities",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "although",
    definition: "despite the fact that; even though",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "at risk",
    definition:
      "in a situation where something dangerous or harmful could happen",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "bin",
    definition: "a container used for holding waste or garbage",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "boring",
    definition: "not interesting or exciting; dull",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "calorie",
    definition:
      "a unit of energy that is used to measure the energy content of food",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "campaign",
    definition:
      "a planned series of activities or events to achieve a specific goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "clever",
    definition: "quick to learn or understand; smart",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "complicated",
    definition:
      "difficult to understand or deal with due to many parts or factors",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "contagious",
    definition:
      "able to spread from one person to another, especially a disease",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "debate",
    definition:
      "a discussion where people express different opinions about a topic",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "diabetes",
    definition:
      "a medical condition where the body is unable to control the level of sugar in the blood",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "diet",
    definition: "the food and drink that a person regularly consumes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "effect",
    definition: "a change or result caused by an action or situation",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "effective",
    definition: "having the desired result or effect",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "exciting",
    definition:
      "making you feel happy and eager because something is interesting or enjoyable",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "exercise",
    definition: "physical activity that helps to improve health and fitness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "explanation",
    definition: "a statement that makes something clear or easy to understand",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "fake",
    definition:
      "not real or genuine; something made to look like something else",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "fit",
    definition: "being in good health and physical condition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "force",
    definition:
      "strength or power used to make something happen or to move something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "gesture",
    definition: "a movement of the body or hands to express an idea or feeling",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "give up",
    definition: "to stop trying or quit an activity or goal",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "healthy",
    definition: "good for your health; not harmful to your body",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "heart disease",
    definition:
      "a group of diseases that affect the heart, such as high blood pressure or heart attacks",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "helmet",
    definition:
      "a protective covering for the head, usually worn for safety during activities like biking",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "help",
    definition: "to give assistance or support to someone in need",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "horrible",
    definition: "very unpleasant, shocking, or causing disgust",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "illustrate",
    definition: "to explain or show something with pictures or examples",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "in shape",
    definition: "in good physical condition or fitness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "interest",
    definition: "the feeling of wanting to learn more about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "joy",
    definition: "a feeling of great happiness or pleasure",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "laugh",
    definition: "to make a sound or expression showing amusement or happiness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "life expectancy",
    definition: "the average number of years a person is expected to live",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "litter",
    definition: "trash or waste that is left in a public place or outdoors",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "lose weight",
    definition: "to reduce the amount of body fat through exercise and diet",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "memorable",
    definition:
      "something that is easy to remember because it is interesting or special",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "nutritious",
    definition: "containing substances that help the body stay healthy",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "operation",
    definition:
      "a medical procedure in which a part of the body is treated by cutting it open",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "pedestrian crossing",
    definition:
      "a place where people can safely walk across the street, usually marked with lines",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "powerful",
    definition: "having great strength or influence",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "put on weight",
    definition:
      "to gain body weight, usually as a result of eating more or exercising less",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "reduce",
    definition: "to make something smaller or less in amount, size, or degree",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "relationship",
    definition:
      "the way two or more people or things are connected or interact with each other",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "advertiser",
    definition:
      "a person or company that promotes a product, service, or brand",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "authentic",
    definition: "genuine, not false or copied",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "be set in",
    definition: "to take place in a particular location or time period",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "bravely",
    definition: "in a way that shows courage or determination",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "calmly",
    definition: "in a peaceful and controlled manner",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "carefully",
    definition: "in a way that is cautious and avoids mistakes or accidents",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "classic",
    definition:
      "something that is considered of the highest quality and lasting value",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "dragon",
    definition:
      "a mythical creature often depicted as a large reptile with wings and the ability to fly and breathe fire",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "dramatic",
    definition:
      "something that is striking and exciting, often in an exaggerated way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "elderly",
    definition:
      "a person who is old, often used to describe people in their later years of life",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "emergency exit",
    definition:
      "a door or exit used in case of an emergency, like a fire or earthquake",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "entertain",
    definition: "to provide enjoyment or amusement to others",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "eventually",
    definition: "at some point in the future, after a period of time or events",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "exaggerated",
    definition:
      "described or represented in an overly dramatic or unrealistic way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "explore",
    definition:
      "to investigate or travel through an area to learn more about it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "eyewitness",
    definition:
      "a person who has seen something happen, such as an event or crime",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "fake",
    definition:
      "something that is not real or genuine, made to deceive or mislead",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "fantasy",
    definition:
      "a genre of stories involving magic or imaginary creatures, often set in an unreal world",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "folktale",
    definition:
      "a traditional story, often passed down orally, that tells of myths, legends, or customs",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "genre",
    definition:
      "a category of artistic work, such as music, literature, or film, that shares a particular style or theme",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "hastily",
    definition: "in a hurried or rushed manner, without careful thought",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "hesitantly",
    definition: "in a way that shows uncertainty or a lack of confidence",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "historical fiction",
    definition:
      "a genre of literature that involves stories set in the past, with events and characters that could have actually happened",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "horror",
    definition:
      "a genre of fiction that is intended to evoke fear, often involving supernatural elements or extreme danger",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "incident",
    definition:
      "an event or occurrence, often one that is unexpected or significant",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "inform",
    definition: "to give someone facts or knowledge about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "intrigued",
    definition: "to be very interested or fascinated by something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "investigate",
    definition:
      "to examine or inquire into something systematically to learn more about it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "knight",
    definition:
      "a person who served a king or lord in medieval times, typically wearing armor and riding a horse",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "knowingly",
    definition: "in a way that shows awareness or intention",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "land",
    definition:
      "the solid part of the Earth's surface, as opposed to the sea or air",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "landing",
    definition:
      "the act of coming down to the ground after being in the air, such as with an airplane or parachute",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "made-up",
    definition: "something that is invented or imagined, not real",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "mystery",
    definition:
      "a genre of fiction that involves a puzzle or problem that needs to be solved",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "neutral",
    definition: "not taking sides in an argument or conflict, being impartial",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "novel",
    definition:
      "a long fictional story, typically divided into chapters, that explores characters and themes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "objective",
    definition:
      "something that is based on facts and not influenced by personal feelings or opinions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "persuade",
    definition: "to convince someone to do something or believe in something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "play",
    definition:
      "a dramatic work intended for performance on stage, usually involving characters and dialogue",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "plot",
    definition:
      "the main events of a story, usually presented in a structured and sequential manner",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "poetry",
    definition:
      "a literary form that uses rhythmic and often figurative language to express emotions or ideas",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "realistic",
    definition:
      "something that is based on real-life situations or true-to-life portrayals",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "refuse",
    definition: "to decline or reject something offered or requested",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "reliable",
    definition:
      "something or someone that can be trusted to do what is expected or promised",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "report",
    definition:
      "a detailed account of an event or situation, often for official purposes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "reporter",
    definition: "a person who writes or broadcasts news stories or articles",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "response",
    definition: "an answer or reaction to something that has been said or done",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "science fiction",
    definition:
      "a genre of fiction that deals with imagined scientific or technological advancements, often set in the future",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "setting",
    definition: "the time and place in which a story occurs",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "source",
    definition: "the origin or place from which something comes",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "stream",
    definition:
      "a continuous flow of water, or a continuous flow of information or data",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "subjective",
    definition:
      "something that is influenced by personal feelings, tastes, or opinions",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "suspiciously",
    definition: "in a manner that suggests doubt or mistrust",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "theme",
    definition:
      "the central idea or underlying message of a story, play, or other artistic work",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "unreliable",
    definition: "something or someone that cannot be depended on or trusted",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "venture",
    definition: "to take a risk by doing something new or unknown",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "villager",
    definition: "a person who lives in a village, typically in a rural area",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "wearily",
    definition: "in a tired or exhausted manner",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 9",
  },
  {
    word: "accommodations",
    definition:
      "a place where someone can live or stay, such as a hotel or apartment",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "annoyed",
    definition: "feeling displeased or irritated",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "annoying",
    definition: "something or someone that causes irritation or displeasure",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "architect",
    definition: "a person who designs buildings and other structures",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "be situated in",
    definition: "to be located in a particular place",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "bored",
    definition:
      "feeling uninterested or weary because of a lack of activity or excitement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "boring",
    definition: "something that causes a lack of interest or excitement",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "cafeteria",
    definition:
      "a self-service restaurant or dining area, often found in schools or workplaces",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "climate change",
    definition:
      "the long-term alteration of temperature and typical weather patterns in a place",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "colleague",
    definition:
      "a person who works with you, typically in the same organization",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "collect",
    definition: "to gather things together from different places",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "come back",
    definition: "to return to a place after being away",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "come up",
    definition: "to happen or arise unexpectedly",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "complain",
    definition: "to express dissatisfaction or annoyance about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "confused",
    definition: "feeling unable to understand or think clearly",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "confusing",
    definition: "something that makes you feel uncertain or unclear",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "congratulate",
    definition: "to praise someone for their achievement or success",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "continent",
    definition: "one of the large landmasses on Earth, such as Africa or Asia",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "deep",
    definition: "having a great depth; extending far down from the surface",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "depressed",
    definition: "feeling very sad and hopeless",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "depressing",
    definition: "something that makes you feel sad, hopeless, or low",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "disappointed",
    definition:
      "feeling sad or dissatisfied because something did not meet expectations",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "disappointing",
    definition:
      "something that fails to meet expectations, causing sadness or dissatisfaction",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "dormitory",
    definition:
      "a building providing living accommodations for many people, often used by students or workers",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "embarrassed",
    definition: "feeling self-conscious, ashamed, or awkward about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "embarrassing",
    definition:
      "something that makes you feel awkward, ashamed, or uncomfortable",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "entertainment venue",
    definition:
      "a place where entertainment is provided, such as a theater or stadium",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "excited",
    definition: "feeling enthusiastic and eager about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "exciting",
    definition:
      "something that causes a feeling of great enthusiasm or interest",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "flush",
    definition: "to become red in the face, often from embarrassment or heat",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "freeze",
    definition: "to become solid because of cold or to stop moving suddenly",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "frightened",
    definition: "feeling fear or alarm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "frightening",
    definition: "causing fear or alarm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "frustrated",
    definition:
      "feeling upset or annoyed because of inability to achieve something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "frustrating",
    definition: "something that causes feelings of annoyance or difficulty",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "generate",
    definition: "to produce or create something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "get better",
    definition: "to improve in health, quality, or condition",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "go down",
    definition: "to decrease or become lower in amount or level",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "grateful",
    definition: "feeling thankful or appreciative",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "green space",
    definition:
      "an area of land with grass, trees, or plants, usually in a city or town",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "heat",
    definition: "the quality of being hot or warm",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "indoors",
    definition: "inside a building or structure",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "inhabitant",
    definition: "a person or animal that lives in a particular place",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "isolation",
    definition: "the state of being separated from others or alone",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "keep on",
    definition: "to continue doing something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "location",
    definition: "a place or position where something is situated",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "maintain",
    definition: "to keep something in good condition or working order",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "music festival",
    definition:
      "an event where live music is performed for an audience, often outdoors",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "outdoors",
    definition: "outside, in the open air",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "penguin",
    definition:
      "a type of bird that cannot fly, typically living in cold regions and known for its distinctive black and white feathers",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "pipe",
    definition:
      "a long, hollow tube used to carry liquids, gases, or other substances",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "public transportation",
    definition:
      "transportation services, such as buses and trains, available to the general public",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "remote",
    definition: "distant in space or time; far away",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "rise",
    definition: "to move upward or increase in amount or level",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "sandy beach",
    definition: "a shoreline area covered with sand",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "skate park",
    definition: "a public area designed for skateboarding or rollerblading",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "sports facilities",
    definition:
      "locations or buildings where sports activities are organized and practiced",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "staff",
    definition:
      "a group of people working together for an organization or company",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "street art",
    definition:
      "artwork created in public spaces, often unauthorized, such as graffiti",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "take care of",
    definition:
      "to look after someone or something in order to ensure well-being",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "tired",
    definition: "feeling a need for rest or sleep",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "tiring",
    definition: "causing fatigue or exhaustion",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "trendy store",
    definition: "a shop that sells fashionable or popular items",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "wind turbine",
    definition: "a device that converts wind energy into electrical power",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "worried",
    definition: "feeling anxious or concerned about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 10",
  },
  {
    word: "aggressive",
    definition: "behaving in a forceful or violent way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "basement",
    definition: "a room or space below the ground floor of a building",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "boarding school",
    definition: "a school where students live during the term",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "bramble",
    definition: "a type of thorny bush, often bearing berries",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "bravery",
    definition: "the quality of being brave and willing to face danger",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "butler",
    definition: "a person in charge of the house staff in a large household",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "cart",
    definition:
      "a vehicle with two or four wheels used for carrying goods, often pulled by animals",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "cattle",
    definition:
      "large domesticated animals, such as cows or bulls, raised for milk or meat",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "charge along",
    definition: "to move quickly or forcefully in a particular direction",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "cheerful",
    definition: "feeling happy and showing it in your mood or behavior",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "clamber",
    definition: "to climb or move with difficulty, often using hands and feet",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "curious",
    definition: "eager to learn or find out more about something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "ditch",
    definition: "a long, narrow hole or trench, usually used for drainage",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "driving rain",
    definition:
      "rain that is falling very heavily and fast, often with strong wind",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "easy-going",
    definition: "relaxed and unconcerned about things",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "evil",
    definition: "morally wrong or wicked",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "fairy",
    definition:
      "a small, magical creature often depicted as a woman with wings",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "gaze",
    definition: "to look at something steadily and intently",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "genie",
    definition:
      "a magical being that grants wishes, often depicted in a lamp or bottle",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "gloomy",
    definition: "dark, depressing, or sad in mood",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "greedy",
    definition:
      "wanting more than what is needed, especially in terms of money or food",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "grim",
    definition: "serious, worrying, or unpleasant in nature",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "gun",
    definition: "a weapon designed to shoot bullets or other projectiles",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "hedge",
    definition:
      "a fence or boundary formed by a dense row of shrubs or low trees",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "interrupt",
    definition:
      "to stop someone from speaking or completing something by speaking or doing something first",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "ivy",
    definition: "a type of climbing plant with green, shiny leaves",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "load",
    definition:
      "a heavy or large amount of something that is being carried or transported",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "loyal",
    definition: "showing strong support or allegiance to someone or something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "lump along",
    definition: "to move in a slow or heavy way",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "maid",
    definition:
      "a woman employed to do domestic work such as cleaning or cooking",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "moor",
    definition:
      "a large, open area of land, often covered with heather or grass",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "mysteriously",
    definition: "in a way that is difficult to understand or explain",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "myth",
    definition:
      "a traditional story, especially one concerning the early history of a people or explaining a natural phenomenon",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "pale",
    definition:
      "having a light or faint color, or appearing less bright than usual",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "pass down",
    definition:
      "to give something from one person to another, especially from one generation to the next",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "plain",
    definition: "simple and not decorated or elaborate",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "radiator",
    definition:
      "a device that heats a room by emitting heat from hot water or steam passing through it",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "rude",
    definition:
      "showing a lack of respect or politeness, especially by not being considerate",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "ruthless",
    definition: "having no pity or compassion for others; cruel",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "sadness",
    definition: "the feeling of being unhappy or sorrowful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "scramble",
    definition:
      "to move quickly and awkwardly, often using your hands for support",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "selfish",
    definition:
      "showing a lack of concern for others and only caring about oneself",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "servant",
    definition: "a person employed to perform domestic duties",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "starve",
    definition: "to suffer or die from lack of food",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "surroundings",
    definition: "the things, conditions, and people around you",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "swing",
    definition:
      "to move back and forth or side to side, often in a hanging position",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "terrified",
    definition: "feeling extremely afraid",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "tramp",
    definition:
      "a person who travels from place to place on foot, often without a permanent home or job",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "untie",
    definition: "to loosen or undo something that has been tied",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "whistle by",
    definition: "to pass by with a whistling sound",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "witch",
    definition:
      "a woman thought to have magical powers, often associated with evil or malevolent behavior",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "glimpse",
    definition: "a brief or hurried look at something",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "thief",
    definition: "a person who steals something from another person or place",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "troops",
    definition: "soldiers or military forces",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 11",
  },
  {
    word: "seat belt",
    definition:
      "a strap in a car or airplane that holds you in place for safety",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "serious",
    definition:
      "having an important or significant effect; not lighthearted or trivial",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "shocking",
    definition:
      "surprising or upsetting in a way that causes a strong reaction",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "simple",
    definition: "easy to understand or do; not complicated",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "slogan",
    definition:
      "a short, memorable phrase used to promote something, such as a brand or cause",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "smile",
    definition:
      "to make a facial expression that shows happiness or friendliness",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "start",
    definition: "to begin an action or process",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "strengthen",
    definition: "to make something stronger or more powerful",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "stressful",
    definition: "causing sb to feel worried and nervous",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "study",
    definition:
      "the activity of learning about something in detail, often in school or university",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "take up",
    definition: "to begin a new activity or hobby",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "unhealthy",
    definition: "not good for your health or well-being",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },
  {
    word: "upsetting",
    definition: "causing someone to feel sad, worried, or angry",
    book: "Oxford Discover Futures",
    level: "1",
    unit: "Unit 8",
  },

  {
    word: "algorithm",
    definition: "a set of rules or steps to follow in order to solve a problem",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "astronaut",
    definition: "a person who travels in space",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "belief",
    definition: "a strong feeling that something is true or real",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "beware",
    definition: "to be careful of something dangerous or harmful",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "biometric",
    definition:
      "relating to measurements of the body, like fingerprints or eye scans",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "blocked",
    definition: "when something is closed off or unable to pass through",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "blond-haired",
    definition: "having light yellow or pale gold-colored hair",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "blue-eyed",
    definition: "having eyes that are the color blue",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "camera",
    definition: "a device used to take photographs or record videos",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "casual",
    definition: "comfortable and informal clothing or behavior",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "choir",
    definition: "a group of people who sing together, especially in a church",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "combination",
    definition: "a mix of different things or parts",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "common",
    definition: "something that is found or happens often, or is usual",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "confusion",
    definition:
      "a state of not understanding or being uncertain about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "contain",
    definition: "to hold or have something inside",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "courageous",
    definition:
      "showing bravery and not being afraid to face danger or difficulty",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "desire",
    definition: "a strong feeling of wanting something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "determined",
    definition: "deciding to achieve something and not giving up easily",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "doppelgänger",
    definition: "a person who looks very similar to another person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "eccentric",
    definition:
      "behaving in a way that is unusual or different from the normal",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "elderly",
    definition: "referring to old people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "enthusiastic",
    definition:
      "showing a lot of interest, excitement, or energy about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "experience",
    definition: "the knowledge or skill gained by doing something over time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "fake",
    definition: "not real or genuine, made to look like something else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "feed",
    definition: "to give food to someone or something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "female",
    definition: "a word used for women or girls",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "fingerprint",
    definition: "a unique mark left by the skin on your fingers",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "hacker",
    definition:
      "someone who uses technology to break into computers or networks",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "hotspot",
    definition: "a place where you can connect to the internet wirelessly",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "identify",
    definition: "to recognize or name something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "identity",
    definition: "the characteristics that make a person or thing unique",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "identity theft",
    definition: "when someone steals your personal information to commit fraud",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "influence",
    definition: "the power to affect the way someone thinks or behaves",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "insecure",
    definition: "feeling not confident or uncertain about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "inspiration",
    definition:
      "something that makes you feel excited and motivated to do something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "install",
    definition:
      "to put something, like software or equipment, in place so it works",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "kind",
    definition: "being nice, caring, and helpful to others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "kindness",
    definition: "the quality of being nice, caring, and generous",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "knowledge",
    definition:
      "information and understanding that you gain from learning or experience",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "link",
    definition: "a connection between two things, such as websites or ideas",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "male",
    definition: "a word used for men or boys",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "measurement",
    definition:
      "the process of finding the size, amount, or degree of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "middle-aged",
    definition:
      "describing a person who is between young adulthood and old age",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "modest",
    definition:
      "not showing off or bragging about yourself or your achievements",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "nationality",
    definition: "the country where a person is from or has citizenship",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "neighborhood",
    definition:
      "the area or community where people live, usually close to each other",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "optimistic",
    definition:
      "having a positive attitude and expecting good things to happen",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "originally",
    definition:
      "used to show the first situation or condition before anything changed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "pacifist",
    definition: "a person who believes in not using violence to solve problems",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "password",
    definition:
      "a secret word or phrase used to protect your information or accounts",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "physical",
    definition: "related to the body or to things you can touch and see",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },

  {
    word: "post",
    definition:
      "to send a letter, message, or information to someone or somewhere",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "privacy",
    definition:
      "the state of keeping your personal information safe and not shared with others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "proud",
    definition:
      "feeling pleased or happy about something you've done or achieved",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "punch",
    definition: "to hit someone or something with a closed fist",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "runner",
    definition:
      "a person who runs as a sport or exercise, or someone who delivers something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "safety",
    definition: "the condition of being protected from harm or danger",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "scanner",
    definition:
      "a device used to read and copy information, such as from documents or barcodes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "screen",
    definition:
      "the flat surface on a computer, phone, or TV where images or text appear",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "secure",
    definition: "free from danger or risk, or protected by a password or lock",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "shark",
    definition: "a large fish with sharp teeth that lives in the ocean",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "show off",
    definition:
      "to display your abilities or achievements in a way that is meant to impress others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "similarity",
    definition: "a quality or feature that makes things seem alike or the same",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "spyware",
    definition:
      "software that secretly collects information from your computer or device",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "stand out",
    definition: "to be clearly different from others, especially in a good way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "statistics",
    definition:
      "facts or information presented in numbers or data to show patterns or trends",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "strike",
    definition: "to hit something with force, or to stop working as a protest",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "teenage",
    definition: "describing a person who is between the ages of 13 and 19",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "trick",
    definition: "a clever or sneaky action intended to deceive someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "unblock",
    definition: "to remove something that is blocking an access or path",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "uniqueness",
    definition:
      "the quality of being one of a kind or different from everything else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "virus",
    definition:
      "a small organism that can make you sick or damage your computer",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "well-dressed",
    definition: "wearing nice, stylish clothes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 1",
  },
  {
    word: "access",
    definition: "the ability to get or use something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "according to",
    definition: "as stated or reported by someone or something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "agriculture",
    definition:
      "the science or practice of farming, including growing crops and raising animals",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "aim",
    definition: "to direct your efforts toward a specific goal or purpose",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "allow",
    definition: "to give permission for something to happen or be done",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "ancestor",
    definition:
      "a person from whom you are descended, such as a grandparent or earlier generations",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "ancient",
    definition:
      "very old, often related to past civilizations or historical periods",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "arrow",
    definition:
      "a pointed weapon that is shot from a bow, often used as a symbol",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "augmented reality app",
    definition:
      "an application that overlays digital images or information onto the real world",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "axe",
    definition:
      "a tool with a heavy blade used for cutting wood or other materials",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "badly",
    definition: "in a way that is not good or is done incorrectly",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "battle",
    definition: "a fight between two or more groups, often part of a war",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "beautifully",
    definition: "in a very attractive or pleasing way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "biography",
    definition: "a written account of someone's life",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "bravely",
    definition: "in a way that shows courage or fearlessness",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "bring about",
    definition: "to cause something to happen or exist",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "carefully",
    definition: "in a way that is cautious and pays attention to details",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "census",
    definition: "an official count or survey of a population",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "civil rights movement",
    definition:
      "a movement to fight for equal rights and justice for all people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "civilization",
    definition:
      "an advanced state of human society with well-developed arts, science, and government",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "clue",
    definition: "a piece of information that helps solve a problem or mystery",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "consequence",
    definition: "the result or effect of something that has happened",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "construction",
    definition: "the process of building or making something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "declare",
    definition: "to announce or say something publicly",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "development",
    definition: "the process of growing or improving something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "discovery",
    definition: "finding something new that was not known before",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "drip",
    definition: "to fall in small drops, usually from a liquid",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "effect",
    definition: "a change that happens because of an action or event",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "enable",
    definition: "to make something possible or allow it to happen",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "eventually",
    definition: "after a period of time, at some point in the future",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "exploration",
    definition: "the act of searching or investigating new places or ideas",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "founding",
    definition:
      "the act of starting something, such as a company or organization",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "fungus",
    definition:
      "a type of living thing, such as mushrooms or mold, that often grows in damp places",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "give up",
    definition: "to stop trying or surrender",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "greedily",
    definition: "in a way that shows wanting more than what is needed or fair",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "guide",
    definition: "to lead or show someone the way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "heavily",
    definition: "with a lot of weight or force",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "interview",
    definition:
      "a meeting or conversation to ask questions, often for a job or research",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "invention",
    definition: "a new device, method, or idea that has been created",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "launch",
    definition:
      "to start or send something into action, such as a product or rocket",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "microscope",
    definition:
      "an instrument used to see very small objects by magnifying them",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "monument",
    definition:
      "a large structure built to remember an important person or event",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "mummified remains",
    definition:
      "the preserved body of a person or animal, usually from ancient times",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "ozone layer",
    definition:
      "the part of the Earth's atmosphere that protects us from harmful rays from the Sun",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "nickname",
    definition:
      "a special name given to a person, often based on a characteristic or quality",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "peace",
    definition: "a state of calm, without fighting or war",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "plaque",
    definition:
      "a flat, often engraved object used to mark an event or place of importance",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "politics",
    definition:
      "the activities, actions, or ideas related to the government and the way a country is run",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "possession",
    definition: "something that you own or have",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "preserve",
    definition:
      "to keep something in its original condition or protect it from harm",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "progress",
    definition: "the process of improving or moving forward in development",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "quickly",
    definition: "doing something at a fast speed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "scientific technique",
    definition:
      "a method or way of doing something based on science or experiments",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "skilled",
    definition: "having the ability or experience to do something well",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "slippery",
    definition: "something that is smooth and makes it easy to slide or fall",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "slowly",
    definition: "doing something at a slow speed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "spread",
    definition: "to move or extend over a large area",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "storekeeper",
    definition:
      "a person who manages or works in a store, especially a small shop",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "strength",
    definition: "the quality of being strong, either physically or mentally",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "struggle",
    definition: "to try hard to do something, especially when it's difficult",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "substance",
    definition:
      "a material or matter that has particular qualities or features",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "telescope",
    definition:
      "an instrument used for looking at distant objects, like stars and planets",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "tour",
    definition: "a journey or trip to see places of interest",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "trade",
    definition:
      "the activity of buying, selling, or exchanging goods and services",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "turning point",
    definition:
      "a moment in time that causes a significant change or development",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "valuable",
    definition:
      "something that is worth a lot of money or is highly useful or important",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "war",
    definition: "a conflict or battle between groups or countries",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "warrior chieftain",
    definition: "a leader of a group of warriors, often in historical times",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "wound",
    definition: "an injury to the body caused by something sharp or violent",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 2",
  },
  {
    word: "accent",
    definition:
      "the way in which a person pronounces words, often based on where they are from",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "extinct",
    definition:
      "when something no longer exists, especially a species of animal or plant",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "explain",
    definition:
      "to make something clear or easy to understand by describing it in detail",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "eyebrow",
    definition: "the line of hair that grows above your eyes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "fail",
    definition: "to not succeed in achieving something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "fidget",
    definition: "to make small, nervous movements with your hands or feet",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "fluently",
    definition: "speaking or writing in a smooth, effortless way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "focus on",
    definition: "to give your full attention to something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "freeze",
    definition: "to stop moving suddenly, often due to fear or surprise",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "gesture",
    definition:
      "a movement of the hands, arms, or head to express an idea or meaning",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "have the chance",
    definition: "to have an opportunity to do something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "hesitate",
    definition:
      "to pause before doing something because you're unsure or uncertain",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "high five",
    definition:
      "a gesture where two people raise one hand and slap their palms together, often as a greeting or celebration",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "hip",
    definition:
      "a part of your body on each side, below the waist, where your legs join your torso",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "ideogram",
    definition:
      "a written symbol that represents an idea or object, rather than a word",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "ignore",
    definition: "to deliberately pay no attention to something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "in disbelief",
    definition: "feeling shocked or unable to believe something is true",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "indication",
    definition: "a sign or piece of information that suggests something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "statue",
    definition:
      "a figure of a person or animal made from stone, metal, or other materials",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "inform",
    definition: "to give someone knowledge about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "intensity",
    definition: "the strength or degree of something, like light or emotion",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "interpreter",
    definition: "a person who translates spoken language",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "interrupt",
    definition: "to stop someone while they are speaking or doing something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "irritate",
    definition: "to make someone feel annoyed or angry",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "join",
    definition: "to become a part of something or connect with others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "laugh",
    definition:
      "to make sounds and movements of the face that show happiness or amusement",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "limited",
    definition: "restricted in size, amount, or extent",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "look forward",
    definition:
      "to feel excited or happy about something that will happen in the future",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "magical",
    definition:
      "having special powers or qualities that seem to come from fantasy or imagination",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "message",
    definition: "a piece of information that is sent or received",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "multilingual",
    definition: "able to speak or use more than one language",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "mysterious",
    definition: "something that is difficult to understand or explain",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "native speaker",
    definition: "a person who speaks a language as their first language",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "overpowering",
    definition: "too strong or intense to be resisted",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "patient",
    definition: "able to wait calmly for something without getting angry",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "alphabet",
    definition: "a set of letters used to write a language in a certain order",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "placement",
    definition:
      "the act of placing or positioning something in a particular place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "awareness",
    definition: "the state of knowing about something or being conscious of it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "polite",
    definition: "showing good manners and respect for others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "bilingual",
    definition: "able to speak two languages fluently",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "possess",
    definition: "to have or own something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "cheek",
    definition: "the soft part of the face below the eye",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "raise",
    definition: "to lift something to a higher position",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "concentration",
    definition: "the ability to focus on something for a period of time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "react",
    definition: "to respond to something that happens or is said",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "cute",
    definition: "attractive in a pretty or charming way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "roll",
    definition: "to move by turning over and over, like a ball",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "dialect",
    definition:
      "a form of a language spoken in a particular region or by a specific group of people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "scream",
    definition: "to make a loud, high sound, often from fear or excitement",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "eventually",
    definition: "after a period of time, at some point in the future",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "shout",
    definition: "to say something in a loud voice",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "exchange program",
    definition:
      "a program that allows students to live and study in another country for a period of time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "shrug",
    definition:
      "to raise your shoulders as a sign of not knowing or not caring",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "slang",
    definition: "informal language, often used by specific groups of people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "sore",
    definition: "feeling pain or discomfort, usually on the body",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "stare",
    definition:
      "to look at something or someone for a long time without moving your eyes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "stationery",
    definition: "materials used for writing, such as paper, pens, and pencils",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "survey",
    definition: "a method of collecting information or opinions from people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "tough",
    definition: "strong and able to endure difficult conditions",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "transform",
    definition: "to change something completely in form or appearance",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "translator",
    definition: "a person who translates written language",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "trouble",
    definition: "problems or difficulties",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "understand",
    definition: "to know the meaning of something or to comprehend it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 3",
  },
  {
    word: "access",
    definition: "the ability to reach or enter something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "act out",
    definition: "to perform or show something using actions",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "advertising",
    definition:
      "the activity of making products or services known to the public",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "alter",
    definition: "to change something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "artistic photography",
    definition: "pictures taken with a focus on creativity and beauty",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "background",
    definition: "the part of a scene or image that is farthest from the viewer",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "blurry",
    definition: "unclear or not sharp in appearance",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "bomb attack",
    definition: "an explosion caused by a bomb, often with harmful effects",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "bombardment",
    definition: "a continuous attack with bombs, shells, or other missiles",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "breathtaking",
    definition: "extremely beautiful or surprising",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "campaign",
    definition:
      "a series of actions to achieve a goal, often related to public awareness",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "celebrity",
    definition: "a famous person, especially in entertainment",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "check out",
    definition: "to look at something to find out more about it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "completely",
    definition: "totally or fully",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "copyright",
    definition:
      "the legal right to control the use of something created, like a book or music",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "creativity",
    definition: "the ability to make new and original ideas",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "drown",
    definition: "to die by being underwater and unable to breathe",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "embarrassing",
    definition: "making someone feel uncomfortable or self-conscious",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "exaggerated",
    definition:
      "described as being larger, greater, or more important than it really is",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "eye-popping",
    definition: "extremely surprising or impressive",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "fashion photography",
    definition: "pictures taken to showcase clothing, accessories, or styles",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "fault",
    definition: "a mistake or problem caused by someone or something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "flood",
    definition: "an overflow of water that submerges land or places",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "foreground",
    definition: "the part of a scene or image that is closest to the viewer",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "galaxy",
    definition: "a large group of stars, planets, and other space objects",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "heartbreaking",
    definition: "causing sadness or sorrow",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "heartwarming",
    definition: "causing happiness and a feeling of kindness",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "helicopter",
    definition: "a type of aircraft that flies using rotating blades",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "impact",
    definition:
      "the strong effect or influence something has on something else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "jungle",
    definition: "a dense, tropical forest with a variety of plants and animals",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "make out",
    definition: "to understand or see something clearly",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "man-eating",
    definition: "describing animals that hunt and eat humans",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "manipulate",
    definition: "to control or influence someone or something in a clever way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "marvelous",
    definition: "extremely good, wonderful, or impressive",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "mechanic",
    definition: "a person who repairs machines or vehicles",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "miming",
    definition:
      "the act of performing actions or expressing emotions without words",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "mind-numbing",
    definition: "extremely boring or dull",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "modify",
    definition: "to change or adjust something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "mouth-watering",
    definition: "making you feel hungry because it looks or smells very good",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "navigate",
    definition: "to find your way around a place or system",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "neverending",
    definition: "seeming to last forever",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "out of focus",
    definition: "when an image or scene is unclear or blurry",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "permission",
    definition: "the act of allowing someone to do something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "photojournalism",
    definition: "journalism that uses photos to tell a story",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "portrait",
    definition: "a picture or photograph of a person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "power",
    definition: "the ability or control to do something or influence others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "print",
    definition: "to make a copy of text or images using ink on paper",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "propaganda",
    definition:
      "information spread to influence people's opinions, often in a biased way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "protect",
    definition: "to keep someone or something safe from harm",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "publicity",
    definition: "attention given to something, usually by the media",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "record-breaking",
    definition: "achieving the best or highest result in a particular category",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "retouch",
    definition: "to improve or change a photograph or image",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "setting",
    definition: "the place or time where something happens",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "snapshot",
    definition: "a quick or casual photograph",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "starving",
    definition: "extremely hungry or in need of food",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "tag",
    definition: "to label or mark something, often for identification",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "thought-provoking",
    definition: "making you think deeply or consider new ideas",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "trap",
    definition: "a device or situation that catches or harms someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "tribe",
    definition: "a group of people, often with a shared culture and history",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "universe",
    definition:
      "everything that exists, including stars, planets, and galaxies",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },
  {
    word: "weird",
    definition: "strange or unusual in a way that is hard to explain",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 4",
  },

  {
    word: "adventurer",
    definition: "someone who enjoys taking risks and exploring new places",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "aid worker",
    definition:
      "a person who helps provide assistance, especially in emergencies or disasters",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "alternative",
    definition: "different from what is usual or expected",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "ambulance",
    definition:
      "a vehicle used for transporting sick or injured people to the hospital",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "arrival",
    definition: "the act of reaching a place, especially after a journey",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "backpacker",
    definition:
      "a person who travels with only a backpack, usually for long periods and on a budget",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "break away",
    definition: "to escape from a place or situation",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "break out",
    definition:
      "to start suddenly, especially a dangerous event like a fire or disease",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "break up",
    definition: "when two people end their relationship",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "calculate",
    definition: "to find an answer by using numbers or mathematical methods",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "care",
    definition:
      "to look after someone or something, or to feel concern for someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "clean out",
    definition: "to remove unwanted things from a place or container",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "clean up",
    definition: "to tidy or make a place clean again",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "commuter",
    definition:
      "a person who travels regularly between home and work or school",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "companion",
    definition: "a person or animal who is with you and spends time with you",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "compass",
    definition: "a tool used for finding directions",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "congested",
    definition: "when a place is very crowded or full of people or vehicles",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "currency",
    definition: "the money used in a particular country",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "currently",
    definition: "at the present moment or time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "cut up",
    definition: "to divide something into smaller pieces",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "departure",
    definition: "the act of leaving or going away from a place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "destination",
    definition: "the place you are going to or aiming for",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "destroy",
    definition: "to break something so that it cannot be used again",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "directions",
    definition: "instructions on how to get to a place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "drive out",
    definition: "to force someone or something to leave a place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "emergency",
    definition: "a sudden, urgent situation that requires immediate action",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "end up",
    definition:
      "to finally be in a particular place or situation after a series of events",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "envy",
    definition: "a feeling of wanting what someone else has",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "exchange rate",
    definition: "the value of one currency compared to another",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "explorer",
    definition: "someone who travels to new or unknown places",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "fabulous",
    definition: "extremely good or amazing",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "fancy",
    definition: "to want or feel attracted to something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "finish up",
    definition: "to complete or end something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "fragrance",
    definition: "a pleasant smell or scent",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "give away",
    definition:
      "to give something to someone without expecting anything in return",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "go away",
    definition: "to leave a place or person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "grow up",
    definition: "to become an adult or mature",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "hide out",
    definition: "to hide or take shelter in a secret place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "illegal",
    definition: "not allowed by law",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "immigrant",
    definition: "a person who moves to another country to live there",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "landmark",
    definition:
      "a famous building, statue, or place that helps people recognize a location",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "locate",
    definition: "to find or discover where something is",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "location",
    definition: "a particular place or position",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "miss out",
    definition:
      "to fail to take part in something, or not to have the chance to experience something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "motorist",
    definition: "a person who drives a car",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "move away",
    definition: "to leave your home to live somewhere else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "nomad",
    definition:
      "a person who moves from place to place, often with no permanent home",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "orphan",
    definition: "a child whose parents are dead or have abandoned them",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "overloaded",
    definition:
      "having too much of something, especially when it's difficult to carry or manage",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "pack up",
    definition:
      "to put things into a bag or container to prepare for a journey",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "passenger",
    definition: "a person who is traveling in a vehicle but is not driving it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "platform",
    definition:
      "a raised area at a train station or airport where passengers wait for transportation",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "poverty",
    definition: "the state of being very poor, with little or no money",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "refugee",
    definition:
      "a person who has been forced to leave their country because of war, disaster, or persecution",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "roots",
    definition: "the basic origins of something, or a person's family history",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "scenery",
    definition:
      "the natural features of a place, like mountains, forests, and rivers",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "schedule",
    definition: "a list of planned activities or events",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "shrine",
    definition:
      "a place where people go to worship or show respect for a person or god",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "smuggler",
    definition: "someone who illegally transports goods or people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "stay away",
    definition: "to not go near something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "stay out",
    definition: "to remain outside, or not enter a place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "stay up",
    definition: "to remain awake and not go to bed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "sumo wrestler",
    definition:
      "a person who takes part in a traditional Japanese wrestling sport",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "tidy up",
    definition: "to clean and organize things",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "tourist",
    definition: "a person who visits a place for pleasure or exploration",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "volunteer",
    definition: "a person who offers to help or work without being paid",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "wander",
    definition: "to move around without a specific direction or purpose",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 5",
  },
  {
    word: "alert",
    definition: "quick to notice and react to things",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "allow",
    definition: "to let someone do something or have something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "all-round",
    definition: "having many different skills or qualities",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "aloud",
    definition: "spoken out loud, not silently",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "assessment",
    definition: "an evaluation or judgment about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "assignment",
    definition: "a task or work given to someone as part of their job or study",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "ban",
    definition: "to officially stop something from happening",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "baths",
    definition: "a process of cleaning the body in water",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "behavior",
    definition: "the way a person acts or conducts themselves",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "bullying",
    definition: "the act of being mean or aggressive toward others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "carefree",
    definition: "free from worries or problems",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "chug",
    definition: "to drink something quickly or in large amounts",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "concentrate",
    definition: "to focus your attention on something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "covered",
    definition: "to have something on top of or hiding something else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "drag",
    definition: "to pull something with difficulty",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "educate",
    definition: "to teach or give knowledge to someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "educational",
    definition: "related to learning or teaching",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "ethics",
    definition: "the moral rules about what is right or wrong",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "exhaustion",
    definition: "the state of being very tired",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "experiment",
    definition: "a test or trial to discover something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "famished",
    definition: "extremely hungry",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "figure out",
    definition: "to understand or solve something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "formula",
    definition: "a set of rules or principles to follow",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "full of",
    definition: "having a lot of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 6",
  },
  {
    word: "affordable",
    definition: "something that is reasonably priced and not too expensive",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "ahead of its time",
    definition:
      "something that is more advanced or modern than what exists currently",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "anti-age",
    definition: "designed to stop or slow down the effects of getting older",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "antisocial",
    definition: "avoiding social interaction or preferring to be alone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "appliance",
    definition: "a machine or device used for household tasks",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "bacteria",
    definition: "tiny living organisms that can cause disease",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "behind the times",
    definition: "not modern or outdated in style or technology",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "biomedical",
    definition: "related to the science of biology and medicine",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "bionic",
    definition: "having artificial body parts or enhancements",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "blood vessel",
    definition: "tubes in the body through which blood flows",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "compatible",
    definition: "able to work or exist together without problems",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "cons",
    definition: "disadvantages or negative aspects of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "contact lens",
    definition: "a small lens placed directly on the eye to improve vision",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "deal",
    definition:
      "an agreement or arrangement, often involving a business transaction",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "device",
    definition: "a tool or piece of equipment used for a specific purpose",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "download",
    definition:
      "to transfer data from the internet or another computer to your device",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "drone",
    definition: "a flying robot, often used for surveillance or delivery",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "electrode",
    definition:
      "a conductor used to make electrical contact with a part of a circuit",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "eliminate",
    definition: "to remove or get rid of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "enhance",
    definition: "to improve or make something better",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "environmentally-friendly",
    definition: "causing little or no harm to the environment",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "experimental",
    definition: "involving a new idea or method that is being tested",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "fundamental",
    definition: "basic and important; essential",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "gadget",
    definition: "a small tool or device with a specific function",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "hack into",
    definition: "to illegally access or break into a computer system",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "implant",
    definition: "to insert something inside the body for medical reasons",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "implement",
    definition: "to put a plan or system into action",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "improvement",
    definition: "the process of making something better or more effective",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "in an instant",
    definition: "very quickly, without any delay",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "increasingly",
    definition: "becoming greater or more intense over time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "indispensable",
    definition: "absolutely necessary; cannot do without",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "innovative",
    definition: "introducing new ideas or methods",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "interaction",
    definition: "communication or involvement with others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "interface",
    definition: "a way for two systems or devices to connect and work together",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "international",
    definition: "involving more than one country",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "life-saving",
    definition: "something that is essential for preventing death",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "limb",
    definition: "an arm or leg of a human or animal",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "medical problem",
    definition: "an issue or condition affecting someone's health",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "microscopic",
    definition: "so small that it can only be seen with a microscope",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "microwave",
    definition:
      "a type of oven that cooks food quickly using electromagnetic waves",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "military",
    definition: "related to soldiers, armed forces, or war",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "motorized",
    definition: "powered by a machine or engine",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "nanobot",
    definition: "a tiny robot designed to perform tasks at the molecular level",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "nanotechnology",
    definition:
      "the science of manipulating very small particles to create new materials or devices",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "on demand",
    definition: "available whenever needed or requested",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "pacemaker",
    definition: "a medical device that helps regulate the heartbeat",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "people with disabilities",
    definition:
      "individuals who have physical or mental challenges that affect their daily lives",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "portable",
    definition: "easy to carry or move from place to place",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "practical",
    definition: "useful and effective in real-life situations",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "predict",
    definition:
      "to say what will happen in the future based on current information or trends",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "properties",
    definition: "the characteristics or qualities of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "pros",
    definition: "the advantages or positive aspects of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "purchase",
    definition: "to buy something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "radically",
    definition: "in a way that is very different from the usual or traditional",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "recreate",
    definition: "to make something again, especially in a new or improved way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "remarkable",
    definition:
      "something that is very unusual, interesting, or worthy of attention",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "repair",
    definition: "to fix or mend something that is broken or damaged",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "repetitive",
    definition: "involving doing the same thing over and over again",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "replacement",
    definition: "something that takes the place of something else",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "restore",
    definition: "to bring something back to its original or better condition",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "revolutionize",
    definition: "to change something in a completely new and innovative way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "risk",
    definition: "the possibility of something harmful or dangerous happening",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "spare part",
    definition: "an extra piece of equipment kept to replace a broken one",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "stream",
    definition:
      "to transmit or receive data, such as music or videos, over the internet",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "streaming",
    definition:
      "the process of transmitting or receiving content (like video or music) in real-time over the internet",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "succeed",
    definition: "to achieve what you set out to do or reach a goal",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "suitability",
    definition:
      "how appropriate or fitting something is for a particular purpose or situation",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "summarize",
    definition:
      "to give a brief and clear explanation of the main points of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "superhero",
    definition:
      "a fictional character with extraordinary abilities who fights crime or saves people",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "supermarket",
    definition: "a large store that sells food and other household items",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "transportation",
    definition:
      "the movement of people or goods from one place to another, typically using vehicles",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "tried and tested",
    definition: "something that has been proven to work well over time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "upgrade",
    definition: "to improve or raise something to a higher standard or version",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "vinyl record",
    definition:
      "a flat, round piece of plastic used to store music, played on a turntable",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "zoom capability",
    definition:
      "the ability to magnify or get a closer view of something, especially in video or imaging technology",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 7",
  },
  {
    word: "blame yourself",
    definition: "to hold yourself responsible for something bad that happened",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "charity",
    definition:
      "helping people who are in need or donating money for a good cause",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "child labor",
    definition:
      "the use of children for work, often in difficult or harmful conditions",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "community service",
    definition:
      "work that is done to help others or improve the local area, often as a punishment or volunteer activity",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "compassion",
    definition:
      "a strong feeling of sympathy and concern for others who are suffering",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "conflict",
    definition: "a serious disagreement or argument between people or groups",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "contribute",
    definition:
      "to give something, such as money, time, or effort, to help achieve a goal",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "debate",
    definition:
      "a formal discussion or argument about a topic where opposing views are expressed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "degree",
    definition:
      "a unit of measurement for temperature or angle; or a level of education",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "disabled",
    definition:
      "someone who has a physical or mental condition that limits their ability to do certain things",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "donate",
    definition:
      "to give something, especially money or goods, to a person or organization in need",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "drivers license",
    definition:
      "an official document that allows someone to drive a car or other vehicle",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "education",
    definition:
      "the process of learning and teaching, especially in schools or universities",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "encourage",
    definition: "to give someone the support or confidence to do something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "enquire",
    definition: "to ask about something, especially in a formal way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "environment",
    definition: "the natural world and surroundings where living things exist",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "fire alarm",
    definition: "a device that makes a loud noise to warn people of a fire",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "fundraising",
    definition: "collecting money for a cause or charity",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "garbage bag",
    definition: "a bag used to hold trash or waste",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "get involved",
    definition: "to take part in or become part of an activity or organization",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "get to know",
    definition: "to become familiar with someone or something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "give a hand",
    definition: "to offer help or assistance",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "hard-working",
    definition: "describes someone who puts a lot of effort into their work",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "health",
    definition: "the state of being free from illness or injury",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "help out",
    definition: "to assist or support someone, often in a time of need",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "homeless",
    definition: "without a permanent place to live",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "household chore",
    definition:
      "a task or job that needs to be done around the house, such as cleaning or cooking",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "housing",
    definition: "buildings or accommodations where people live",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "hunger",
    definition: "the feeling of needing or wanting food",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "hurricane",
    definition: "a very strong tropical storm with heavy rain and winds",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "hurt yourself",
    definition: "to cause injury or pain to your own body",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "insist",
    definition: "to strongly state that something is true or must be done",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "introduce yourself",
    definition:
      "to tell people your name and some basic information about yourself",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "literacy",
    definition: "the ability to read and write",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "make sense",
    definition: "to be clear, logical, or easy to understand",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "neighbor",
    definition: "a person who lives next door or near you",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "on standby",
    definition: "being ready to act or respond if needed",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "philosophy",
    definition:
      "the study of fundamental questions about existence, knowledge, and ethics",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "pollution",
    definition: "the presence of harmful substances in the environment",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "poverty",
    definition: "the state of being extremely poor, lacking basic necessities",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "refreshments",
    definition: "food or drinks served at an event",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "respect",
    definition: "showing consideration and care for others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "responsibility",
    definition: "the duty to take care of something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "rights",
    definition: "the freedoms and protections that are guaranteed to everyone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "sanitation",
    definition:
      "the process of keeping places clean and safe, especially by removing waste",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "shelter",
    definition:
      "a place where someone can stay and be protected from the weather",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "shocking",
    definition:
      "causing surprise or disbelief because of being very bad or unexpected",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "shooting",
    definition: "the act of using a gun to fire a bullet or other projectile",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "sociable",
    definition: "friendly and enjoying the company of others",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "sponsored walk",
    definition:
      "a walk organized to raise money for a charity, with people pledging money for each step or mile",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "starfish",
    definition: "a sea creature with a star-shaped body",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "teach yourself",
    definition: "to learn something on your own without a teacher or guide",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },
  {
    word: "total",
    definition: "the complete amount or sum of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 8",
  },

  {
    word: "achieve",
    definition: "to succeed in doing something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "adjustment",
    definition: "a small change made to improve something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "ambition",
    definition: "a strong desire to succeed or do well",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "approach",
    definition: "a way of doing or thinking about something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "attempt",
    definition: "to try to do something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "autobiography",
    definition: "the story of a person's life written by that person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "award",
    definition: "a prize given for achievement or success",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "challenge",
    definition: "a difficult task or problem that requires effort to solve",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "collision",
    definition: "an event where two things crash into each other",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "concrete",
    definition: "a strong material made from cement, used for building",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "conference",
    definition: "a meeting where people discuss important topics",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "deadline",
    definition: "the latest time or date by which something must be done",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "demanding",
    definition: "requiring a lot of effort or attention",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "depressed",
    definition: "feeling sad, hopeless, or downhearted",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "disappointed",
    definition: "feeling unhappy because something didn't happen as expected",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "dream",
    definition:
      "a series of thoughts or images that happen while you sleep, or something you hope to achieve",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "excerpt",
    definition: "a short part of a book, article, or speech",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "generate electricity",
    definition: "to produce power that can be used for machines or lights",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "goal",
    definition: "something you want to achieve",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "guarantee",
    definition: "a promise that something will happen or be done",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "heroic",
    definition: "showing great courage and bravery",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "howl",
    definition: "a long, loud cry or sound, especially from an animal",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "indifferent",
    definition: "not caring about something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "measurable",
    definition: "something that can be counted or measured",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "mob",
    definition: "a large group of people, often behaving in a disorderly way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "murmur",
    definition: "to speak quietly or softly",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "objective",
    definition: "something you are trying to achieve or accomplish",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "obligation",
    definition: "something you must do because it is your responsibility",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "personally",
    definition: "doing something yourself or in your own way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "pointless",
    definition: "having no purpose or reason",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "precise",
    definition: "exact and accurate in details",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "prize",
    definition: "an award given for winning or achieving something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "profit",
    definition: "the money made from business after expenses",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "prosperous",
    definition: "having a lot of money or success",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "prove",
    definition: "to show that something is true or correct",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "realistic",
    definition: "reasonable and likely to happen",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "relevant",
    definition: "connected with or related to the matter at hand",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "risky",
    definition: "involving danger or uncertainty",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "roar",
    definition: "a loud, deep sound made by an animal or person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "satellite",
    definition:
      "an object sent into space to orbit the Earth or another planet",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "scholar",
    definition: "a person who studies or is an expert in a particular subject",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "scratch",
    definition:
      "to rub or mark the surface of something with something sharp or rough",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "set a goal",
    definition: "to decide something you want to achieve",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "shriek",
    definition: "a loud, high sound or cry, often of fear or surprise",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "shuffle",
    definition: "to move or mix things around by sliding or dragging them",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "simply",
    definition: "in a clear or easy way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "sleeve",
    definition:
      "the part of a shirt, coat, or other clothing that covers your arm",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "space agency",
    definition:
      "an organization responsible for the development and exploration of space",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "summit",
    definition: "the highest point of something, especially a mountain",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "time limit",
    definition: "a specific time by which something must be finished",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "trivial",
    definition: "unimportant or of little value",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "vague",
    definition: "unclear or not specific",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "water pump",
    definition: "a device used to move water from one place to another",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "windmill",
    definition:
      "a machine that uses wind to produce energy, often to grind grain",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 9",
  },
  {
    word: "algae",
    definition:
      "simple plants that grow in water, often seen as green or brown",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "aquarium",
    definition:
      "a container or building where water animals and plants are kept",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "awesome",
    definition: "causing a strong feeling of wonder or admiration",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "beautify",
    definition: "to make something look more attractive or beautiful",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "brighten",
    definition: "to make something lighter or more colorful",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "calculate",
    definition: "to find a number or answer by using math",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "cancer",
    definition: "a serious illness where abnormal cells grow in the body",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "chemical",
    definition: "a substance that has a particular structure or effect",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "chunk",
    definition: "a large piece or part of something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "circulate",
    definition: "to move or pass around, especially air or water",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "coal",
    definition: "a black rock that is burned for energy or heat",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "conductor",
    definition: "a material that allows electricity or heat to pass through it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "conserve",
    definition: "to save or protect something from being wasted",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "contain",
    definition: "to hold or have something inside",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "copper",
    definition: "a reddish-brown metal that is used in wires and coins",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "coral reef",
    definition: "a large underwater structure made of tiny living organisms",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "cyanide",
    definition: "a very poisonous chemical often used in mining and industry",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "darken",
    definition: "to make something become less light",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "dentistry",
    definition: "the medical field related to teeth and oral care",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "deposit",
    definition: "to put money or items into a place for safekeeping",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "disturb",
    definition: "to interrupt or cause problems with something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "dolphin",
    definition:
      "a type of intelligent sea mammal that is known for being playful",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "drill",
    definition: "a tool used for making holes or a practice exercise",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "dump",
    definition: "to throw away or get rid of something in a careless way",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "endangered",
    definition: "a species that is at risk of becoming extinct",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "entire",
    definition: "whole, complete, with nothing left out",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "excavate",
    definition: "to dig something up from the ground",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "extract",
    definition: "to remove something from a place, usually with effort",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "fossil fuels",
    definition:
      "energy resources like coal, oil, and natural gas formed from the remains of ancient plants and animals",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "habitat",
    definition: "the natural environment where an animal or plant lives",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "harden",
    definition: "to become firm or solid",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "harm",
    definition: "to hurt or damage something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "identify",
    definition: "to recognize or name something or someone",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "in captivity",
    definition: "being kept in an enclosed space or area, like a zoo or prison",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "infrared",
    definition: "a type of light that cannot be seen but can be felt as heat",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "intense",
    definition: "strong or extreme in feeling or degree",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "invest",
    definition:
      "to put money or effort into something to make it grow or improve",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "jewelry",
    definition:
      "decorative items worn, such as rings, necklaces, and bracelets",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "leave",
    definition: "to go away from a place or person",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "litter",
    definition: "trash or waste that is left on the ground in public places",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "manufacture",
    definition: "to make something in large amounts, usually in a factory",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "materialize",
    definition: "to come into existence or become real",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "medical condition",
    definition: "a specific illness or problem with someone's health",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "melt",
    definition: "to change from a solid to a liquid, usually by heating",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "meteorite",
    definition: "a rock from space that lands on Earth",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "mine",
    definition:
      "a place where valuable substances like minerals or coal are dug from the ground",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "mineral",
    definition:
      "a naturally occurring substance, often found in the ground and used for various purposes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "obtain",
    definition: "to get or acquire something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "oil",
    definition:
      "a liquid used for energy, lubrication, and many other purposes",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "originate",
    definition: "to come from a particular place or source",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "particle",
    definition: "a very small piece of matter",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "personal belongings",
    definition:
      "things that belong to an individual, like clothing or items in a bag",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "pick",
    definition: "to choose or select something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "poison",
    definition: "a harmful substance that can cause illness or death",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "precious",
    definition: "something that is valuable or of great importance",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "radiation",
    definition:
      "energy that travels in the form of waves or particles, sometimes harmful",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "renewable",
    definition:
      "a type of energy or resource that can be used over and over again without running out",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "resist",
    definition: "to fight against or not be affected by something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "resource",
    definition: "something that can be used for help or to produce something",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "solar panel",
    definition:
      "a device that collects energy from the sun and converts it into electricity",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "solar system",
    definition:
      "the collection of planets, moons, and other objects that orbit around the sun",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "solidify",
    definition: "to change from a liquid to a solid form",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "specialize",
    definition: "to focus on one particular subject or activity",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "spine",
    definition: "the long column of bones in the back that supports the body",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "stabilize",
    definition: "to make something stay steady and not change",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "tank",
    definition: "a large container used to store liquids or gases",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "temptation",
    definition:
      "the desire to do something, especially something wrong or unhealthy",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "toil",
    definition: "to work hard and with effort, especially for a long time",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "toxic",
    definition: "something that is harmful or poisonous to living things",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "trample",
    definition: "to step on something in a way that damages it",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "trash",
    definition: "garbage or waste material that is thrown away",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },
  {
    word: "visor",
    definition:
      "a part of a hat or helmet that protects your eyes from the sun",
    book: "Oxford Discover Futures",
    level: "2",
    unit: "Unit 10",
  },

  {
    word: "animal",
    definition: "สัตว์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "bear",
    definition: "หมี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "bee",
    definition: "ผึ้ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "bird",
    definition: "นก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "cat",
    definition: "แมว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "chicken",
    definition: "ไก่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "cow",
    definition: "วัว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "crocodile",
    definition: "จระเข้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "dog",
    definition: "สุนัข",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "donkey",
    definition: "ลา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "duck",
    definition: "เป็ด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "elephant",
    definition: "ช้าง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "fish",
    definition: "ปลา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "frog",
    definition: "กบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "giraffe",
    definition: "ยีราฟ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "goat",
    definition: "แพะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "hippo",
    definition: "ฮิปโป",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "horse",
    definition: "ม้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "jellyfish",
    definition: "แมงกะพรุน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "lizard",
    definition: "กิ้งก่า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "monkey",
    definition: "ลิง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "mouse",
    definition: "หนู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "mice",
    definition: "หนู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "pet",
    definition: "สัตว์เลี้ยง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "polar bear",
    definition: "หมีขั้วโลก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "sheep",
    definition: "แกะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "snake",
    definition: "งู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "spider",
    definition: "แมงมุม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "tail",
    definition: "หาง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "tiger",
    definition: "เสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "zebra",
    definition: "ม้าลาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "zoo",
    definition: "สวนสัตว์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Animals",
  },
  {
    word: "arm",
    definition: "แขน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "body",
    definition: "ร่างกาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "ear",
    definition: "หู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "eye",
    definition: "ดวงตา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "face",
    definition: "ใบหน้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "foot",
    definition: "เท้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "hair",
    definition: "ผม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "hand",
    definition: "มือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "head",
    definition: "ศีรษะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "leg",
    definition: "ขา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "mouth",
    definition: "ปาก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "nose",
    definition: "จมูก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "smile",
    definition: "รอยยิ้ม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The body and the face",
  },
  {
    word: "clothes",
    definition: "เสื้อผ้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "bag",
    definition: "ถุง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "baseball cap",
    definition: "หมวกเบสบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "boots",
    definition: "รองเท้าบูท",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "clothes",
    definition: "เสื้อผ้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "dress",
    definition: "ชุด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "glasses",
    definition: "แว่นตา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "handbag",
    definition: "กระเป๋าถือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "hat",
    definition: "หมวก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "jacket",
    definition: "เสื้อแจ็กเกต",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "jeans",
    definition: "ยีนส์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "shirt",
    definition: "เสื้อ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "shoe",
    definition: "รองเท้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "shorts",
    definition: "กางเกงขาสั้น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "skirt",
    definition: "กระโปรง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "sock",
    definition: "ถุงเท้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "trousers",
    definition: "กางเกงขายาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "t-shirt",
    definition: "เสื้อยืด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "wear",
    definition: "สวมใส่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Clothes",
  },
  {
    word: "black",
    definition: "สีดำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "blue",
    definition: "สีฟ้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "brown",
    definition: "สีน้ำตาล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "color",
    definition: "สี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "gray",
    definition: "สีเทา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "green",
    definition: "สีเขียว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "gray",
    definition: "สีเทา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "orange",
    definition: "ส้ม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "pink",
    definition: "สีชมพู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "purple",
    definition: "สีม่วง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "red",
    definition: "สีแดง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "white",
    definition: "สีขาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "yellow",
    definition: "สีเหลือง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Colors",
  },
  {
    word: "baby",
    definition: "ที่รัก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "boy",
    definition: "เด็กผู้ชาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "brother",
    definition: "พี่ชาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "child",
    definition: "เด็ก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "children",
    definition: "เด็ก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "classmate",
    definition: "เพื่อนร่วมชั้น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "cousin",
    definition: "ลูกพี่ลูกน้อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "dad",
    definition: "พ่อ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "family",
    definition: "ตระกูล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "father",
    definition: "พ่อ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "friend",
    definition: "เพื่อน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "girl",
    definition: "สาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "grandfather",
    definition: "คุณปู่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "grandma",
    definition: "ยาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "grandmother",
    definition: "ยาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "grandpa",
    definition: "คุณปู่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "kid",
    definition: "เด็ก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "live",
    definition: "สด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "man",
    definition: "ผู้ชาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "mother",
    definition: "แม่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "mum",
    definition: "แม่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "old",
    definition: "เก่า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "person",
    definition: "บุคคล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "sister",
    definition: "น้องสาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "woman",
    definition: "ผู้หญิง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "young",
    definition: "หนุ่มสาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "people",
    definition: "ประชากร",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "women",
    definition: "ผู้หญิง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "men",
    definition: "ผู้ชาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Family & friends",
  },
  {
    word: "apple",
    definition: "แอปเปิล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "banana",
    definition: "กล้วย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "bean",
    definition: "ถั่ว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "bread",
    definition: "ขนมปัง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "breakfast",
    definition: "อาหารเช้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "burger",
    definition: "ชาวเมือง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "cake",
    definition: "เค้ก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "candy",
    definition: "ลูกอม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "carrot",
    definition: "แครอท",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "chicken",
    definition: "ไก่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "fries",
    definition: "มันฝรั่งทอด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "chips",
    definition: "ชิป",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "chocolate",
    definition: "ช็อคโกแลต",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "coconut",
    definition: "มะพร้าว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "dinner",
    definition: "อาหารเย็น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "drink",
    definition: "ดื่ม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "eat",
    definition: "กิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "egg",
    definition: "ไข่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "fish",
    definition: "ปลา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "food",
    definition: "อาหาร",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "fruit",
    definition: "ผลไม้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "grape",
    definition: "องุ่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "ice cream",
    definition: "ไอศครีม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "juice",
    definition: "น้ำผลไม้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "kiwi",
    definition: "กีวี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "lemon",
    definition: "มะนาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "lemonade",
    definition: "น้ำมะนาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "lime",
    definition: "มะนาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "lunch",
    definition: "อาหารกลางวัน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "mango",
    definition: "มะม่วง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "meat",
    definition: "เนื้อ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "meatballs",
    definition: "ลูกชิ้น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "milk",
    definition: "น้ำนม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "onion",
    definition: "หัวหอม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "orange",
    definition: "ส้ม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "pea",
    definition: "ถั่ว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "pear",
    definition: "ลูกแพร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "pie",
    definition: "พาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "pineapple",
    definition: "สัปปะรด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "potato",
    definition: "มันฝรั่ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "rice",
    definition: "ข้าว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "sausage",
    definition: "ไส้กรอก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "sweets",
    definition: "ขนม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "tomato",
    definition: "มะเขือเทศ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "water",
    definition: "น้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "watermelon",
    definition: "แตงโม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Food & drink",
  },
  {
    word: "armchair",
    definition: "เก้าอี้นวม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "bath",
    definition: "อาบน้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "bathroom",
    definition: "ห้องน้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "bed",
    definition: "เตียง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "bedroom",
    definition: "ห้องนอน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "bookcase",
    definition: "ตู้หนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "box",
    definition: "กล่อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "camera",
    definition: "กล้อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "chair",
    definition: "เก้าอี้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "clock",
    definition: "นาฬิกา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "computer",
    definition: "คอมพิวเตอร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "cupboard",
    definition: "ตู้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "desk",
    definition: "โต๊ะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "dining room",
    definition: "ห้องรับประทานอาหาร",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "doll",
    definition: "ตุ๊กตา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "door",
    definition: "ประตู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "flat",
    definition: "แบน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "flower",
    definition: "ดอกไม้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "garden",
    definition: "สวน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "hall",
    definition: "ห้องโถง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "home",
    definition: "บ้าน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "house",
    definition: "บ้าน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "kitchen",
    definition: "ครัว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "lamp",
    definition: "โคมไฟ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "living room",
    definition: "ห้องนั่งเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "mat",
    definition: "เสื่อ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "mirror",
    definition: "กระจกเงา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "phone",
    definition: "โทรศัพท์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "picture",
    definition: "รูปภาพ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "radio",
    definition: "วิทยุ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "room",
    definition: "ห้อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "rug",
    definition: "พรม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "sleep",
    definition: "นอน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "sofa",
    definition: "โซฟา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "table",
    definition: "โต๊ะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "toy",
    definition: "ของเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "tree",
    definition: "ต้นไม้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },

  {
    word: "television",
    definition: "โทรทัศน์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "wall",
    definition: "กำแพง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "watch",
    definition: "ดู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "window",
    definition: "หน้าต่าง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Home & apartment",
  },
  {
    word: "paper",
    definition: "กระดาษ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Materials",
  },
  {
    word: "behind",
    definition: "ด้านหลัง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "between",
    definition: "ระหว่าง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "bookshop",
    definition: "ร้านหนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "end",
    definition: "จบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "here",
    definition: "ที่นี่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "in",
    definition: "ใน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "in front of",
    definition: "ด้านหน้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "on",
    definition: "บน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "park",
    definition: "สวน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "playground",
    definition: "สนามเด็กเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "shop",
    definition: "ร้านค้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "store",
    definition: "เก็บ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "street",
    definition: "ถนน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "there",
    definition: "ที่นั่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "under",
    definition: "ภายใต้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "zoo",
    definition: "สวนสัตว์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Places & directions",
  },
  {
    word: "alphabet",
    definition: "ตัวอักษร",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "answer",
    definition: "คำตอบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "ask",
    definition: "ถาม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "board",
    definition: "กระดาน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "book",
    definition: "หนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "bookcase",
    definition: "ตู้หนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "class",
    definition: "ระดับ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "classroom",
    definition: "ห้องเรียน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "close",
    definition: "ปิด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "color",
    definition: "สี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "computer",
    definition: "คอมพิวเตอร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "correct",
    definition: "ถูกต้อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "crayon",
    definition: "ดินสอสี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "cross",
    definition: "ข้าม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "cupboard",
    definition: "ตู้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "desk",
    definition: "โต๊ะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "door",
    definition: "ประตู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "draw",
    definition: "วาด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "english",
    definition: "ภาษาอังกฤษ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "eraser",
    definition: "ยางลบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "rubber",
    definition: "ยาง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "example",
    definition: "ตัวอย่าง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "find",
    definition: "หา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "floor",
    definition: "พื้น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "keyboard",
    definition: "แป้นพิมพ์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "learn",
    definition: "เรียนรู้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "lesson",
    definition: "บทเรียน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "letter",
    definition: "จดหมาย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "line",
    definition: "เส้น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "listen",
    definition: "ฟัง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "look",
    definition: "ดู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "mouse",
    definition: "หนู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "music",
    definition: "ดนตรี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "number",
    definition: "ตัวเลข",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "open",
    definition: "เปิด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "page",
    definition: "หน้าหนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "painting",
    definition: "จิตรกรรม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "paper",
    definition: "กระดาษ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "part",
    definition: "ส่วนหนึ่ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "pen",
    definition: "ปากกา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "pencil",
    definition: "ดินสอ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "picture",
    definition: "รูปภาพ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "playground",
    definition: "สนามเด็กเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "poster",
    definition: "โปสเตอร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "question",
    definition: "คำถาม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "read",
    definition: "อ่าน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "right",
    definition: "ขวา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "eraser",
    definition: "ยางลบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "ruler",
    definition: "ไม้บรรทัด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "school",
    definition: "โรงเรียน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "sentence",
    definition: "ประโยค",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "sit",
    definition: "นั่ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "spell",
    definition: "สะกด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "stand",
    definition: "ยืน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "story",
    definition: "เรื่องราว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "teacher",
    definition: "ครู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "tell",
    definition: "บอก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "tick",
    definition: "ติ๊ก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "understand",
    definition: "เข้าใจ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "wall",
    definition: "กำแพง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "window",
    definition: "หน้าต่าง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "word",
    definition: "คำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "write",
    definition: "เขียน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "School",
  },
  {
    word: "badminton",
    definition: "แบดมินตัน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "ball",
    definition: "ลูกบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "baseball",
    definition: "เบสบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "basketball",
    definition: "บาสเกตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "bat",
    definition: "ค้างคาว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "beach",
    definition: "ชายหาด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "bike",
    definition: "จักรยาน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "boat",
    definition: "เรือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "book",
    definition: "หนังสือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "bounce",
    definition: "เด้ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "camera",
    definition: "กล้อง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "catch",
    definition: "จับ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "doll",
    definition: "ตุ๊กตา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "draw",
    definition: "วาด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "drawing",
    definition: "การวาดภาพ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "drive",
    definition: "ขับ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "enjoy",
    definition: "สนุก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "favorite",
    definition: "ที่ชื่นชอบ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "fishing",
    definition: "ตกปลา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "fly",
    definition: "บิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "football",
    definition: "ฟุตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "soccer",
    definition: "ฟุตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "game",
    definition: "เกม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "guitar",
    definition: "กีตาร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "hobby",
    definition: "งานอดิเรก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "hockey",
    definition: "ฮอกกี้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "jump",
    definition: "กระโดด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "kick",
    definition: "เตะ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "kite",
    definition: "ว่าว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "listen",
    definition: "ฟัง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "music",
    definition: "ดนตรี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "photo",
    definition: "รูปถ่าย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "piano",
    definition: "เปียโน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "picture",
    definition: "รูปภาพ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "play",
    definition: "เล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "radio",
    definition: "วิทยุ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "read",
    definition: "อ่าน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "ride",
    definition: "ขี่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "run",
    definition: "วิ่ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "sing",
    definition: "ร้องเพลง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "skateboard",
    definition: "สเก็ตบอร์ด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "skateboarding",
    definition: "สเก็ตบอร์ด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "song",
    definition: "เพลง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "sport",
    definition: "กีฬา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "story",
    definition: "เรื่องราว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "swim",
    definition: "ว่ายน้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "table tennis",
    definition: "เทเบิลเทนนิส",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "take a photo",
    definition: "ถ่ายรูป",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "picture",
    definition: "รูปภาพ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "television",
    definition: "โทรทัศน์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },

  {
    word: "tennis",
    definition: "เทนนิส",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "tennis racket",
    definition: "ไม้เทนนิส",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "throw",
    definition: "โยน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "toy",
    definition: "ของเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "walk",
    definition: "เดิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "watch",
    definition: "ดู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Sports & leisure",
  },
  {
    word: "afternoon",
    definition: "ตอนบ่าย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "birthday",
    definition: "วันเกิด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "clock",
    definition: "นาฬิกา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "day",
    definition: "วัน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "evening",
    definition: "ตอนเย็น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "in",
    definition: "ใน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "morning",
    definition: "เช้า",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "night",
    definition: "กลางคืน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "today",
    definition: "วันนี้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "watch",
    definition: "ดู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "year",
    definition: "ปี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Time",
  },
  {
    word: "alien",
    definition: "คนต่างด้าว",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "ball",
    definition: "ลูกบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "balloon",
    definition: "บอลลูน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "baseball",
    definition: "เบสบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "basketball",
    definition: "บาสเกตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "bike",
    definition: "จักรยาน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "board game",
    definition: "เกมกระดาน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "boat",
    definition: "เรือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "car",
    definition: "รถ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "doll",
    definition: "ตุ๊กตา",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "football",
    definition: "ฟุตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "game",
    definition: "เกม",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "helicopter",
    definition: "เฮลิคอปเตอร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "truck",
    definition: "รถบรรทุก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "monster",
    definition: "สัตว์ประหลาด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "motorbike",
    definition: "รถมอเตอร์ไซค์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "plane",
    definition: "เครื่องบิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "robot",
    definition: "หุ่นยนต์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "soccer",
    definition: "ฟุตบอล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "teddy bear",
    definition: "ตุ๊กตาหมี",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "toy",
    definition: "ของเล่น",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "train",
    definition: "รถไฟ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Toys",
  },
  {
    word: "bike",
    definition: "จักรยาน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "boat",
    definition: "เรือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "bus",
    definition: "รสบัส",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "car",
    definition: "รถ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "drive",
    definition: "ขับ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "fly",
    definition: "บิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "go",
    definition: "ไป",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "helicopter",
    definition: "เฮลิคอปเตอร์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "plane",
    definition: "เครื่องบิน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "ride",
    definition: "ขี่",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "run",
    definition: "วิ่ง",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "ship",
    definition: "เรือ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "swim",
    definition: "ว่ายน้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "train",
    definition: "รถไฟ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "truck",
    definition: "รถบรรทุก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Transport",
  },
  {
    word: "sun",
    definition: "ดวงอาทิตย์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Weather",
  },
  {
    word: "teacher",
    definition: "ครู",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "Work",
  },
  {
    word: "beach",
    definition: "ชายหาด",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "sand",
    definition: "ทราย",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "sea",
    definition: "ทะเล",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "shell",
    definition: "เปลือก",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "street",
    definition: "ถนน",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "sun",
    definition: "ดวงอาทิตย์",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "tree",
    definition: "ต้นไม้",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "water",
    definition: "น้ำ",
    book: "Cambridge",
    level: "Pre A1 Starters",
    unit: "The world around us",
  },
  {
    word: "bat",
    definition: "ค้างคาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "cage",
    definition: "กรง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "dolphin",
    definition: "ปลาโลมา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "fly",
    definition: "บิน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "kangaroo",
    definition: "จิงโจ้",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "kitten",
    definition: "ลูกแมว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "lion",
    definition: "สิงโต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "panda",
    definition: "หมีแพนด้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "parrot",
    definition: "นกแก้ว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "penguin",
    definition: "เพนกวิน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "puppy",
    definition: "ลูกสุนัข",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "rabbit",
    definition: "กระต่าย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "shark",
    definition: "ปลาฉลาม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "snail",
    definition: "หอยทาก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "whale",
    definition: "วาฬ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Animals",
  },
  {
    word: "back",
    definition: "กลับ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "beard",
    definition: "หนวดเครา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "blond",
    definition: "สีบลอนด์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "blonde",
    definition: "สีบลอนด์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "curly",
    definition: "หยิกงอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "fair",
    definition: "ยุติธรรม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "fat",
    definition: "อ้วน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "moustache",
    definition: "หนวด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "neck",
    definition: "คอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "shoulder",
    definition: "ไหล่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "stomach",
    definition: "ท้อง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "thin",
    definition: "บาง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "tooth",
    definition: "ฟัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "teeth",
    definition: "ฟัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The body and the face",
  },
  {
    word: "coat",
    definition: "เสื้อโค้ท",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Clothes",
  },
  {
    word: "helmet",
    definition: "หมวกนิรภัย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Clothes",
  },
  {
    word: "scarf",
    definition: "ผ้าพันคอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Clothes",
  },
  {
    word: "sweater",
    definition: "เสื้อกันหนาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Clothes",
  },
  {
    word: "swimsuit",
    definition: "ชุดว่ายน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Clothes",
  },
  {
    word: "aunt",
    definition: "ป้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "daughter",
    definition: "ลูกสาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "granddaughter",
    definition: "หลานสาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "grandparent",
    definition: "ปู่ย่าตายาย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "grandson",
    definition: "หลานชาย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "grown-up",
    definition: "โตขึ้น",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "parent",
    definition: "พ่อแม่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "son",
    definition: "ลูกชาย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "uncle",
    definition: "ลุง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Family & friends",
  },
  {
    word: "bottle",
    definition: "ขวด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "bowl",
    definition: "ชาม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "cheese",
    definition: "ชีส",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "coffee",
    definition: "กาแฟ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "cup",
    definition: "ถ้วย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "glass",
    definition: "กระจก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "hungry",
    definition: "หิว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "milkshake",
    definition: "มิลค์เชค",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "noodles",
    definition: "ก๋วยเตี๋ยว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "pancake",
    definition: "แพนเค้ก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "pasta",
    definition: "พาสต้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "picnic",
    definition: "ปิกนิก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "plate",
    definition: "จาน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "salad",
    definition: "สลัด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "sandwich",
    definition: "แซนด์วิช",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "sauce",
    definition: "ซอส",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "soup",
    definition: "ซุป",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "tea",
    definition: "ชา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "thirsty",
    definition: "กระหายน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "vegetable",
    definition: "ผัก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Food & drink",
  },
  {
    word: "cold",
    definition: "เป็นหวัด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "cough",
    definition: "ไอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "cry",
    definition: "ร้องไห้",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "dentist",
    definition: "ทันตแพทย์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "doctor",
    definition: "หมอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "earache",
    definition: "ปวดหู",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "fall",
    definition: "ตก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "fine",
    definition: "ดี",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "headache",
    definition: "ปวดศีรษะ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "hospital",
    definition: "โรงพยาบาล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "ill",
    definition: "ป่วย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "matter",
    definition: "วัตถุ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "nurse",
    definition: "พยาบาล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "sick",
    definition: "ป่วย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "stomach-ache",
    definition: "ปวดท้อง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "temperature",
    definition: "อุณหภูมิ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "tired",
    definition: "เหนื่อย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "toothache",
    definition: "อาการปวดฟัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Health",
  },
  {
    word: "address",
    definition: "ที่อยู่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "balcony",
    definition: "ระเบียง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "basement",
    definition: "ชั้นใต้ดิน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "blanket",
    definition: "ผ้าห่ม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "downstairs",
    definition: "ชั้นล่าง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "dream",
    definition: "ฝัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "elevator",
    definition: "ลิฟต์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "lift",
    definition: "ยก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "floor",
    definition: "พื้น",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "internet",
    definition: "อินเทอร์เน็ต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "message",
    definition: "ข้อความ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "roof",
    definition: "หลังคา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "seat",
    definition: "ที่นั่ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "shower",
    definition: "อาบน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "stairs",
    definition: "บันได",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "toothbrush",
    definition: "แปรงสีฟัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "toothpaste",
    definition: "ยาสีฟัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "towel",
    definition: "ผ้าขนหนู",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "upstairs",
    definition: "ชั้นบน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "wash",
    definition: "ล้าง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The home",
  },
  {
    word: "above",
    definition: "ข้างบน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "below",
    definition: "ด้านล่าง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "building",
    definition: "อาคาร",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "bus station",
    definition: "สถานีขนส่ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "bus stop",
    definition: "ป้ายรถเมล์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "café",
    definition: "คาเฟ่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "car park",
    definition: "ที่จอดรถ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "cinema",
    definition: "โรงหนัง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "circle",
    definition: "วงกลม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "circus",
    definition: "ละครสัตว์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "city  center",
    definition: "ใจกลางเมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "town center",
    definition: "ใจกลางเมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "farm",
    definition: "ฟาร์ม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "funfair",
    definition: "สวนสนุก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "hospital",
    definition: "โรงพยาบาล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "library",
    definition: "ห้องสมุด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "map",
    definition: "แผนที่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "market",
    definition: "ตลาด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "near",
    definition: "ใกล้",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "opposite",
    definition: "ตรงข้าม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "place",
    definition: "สถานที่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "shopping center",
    definition: "ศูนย์การค้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "sports center",
    definition: "ศูนย์กีฬา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "square",
    definition: "สี่เหลี่ยม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "station",
    definition: "สถานี",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "straight",
    definition: "ตรง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "supermarket",
    definition: "ซูเปอร์มาร์เก็ต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "swimming pool",
    definition: "สระว่ายน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "town",
    definition: "เมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "city center",
    definition: "ใจกลางเมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Places & directions",
  },
  {
    word: "break",
    definition: "หยุดพัก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "homework",
    definition: "การบ้าน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "mistake",
    definition: "ความผิดพลาด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "teach",
    definition: "สอน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "text",
    definition: "ข้อความ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "website",
    definition: "เว็บไซต์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "School",
  },
  {
    word: "band",
    definition: "วงดนตรี",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "cinema",
    definition: "โรงหนัง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "comic",
    definition: "การ์ตูน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "comic book",
    definition: "หนังสือการ์ตูน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "dance",
    definition: "เต้นรำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "drive",
    definition: "ขับ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "email",
    definition: "อีเมล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "movie",
    definition: "ภาพยนตร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "fish",
    definition: "ปลา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "go shopping",
    definition: "ไปช้อปปิ้ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "goal",
    definition: "เป้าหมาย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "holiday",
    definition: "วันหยุด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "hop",
    definition: "กระโดด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "ice skates",
    definition: "สเก็ตน้ำแข็ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "ice skating",
    definition: "สเก็ตน้ำแข็ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "kick",
    definition: "เตะ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "net",
    definition: "สุทธิ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "party",
    definition: "งานสังสรรค์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "player",
    definition: "ผู้เล่น",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "pool",
    definition: "สระน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "practice",
    definition: "ฝึกฝน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "practise",
    definition: "ฝึกฝน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "present",
    definition: "ปัจจุบัน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "ride",
    definition: "ขี่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "roller skates",
    definition: "โรลเลอร์สเกต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "roller skating",
    definition: "โรลเลอร์สเกต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "sail",
    definition: "แล่นเรือ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "score",
    definition: "คะแนน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "skate",
    definition: "เล่นสเก็ต",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "skip",
    definition: "ข้าม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "sports center",
    definition: "ศูนย์กีฬา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "swim",
    definition: "ว่ายน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "swimming pool",
    definition: "สระว่ายน้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "text",
    definition: "ข้อความ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "towel",
    definition: "ผ้าขนหนู",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "video",
    definition: "วิดีโอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "walk",
    definition: "เดิน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Sports & leisure",
  },
  {
    word: "after",
    definition: "หลังจาก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "always",
    definition: "เสมอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "before",
    definition: "ก่อน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "every",
    definition: "ทั้งหมด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "never",
    definition: "ไม่เคย",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "o’clock",
    definition: "โมง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "sometimes",
    definition: "บางครั้ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "week",
    definition: "สัปดาห์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "weekend",
    definition: "สุดสัปดาห์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "yesterday",
    definition: "เมื่อวาน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Time",
  },
  {
    word: "monday",
    definition: "วันจันทร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "tuesday",
    definition: "วันอังคาร",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "wednesday",
    definition: "วันพุธ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "thursday",
    definition: "วันพฤหัสบดี",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "friday",
    definition: "วันศุกร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "saturday",
    definition: "วันเสาร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "sunday",
    definition: "วันอาทิตย์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The days of the week:",
  },
  {
    word: "model",
    definition: "แบบอย่าง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Toys",
  },
  {
    word: "bus station",
    definition: "สถานีขนส่ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "bus stop",
    definition: "ป้ายรถเมล์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "drive",
    definition: "ขับ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "driver",
    definition: "คนขับ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "ride",
    definition: "ขี่",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "station",
    definition: "สถานี",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "ticket",
    definition: "ตั๋ว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "tractor",
    definition: "รถแทรกเตอร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "trip",
    definition: "การเดินทาง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Transport",
  },
  {
    word: "cloud",
    definition: "คลาวด์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "cloudy",
    definition: "เมฆมาก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "ice",
    definition: "น้ำแข็ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "rain",
    definition: "ฝน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "rainbow",
    definition: "รุ้ง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "sky",
    definition: "ท้องฟ้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "snow",
    definition: "หิมะ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "sunny",
    definition: "แดดจัด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "weather",
    definition: "สภาพอากาศ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "wind",
    definition: "ลม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "windy",
    definition: "ลมแรง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Weather",
  },
  {
    word: "circus",
    definition: "ละครสัตว์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "clown",
    definition: "ตัวตลก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "cook",
    definition: "ทำอาหาร",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "dentist",
    definition: "ทันตแพทย์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "doctor",
    definition: "หมอ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "driver",
    definition: "คนขับ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "farmer",
    definition: "ชาวนา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "movie",
    definition: "ภาพยนตร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "star",
    definition: "ดาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "hospital",
    definition: "โรงพยาบาล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "nurse",
    definition: "พยาบาล",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "pirate",
    definition: "โจรสลัด",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "pop star",
    definition: "ป๊อปสตาร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "work",
    definition: "งาน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "Work",
  },
  {
    word: "building",
    definition: "อาคาร",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "city",
    definition: "เมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "country",
    definition: "ประเทศ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "countryside",
    definition: "ชนบท",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "field",
    definition: "สนาม",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "forest",
    definition: "ป่า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "grass",
    definition: "หญ้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "ground",
    definition: "พื้น",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "island",
    definition: "เกาะ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "lake",
    definition: "ทะเลสาบ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "leaf",
    definition: "ใบไม้",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "leaves",
    definition: "ออกจาก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "moon",
    definition: "ดวงจันทร์",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "mountain",
    definition: "ภูเขา",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "plant",
    definition: "ปลูก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "river",
    definition: "แม่น้ำ",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "road",
    definition: "ถนน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "rock",
    definition: "หิน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "sky",
    definition: "ท้องฟ้า",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "star",
    definition: "ดาว",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "town",
    definition: "เมือง",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "village",
    definition: "หมู่บ้าน",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "waterfall",
    definition: "น้ำตก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "wave",
    definition: "คลื่น",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "world",
    definition: "โลก",
    book: "Cambridge",
    level: "A1 Movers",
    unit: "The world around us",
  },
  {
    word: "beetle",
    definition: "ด้วง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "butterfly",
    definition: "ผีเสื้อ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "camel",
    definition: "อูฐ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "creature",
    definition: "สิ่งมีชีวิต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "dinosaur",
    definition: "ไดโนเสาร์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "eagle",
    definition: "นกอินทรี",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "extinct",
    definition: "สูญพันธุ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "fur",
    definition: "ขน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "insect",
    definition: "แมลง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "nest",
    definition: "รัง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "octopus",
    definition: "ปลาหมึกยักษ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "swan",
    definition: "หงส์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "tortoise",
    definition: "เต่า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "wild",
    definition: "ป่า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "wing",
    definition: "ปีก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Animals",
  },
  {
    word: "elbow",
    definition: "ข้อศอก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "finger",
    definition: "นิ้ว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "knee",
    definition: "เข่า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "toe",
    definition: "นิ้วเท้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "clothes",
    definition: "เสื้อผ้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "belt",
    definition: "เข็มขัด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "bracelet",
    definition: "สร้อยข้อมือ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "costume",
    definition: "เครื่องแต่งกาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "crown",
    definition: "มงกุฎ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "glove",
    definition: "ถุงมือ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "necklace",
    definition: "สร้อยคอ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "pajamas",
    definition: "ชุดนอน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "pocket",
    definition: "กระเป๋า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "ring",
    definition: "แหวน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "spot",
    definition: "จุด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "spotted",
    definition: "ด่าง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "stripe",
    definition: "ลายทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "striped",
    definition: "ลาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "sunglasses",
    definition: "แว่นกันแดด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "trainers",
    definition: "ผู้ฝึกสอน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "umbrella",
    definition: "ร่ม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "uniform",
    definition: "เครื่องแบบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The body and the face",
  },
  {
    word: "gold",
    definition: "ทอง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "silver",
    definition: "เงิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "spot",
    definition: "จุด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "spotted",
    definition: "ด่าง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "stripe",
    definition: "ลายทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "striped",
    definition: "ลาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Colors",
  },
  {
    word: "husband",
    definition: "สามี",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Family & friends",
  },
  {
    word: "married",
    definition: "แต่งงานแล้ว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Family & friends",
  },
  {
    word: "surname",
    definition: "นามสกุล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Family & friends",
  },
  {
    word: "wife",
    definition: "ภรรยา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Family & friends",
  },
  {
    word: "butter",
    definition: "เนย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "cereal",
    definition: "ซีเรียล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "chopsticks",
    definition: "ตะเกียบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "cookie",
    definition: "คุกกี้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "flour",
    definition: "แป้ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "fork",
    definition: "ส้อม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "honey",
    definition: "น้ำผึ้ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "jam",
    definition: "แยม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "knife",
    definition: "มีด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "meal",
    definition: "มื้อ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "olives",
    definition: "มะกอก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "pepper",
    definition: "พริกไทย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "piece",
    definition: "ชิ้นส่วน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "pizza",
    definition: "พิซซ่า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "salt",
    definition: "เกลือ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "smell",
    definition: "กลิ่น",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "snack",
    definition: "อาหารว่าง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "spoon",
    definition: "ช้อน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "strawberry",
    definition: "สตรอเบอร์รี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "sugar",
    definition: "น้ำตาล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "taste",
    definition: "รสชาติ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "yoghurt",
    definition: "โยเกิร์ต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Food & drink",
  },
  {
    word: "bandage",
    definition: "ผ้าพันแผล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "drugstore",
    definition: "ร้านขายยา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "cut",
    definition: "ตัด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "fall over",
    definition: "ล้มลง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "medicine",
    definition: "ยา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "x-ray",
    definition: "เอ็กซ์เรย์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Health",
  },
  {
    word: "brush",
    definition: "แปรง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "comb",
    definition: "หวี",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "cooker",
    definition: "หม้อหุงข้าว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "cushion",
    definition: "เบาะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "diary",
    definition: "ไดอารี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "entrance",
    definition: "ทางเข้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "envelope",
    definition: "ซองจดหมาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "fridge",
    definition: "ตู้เย็น",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "gate",
    definition: "ประตู",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "key",
    definition: "สำคัญ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "letter",
    definition: "จดหมาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "oven",
    definition: "เตาอบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "screen",
    definition: "หน้าจอ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "shampoo",
    definition: "แชมพู",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "shelf",
    definition: "ชั้นวาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "soap",
    definition: "สบู่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "stamp",
    definition: "แสตมป์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "step",
    definition: "ขั้นตอน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "swing",
    definition: "แกว่ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "telephone",
    definition: "โทรศัพท์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The home",
  },
  {
    word: "card",
    definition: "การ์ด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "glass",
    definition: "กระจก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "gold",
    definition: "ทอง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "metal",
    definition: "โลหะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "plastic",
    definition: "พลาสติก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "silver",
    definition: "เงิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "wood",
    definition: "ไม้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "wool",
    definition: "ขนสัตว์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Materials",
  },
  {
    word: "airport",
    definition: "สนามบิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "bank",
    definition: "ธนาคาร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "bridge",
    definition: "สะพาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "castle",
    definition: "ปราสาท",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "drug strore",
    definition: "ร้านขายยา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "club",
    definition: "สโมสร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "college",
    definition: "วิทยาลัย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "corner",
    definition: "มุม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "east",
    definition: "ทิศตะวันออก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "factory",
    definition: "โรงงาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "fire station",
    definition: "สถานีดับเพลิง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "front",
    definition: "ด้านหน้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "get to",
    definition: "ได้รับการ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "hotel",
    definition: "โรงแรม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "kilometer",
    definition: "กิโลเมตร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "left",
    definition: "ซ้าย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "middle",
    definition: "กลาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "museum",
    definition: "พิพิธภัณฑ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "north",
    definition: "ทิศเหนือ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "over",
    definition: "เกิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "path",
    definition: "เส้นทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "police station",
    definition: "สถานีตำรวจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "post office",
    definition: "ที่ทำการไปรษณีย์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "restaurant",
    definition: "ร้านอาหาร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "right",
    definition: "ขวา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "right",
    definition: "ขวา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "skyscraper",
    definition: "ตึกระฟ้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "south",
    definition: "ใต้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "stadium",
    definition: "สนามกีฬา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "straight on",
    definition: "ตรงไป",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "theater",
    definition: "โรงละครสหรัฐฯ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "university",
    definition: "มหาวิทยาลัย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "way",
    definition: "ทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "west",
    definition: "ตะวันตก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Places & directions",
  },
  {
    word: "art",
    definition: "ศิลปะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "backpack",
    definition: "กระเป๋าเป้สะพายหลัง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "bin",
    definition: "ถังขยะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "club",
    definition: "สโมสร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "college",
    definition: "วิทยาลัย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "competition",
    definition: "การแข่งขัน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "dictionary",
    definition: "พจนานุกรม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "flag",
    definition: "ธง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "geography",
    definition: "ภูมิศาสตร์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "glue",
    definition: "กาว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "group",
    definition: "กลุ่ม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "gym",
    definition: "โรงยิม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "history",
    definition: "ประวัติศาสตร์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "language",
    definition: "ภาษา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "maths",
    definition: "คณิตศาสตร์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "online",
    definition: "ออนไลน์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "project",
    definition: "โครงการ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "science",
    definition: "ศาสตร์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "scissors",
    definition: "กรรไกร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "screen",
    definition: "หน้าจอ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "shelf",
    definition: "ชั้นวาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "student",
    definition: "นักเรียน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "study",
    definition: "ศึกษา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "subject",
    definition: "เรื่อง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "timetable",
    definition: "ตารางเวลา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "university",
    definition: "มหาวิทยาลัย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "School",
  },
  {
    word: "backpack",
    definition: "กระเป๋าเป้สะพายหลัง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "cartoon",
    definition: "การ์ตูน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "channel",
    definition: "ช่อง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "chess",
    definition: "หมากรุก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "collect",
    definition: "เก็บรวบรวม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "concert",
    definition: "คอนเสิร์ต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "diary",
    definition: "ไดอารี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "drum",
    definition: "กลอง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "festival",
    definition: "งานเทศกาล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "flashlight",
    definition: "ไฟฉาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "golf",
    definition: "กอล์ฟ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "hotel",
    definition: "โรงแรม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "instrument",
    definition: "อุปกรณ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "invitation",
    definition: "การเชิญ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "join a club",
    definition: "เข้าร่วมสโมสร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "magazine",
    definition: "นิตยสาร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "football match",
    definition: "การแข่งขันฟุตบอล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "meet",
    definition: "พบปะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "member",
    definition: "สมาชิก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "online",
    definition: "ออนไลน์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "pop music",
    definition: "เพลงป๊อป",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "prize",
    definition: "รางวัล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "program",
    definition: "โปรแกรม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "puzzle",
    definition: "ปริศนา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "pyramid",
    definition: "ปิรามิด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "quiz",
    definition: "แบบทดสอบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "race",
    definition: "แข่ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "rock music",
    definition: "เพลงร็อค",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "score",
    definition: "คะแนน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "ski",
    definition: "สกี",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "sledge",
    definition: "เลื่อน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "snowball",
    definition: "ก้อนหิมะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "snowboard",
    definition: "สโนว์บอร์ด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "snowboarding",
    definition: "สโนว์บอร์ด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "snowman",
    definition: "มนุษย์หิมะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "stage",
    definition: "เวที",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "suitcase",
    definition: "กระเป๋าเดินทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "swing",
    definition: "แกว่ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "team",
    definition: "ทีม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "tent",
    definition: "เต็นท์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "flashlight",
    definition: "ไฟฉาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "tune",
    definition: "ปรับแต่ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "tire",
    definition: "ยาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "umbrella",
    definition: "ร่ม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "violin",
    definition: "ไวโอลิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "volleyball",
    definition: "วอลเลย์บอล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Sports & leisure",
  },
  {
    word: "winner",
    definition: "ผู้ชนะ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "",
  },
  {
    word: "after",
    definition: "หลังจาก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "ago",
    definition: "ที่ผ่านมา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "autumn",
    definition: "ฤดูใบไม้ร่วง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "fall",
    definition: "ตก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "before",
    definition: "ก่อน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "calendar",
    definition: "ปฏิทิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "century",
    definition: "ศตวรรษ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "date",
    definition: "วันที่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "early",
    definition: "แต่แรก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "end",
    definition: "จบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "future",
    definition: "อนาคต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "hour",
    definition: "ชั่วโมง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "how long",
    definition: "นานแค่ไหน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "late",
    definition: "ช้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "later",
    definition: "ภายหลัง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "midday",
    definition: "เที่ยงวัน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "midnight",
    definition: "เที่ยงคืน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "minute",
    definition: "นาที",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "month",
    definition: "เดือน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "past",
    definition: "อดีต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "quarter",
    definition: "หนึ่งในสี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "spring",
    definition: "ฤดูใบไม้ผลิ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "summer",
    definition: "ฤดูร้อน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "time",
    definition: "เวลา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "tomorrow",
    definition: "พรุ่งนี้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "tonight",
    definition: "คืนนี้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "winter",
    definition: "ฤดูหนาว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Time",
  },
  {
    word: "january",
    definition: "มกราคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "february",
    definition: "กุมภาพันธ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "march",
    definition: "มีนาคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "april",
    definition: "เมษายน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "may",
    definition: "อาจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "june",
    definition: "มิถุนายน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "july",
    definition: "กรกฎาคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "august",
    definition: "สิงหาคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "September",
    definition: "กันยายน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "october",
    definition: "ตุลาคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "november",
    definition: "พฤศจิกายน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "december",
    definition: "ธันวาคม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The months of the year",
  },
  {
    word: "ambulance",
    definition: "รถพยาบาล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "bicycle",
    definition: "จักรยาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "fire truck",
    definition: "รถดับเพลิง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "journey",
    definition: "การเดินทาง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "lift",
    definition: "ยก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "motorway",
    definition: "มอเตอร์เวย์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "passenger",
    definition: "ผู้โดยสาร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "platform",
    definition: "แพลตฟอร์ม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "racing car",
    definition: "รถแข่ง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "racing bike",
    definition: "แข่งจักรยาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "railway",
    definition: "ทางรถไฟ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "rocket",
    definition: "จรวด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "spaceship",
    definition: "ยานอวกาศ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "taxi",
    definition: "แท็กซี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "tour",
    definition: "การท่องเที่ยว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "traffic",
    definition: "การจราจร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "wheel",
    definition: "ล้อ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Transport",
  },
  {
    word: "fog",
    definition: "หมอก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Weather",
  },
  {
    word: "foggy",
    definition: "มีหมอกลง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Weather",
  },
  {
    word: "storm",
    definition: "พายุ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Weather",
  },
  {
    word: "actor",
    definition: "นักแสดงชาย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "airport",
    definition: "สนามบิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "ambulance",
    definition: "รถพยาบาล",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "artist",
    definition: "ศิลปิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "astronaut",
    definition: "นักบินอวกาศ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "business",
    definition: "ธุรกิจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "businessman",
    definition: "นักธุรกิจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "businesswoman",
    definition: "นักธุรกิจหญิง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "designer",
    definition: "นักออกแบบ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "engineer",
    definition: "วิศวกร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "factory",
    definition: "โรงงาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "fire truck",
    definition: "รถดับเพลิง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "fire fighter",
    definition: "นักดับเพลิง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "job",
    definition: "งาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "journalist",
    definition: "นักข่าว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "manager",
    definition: "ผู้จัดการ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "mechanic",
    definition: "ช่างเครื่อง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "meeting",
    definition: "การประชุม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "news",
    definition: "ข่าว",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "newspaper",
    definition: "หนังสือพิมพ์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "office",
    definition: "สำนักงาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "photographer",
    definition: "ช่างภาพ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "pilot",
    definition: "นักบิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "police officer",
    definition: "เจ้าหน้าที่ตำรวจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "police station",
    definition: "สถานีตำรวจ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "queen",
    definition: "ราชินี",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "rocket",
    definition: "จรวด",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "singer",
    definition: "นักร้อง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "taxi",
    definition: "แท็กซี่",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "waiter",
    definition: "บริกร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "Work",
  },
  {
    word: "air",
    definition: "อากาศ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "bridge",
    definition: "สะพาน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "castle",
    definition: "ปราสาท",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "cave",
    definition: "ถ้ำ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "desert",
    definition: "ทะเลทราย",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "earth",
    definition: "โลก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "entrance",
    definition: "ทางเข้า",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "environment",
    definition: "สิ่งแวดล้อม",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "exit",
    definition: "ออก",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "fire",
    definition: "ไฟ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "future",
    definition: "อนาคต",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "hill",
    definition: "เนินเขา",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "land",
    definition: "ที่ดิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "ocean",
    definition: "มหาสมุทร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "planet",
    definition: "ดาวเคราะห์",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "pond",
    definition: "บ่อน้ำ",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "space",
    definition: "ช่องว่าง",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "stone",
    definition: "หิน",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "stream",
    definition: "ลำธาร",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "view",
    definition: "ดู",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },
  {
    word: "wood",
    definition: "ไม้",
    book: "Cambridge",
    level: "A2 Flyers",
    unit: "The world around us",
  },

  {
    word: "extrovert",
    definition: "an outgoing, socially confident person.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "friendly",
    definition:
      "A game which is of no consequence in terms of ranking, betting etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "funny",
    definition: "A joke.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "generous",
    definition:
      "Noble in behaviour or actions; principled, not petty; kind, magnanimous.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "hard-working",
    definition:
      "Of a person, taking their work seriously and doing it well and rapidly.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "lazy",
    definition: "unwilling to work or use energy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "mean",
    definition: "To lament.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "quiet",
    definition: "The absence of sound; A person who doesn't talk much",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "serious",
    definition:
      "Without humor or expression of happiness; grave in manner or disposition",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "shy",
    definition: "An act of throwing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "talkative",
    definition: "Tending to talk a lot.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "unfriendly",
    definition: "An enemy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Personality adjectives",
  },
  {
    word: "arm",
    definition:
      "The portion of the upper human appendage, from the shoulder to the wrist and sometimes including the hand.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "back",
    definition:
      "The rear of the body, especially the part between the neck and the end of the spine and opposite the chest and belly.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "bite",
    definition: "The act of biting.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "brain",
    definition:
      "The control center of the central nervous system of an animal located in the skull which is responsible for perception, cognition, attention, memory, emotion, and action.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "ear",
    definition:
      "The organ of hearing, consisting of the pinna, auditory canal, malleus, incus, stapes and cochlea.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "eye",
    definition:
      "An organ through which animals see (perceive surroundings via light).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "face",
    definition:
      "The front part of the head of a human or other animal, featuring the eyes, nose and mouth, and the surrounding area.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "feel",
    definition: "A quality of an object experienced by touch.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "feet",
    definition:
      "A biological structure found in many animals that is used for locomotion and that is frequently a separate organ at the terminal part of the leg.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "finger",
    definition:
      "A slender jointed extremity of the human hand, exclusive of the thumb.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "foot",
    definition:
      "A biological structure found in many animals that is used for locomotion and that is frequently a separate organ at the terminal part of the leg.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "hair",
    definition:
      "A pigmented filament of keratin which grows from a follicle on the skin of humans and other mammals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "hand",
    definition:
      "The part of the forelimb below the forearm or wrist in a human, and the corresponding part in many other animals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "head",
    definition:
      "The part of the body of an animal or human which contains the brain, mouth and main sense organs.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "hear",
    definition: "(stative) To perceive sounds through the ear.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "heart",
    definition:
      "A muscular organ that pumps blood through the body, traditionally thought to be the seat of emotion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "kick",
    definition: "A hit or strike with the leg, foot or knee.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "kiss",
    definition:
      "To touch with the lips or press the lips against, usually to show love or affection or passion, or as part of a greeting.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "knee",
    definition:
      "In humans, the joint or the region of the joint in the middle part of the leg between the thigh and the shank.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "leg",
    definition:
      "A limb or appendage that an animal uses for support or locomotion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "lip",
    definition:
      "Either of the two fleshy protrusions around the opening of the mouth.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "mouth",
    definition: "The opening of a creature through which food is ingested.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "neck",
    definition:
      "The part of the body connecting the head and the trunk found in humans and some animals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "nose",
    definition:
      "A protuberance on the face housing the nostrils, which are used to breathe or smell.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "see",
    definition:
      "(stative) To perceive or detect with the eyes, or as if by sight.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "shoulder",
    definition:
      "The part of an animal's body between the base of the neck and forearm socket.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "smell",
    definition:
      "A sensation, pleasant or unpleasant, detected by inhaling air (or, the case of water-breathing animals, water) carrying airborne molecules of a substance.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "smile",
    definition:
      "A facial expression comprised by flexing the muscles of both ends of one's mouth, often showing the front teeth, without vocalisation, and in humans is a common involuntary or voluntary expression of happiness, pleasure, amusement or anxiety.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "stomach",
    definition:
      "An organ in animals that stores food in the process of digestion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "teeth",
    definition:
      "The ability to be enforced, or to be enforced to any useful effect.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "think",
    definition:
      "to have a particular opinion, belief, or idea about someone or something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "toe",
    definition: "Each of the five digits on the end of the foot.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "tongue",
    definition:
      "The flexible muscular organ in the mouth that is used to move food around, for tasting and that is moved into various positions to modify the flow of air from the lungs in order to produce different sounds in speech.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "tooth",
    definition:
      "A hard, calcareous structure present in the mouth of many vertebrate animals, generally used for eating.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "touch",
    definition: "come into or be in contact with.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The body",
  },
  {
    word: "another",
    definition: "An additional one of the same kind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "art gallery",
    definition:
      "A room, group of rooms, or other space where works of art are placed on display, possibly for sale.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "artist",
    definition: "A person who creates art.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "at least",
    definition: "not less than; at the minimum.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "author",
    definition:
      "The originator or creator of a work, especially of a literary composition.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "at the back",
    definition: "opposite to the front",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "choose",
    definition: "To pick; to make the choice of; to select.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "contain",
    definition: "To hold inside.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "definition",
    definition:
      "A statement of the meaning of a word or word group or a sign or symbol.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "go on a date",
    definition:
      "to meet someone socially, usually with the intention of exploring a potential romantic relationship, by engaging in a planned activity together",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "draw",
    definition: "The result of a contest that neither side has won; a tie.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "each",
    definition:
      "(operations) An individual item: the least quantitative unit in a grouping.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "exam",
    definition: "other word for test",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "explain",
    definition:
      "To make plain, manifest, or intelligible; to clear of obscurity; to illustrate the meaning of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "famous for",
    definition:
      "known and recognized by many people because of a particular feature",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "favourite",
    definition: "A person or thing who enjoys special regard or favour.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "for example",
    definition: "used to introduce something chosen as a typical case.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "foreign languages",
    definition:
      "a language that is not the official language of a country, or is not typically spoken by people born and raised in that country",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "have in common",
    definition: "in joint use or possession; shared.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "introduce",
    definition:
      "(of people) To cause (someone) to be acquainted (with someone else).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "mime",
    definition: "A form of acting without words.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "painting",
    definition: "To apply paint to.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "panic",
    definition:
      "Overpowering fright, often affecting groups of people or animals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "partner",
    definition:
      "Someone who is associated with another in a common activity or interest.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "picture",
    definition:
      "A representation of anything (as a person, a landscape, a building) upon canvas, paper, or other surface, by drawing, painting, printing, photography, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "popular",
    definition:
      "liked or admired by many people or by a particular person or group.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "poster",
    definition:
      "A picture of a celebrity, an event etc., intended to be attached to a wall.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "prefer",
    definition:
      "To be in the habit of choosing something rather than something else; to favor; to like better.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "recognize",
    definition:
      "To match (something or someone which one currently perceives) to a memory of some previous encounter with the same person or thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "sporty",
    definition: "Favourable to sports",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "the opposite",
    definition:
      "situated on the other or further side when seen from a specified or implicit viewpoint; facing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "traditional",
    definition:
      "classic, classical, common, conventional, historic, long-established, old, popular, regular, time-honored, universal.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "try",
    definition: "An attempt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "unusual",
    definition: "not habitually or commonly occurring or done.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "website",
    definition:
      "A collection of interlinked web pages on the World Wide Web that are typically accessible from the same base URL and reside on the same server.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "abroad",
    definition: "in or to a foreign country or countries.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "appearance",
    definition:
      "The act of appearing or coming into sight; the act of becoming visible to the eye.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "be born",
    definition: "come into existence as a result of birth.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "behind",
    definition: "The rear, back-end",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "between",
    definition:
      "A kind of needle, shorter than a sharp, with a small rounded eye, used for making fine stitches on heavy fabrics.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "borrow",
    definition:
      "Deviation of the path of a rolling ball from a straight line; slope; slant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "carefully",
    definition: "Sorrowfully.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "chat",
    definition: "Informal conversation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "choice",
    definition:
      "An option; a decision; an opportunity to choose or select something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "coins",
    definition:
      "(money) A piece of currency, usually metallic and in the shape of a disc, but sometimes polygonal, or with a hole in the middle.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "colleague",
    definition:
      "A fellow member of a profession, staff, academic faculty or other organization; an associate.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "company",
    definition: "A team; a group of people who work together professionally.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "conference",
    definition:
      "The act of consulting together formally; serious conversation or discussion; interchange of views.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "dislike",
    definition: "An attitude or a feeling of distaste or aversion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "flight",
    definition: "The act of flying.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "get on well with",
    definition:
      "to have a good relationship or deal successfully with a situation",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "in",
    definition: "A position of power or influence, or a way to get it.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "in front of",
    definition: "At or near the front part of (something).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "middle",
    definition:
      "the point or position at an equal distance from the sides, edges, or ends of something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "instrument",
    definition: "A device used to produce music.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "lose",
    definition:
      "To cause (something) to cease to be in one's possession or capability due to unfortunate or unknown circumstances, events or reasons.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "next to",
    definition: "Beside, alongside, by, adjacent to, or near.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "on",
    definition: "physically in contact with and supported by (a surface).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "opposite",
    definition:
      "situated on the other or further side when seen from a specified or implicit viewpoint; facing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "pleased",
    definition: "To make happy or satisfy; to give pleasure to.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "programme",
    definition: "A set of structured activities.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "purpose",
    definition: "An objective to be reached; a target; an aim; a goal.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "puzzle",
    definition: "Anything that is difficult to understand or make sense of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "same",
    definition:
      "Not different or other; not another or others; not different as regards self; identical.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "sense of humour",
    definition: "The quality of an individual to find certain things funny.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "show",
    definition: "A play, dance, or other entertainment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "single",
    definition:
      "A 45 RPM vinyl record with one song on side A and one on side B.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "stand",
    definition:
      "have or maintain an upright position, supported by one's feet.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "sunglasses",
    definition: "Tinted glasses worn to protect the eyes from the sun.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "surprised",
    definition:
      "To cause (someone) to feel unusually alarmed or delighted by something unexpected.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "sweet",
    definition: "The basic taste sensation induced by sugar.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "type",
    definition: "A grouping based on shared characteristics; a class.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "under",
    definition: "Being lower; being beneath something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "wrong",
    definition: "Something that is immoral or not good.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 1",
  },
  {
    word: "go abroad",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go away for the weekend",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go by car",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go camping",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go for a drink",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go by bus",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go by plane",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go by train",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go for a walk",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go shopping",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go sightseeing",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go skiing",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go swimming",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go sailing",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "go to the beach",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "other holiday activities",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "buy souvenirs",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "have a good time",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "hire a car",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "meet friends",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "rent an apartment",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "spend money",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "stay in a hotel",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "sunbathe on the beach",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "spend time",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "stay in a campsite",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "take photos",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "walk in the mountains",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "walk around the town",
    definition: "Phrases with go",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrases with go",
  },
  {
    word: "boiling",
    definition:
      "(of liquids) To heat to the point where it begins to turn into a gas.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "cloudy",
    definition: "Covered with or characterised by clouds; overcast.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "cold",
    definition: "(of a thing) Having a low temperature.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "foggy",
    definition: "Obscured by mist or fog; unclear; hazy",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "freezing",
    definition:
      "Especially of a liquid, to become solid due to low temperature.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "hot",
    definition: "having a high degree of heat or a high temperature.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "rain",
    definition: "Condensed water falling from a cloud.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "snow",
    definition:
      "The frozen, crystalline state of water that falls as precipitation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "sunny",
    definition: "A sunfish.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "windy",
    definition: "Fart",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "The weather",
  },
  {
    word: "again",
    definition: "Another time; once more.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "attack",
    definition:
      "An attempt to cause damage, injury to, or death of opponent or enemy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "awful",
    definition: "Very bad.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "balcony",
    definition:
      "An accessible structure extending from a building, especially outside a window.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "become",
    definition: "To arrive, come (to a place).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "break up with",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "club",
    definition:
      "An association of members joining together for some common purpose, especially sports or recreation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "dark",
    definition: "Having an absolute or (more often) relative lack of light.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "delicious",
    definition: "Pleasing to taste; tasty.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "deserve",
    definition:
      "To be entitled to, as a result of past actions; to be worthy to have.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "dj",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "each other",
    definition:
      "(reciprocal pronoun) To one another; one to the other; signifies that a verb applies to two or more entities both as subjects and as direct objects:",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "escape from",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "every",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "exhibition",
    definition: "An instance of exhibiting, or something exhibited.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "fantastic",
    definition: "A fanciful or whimsical person.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "follow",
    definition:
      "go or come after (a person or thing proceeding ahead); move or travel behind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "furious",
    definition: "Feeling great anger; raging; violent.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "great",
    definition: "A person of major significance, accomplishment or acclaim.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "immediately",
    definition: "In an immediate manner; instantly or without delay.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "in fact",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lead singer",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lovely",
    definition: "An attractive, especially a (professional) beauty.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "luckily",
    definition: "In a lucky manner; by good fortune; fortunately.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lyrics",
    definition: "A lyric poem.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "madly",
    definition: "In a mad manner; without reason or understanding; wildly.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "miserable",
    definition: "(of a person) wretchedly unhappy or uncomfortable.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "perfect",
    definition:
      "having all the required or desirable elements, qualities, or characteristics; as good as it is possible to be.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "plane crash",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "poems",
    definition: "A literary piece written in verse.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "share",
    definition:
      "A portion of something, especially a portion given or allotted to someone.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "shout",
    definition:
      "A loud burst of voice or voices; a violent and sudden outcry, especially that of a multitude expressing joy, triumph, exultation, anger, or animated courage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "sign",
    definition: "(sometimes also used uncountably) A visible indication.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "stone",
    definition: "A hard earthen substance that can form large rocks.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "suddenly",
    definition:
      "Happening quickly and with little or no warning; in a sudden manner.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "terrible",
    definition: "Dreadful; causing terror, alarm and fear; awesome",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "the news",
    definition: "",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "wedding",
    definition: "To perform the marriage ceremony for; to join in matrimony.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "wonderful",
    definition: "Tending to excite wonder; surprising, extraordinary.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "ambition",
    definition:
      "Eager or inordinate desire for some object that confers distinction, as preferment, honor, superiority, political power, or literary fame; desire to distinguish one's self from other people.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "argue",
    definition: "To show grounds for concluding (that); to indicate, imply.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "cocktail",
    definition: "A mixed alcoholic beverage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "couple",
    definition: "Two partners in a romantic or sexual relationship.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "decade",
    definition: "A group, set, or series of ten , particularly:",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "degrees",
    definition:
      "A stage of proficiency or qualification in a course of study, now especially an award bestowed by a university or, in some countries, a college, as a certification of academic achievement. (In the United States, can include secondary schools.)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "disaster",
    definition:
      "An unexpected natural or man-made catastrophe of substantial extent causing significant physical damage or destruction, loss of life or sometimes permanent change to the natural environment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "diving",
    definition: "To swim under water.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "dramatic",
    definition: "Of or relating to the drama.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "elevator",
    definition: "Anything that raises or uplifts.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "heat",
    definition: "Thermal energy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "heatwave",
    definition: "A period of very hot weather.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "hold",
    definition: "A grasp or grip.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "honeymoon",
    definition: "The period of time immediately following a marriage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "hurry",
    definition: "Rushed action.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "invite",
    definition:
      "To ask for the presence or participation of someone or something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "knock",
    definition:
      "Sudden fatigue as a result of glycogen depletion from not having taken in enough nutrition.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lifetime",
    definition: "The duration of the life of someone or something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lift",
    definition: "raise to a higher position or level.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "memorable",
    definition:
      "worth remembering or easily remembered, especially because of being special or unusual.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "memory",
    definition:
      "The ability of the brain to record information or impressions with the facility of recalling them later at will.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "paradise",
    definition:
      "The place where sanctified souls are believed to live after death.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "peaceful",
    definition: "Not at war or disturbed by strife or turmoil.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "romantic",
    definition:
      "Of or dealing with languages or cultures derived from Roman influence and Latin: Italian, French, Portuguese, Spanish, Romanian, Catalan, Occitan, Corsican, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "room service",
    definition:
      "The provision of food, drink and other refreshments to a guest room.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "seafood",
    definition: "Fish, shellfish, seaweed, and other edible aquatic life.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "shock",
    definition: "A sudden, heavy impact.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "sights",
    definition: "(in the singular) The ability to see.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "somewhere",
    definition: "Unspecified or unknown (unlocated) place or location.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "arrive",
    definition: "To reach; to get to a certain place.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "leave",
    definition: "To have a consequence or remnant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "borrow",
    definition:
      "Deviation of the path of a rolling ball from a straight line; slope; slant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "lend",
    definition: "The lumbar region; loin.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "buy",
    definition: "Something which is bought; a purchase.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "sell",
    definition: "give or hand over (something) in exchange for money.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "catch",
    definition: "The act of seizing or capturing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "miss",
    definition: "A failure to hit.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "fail",
    definition: "Poor quality; substandard workmanship.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "pass",
    definition: "To change place.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "find",
    definition:
      "Anything that is found (usually valuable), as objects on an archeological site or a person with talent.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "lose",
    definition:
      "To cause (something) to cease to be in one's possession or capability due to unfortunate or unknown circumstances, events or reasons.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "forget",
    definition: "To lose remembrance of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "remember",
    definition:
      "To recall from one's memory; to have an image in one's memory.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "get",
    definition: "Offspring.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "receive",
    definition: "be given, presented with, or paid (something).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "send",
    definition:
      "The rising motion of water as a wave passes; a surge; the upward angular displacement of a vessel, opposed to pitch, the correlative downward movement.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "learn",
    definition:
      "gain or acquire knowledge of or skill in (something) by study, experience, or being taught.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "teach",
    definition:
      "To show (someone) the way; to guide, conduct; to point, indicate.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "pull",
    definition:
      "exert force on (someone or something) so as to cause movement towards oneself.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "push",
    definition: "A short, directed application of force",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "turn off",
    definition: "To dismiss; to fire.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "turn on",
    definition:
      "To set a flow of fluid or gas running by rotating a tap or valve.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "win",
    definition: "To conquer, defeat.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "lose",
    definition:
      "To cause (something) to cease to be in one's possession or capability due to unfortunate or unknown circumstances, events or reasons.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite verbs",
  },
  {
    word: "appear",
    definition: "To come or be in sight; to be in view; to become visible.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "as well as",
    definition: "In addition to; further to.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "au pair",
    definition:
      "A single girl (or, rarely, a boy) who helps a host family with childcare, housework, or both while staying as a guest with a host family and generally receiving a small allowance (or pocket money).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "builder",
    definition: "A person who builds or constructs things.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "busy",
    definition: "A police officer.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "champagne",
    definition:
      "A sparkling white wine made from a blend of grapes, especially Chardonnay and pinot, produced in Champagne, France, by the méthode champenoise.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cheer up",
    definition: "To become happy",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "congratulations",
    definition: "The act of congratulating.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "definitely",
    definition: "Without question and beyond doubt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "electrician",
    definition:
      "A tradesman who installs, repairs and maintains electrical wiring and equipment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "especially",
    definition: "(manner) In a special manner; specially.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "everything",
    definition: "All the things under discussion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "for a short time",
    definition: 'similar to "for a little while".',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "frightened",
    definition:
      "To cause to feel fear; to scare; to cause to feel alarm or fright.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "go on",
    definition: "To continue in extent.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "good luck",
    definition: "Desirable or beneficial fortune.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hurt",
    definition: "An emotional or psychological humiliation or bad experience.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "i think so",
    definition: 'similar to  "yes, I believe so,',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "i hope so",
    definition: "similar to \"I'm keeping my fingers crossed,",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "i hope not",
    definition: "Hopefully not",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "improve",
    definition:
      "To make (something) better; to increase the value or productivity (of something).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "injection",
    definition: "The act of injecting, or something that is injected.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "it depends",
    definition: 'Similar to "I am not sure',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "journey",
    definition:
      "A set amount of travelling, seen as a single unit; a discrete trip, a voyage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "maybe",
    definition: "Something that is possibly true.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "my own",
    definition: "belonging to me, personal",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "nervous",
    definition: "Of sinews and tendons.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "oh dear",
    definition: "used to express disappointment, sadness, or sympathy",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "patient",
    definition:
      "A person or animal who receives treatment from a doctor or other medically educated person.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "perhaps",
    definition: "An uncertainty.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "piece of paper",
    definition:
      "a thin, flat material made from wood pulp or other plant fibers that is used for writing, printing, or drawing",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "plumber",
    definition: "One who works in or with lead.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "probably",
    definition: "In all likelihood.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "promise",
    definition: "An oath or affirmation; a vow",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "romance",
    definition:
      "A story relating to chivalry; a story involving knights, heroes, adventures, quests, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "secret",
    definition:
      "A piece of knowledge that is hidden and intended to be kept hidden.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "successful",
    definition:
      "Resulting in success; assuring, or promoting, success; accomplishing what was proposed; having the desired effect",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "until",
    definition: "Up to the time of (something happening).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "violin",
    definition:
      "A musical four-string instrument, generally played with a bow or by plucking the string, with the pitch set by pressing the strings at the appropriate place with the fingers",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "a bit",
    definition: "To a small extent; in a small amount; rather.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "already",
    definition:
      "Prior to some specified time, either past, present, or future; by this time; previously.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "arrangements",
    definition: "The act of arranging.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "baked",
    definition: "(with person as subject) To cook (something) in an oven.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "best wishes",
    definition: "Used as a polite way to end a letter or see someone off.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "call back",
    definition:
      "to phone someone again, or to phone someone who called you earlier",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "celebration",
    definition:
      "The formal performance of a solemn rite, such as Christian sacrament.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "come back",
    definition: "To return to a place.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "depressed",
    definition: "To press down.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "double-decker",
    definition:
      "A vehicle (usually a bus, coach or train) or aircraft with two floors.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "either",
    definition: "(after a negative) As well.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "eternal",
    definition: "One who lives forever; an immortal.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "exotic",
    definition:
      "originating in or characteristic of a distant foreign country.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "give back",
    definition: "return what you took before.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "last",
    definition: "Final, ultimate, coming after all others of its kind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "look after",
    definition:
      "To follow with the eyes; to look in the direction of (someone or something departing).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "look for",
    definition: "To search for; to seek.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "look forward to",
    definition:
      "To anticipate, expect, or wait for, especially with a feeling of approval or pleasure. To be excited or eager to.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "main course",
    definition: "The main serving or dish of a meal, usually cooked.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "mushroom",
    definition:
      "Any of the fleshy fruiting bodies of fungi typically produced above ground on soil or on their food sources (such as decaying wood).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "operation",
    definition: "The method by which a device performs its function.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "organize",
    definition: "To arrange in working order.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "owl",
    definition:
      "Any of various birds of prey of the order Strigiformes that are primarily nocturnal and have forward-looking, binocular vision, limited eye movement, and good hearing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "pay back",
    definition: "To pay an amount of money owed to another, to repay",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "pessimist",
    definition:
      "Someone who habitually expects the worst outcome; one who looks on the dark side of things.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "phrase book",
    definition:
      "A (usually pocket-sized) book consisting of everyday expressions and vocabulary in two languages and intended for travellers who wish to communicate with locals while in other countries (or regions in which a different language is spoken).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "positive",
    definition: "A thing capable of being affirmed; something real or actual.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "psychoanalyst",
    definition: "A practitioner of psychoanalysis.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "rare",
    definition: "A scarce or uncommon item.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "research",
    definition:
      "Diligent inquiry or examination to seek or revise facts, principles, theories, applications, etc.; laborious or continued search after truth.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "starter",
    definition: "Someone who starts something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "steak",
    definition: "a slice of meat broiled or cut for broiling.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "swap",
    definition:
      "To exchange or give (something) in an exchange (for something else).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "symbol",
    definition: "A character or glyph representing an idea, concept or object.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "take back",
    definition: "To retract/withdraw an earlier statement",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "tip",
    definition:
      "the pointed or rounded end or extremity of something slender or tapering.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "truth",
    definition: "True facts, genuine depiction or statements of reality.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "well done",
    definition: "Performed well.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "wine list",
    definition:
      "A list of wines, usually with a short description, available at e.g. a restaurant or bar.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "belt",
    definition:
      "A band worn around the waist to hold clothing to one's body (usually pants), hold weapons (such as a gun or sword), or serve as a decorative piece of clothing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "blouse",
    definition:
      "A shirt, typically loose and reaching from the neck to the waist.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "cap",
    definition: "A close-fitting hat, either brimless or peaked.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "coat",
    definition: "An outer garment covering the upper torso and arms.Wp",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "dress",
    definition:
      "An item of clothing (usually worn by a woman or young girl) which both covers the upper part of the body and includes skirts below the waist.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "hat",
    definition:
      "A covering for the head, often in the approximate form of a cone or a cylinder closed at its top end, and sometimes having a brim and other decoration.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "jacket",
    definition:
      "A piece of clothing worn on the upper body outside a shirt or blouse, often waist length to thigh length.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "scarf",
    definition: "A long, often knitted, garment worn around the neck.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "shirt",
    definition:
      "An article of clothing that is worn on the upper part of the body, and often has sleeves, either long or short, that cover the arms.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "skirt",
    definition:
      "An article of clothing, usually worn by women and girls, that hangs from the waist and covers the lower part of the body.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "suit",
    definition:
      "A set of clothes to be worn together, now especially a man's matching jacket and trousers, or a similar outfit for a woman.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "sweater",
    definition:
      "A knitted jacket or jersey, usually of thick wool, worn by athletes before or after exercise.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "tie",
    definition: "A knot; a fastening.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "top",
    definition: "The highest or uppermost part of something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "tracksuit",
    definition:
      "A garment, usually consisting of a top and trousers worn as an outer layer by participants in sporting events such as athletics. It is usually designed to be easily removed or replaced, before or after competing. It has also been adopted in some cultures as leisurewear.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "t-shirt",
    definition:
      "A lightweight shirt without buttons, usually with short sleeves and no collar. Often made of cotton and frequently bears a picture or slogan.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Singular clothes",
  },
  {
    word: "boots",
    definition: "A heavy shoe that covers part of the leg.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "jeans",
    definition: "Denim.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "pyjamas",
    definition:
      "Clothes for wearing to bed and sleeping in, usually consisting of a loose-fitting shirt and pants/trousers.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "shoes",
    definition:
      "A protective covering for the foot, with a bottom part composed of thick leather or plastic sole and often a thicker heel, and a softer upper part made of leather or synthetic material. Shoes generally do not extend above the ankle, as opposed to boots, which do.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "shorts",
    definition: "A short circuit.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "socks",
    definition: "A knitted or woven covering for the foot.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "tights",
    definition:
      "A close-fitting, sheer or non-sheer skin-tight garment worn principally by women and girls that covers the body completely from the waist down, usually including the feet.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "trainers",
    definition: "A person who trains another; a coach.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "trousers",
    definition:
      "An article of clothing that covers the part of the body between the waist and the ankles or knees, and is divided into a separate part for each leg.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Plural clothes",
  },
  {
    word: "get dressed",
    definition: "Verbs used with clothes",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs used with clothes",
  },
  {
    word: "put on",
    definition: "Verbs used with clothes",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs used with clothes",
  },
  {
    word: "take off",
    definition: "Verbs used with clothes",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs used with clothes",
  },
  {
    word: "try on",
    definition: "Verbs used with clothes",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs used with clothes",
  },
  {
    word: "wear",
    definition: "Verbs used with clothes",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs used with clothes",
  },
  {
    word: "boring",
    definition: "To inspire boredom in somebody.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "exciting",
    definition: "To stir the emotions of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "interesting",
    definition:
      "To engage the attention of; to awaken interest in; to excite emotion or passion in, in behalf of a person or thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "clean",
    definition: "Removal of dirt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "polluted",
    definition:
      "To make something harmful, especially by the addition of some unwanted product.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "comfortable",
    definition: "A stuffed or quilted coverlet for a bed; a comforter.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "uncomfortable",
    definition: "Not comfortable; causing discomfort.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "crowded",
    definition: "To press forward; to advance by pushing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "empty",
    definition:
      "a container, especially a bottle, whose contents have been used up.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "dangerous",
    definition: "Full of danger.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "safe",
    definition:
      "A box, usually made of metal, in which valuables can be locked.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "far",
    definition: "Distant; remote in space.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "near",
    definition:
      "The left side of a horse or of a team of horses pulling a carriage etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "happy",
    definition: "feeling or showing pleasure or contentment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "unhappy",
    definition: "An individual who is not happy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "healthy",
    definition: "Enjoying health and vigor of body, mind, or spirit: well.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "unhealthy",
    definition: "Characterized by, or conducive to poor health",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "modern",
    definition:
      "relating to the present or recent times as opposed to the remote past.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "old",
    definition:
      "belonging to the past; former; having lived for a long time; no longer young.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "noisy",
    definition: "Making a noise, especially a loud unpleasant sound",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "quiet",
    definition: "The absence of sound; A person who doesn't talk much.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "patient",
    definition:
      "A person or animal who receives treatment from a doctor or other medically educated person.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "impatient",
    definition: "Restless and intolerant of delays.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "polite",
    definition:
      "having or showing behaviour that is respectful and considerate of other people.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "impolite",
    definition: "Not polite; not of polished manners; wanting in good manners.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "rude",
    definition: "Bad-mannered.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "possible",
    definition: "able to be done or achieved.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "impossible",
    definition: "An impossibility",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "tidy",
    definition: "A tabletop container for pens and stationery.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "untidy",
    definition: "not arranged neatly and in order.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Opposite adjectives",
  },
  {
    word: "accident",
    definition:
      "An unexpected event with negative consequences occurring without the intention of the one suffering the consequences.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "at the last minute",
    definition: 'similar to "at the last moment',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "business",
    definition: "A specific commercial enterprise or establishment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "chain",
    definition:
      "A series of interconnected rings or links usually made of metal.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "change",
    definition: "The process of becoming different.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "company",
    definition: "A team; a group of people who work together professionally.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "complain",
    definition: "To express feelings of pain, dissatisfaction, or resentment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "covered with",
    definition: "having a lot of something on a surface or object",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "exactly the same",
    definition: "completely identical, with no differences whatsoever",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "fall over",
    definition:
      "To fall from an upright or standing position to a horizontal or prone position.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "fashionable",
    definition:
      "characteristic of, influenced by, or representing a current popular style.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "find out",
    definition: "To discover, as by asking or investigating.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "gardener",
    definition: "One who gardens; one who grows plants or cultivates a garden.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "go to court",
    definition: "take legal action against someone.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "greengrocer",
    definition:
      "A person who sells fresh vegetables and fruit, normally from a relatively small shop",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "have an argument",
    definition: "fight with someone.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "headlines",
    definition: "The heading or title of a magazine or newspaper article.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "insult",
    definition:
      "Action or form of speech deliberately intended to be rude; a particular act or statement having this effect.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "judge",
    definition:
      "A public official whose duty it is to administer the law, especially by presiding over trials and rendering judgments; a justice.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "mess",
    definition:
      "A disagreeable mixture or confusion of things; hence, a situation resulting from blundering or from misunderstanding; disorder.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "moody",
    definition: "Given to sudden or frequent changes of mind; temperamental.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "obsessed with",
    definition:
      "unable to stop thinking about something; too interested in or worried about something",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "of course",
    definition:
      "That is part of ordinary behaviour or custom; customary, natural.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "previous",
    definition: "existing or occurring before in time or order.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "relaxed",
    definition: "To calm down.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "reputation",
    definition: "What somebody is known for.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "several",
    definition:
      "An area of land in private ownership (as opposed to common land).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "slow down",
    definition: "to lower your speed.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "speed",
    definition:
      "The state of moving quickly or the capacity for rapid motion; rapidity.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "store",
    definition: "A place where items may be accumulated or routinely kept.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "stressed",
    definition: "To apply force to (a body or structure) causing strain.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "stressful",
    definition: "Irritating; causing stress.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "sure",
    definition: "Physically secure and certain, non-failing, reliable.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "throw out",
    definition: "To discard; to dispense with something; to throw away.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "too much",
    definition:
      "A quantity which is excessive to the point of being inappropriate, harmful, or overwhelming.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "traffic",
    definition:
      "Pedestrians or vehicles on roads, or the flux or passage thereof.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "treat",
    definition:
      "An entertainment, outing, food, drink, or other indulgence provided by someone for the enjoyment of others.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "washing machine",
    definition: "A machine, usually automatic, which washes clothes, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "accent",
    definition:
      "A higher-pitched or stronger articulation of a particular syllable of a word or phrase in order to distinguish it from the others or to emphasize it.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "answerphone",
    definition:
      "A telephone containing a built-in device for recording incoming callers’ messages for later retrieval.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "atmosphere",
    definition: "The gases surrounding the Earth or any astronomical body.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "chat",
    definition: "Informal conversation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "clean",
    definition: "Removal of dirt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "contribute",
    definition: "To give something that is or becomes part of a larger whole.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "fancy dress",
    definition:
      "A costume, disguise to masquerade as something or someone else.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "guess",
    definition: "To reach a partly (or totally) unqualified conclusion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "hammer",
    definition: "A tool with a heavy head and a handle used for pounding.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "inexpensive",
    definition: "Low in price",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "intolerable",
    definition: "Not tolerable; not capable of being borne or endured",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "key ring",
    definition:
      "A ring, normally of metal or plastic, for holding keys together.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "kind",
    definition:
      "A type, race or category; a group of entities that have common characteristics such that they may be grouped together.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "leave",
    definition: "To have a consequence or remnant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "narrow",
    definition: "of small width in relation to length.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "outdoor",
    definition:
      "(in some African communities) To publicly display a child after it has been named",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "population",
    definition:
      "The people living within a political or geographical boundary.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "predict",
    definition:
      "say or estimate that (a specified thing) will happen in the future or will be a consequence of something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "public transport",
    definition:
      "Any form of transport that can be used by a member of public (who usually pays a fare); as opposed to private ownership of e.g. cars.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "react",
    definition: "act in response to something; respond in a particular way.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "ruin",
    definition:
      "(sometimes in the plural) The remains of a destroyed or dilapidated construction, such as a house or castle.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "save time",
    definition: "to make something happen faster or to do something quickly",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "sickness",
    definition: "The quality or state of being sick or diseased; illness.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "tidy",
    definition: "A tabletop container for pens and stationery.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "underground",
    definition: "beneath the surface of the ground.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "waste time",
    definition:
      "to not use your time well, or to spend time on something that is unnecessary or unproductive",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 4",
  },
  {
    word: "decide",
    definition:
      "To resolve (a contest, problem, dispute, etc.); to choose, determine, or settle",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "forget",
    definition: "To lose remembrance of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "help",
    definition: "Action given to provide assistance; aid.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "hope",
    definition:
      "To want something to happen, with a sense of expectation that it might.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "learn",
    definition:
      "gain or acquire knowledge of or skill in (something) by study, experience, or being taught.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "need",
    definition:
      "require (something) because it is essential or very important rather than just desirable.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "offer",
    definition: "A proposal that has been made.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "plan",
    definition:
      "A drawing showing technical details of a building, machine, etc., with unwanted details omitted, and often using symbols rather than detailed drawing to represent doors, valves, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "pretend",
    definition:
      "To claim, to allege, especially when falsely or as a form of deliberate deception.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "promise",
    definition: "An oath or affirmation; a vow",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "remember",
    definition:
      "To recall from one's memory; to have an image in one's memory.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "try",
    definition: "An attempt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "want",
    definition: "A desire, wish, longing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "would like",
    definition: 'similar to "like',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + infinitive",
  },
  {
    word: "enjoy",
    definition: "To receive pleasure or satisfaction from something",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "finish",
    definition: "An end; the end of anything.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "go on",
    definition: "To continue in extent.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "hate",
    definition: "An object of hatred.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "like",
    definition:
      "having the same characteristics or qualities as; similar to; to enjoy or approve of something or someone",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "love",
    definition: "Strong affection.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "mind",
    definition: "The ability for rational thought.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "spend time",
    definition: "to use time doing something or being somewhere",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "start",
    definition: "The beginning of an activity.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "stop",
    definition:
      "A (usually marked) place where buses, trams or trains halt to let passengers get on and off, usually smaller than a station.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Verbs + -ing",
  },
  {
    word: "across",
    definition:
      "(crosswords, often in combination) A word that runs horizontally in the completed puzzle grid or its associated clue.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "along",
    definition: "In company; together.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "down",
    definition:
      "towards or in a lower place or position, especially to or on the ground or another surface.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "into",
    definition: "To or towards the inside of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "out of",
    definition:
      "Expressing motion away, literal or figurative; opposed to into.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "over",
    definition: "A set of six legal balls bowled.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "past",
    definition: "To change place.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "round",
    definition:
      "So as to form a circle or trace a circular path, or approximation thereof.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "through",
    definition:
      "A large slab of stone laid in a dry-stone wall from one side to the other; a perpend.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "towards",
    definition: "In the direction of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "under",
    definition: "Being lower; being beneath something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "up",
    definition: "The direction opposed to the pull of gravity.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Prepositions of movement",
  },
  {
    word: "a whole day",
    definition: "24 hours",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "against the rules",
    definition: "forbidden by rules. Not allowed",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "be good at",
    definition:
      "to have the necessary skill, knowledge or experience to do something well",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "breathe",
    definition:
      "To draw air into (inhale), and expel air from (exhale), the lungs in order to extract oxygen and excrete waste gases.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "celebration",
    definition:
      "The formal performance of a solemn rite, such as Christian sacrament.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "complicated",
    definition:
      "To make complex; to modify so as to make something intricate or difficult.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "control",
    definition: "Influence or authority over something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "experiment",
    definition:
      "A test under controlled conditions made to either demonstrate a known truth, examine the validity of a hypothesis, or determine the efficacy of something previously untried.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "fans",
    definition:
      "A hand-held device consisting of concertinaed material, or slats of material, gathered together at one end, that may be opened out into the shape of a sector of a circle and waved back and forth in order to move air towards oneself and cool oneself.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "fire",
    definition:
      "A (usually self-sustaining) chemical reaction involving the bonding of oxygen with carbon or other fuel, with the production of heat and the presence of flame or smouldering.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "forest",
    definition:
      "A dense uncultivated tract of trees and undergrowth, larger than woods.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "goal",
    definition: "A result that one is attempting to achieve.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "great-grandmother",
    definition: "The mother of one's grandparent.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "guide",
    definition:
      "a person who shows the way to others, especially one employed to show tourists around places of interest.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "hairdresser",
    definition:
      "A person who cuts or styles hair as an occupation or profession.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "hairstyle",
    definition: "The style in which someone's hair has been cut and arranged.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "hole",
    definition:
      "A hollow place or cavity; an excavation; a pit; an opening in or through a solid body, a fabric, etc.; a perforation; a rent; a fissure.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "impersonal",
    definition: "not influenced by, showing, or involving personal feelings.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "incredible",
    definition: "Too implausible to be credible; beyond belief; unbelievable.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "match",
    definition:
      "a contest in which people or teams compete against each other in a particular sport.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "motivate",
    definition:
      "To provide someone with an incentive to do something; to encourage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "mystery",
    definition: "Something secret or unexplainable; an unknown.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "nature",
    definition:
      "The natural world; that which consists of all things unaffected by or predating human technology, production, and design. (Compare ecosystem.)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "obligatory",
    definition:
      "Imposing obligation, legally, morally, or otherwise; binding; mandatory.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "permitted",
    definition: "To allow (something) to happen, to give permission for.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "phrase book",
    definition:
      "A (usually pocket-sized) book consisting of everyday expressions and vocabulary in two languages and intended for travellers who wish to communicate with locals while in other countries (or regions in which a different language is spoken).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "pitch",
    definition: "A sticky, gummy substance secreted by trees; sap.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "player",
    definition: "One that plays",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "psychiatrist",
    definition: "A medical doctor specializing in psychiatry.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "recommend",
    definition:
      "To bestow commendation on; to represent favourably; to suggest, endorse or encourage as an appropriate choice.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "score",
    definition:
      "The total number of goals, points, runs, etc. earned by a participant in a game.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "stadium",
    definition: "A venue where sporting events are held.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "storm",
    definition:
      "Any disturbed state of the atmosphere, especially as affecting the earth's surface, and strongly implying destructive or unpleasant weather.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "survive",
    definition: "Of a person, to continue to live; to remain alive.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "take off",
    definition: "To remove.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "track",
    definition: "A mark left by something that has passed along.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "unbelievable",
    definition: "Not to be believed.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "accommodation",
    definition:
      "(usually a mass noun) Lodging in a dwelling or similar living quarters afforded to travellers in hotels or on cruise ships, or prisoners, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "aerobics",
    definition:
      "A form of exercise, designed to enhance one's cardiovascular fitness, normally performed to music.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "ahead",
    definition: "In or to the front; in advance; onward.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "alive",
    definition: "Having life; living; not dead",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "baseball",
    definition:
      "A sport common in North America, the Caribbean, and Japan, in which the object is to strike a ball so that one of a nine-person team can run counter-clockwise among four bases, resulting in the scoring of a run. The team with the most runs after termination of play, usually nine innings, wins.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "basketball",
    definition:
      "A sport in which two opposing teams of five players strive to put a ball through a hoop.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "blow",
    definition: "A strong wind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "body-language",
    definition:
      "a type of nonverbal communication in which physical behaviors, as opposed to words, are used to express or convey information",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "champions",
    definition: "An ongoing winner in a game or contest.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "cry",
    definition:
      "shed tears, typically as an expression of distress, pain, or sorrow.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "cycling",
    definition: "To ride a bicycle or other cycle.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "despair",
    definition: "Loss of hope; utter hopelessness; complete despondency.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "dial",
    definition:
      "A graduated, circular scale over which a needle moves to show a measurement (such as speed).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "dominate",
    definition: "Ruling; governing; prevailing",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "exchange",
    definition: "An act of exchanging or trading.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "expect",
    definition: "To predict or believe that something will happen",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "golf",
    definition:
      "A ball game played by individuals competing against one another in which the object is to hit a ball into each of a series of (usually 18 or nine) holes in the minimum number of strokes.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "health",
    definition:
      "The state of being free from physical or psychological disease, illness, or malfunction; wellness.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "impression",
    definition:
      "The indentation or depression made by the pressure of one object on or into another.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "intense",
    definition: "Strained; tightly drawn.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "intensive",
    definition:
      "concentrated on a single subject or into a short time; very thorough or vigorous.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "judo",
    definition: "A Japanese martial art and sport adapted from jujutsu.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "karaoke",
    definition:
      "A form of entertainment popular in clubs, at parties, etc, in which individual members of the public sing along to pre-recorded instrumental versions of popular songs, the lyrics of which are displayed for the singer on a screen in time with the music.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "miss",
    definition: "A failure to hit.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "never mind",
    definition:
      "It is not important; do not fret; used to reassure or comfort the person to whom it is said.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "outside",
    definition: "The part of something that faces out; the outer surface.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "pain",
    definition:
      "An ache or bodily suffering, or an instance of this; an unpleasant sensation, resulting from a derangement of functions, disease, or injury by violence; hurt.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "pleasure",
    definition: "A state of being pleased or contented; gratification.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "posts",
    definition:
      "A long dowel or plank protruding from the ground; a fencepost; a lightpost.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "quite",
    definition:
      "(heading) To the greatest extent or degree; completely, entirely.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "really",
    definition: "In a way or manner that is real, not unreal.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "receipt",
    definition: "The act of receiving, or the fact of having been received.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "referee",
    definition:
      "An umpire or judge; an official who makes sure the rules are followed during a game.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "rugby",
    definition:
      "(usually uncountable) A form of football in which players can hold or kick an ovoid ball. The ball cannot be handled forwards and points are scored by touching the ball to the ground in the area past their opponent’s territory or kicking the ball between goalposts and over a crossbar.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "skiing",
    definition: "To move on skis",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "technique",
    definition:
      "The practical aspects of a given art, occupation etc.; formal requirements.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "volleyball",
    definition:
      "A game played on a rectangular court between two teams of two to six players which involves striking a ball back and forth over a net.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "warm",
    definition:
      "Having a temperature slightly higher than usual, but still pleasant; mildly hot.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "whistle",
    definition:
      "A device designed to be placed in the mouth and blown, or driven by steam or some other mechanism, to make a whistling sound.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "yours faithfully",
    definition:
      "A polite formula to end a letter, especially when the recipient's name is unknown to the sender, for example in a business letter",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 5",
  },
  {
    word: "carry",
    definition:
      "A manner of transporting or lifting something; the grip or position in which something is carried.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "wear",
    definition: "(in combination) clothing",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "do",
    definition: "A party, celebration, social function.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "make",
    definition: "Brand or kind; model.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "earn",
    definition:
      "To gain (success, reward, recognition) through applied effort or work.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "win",
    definition: "To conquer, defeat.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "know",
    definition: "be aware of through observation, inquiry, or information.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "meet",
    definition:
      "arrange or happen to come into the presence or company of (someone).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "hope",
    definition:
      "To want something to happen, with a sense of expectation that it might.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "wait",
    definition: "A delay.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "look",
    definition:
      "the appearance of someone or something, especially as expressing a particular quality.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "look like",
    definition: "to have a similar appearance to someone or something",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "look at",
    definition: "To use your eyes to observe something",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "watch",
    definition: "A portable or wearable timepiece.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Confusing verbs",
  },
  {
    word: "bear",
    definition:
      "A large omnivorous mammal, related to the dog and raccoon, having shaggy hair, a very small tail, and flat feet; a member of family Ursidae.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "bee",
    definition:
      "A flying insect, of the clade Anthophila within the hymenopteran superfamily Apoidea, known for its organised societies (though only a minority have them), for collecting pollen and (in some species) producing wax and honey.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "bird",
    definition:
      "A member of the class of animals Aves in the phylum Chordata, characterized by being warm-blooded, having feathers and wings usually capable of flight, and laying eggs.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "bull",
    definition: "An adult male of domesticated cattle or oxen.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "butterfly",
    definition:
      "A flying insect of the order Lepidoptera, distinguished from moths by their diurnal activity and generally brighter colouring.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "camel",
    definition:
      "A beast of burden, much used in desert areas, of the genus Camelus.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "chicken",
    definition: "A domestic fowl, Gallus gallus, especially when young.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "cow",
    definition:
      "(properly) An adult female of the species Bos taurus, especially one that has calved.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "crocodile",
    definition:
      "Any of the predatory amphibious reptiles of the family Crocodylidae; a crocodilian, any species of the order Crocodilia, which also includes the alligators, caimans and gavials.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "dolphin",
    definition:
      "A carnivorous aquatic mammal in one of several families of order Cetacea, famed for its intelligence and occasional willingness to approach humans.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "duck",
    definition:
      "To quickly lower the head or body in order to prevent it from being struck by something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "eagle",
    definition:
      "Any of several large carnivorous and carrion-eating birds in the family Accipitridae, having a powerful hooked bill and keen vision.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "elephant",
    definition:
      "A mammal of the order Proboscidea, having a trunk, and two large ivory tusks jutting from the upper jaw.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "farm",
    definition:
      "A place where agricultural and similar activities take place, especially the growing of crops or the raising of livestock.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "fly",
    definition:
      "Any insect of the order Diptera; characterized by having two wings (except for some wingless species), also called true flies.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "giraffe",
    definition:
      "A ruminant, of the genus Giraffa, of the African savannah with long legs and highly elongated neck, which make it the tallest living animal; yellow fur patterned with dark spots, often in the form of a network; and two or more short, skin-covered horns, so-called; strictly speaking the horn-like projections are ossicones.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "goat",
    definition:
      "A mammal, Capra aegagrus hircus, and similar species of the genus Capra.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "gorilla",
    definition:
      "The largest of the apes, native to the forests of central Africa, and known for their trait of knuckle-walking.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "horse",
    definition: "Any of several animals related to Equus ferus caballus.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "insect",
    definition:
      "An arthropod in the class Insecta, characterized by six legs, up to four wings, and a chitinous exoskeleton.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "kangaroo",
    definition:
      "A member of the Macropodidae family of large marsupials with strong hind legs for hopping, native to Australia.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "lion",
    definition:
      "A big cat, Panthera leo, native to Africa, India and formerly much of Europe.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "mosquito",
    definition:
      "A small flying insect of the family Culicidae, the females of which bite humans and animals and suck blood, leaving an itching bump on the skin, and sometimes carrying diseases like malaria and yellow fever.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "mouse",
    definition: "Any small rodent of the genus Mus.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "pig",
    definition:
      "Any of several intelligent mammalian species of the genus Sus, having cloven hooves, bristles and a nose adapted for digging; especially the domesticated animal Sus scrofa.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "rabbit",
    definition:
      "A mammal of the family Leporidae, with long ears, long hind legs and a short, fluffy tail.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "shark",
    definition:
      "A scaleless, predatory fish of the superorder Selachimorpha, with a cartilaginous skeleton and 5 to 7 gill slits on each side of its head.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "sheep",
    definition: "A woolly ruminant of the genus Ovis.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "spider",
    definition:
      "Any of various eight-legged, predatory arthropods, of the order Araneae, most of which spin webs to catch prey.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "swan",
    definition:
      "Any of various species of large, long-necked waterfowl, of genus Cygnus (bird family: Anatidae), most of which have white plumage.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "tiger",
    definition:
      "Panthera tigris, a large predatory mammal of the cat family, indigenous to Asia.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "wasp",
    definition:
      "Any of many types of stinging flying insect resembling a hornet.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "whale",
    definition:
      "Any of several species of large sea mammals of the infraorder Cetacea.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Animals",
  },
  {
    word: "get angry",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get divorced",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get fit",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get lost",
    definition: "To lose one's way.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get married",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get better",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get older",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get worse",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a flat",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a job",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a newspaper",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a ticket",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get into",
    definition: "To move into (an object), such that one ends up inside it.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get off",
    definition:
      "To move from being on top of (something) to not being on top of it.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get on",
    definition: "To board or mount (something), especially a vehicle.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get on well with",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get out of",
    definition: "To leave, exit or escape from; to become free of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get up",
    definition: "To move in an upward direction; to ascend or climb.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get home",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get to school",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get to work",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a letter",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a present",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get a salary",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "get an email",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "get",
  },
  {
    word: "ask for",
    definition: 'A phrase with "get',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "bank",
    definition:
      "An institution where one can place and borrow money and take care of financial affairs.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "bark",
    definition:
      "The short, loud, explosive sound uttered by a dog, a fox, and some other animals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "belong",
    definition: "To have its proper place.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "change your mind",
    definition: "adopt a different opinion or plan.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "climb",
    definition: "go or come up a (slope or staircase); ascend.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "compare",
    definition: "Comparison.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "confuse",
    definition: "To puzzle, perplex, baffle, bewilder (somebody).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "cupboard",
    definition:
      "A board or table used to openly hold and display silver plate and other dishware; a sideboard; a buffet.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "decisive",
    definition:
      "Having the power or quality of deciding a question or controversy; putting an end to contest or controversy; final; conclusive.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "desperate",
    definition:
      "feeling or showing a hopeless sense that a situation is so bad as to be impossible to deal with.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "directly",
    definition: "In a direct manner; in a straight line or course.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "disappear",
    definition: "To vanish.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "drown",
    definition:
      "To die from suffocation while immersed in water or other fluid.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "fur coat",
    definition: "an outer garment made from the skins of mammals",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "indecisive",
    definition:
      "(of a person) Not decisive; not marked by promptness or decision.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "investigate",
    definition:
      "To inquire into or study in order to ascertain facts or information.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "not worth it",
    definition:
      "ou don't gain anything from an action or that the action isn't worth the effort, cost, or trouble.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "kill",
    definition: "cause the death of (a person, animal, or other living thing).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "law",
    definition:
      "The body of binding rules and regulations, customs and standards established in a community by its legislative and judicial authorities.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "lock",
    definition:
      "Something used for fastening, which can only be opened with a key or combination.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "make a decision",
    definition:
      "to make a choice about something, especially after thinking about several possibilities:",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "make a list",
    definition:
      "to write down a series of items or tasks, often in a specific order",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "make an excuse",
    definition:
      "to give a false reason for why you can't do something or why you can't be somewhere.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "offended",
    definition:
      "To hurt the feelings of; to displease; to make angry; to insult.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "options",
    definition: "One of a set of choices that can be made.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "pet",
    definition: "An animal kept as a companion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "queue",
    definition: "An animal's tail.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "run away",
    definition: "To flee by running",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "sensitive",
    definition:
      "A person with a paranormal sensitivity to something that most cannot perceive.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "simple",
    definition:
      "A herbal preparation made from one plant, as opposed to something made from more than one plant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "size",
    definition:
      "the relative extent of something; a thing's overall dimensions or magnitude; how big something is.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "spill",
    definition: "A mess of something that has been dropped.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "suggestion",
    definition: "Something suggested (with subsequent adposition being for)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "taste",
    definition:
      "One of the sensations produced by the tongue in response to certain chemicals; the quality of giving this sensation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "take your time",
    definition: "not hurry.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "treat",
    definition:
      "An entertainment, outing, food, drink, or other indulgence provided by someone for the enjoyment of others.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "together",
    definition: "Coherent; well organized.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "wave your arms",
    definition:
      "a nonverbal communication gesture that involves moving your hand or arm from side to side or up and down",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "weigh",
    definition: "To determine the weight of an object.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "allergic",
    definition:
      "A person with a tendency to having allergies; a person with multiple or severe allergies.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "annoying",
    definition:
      "To disturb or irritate, especially by continued or repeated acts; to bother with unpleasant deeds.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "backache",
    definition: "Any pain or ache in the back.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "bless you",
    definition: "said to a person who has just sneezed.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "communicate",
    definition: "To impart",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "communication",
    definition: "The act or fact of communicating anything; transmission.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "cough",
    definition:
      "A sudden, usually noisy expulsion of air from the lungs, often involuntary.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "educate",
    definition: "To instruct or train",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "education",
    definition: "The process of imparting knowledge, skill and judgment.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "elect",
    definition: "One chosen or set apart.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "election",
    definition:
      "A process of choosing a leader, members of parliament, councillors or other representatives by popular vote.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "excuse",
    definition:
      "Explanation designed to avoid or alleviate guilt or negative judgment; a plea offered in extenuation of a fault.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "fall in love",
    definition:
      "(of a person, animate object following “with”) To come to have feelings of love directed at another person or a thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "headache",
    definition: "A pain or ache in the head.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "hire",
    definition: "Payment for the temporary use of something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "imagination",
    definition:
      "The image-making power of the mind; the act of mentally creating or reproducing an object not previously perceived; the ability to create such images.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "imagine",
    definition:
      "A short fanfic or prompt placing a reader insert in a novel scenario with a character or celebrity.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "indecisive",
    definition:
      "(of a person) Not decisive; not marked by promptness or decision.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "inform",
    definition: "To instruct, train (usually in matters of knowledge).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "information",
    definition:
      'That which resolves uncertainty; anything that answers the question of "what a given entity is".',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "invitation",
    definition:
      "The act of inviting; solicitation; the requesting of a person's company.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "land",
    definition:
      "The part of Earth which is not covered by oceans or other bodies of water.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "muscles",
    definition:
      "A contractile form of tissue which animals use to effect movement.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "obsessive",
    definition: "A person who is obsessed, who has an obsession.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "organization",
    definition: "The quality of being organized.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "painkillers",
    definition: "A drug that numbs the pain in the body.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "symptoms",
    definition:
      "A perceived change in some function, sensation or appearance of a person that indicates a disease or disorder, such as fever, headache or rash.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "tax",
    definition:
      "Money paid to the government other than for transaction-specific goods and services.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "temperature",
    definition:
      "A measure of cold or heat, often measurable with a thermometer.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "translate",
    definition:
      "In Euclidean spaces: a set of points obtained by adding a given fixed vector to each point of a given set.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "translation",
    definition: "The act of translating, in its various senses:",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "wild",
    definition:
      "(of an animal or plant) living or growing in the natural environment; not domesticated or cultivated.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 6",
  },
  {
    word: "according to",
    definition: 'Similar to "As Stated by',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "affect",
    definition: "To influence or alter.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "at war",
    definition:
      "a state of armed conflict between different countries or different groups within a country.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "bikini",
    definition:
      "A brief two-piece bathing suit worn by women, especially one that exposes the midriff and navel.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "biro",
    definition: "A BIRO brand ballpoint pen.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "boat",
    definition:
      "A craft used for transportation of goods, fishing, racing, recreational cruising, or military use on or in the water, propelled by oars or outboard motor or inboard motor or by wind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "bomb",
    definition: "An explosive device used or intended as a weapon.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "bright",
    definition:
      "An artist's brush used in oil and acrylic painting with a long ferrule and a flat, somewhat tapering bristle head.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "bullet-proof vest",
    definition:
      "a type of body armor that protects the wearer's torso from bullets and other projectiles",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "career",
    definition:
      "One's calling in life; a person's occupation; one's profession.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "deteriorate",
    definition:
      "To make worse; to make inferior in quality or value; to impair.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "dishwasher",
    definition: "A machine for washing dishes.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "drama",
    definition:
      "A composition, normally in prose, telling a story and intended to be represented by actors impersonating the characters and speaking the dialogue",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "drugs",
    definition:
      "A substance used to treat an illness, relieve a symptom, or modify a chemical process in the body for a specific purpose.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "entrance",
    definition: "The action of entering, or going in.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "episode",
    definition:
      "An incident, action, or time period standing out by itself, but more or less connected with a complete series of events.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "especially",
    definition: "(manner) In a special manner; specially.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "fight",
    definition:
      "To contend in physical conflict, either singly or in war, battle etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "giant",
    definition: "A mythical human of very great size.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "hairy",
    definition: "Of a person, having a lot of hair on the body.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "light bulb",
    definition:
      "An evacuated glass bulb containing a metal filament which is heated by electrical resistance to produce light.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "nappies",
    definition:
      "An absorbent garment worn by a baby who does not yet have voluntary control of his or her bladder and bowels or by someone who is incontinent; a diaper.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "nominate",
    definition:
      "To name someone as a candidate for a particular role or position, including that of an office.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "parking ticket",
    definition:
      "A legal summons issued for parking a motor vehicle in a place where such parking is restricted, or beyond the time paid for.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "play",
    definition: "Activity for amusement only, especially among the young.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "prison",
    definition:
      "A place or institution of confinement, especially of long-term confinement for those convicted of serious crimes or otherwise considered undesirable by the government.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "protest",
    definition: "A formal objection, especially one by a group.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "qualifications",
    definition:
      "The act or process of qualifying for a position, achievement etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "rebel",
    definition:
      "A person who resists an established authority, often violently",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "recently",
    definition: "In the recent past",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "role",
    definition: "A character or part played by a performer or actor.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "scene",
    definition: "The location of an event that attracts attention.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "stockings",
    definition:
      "A soft garment, usually knit or woven, worn on the foot and lower leg under shoes or other footwear.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "the rest",
    definition:
      "the part that is left when other people or things are gone, used, etc.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "treatment",
    definition: "The process or manner of treating someone or something.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "vacuum cleaner",
    definition:
      "A domestic or industrial mobile machine for cleaning floors and similar surfaces that draws dust and other particles into a container (a cyclonic type bin, or a filter bag) by the suction created by an electric motor driving a fan.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "violence",
    definition: "Extreme force.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "windscreen wipers",
    definition:
      "A device used to clear rain and dirt from a windscreen; normally a pivoting arm with a rubber blade; a windshield wiper.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "afraid",
    definition: "feeling fear or anxiety; frightened.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "appalling",
    definition: "To fill with horror; to dismay.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "appropriately",
    definition: "In an appropriate manner; properly; suitably.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "baptism",
    definition:
      "A Christian sacrament, by which one is received into a church and sometimes given a name, generally involving the candidate to be anointed with or submerged in water.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "base",
    definition: "Something from which other things extend; a foundation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "biology",
    definition: "The study of all life or living matter.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "cab",
    definition: "A taxi.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "chemistry",
    definition:
      "The branch of natural science that deals with the composition and constitution of substances and the changes that they undergo as a consequence of alterations in the constitution of their molecules.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "create",
    definition: "To bring into existence; (sometimes in particular:)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "critics",
    definition: "A person who appraises the works of others.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "cure",
    definition: "A method, device or medication that restores good health.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "design",
    definition:
      "A specification of an object or process, referring to requirements to be satisfied and thus conditions to be met for them to solve a problem.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "discover",
    definition: "To find or learn something for the first time.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "disposable",
    definition:
      "Any object that is designed to be disposed of rather than refilled or repaired.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "fear",
    definition:
      "A strong, uncontrollable, unpleasant emotion or feeling caused by actual or perceived danger or threat.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "flirt",
    definition: "A sudden jerk; a quick throw or cast; a darting motion",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "flying",
    definition:
      "To hit a fly ball; to hit a fly ball that is caught for an out. Compare ground (verb) and line (verb).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "frustrated",
    definition:
      "To disappoint or defeat; to vex by depriving of something expected or desired.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "geography",
    definition:
      "the nature and relative arrangement of places and physical features.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "heating",
    definition:
      'To cause an increase in temperature of (an object or space); to cause to become hot (often with "up").',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "heights",
    definition: "The distance from the base of something to the top.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "history",
    definition: "The aggregate of past events.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "honoured",
    definition:
      "To think of highly, to respect highly; to show respect for; to recognise the importance or spiritual value of",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "lawyer",
    definition:
      "A professional person qualified (as by a law degree or bar exam) and authorized to practice law, i.e. represent parties in lawsuits or trials and give legal advice.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "literature",
    definition: "The body of all written works.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "manufacturer",
    definition: "One that manufactures",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "maths",
    definition:
      "An abstract representational system used in the study of numbers, shapes, structure, change and the relationships between these concepts.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "muse",
    definition: "A source of inspiration.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "name",
    definition:
      "Any nounal word or phrase which indicates a particular person, place, class, or thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "paste",
    definition: "A soft moist mixture, in particular:",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "pe",
    definition:
      "The seventeenth letter of many Semitic alphabets/abjads (Phoenician, Aramaic, Hebrew פ, Syriac ܦ, and others; Arabic has the analog faa).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "phobia",
    definition: "An irrational, abnormal, or obsessive fear (of something).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "physics",
    definition: "A medicine or drug, especially a cathartic or purgative.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "retire",
    definition:
      "leave one's job and cease to work, typically on reaching the normal age for leaving service.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "safety",
    definition: "The condition or feeling of being safe; security; certainty.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "science",
    definition:
      "A particular discipline or branch of learning, especially one dealing with measurable or systematic principles rather than intuition or natural ability.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "separate",
    definition:
      "(usually in the plural) Anything that is sold by itself, especially an article of clothing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "snack bar",
    definition: "A small restaurant serving light meals.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "spaces",
    definition: "(heading) Of time.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "spire",
    definition: "The stalk or stem of a plant.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "statue",
    definition:
      "A three-dimensional work of art, usually representing a person or animal, usually created by sculpting, carving, molding, or casting.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "steps",
    definition:
      "An advance or movement made from one foot to the other; a pace.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "strict",
    definition: "Strained; drawn close; tight.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "technology",
    definition: "The organization of knowledge for practical purposes.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "terrified",
    definition: "Extremely frightened.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "text-messaging",
    definition:
      "the act of sending short, electronic messages between devices, usually cell phones, to communicate with others",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "toothache",
    definition: "A pain or ache in a tooth.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "unemployed",
    definition: "Unemployed people.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "use",
    definition: "The act of using.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "view",
    definition: "(physical) Visual perception.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "wonder",
    definition: "Something that causes amazement or awe; a marvel.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 7",
  },
  {
    word: "be over",
    definition: "finished or complete.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "fill in",
    definition:
      "To fill; to replace material that is absent or has been removed.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "find out",
    definition: "To discover, as by asking or investigating.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "get on with",
    definition: "perform or make progress in a specified way.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "give up",
    definition: "To surrender (someone or something)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "look up",
    definition: "To have better prospects.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "pick up",
    definition: "To lift; to grasp and raise.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "put away",
    definition:
      "To put (something) in its usual storage place; to place out of the way, clean up.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "stay up",
    definition: "To remain in a raised or upright position.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "throw away",
    definition:
      "To discard (trash, garbage, or the like), to toss out, to put in the trash, to dispose of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "turn down",
    definition: "To refuse, decline, or deny.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "turn up",
    definition: "To show up; to appear suddenly or unexpectedly.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "Phrasal verbs",
  },
  {
    word: "active",
    definition: "A person or thing that is acting or capable of acting.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "admit",
    definition:
      "To allow to enter; to grant entrance (to), whether into a place, into the mind, or into consideration",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "adopted",
    definition:
      "To take by choice into relationship (a child, heir, friend, citizen, etc.)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "alarm clock",
    definition:
      "A clock with an alarm that can be set to sound at a determined time.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "allergic",
    definition:
      "A person with a tendency to having allergies; a person with multiple or severe allergies.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "amazing",
    definition:
      "To fill with wonder and surprise; to astonish, astound, surprise or perplex.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "because of",
    definition: "On account of; due to, for the purpose of",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "beliefs",
    definition: "Mental acceptance of a claim as true.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "close",
    definition: "An end or conclusion.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "convinced",
    definition:
      "To make someone believe, or feel sure about something, especially by using logic, argument or evidence.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "day off",
    definition:
      "A day of vacation; a day when one does not attend work, school etc",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "diet",
    definition: "The food and beverage a person or animal consumes.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "discover",
    definition: "To find or learn something for the first time.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "energetic",
    definition: "Possessing or pertaining to energy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "enormous",
    definition: "Deviating from the norm; unusual, extraordinary.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "except",
    definition: "not including; other than.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "exhausted",
    definition: "To draw or let out wholly; to drain off completely",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "exist",
    definition: "have objective reality or being.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "fresh",
    definition: "A rush of water, along a river or onto the land; a flood.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "gene",
    definition:
      "A theoretical unit of heredity of living organisms; it may take several values and in principle predetermines a precise trait of an organism's form (phenotype), such as hair color.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "give up",
    definition: "To surrender (someone or something)",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "go wrong",
    definition: "To fail or go amiss; to have a bad outcome.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "identical",
    definition:
      "(usually pluralized) Something which has exactly the same properties as something else.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "instead of",
    definition: "as an alternative or substitute.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "irritable",
    definition: "Capable of being irritated.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "kids",
    definition: "A young goat.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "latest",
    definition: "Near the end of a period of time.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "lift",
    definition: "raise to a higher position or level.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "on my own",
    definition: 'Similary to "Alone',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "ready",
    definition: "Ready money; cash",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "research",
    definition:
      "Diligent inquiry or examination to seek or revise facts, principles, theories, applications, etc.; laborious or continued search after truth.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "reunited",
    definition: "To unite again.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "skin",
    definition:
      "The outer protective layer of the body of any animal, including of a human.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "so",
    definition: "True, accurate.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sunscreen",
    definition:
      "A cream, to be spread on the skin, containing organic compounds that absorb, and/or titanium dioxide that reflects the sun’s ultraviolet radiation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "tense",
    definition:
      "(grammar) Any of the forms of a verb which distinguish when an action or state of being occurs or exists.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "twins",
    definition:
      "Either of two people (or, less commonly, animals) who shared the same uterus at the same time; one who was born at the same birth as a sibling.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "vote for",
    definition:
      "a formal indication of a choice between two or more candidates or courses of action, expressed typically through a ballot or a show of hands.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "wood",
    definition:
      "The substance making up the central part of the trunk and branches of a tree. Used as a material for construction, to manufacture various items, etc. or as fuel.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "amazed",
    definition:
      "To fill with wonder and surprise; to astonish, astound, surprise or perplex.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "body clock",
    definition: "Biological clock.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "bored",
    definition:
      "feeling weary and impatient because one is unoccupied or lacks interest in one's current activity.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "boring",
    definition: "To inspire boredom in somebody.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "button",
    definition:
      'One who adjusts, especially for the insurance industry\'s employment title "loss adjuster" (or "claims adjuster" in the United States).',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "cheers",
    definition:
      "A cheerful attitude; happiness; a good, happy, or positive mood.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "claustrophobic",
    definition: "Someone with claustrophobia",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "depressed",
    definition: "To press down.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "depressing",
    definition: "To press down.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "determine",
    definition: "To set the boundaries or limits of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "dna",
    definition: "The part of a living being that carries genetic information.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "during",
    definition: "To last, continue, endure.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "excited",
    definition: "To stir the emotions of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "exciting",
    definition: "To stir the emotions of.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "interested",
    definition:
      "To engage the attention of; to awaken interest in; to excite emotion or passion in, in behalf of a person or thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "interesting",
    definition:
      "To engage the attention of; to awaken interest in; to excite emotion or passion in, in behalf of a person or thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "lifestyle",
    definition:
      "A style of living that reflects the attitudes and values of a person or group.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "message",
    definition:
      "A communication, or what is communicated; any concept or information conveyed.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "pessimistic",
    definition:
      "Marked by pessimism and little hopefulness; expecting the worst.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "portion",
    definition: "An allocated amount.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "preferences",
    definition:
      'The selection of one thing or person over others (with the main adposition being "for" in relation to the thing or person, but possibly also "of")',
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "press",
    definition:
      "move or cause to move into a position of contact with something by exerting continuous physical force.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "processed",
    definition: "To perform a particular process on a thing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "psychology",
    definition: "The study of the human mind.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "put through",
    definition: "To connect (a telephone caller with intended callee).",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "regularly",
    definition: "With constant frequency or pattern.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "relaxing",
    definition: "To calm down.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "repair",
    definition:
      "restore (something damaged, faulty, or worn) to a good condition.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "similar",
    definition:
      "having a resemblance in appearance, character, or quantity, without being identical.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "similarity",
    definition: "Closeness of appearance to something else.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "supposed to",
    definition:
      "generally assumed or believed to be the case, but not necessarily so.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "tiring",
    definition: "To become sleepy or weary.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 8",
  },
  {
    word: "almost",
    definition: "Something or someone that doesn't quite make it.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "arrest",
    definition:
      "seize (someone) by legal authority and take them into custody.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "behave",
    definition: "To conduct (oneself) well, or in a given way.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "bride",
    definition:
      "A woman in the context of her own wedding; one who is going to marry or has just been married.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "by your side",
    definition:
      "close to someone, especially so as to give them comfort or moral support.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "commit a crime",
    definition: "to do something illegal or something that is considered wrong",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "fine",
    definition: "Fine champagne; French brandy.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "hold",
    definition: "A grasp or grip.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "jump",
    definition:
      "push oneself off a surface and into the air by using the muscles in one's legs and feet.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "let somebody know",
    definition: "inform someone.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "motorway",
    definition:
      "(parts of Australia) A broad highway designed for high speed traffic, having restrictions on the vehicle types permitted and merging lanes instead of cross traffic; in parts of the United States and other places called freeway.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "porter",
    definition: "A person who carries luggage and related objects.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "rob",
    definition: "To steal from, especially using force or violence.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "scream",
    definition:
      "A loud, emphatic, exclamation of extreme emotion, especially horror, fear, excitement, or anger; it may comprise a word or a sustained, high-pitched vowel sound.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "shine",
    definition: "Brightness from a source of light.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "snore",
    definition: "The act of snoring, and the noise produced.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "tight",
    definition:
      "fixed, fastened, or closed firmly; hard to move, undo, or open.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "whisper",
    definition:
      "The act of speaking in a quiet voice, especially, without vibration of the vocal cords.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "File 9 More Words to Learn",
  },
  {
    word: "accidentally",
    definition: "In an accidental manner; by chance, unexpectedly.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "application",
    definition: "The act of applying or laying on, in a literal sense",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "catch",
    definition: "The act of seizing or capturing.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "citizen",
    definition:
      "A resident of a city or town, especially one with legally-recognized rights or duties.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "customer",
    definition:
      "A patron, a client; one who purchases or receives a product or service from a business or merchant, or intends to do so.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "coast",
    definition:
      "The edge of the land where it meets an ocean, sea, gulf, bay, or large lake.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "luckily",
    definition: "In a lucky manner; by good fortune; fortunately.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "passenger",
    definition:
      "One who rides or travels in a vehicle, but who does not operate it and is not a member of the crew.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "previous",
    definition: "existing or occurring before in time or order.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "raffle",
    definition:
      "A drawing, often held as a fundraiser, in which tickets or chances are sold to win a prize.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "rum",
    definition:
      "A distilled spirit derived from fermented cane sugar and molasses.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "solve",
    definition: "(chiefly law enforcement) A solution; an explanation.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "suddenly",
    definition:
      "Happening quickly and with little or no warning; in a sudden manner.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },
  {
    word: "unfortunately",
    definition:
      "Happening through bad luck, or because of some unfortunate event.",
    book: "English File",
    level: "Pre-Intermediate",
    unit: "More words in File 9",
  },

  {
    word: "address book",
    definition: "สมุดที่อยู่",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "book",
    definition: "หนังสือ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "cigarettes",
    definition: "บุหรี่",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "coins",
    definition: "เหรียญ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "comb",
    definition: "หวี",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "credit card",
    definition: "บัตรเครดิต",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "diary",
    definition: "ไดอารี่",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "dictionary",
    definition: "พจนานุกรม",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "file",
    definition: "ไฟล์",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "glasses",
    definition: "แว่นตา",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "identity card",
    definition: "บัตรประจำตัวประชาชน",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "keys",
    definition: "กุญแจ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "lighter",
    definition: "เบากว่า",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "lipstick",
    definition: "ลิปสติก",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "magazine",
    definition: "นิตยสาร",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "matches",
    definition: "ไม้ขีด",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "mobile",
    definition: "มือถือ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "newspaper",
    definition: "หนังสือพิมพ์",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "pen",
    definition: "ปากกา",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "pencil",
    definition: "ดินสอ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "photo",
    definition: "รูปถ่าย",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "purse",
    definition: "กระเป๋าเงิน",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "stamps",
    definition: "แสตมป์",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "sunglasses",
    definition: "แว่นกันแดด",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "tissues",
    definition: "เนื้อเยื่อ",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "umbrella",
    definition: "ร่ม",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "wallet",
    definition: "กระเป๋าสตางค์",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "watch",
    definition: "ดู",
    book: "English File",
    level: "elementary",
    unit: "Common objects",
  },
  {
    word: "about",
    definition: "เกี่ยวกับ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "afternoon",
    definition: "ตอนบ่าย",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "again",
    definition: "อีกครั้ง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "australia",
    definition: "ออสเตรเลีย",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "business",
    definition: "ธุรกิจ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "canada",
    definition: "แคนาดา",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "check",
    definition: "ตรวจสอบ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "city",
    definition: "เมือง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "classroom",
    definition: "ห้องเรียน",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "delicious",
    definition: "อร่อย",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "difference",
    definition: "ความแตกต่าง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "different",
    definition: "แตกต่าง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "evening",
    definition: "ตอนเย็น",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "every",
    definition: "ทั้งหมด",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "eyes",
    definition: "ดวงตา",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "famous",
    definition: "มีชื่อเสียง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "form",
    definition: "รูปร่าง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "good",
    definition: "ดี",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "guess",
    definition: "เดา",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "happy",
    definition: "มีความสุข",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "help",
    definition: "ช่วย",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "holiday",
    definition: "วันหยุด",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "important",
    definition: "สำคัญ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "instructions",
    definition: "คำแนะนำ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "international",
    definition: "ระหว่างประเทศ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "letter",
    definition: "จดหมาย",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "morning",
    definition: "เช้า",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "music",
    definition: "ดนตรี",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "people",
    definition: "ประชากร",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "please",
    definition: "โปรด",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "pocket",
    definition: "กระเป๋า",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "point",
    definition: "จุด",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "quiz",
    definition: "แบบทดสอบ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "room",
    definition: "ห้อง",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "see",
    definition: "ดู",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "things",
    definition: "สิ่งของ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "think",
    definition: "คิด",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "try",
    definition: "พยายาม",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "word",
    definition: "คำ",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "worry",
    definition: "กังวล",
    book: "English File",
    level: "elementary",
    unit: "Common Everyday Words",
  },
  {
    word: "address",
    definition: "ที่อยู่",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "age",
    definition: "อายุ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "airport",
    definition: "สนามบิน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "answer",
    definition: "คำตอบ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "ask",
    definition: "ถาม",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "be",
    definition: "เป็น",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "beef",
    definition: "เนื้อวัว",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "bike",
    definition: "จักรยาน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "bird",
    definition: "นก",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "board",
    definition: "กระดาน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "boot",
    definition: "บูต",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "boy",
    definition: "เด็กผู้ชาย",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "bull",
    definition: "วัว",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "bye",
    definition: "ลาก่อน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "cat",
    definition: "แมว",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "cd player",
    definition: "เครื่องเล่นซีดี",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "chair",
    definition: "เก้าอี้",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "child",
    definition: "เด็ก",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "close",
    definition: "ปิด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "communication",
    definition: "การสื่อสาร",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "computer",
    definition: "คอมพิวเตอร์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "country",
    definition: "ประเทศ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "door",
    definition: "ประตู",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "ear",
    definition: "หู",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "educate",
    definition: "ให้ความรู้",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "e-mail",
    definition: "อีเมล",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "family",
    definition: "ตระกูล",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "food",
    definition: "อาหาร",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "friday",
    definition: "วันศุกร์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "good evening",
    definition: "สวัสดีตอนเย็น",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "goodbye",
    definition: "ลาก่อน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "goodnight",
    definition: "ราตรีสวัสดิ์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "hi",
    definition: "สวัสดี",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "horse",
    definition: "ม้า",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "hotel",
    definition: "โรงแรม",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "house",
    definition: "บ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "internet",
    definition: "อินเทอร์เน็ต",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "judo",
    definition: "ยูโด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "know",
    definition: "ทราบ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "listen",
    definition: "ฟัง",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "look",
    definition: "ดู",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "man",
    definition: "ผู้ชาย",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "menu",
    definition: "เมนู",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "modem",
    definition: "โมเด็ม",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "name",
    definition: "ชื่อ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "nice",
    definition: "ดี",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "number",
    definition: "ตัวเลข",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "open",
    definition: "เปิด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "phone",
    definition: "โทรศัพท์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "pizza",
    definition: "พิซซ่า",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "postcode",
    definition: "รหัสไปรษณีย์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "read",
    definition: "อ่าน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "remember",
    definition: "จดจำ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "repeat",
    definition: "ทำซ้ำ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "royal",
    definition: "พระราช",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "say",
    definition: "พูด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "school",
    definition: "โรงเรียน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "siesta",
    definition: "นอนพักกลางวัน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "sit down",
    definition: "นั่งลง",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "sorry",
    definition: "ขอโทษ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "spell",
    definition: "สะกด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "stand up",
    definition: "ยืนขึ้น",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "stress",
    definition: "ความเครียด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "surname",
    definition: "นามสกุล",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "table",
    definition: "โต๊ะ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "taxi",
    definition: "แท็กซี่",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "text message",
    definition: "ข้อความ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "thanks",
    definition: "ขอบคุณ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "toilets",
    definition: "ห้องสุขา",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "tourist",
    definition: "นักท่องเที่ยว",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "town",
    definition: "เมือง",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "train",
    definition: "รถไฟ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "travel",
    definition: "การท่องเที่ยว",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "tree",
    definition: "ต้นไม้",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "turn off",
    definition: "ปิด",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "tv",
    definition: "ทีวี",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "understand",
    definition: "เข้าใจ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "video",
    definition: "วิดีโอ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "water",
    definition: "น้ำ",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "window",
    definition: "หน้าต่าง",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "wine",
    definition: "ไวน์",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "woman",
    definition: "ผู้หญิง",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "work",
    definition: "งาน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "write",
    definition: "เขียน",
    book: "English File",
    level: "elementary",
    unit: "File 1 More Words to Learn",
  },
  {
    word: "cook dinner",
    definition: "ทำอาหารเย็น",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "do exercise",
    definition: "ออกกำลังกาย",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "do housework",
    definition: "ทำงานบ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "drink coffee",
    definition: "ดื่มกาแฟ",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "drive a vw",
    definition: "ขับรถโฟล์คสวาเก้น",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "eat fast food",
    definition: "กินอาหารจานด่วน",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "go to the cinema",
    definition: "ไปดูหนัง",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "have children",
    definition: "มีลูก",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "have a sandwich",
    definition: "มีแซนด์วิช",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "like animals",
    definition: "เหมือนสัตว์",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "listen to the radio",
    definition: "ฟังวิทยุ",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "live in a flat",
    definition: "อาศัยอยู่ในแฟลต",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "play tennis",
    definition: "เล่นเทนนิส",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "play the guitar",
    definition: "เล่นกีตาร์",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "read a newspaper",
    definition: "อ่านหนังสือพิมพ์",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "smoke a cigarette",
    definition: "สูบบุหรี่",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "speak german",
    definition: "พูดภาษาเยอรมัน",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "study economics",
    definition: "ศึกษาเศรษฐศาสตร์",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "watch television",
    definition: "ดูโทรทัศน์",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "wear glasses",
    definition: "สวมแว่นตา",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "work in an office",
    definition: "ทำงานในสำนักงาน",
    book: "English File",
    level: "elementary",
    unit: "File 2 Verb phrases",
  },
  {
    word: "actor",
    definition: "นักแสดงชาย",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "actress",
    definition: "นักแสดงหญิง",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "builder",
    definition: "ผู้สร้าง",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "doctor",
    definition: "หมอ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "engineer",
    definition: "วิศวกร",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "footballer",
    definition: "นักฟุตบอล",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "hairdresser",
    definition: "ช่างทำผม",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "housewife",
    definition: "แม่บ้าน",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "journalist",
    definition: "นักข่าว",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "lawyer",
    definition: "ทนายความ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "manager",
    definition: "ผู้จัดการ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "musician",
    definition: "นักดนตรี",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "nurse",
    definition: "พยาบาล",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "pilot",
    definition: "นักบิน",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "police officer",
    definition: "เจ้าหน้าที่ตำรวจ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "policeman",
    definition: "ตำรวจ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "policewoman",
    definition: "ตำรวจหญิง",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "politician",
    definition: "นักการเมือง",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "receptionist",
    definition: "พนักงานต้อนรับ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "secretary",
    definition: "เลขานุการ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "shop assistant",
    definition: "ผู้ช่วยร้านค้า",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "student",
    definition: "นักเรียน",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "waiter",
    definition: "บริกร",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "waitress",
    definition: "พนักงานเสิร์ฟ",
    book: "English File",
    level: "elementary",
    unit: "Jobs",
  },
  {
    word: "aunt",
    definition: "ป้า",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "brother",
    definition: "พี่ชาย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "brother-in-law",
    definition: "พี่เขย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "cousin",
    definition: "ลูกพี่ลูกน้อง",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "daughter",
    definition: "ลูกสาว",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "daughter-in-law",
    definition: "ลูกสะใภ้",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "father",
    definition: "พ่อ",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "father-in-law",
    definition: "พ่อตา",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "grandfather",
    definition: "คุณปู่",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "grandmother",
    definition: "ยาย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "husband",
    definition: "สามี",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "mother",
    definition: "แม่",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "mother-in-law",
    definition: "แม่สามี",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "nephew",
    definition: "หลานชาย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "niece",
    definition: "หลานสาว",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "sister",
    definition: "น้องสาว",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "sister-in-law",
    definition: "พี่สะใภ้",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "son",
    definition: "ลูกชาย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "son-in-law",
    definition: "ลูกเขย",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "uncle",
    definition: "ลุง",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "wife",
    definition: "ภรรยา",
    book: "English File",
    level: "elementary",
    unit: "The family",
  },
  {
    word: "a lot of",
    definition: "มาก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "alcohol",
    definition: "แอลกอฮอล์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "boyfriend",
    definition: "แฟน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "cap",
    definition: "หมวก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "cook",
    definition: "ทำอาหาร",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "earn",
    definition: "ได้รับ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "everywhere",
    definition: "ทุกที่",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "factory",
    definition: "โรงงาน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "fantastic",
    definition: "มหัศจรรย์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "friends",
    definition: "เพื่อน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "girlfriend",
    definition: "แฟน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "glass",
    definition: "กระจก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "hands",
    definition: "มือ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "hat",
    definition: "หมวก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "how many",
    definition: "เท่าไหร่",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "incredible",
    definition: "เหลือเชื่อ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "inside",
    definition: "ข้างใน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "it depends",
    definition: "มันขึ้นอยู่กับ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "just",
    definition: "แค่",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "late",
    definition: "ช้า",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "let’s meet",
    definition: "มาพบกัน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "magazine",
    definition: "นิตยสาร",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "many",
    definition: "มากมาย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "normally",
    definition: "โดยทั่วไป",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "north",
    definition: "ทิศเหนือ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "south",
    definition: "ใต้",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "east",
    definition: "ทิศตะวันออก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "west",
    definition: "ตะวันตก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "other",
    definition: "อื่น",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "outside",
    definition: "ข้างนอก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "partner",
    definition: "พันธมิตร",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "popular",
    definition: "เป็นที่นิยม",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "possessions",
    definition: "ทรัพย์สิน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "really",
    definition: "จริงหรือ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "relatives",
    definition: "ญาติ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "relax",
    definition: "ผ่อนคลาย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "shoes",
    definition: "รองเท้า",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "supermarket",
    definition: "ซูเปอร์มาร์เก็ต",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "terrible",
    definition: "ย่ำแย่",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "together",
    definition: "ด้วยกัน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "too",
    definition: "ด้วย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "typical",
    definition: "ทั่วไป",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "which",
    definition: "ที่",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "who",
    definition: "WHO",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "artist",
    definition: "ศิลปิน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "bag",
    definition: "ถุง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "band",
    definition: "วงดนตรี",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "bar",
    definition: "บาร์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "between",
    definition: "ระหว่าง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "burger",
    definition: "ชาวเมือง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "bus",
    definition: "รสบัส",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "car",
    definition: "รถ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "chess",
    definition: "หมากรุก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "company",
    definition: "บริษัท",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "designer clothes",
    definition: "เสื้อผ้าดีไซเนอร์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "do",
    definition: "ทำ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "dog",
    definition: "สุนัข",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "double",
    definition: "สองเท่า",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "drink",
    definition: "ดื่ม",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "eat",
    definition: "กิน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "every day",
    definition: "ทุกวัน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "flat",
    definition: "แบน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "flower",
    definition: "ดอกไม้",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "girl",
    definition: "สาว",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "go",
    definition: "ไป",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "ground floor",
    definition: "ชั้นล่าง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "have",
    definition: "มี",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "in a hurry",
    definition: "กำลังรีบ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "jazz",
    definition: "แจ๊ส",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "judge",
    definition: "ผู้พิพากษา",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "language",
    definition: "ภาษา",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "later",
    definition: "ภายหลัง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "leg",
    definition: "ขา",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "like",
    definition: "ชอบ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "live",
    definition: "สด",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "lonely",
    definition: "เหงา",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "love",
    definition: "รัก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "make",
    definition: "ทำ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "monkey",
    definition: "ลิง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "nose",
    definition: "จมูก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "occupation",
    definition: "อาชีพ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "parrot",
    definition: "นกแก้ว",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "pay",
    definition: "จ่าย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "person",
    definition: "บุคคล",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "pet",
    definition: "สัตว์เลี้ยง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "reception",
    definition: "แผนกต้อนรับ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "singer",
    definition: "นักร้อง",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "single",
    definition: "เดี่ยว",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "snake",
    definition: "งู",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "spend",
    definition: "ใช้จ่าย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "stand",
    definition: "ยืน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "stressful",
    definition: "เครียด",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "thumb",
    definition: "นิ้วหัวแม่มือ",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "tie",
    definition: "ผูก",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "university",
    definition: "มหาวิทยาลัย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "vase",
    definition: "แจกัน",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "weekend",
    definition: "สุดสัปดาห์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "witch",
    definition: "แม่มด",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "yacht",
    definition: "เรือยอชท์",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "zebra",
    definition: "ม้าลาย",
    book: "English File",
    level: "elementary",
    unit: "File 2 More Words to Learn",
  },
  {
    word: "bad",
    definition: "แย่",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "beautiful",
    definition: "สวย",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "big",
    definition: "ใหญ่",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "black",
    definition: "สีดำ",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "blue",
    definition: "สีฟ้า",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "brown",
    definition: "สีน้ำตาล",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "cheap",
    definition: "ราคาถูก",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "clean",
    definition: "ทำความสะอาด",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "dangerous",
    definition: "อันตราย",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "dark",
    definition: "มืด",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "difficult",
    definition: "ยาก",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "dirty",
    definition: "สกปรก",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "dry",
    definition: "แห้ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "easy",
    definition: "ง่าย",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "empty",
    definition: "ว่างเปล่า",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "expensive",
    definition: "แพง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "fair",
    definition: "ยุติธรรม",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "fast",
    definition: "เร็ว",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "fat",
    definition: "อ้วน",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "full",
    definition: "เต็ม",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "good",
    definition: "ดี",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "green",
    definition: "สีเขียว",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "grey",
    definition: "สีเทา",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "high",
    definition: "สูง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "long",
    definition: "ยาว",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "low",
    definition: "ต่ำ",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "new",
    definition: "ใหม่",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "old",
    definition: "เก่า",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "orange",
    definition: "ส้ม",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "pink",
    definition: "สีชมพู",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "poor",
    definition: "ยากจน",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "red",
    definition: "สีแดง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "rich",
    definition: "รวย",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "safe",
    definition: "ปลอดภัย",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "short",
    definition: "สั้น",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "slow",
    definition: "ช้า",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "small",
    definition: "เล็ก",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "tall",
    definition: "สูง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "thin",
    definition: "บาง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "ugly",
    definition: "น่าเกลียด",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "wet",
    definition: "เปียก",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "white",
    definition: "สีขาว",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "yellow",
    definition: "สีเหลือง",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "young",
    definition: "หนุ่มสาว",
    book: "English File",
    level: "elementary",
    unit: "File 3 Common adjectives",
  },
  {
    word: "do your homework",
    definition: "ทำการบ้านของคุณ",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "finish work",
    definition: "ทำงานให้เสร็จ",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "get dressed",
    definition: "แต่งตัว",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "get home",
    definition: "กลับบ้าน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "get to work",
    definition: "ไปทำงาน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "get up late",
    definition: "ตื่นสาย",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "go home",
    definition: "กลับบ้าน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "go to bed",
    definition: "ไปนอน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "go to english class",
    definition: "ไปชั้นเรียนภาษาอังกฤษ",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "go to the gym",
    definition: "ไปยิม",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "go to work",
    definition: "ไปทำงาน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "have a coffee",
    definition: "ดื่มกาแฟ",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "have a shower",
    definition: "อาบน้ำ",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "have breakfast",
    definition: "รับประทานอาหารเช้า",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "have dinner",
    definition: "ทานอาหารเย็น",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "have lunch",
    definition: "รับประทานอาหารกลางวัน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "make the dinner",
    definition: "ทำอาหารเย็น",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "sleep for seven hours",
    definition: "นอนเจ็ดชั่วโมง",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "start work",
    definition: "เริ่มทำงาน",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "take the dog out",
    definition: "พาสุนัขออกไป",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "wake up early",
    definition: "ตื่นเช้า",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "watch tv",
    definition: "ดูทีวี",
    book: "English File",
    level: "elementary",
    unit: "Daily routine",
  },
  {
    word: "spring",
    definition: "ฤดูใบไม้ผลิ",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "summer",
    definition: "ฤดูร้อน",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "autumn",
    definition: "ฤดูใบไม้ร่วง",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "winter",
    definition: "ฤดูหนาว",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "easter",
    definition: "อีสเตอร์",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "christmas",
    definition: "คริสต์มาส",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "new year",
    definition: "ปีใหม่",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "january",
    definition: "มกราคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "february",
    definition: "กุมภาพันธ์",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "march",
    definition: "มีนาคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "april",
    definition: "เมษายน",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "may",
    definition: "อาจ",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "june",
    definition: "มิถุนายน",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "july",
    definition: "กรกฎาคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "august",
    definition: "สิงหาคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "september",
    definition: "กันยายน",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "october",
    definition: "ตุลาคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "november",
    definition: "พฤศจิกายน",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "december",
    definition: "ธันวาคม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "first",
    definition: "อันดับแรก",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "second",
    definition: "ที่สอง",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "third",
    definition: "ที่สาม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "fourth",
    definition: "ที่สี่",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "fifth",
    definition: "ที่ห้า",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "sixth",
    definition: "ที่หก",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "seventh",
    definition: "ที่เจ็ด",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "eighth",
    definition: "ที่แปด",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "ninth",
    definition: "เก้า",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "tenth",
    definition: "ที่สิบ",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "eleventh",
    definition: "ที่สิบเอ็ด",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twelfth",
    definition: "ที่สิบสอง",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "thirteenth",
    definition: "ที่สิบสาม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "fourteenth",
    definition: "ที่สิบสี่",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twentieth",
    definition: "ที่ยี่สิบ",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twenty-first",
    definition: "ยี่สิบเอ็ด",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twenty-second",
    definition: "ยี่สิบวินาที",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twenty-third",
    definition: "ยี่สิบสาม",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "twenty-fourth",
    definition: "ยี่สิบสี่",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "thirtieth",
    definition: "สามสิบ",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "thirty-first",
    definition: "สามสิบเอ็ด",
    book: "English File",
    level: "elementary",
    unit: "Times and dates",
  },
  {
    word: "a long time",
    definition: "เป็นเวลานาน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "airline",
    definition: "สายการบิน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "all",
    definition: "ทั้งหมด",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "also",
    definition: "อีกด้วย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "another",
    definition: "อื่น",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "because",
    definition: "เพราะ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "birthday",
    definition: "วันเกิด",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "change",
    definition: "เปลี่ยน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "clothes",
    definition: "เสื้อผ้า",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "continue",
    definition: "ดำเนินการต่อ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cup",
    definition: "ถ้วย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cycle",
    definition: "วงจร",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "during",
    definition: "ในระหว่าง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "expert",
    definition: "ผู้เชี่ยวชาญ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "favourite",
    definition: "ที่ชื่นชอบ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "feel",
    definition: "รู้สึก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "fruit",
    definition: "ผลไม้",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "good-looking",
    definition: "ดูดี",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "guide",
    definition: "แนะนำ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hair",
    definition: "ผม",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "healthy",
    definition: "สุขภาพดี",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "unhealthy",
    definition: "ไม่แข็งแรง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "ill",
    definition: "ป่วย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "in front of",
    definition: "ด้านหน้า",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "invite",
    definition: "เชิญ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "island",
    definition: "เกาะ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "kilometre",
    definition: "กิโลเมตร",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "moon",
    definition: "ดวงจันทร์",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "more",
    definition: "มากกว่า",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "pick up",
    definition: "หยิบ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "president",
    definition: "ประธาน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "pretty",
    definition: "สวย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "separate",
    definition: "แยก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "the beginning",
    definition: "จุดเริ่มต้น",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "the middle",
    definition: "ตรงกลาง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "the same",
    definition: "เหมือนกัน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "traditional",
    definition: "แบบดั้งเดิม",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "until",
    definition: "จนกระทั่ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "usual",
    definition: "ตามปกติ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "unusual",
    definition: "ผิดปกติ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "vegetables",
    definition: "ผัก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "want",
    definition: "ต้องการ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "active",
    definition: "คล่องแคล่ว",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "after",
    definition: "หลังจาก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "always",
    definition: "เสมอ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "at home",
    definition: "ที่บ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "babysitter",
    definition: "พี่เลี้ยงเด็ก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "beach",
    definition: "ชายหาด",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "before",
    definition: "ก่อน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "brownie",
    definition: "บราวนี่",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "busy",
    definition: "ยุ่ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "canteen",
    definition: "โรงอาหาร",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cappuccino",
    definition: "คาปูชิโน่",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cold",
    definition: "เย็น",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "comfortable",
    definition: "สะดวกสบาย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cookie",
    definition: "คุกกี้",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "cut out",
    definition: "ตัดออก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "day",
    definition: "วัน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "diet",
    definition: "อาหาร",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "energy",
    definition: "พลังงาน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "enjoy",
    definition: "สนุก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "enthusiastic",
    definition: "กระตือรือร้น",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "espresso",
    definition: "เอสเพรสโซ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "far away",
    definition: "ห่างไกล",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hardly ever",
    definition: "แทบจะไม่เคยเลย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hot",
    definition: "ร้อน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hour",
    definition: "ชั่วโมง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "hungry",
    definition: "หิว",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "jeans",
    definition: "ยีนส์",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "jogging",
    definition: "วิ่งจ๊อกกิ้ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "minute",
    definition: "นาที",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "month",
    definition: "เดือน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "never",
    definition: "ไม่เคย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "often",
    definition: "บ่อยครั้ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "personal trainer",
    definition: "ผู้ฝึกสอนส่วนบุคคล",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "public holiday",
    definition: "วันหยุดนักขัตฤกษ์",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "public transport",
    definition: "การขนส่งสาธารณะ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "resolution",
    definition: "ปณิธาน",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "sad",
    definition: "เศร้า",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "season",
    definition: "ฤดูกาล",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "second",
    definition: "ที่สอง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "sometimes",
    definition: "บางครั้ง",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "sunset",
    definition: "พระอาทิตย์ตก",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "temperature",
    definition: "อุณหภูมิ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "then",
    definition: "แล้ว",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "thirsty",
    definition: "กระหายน้ำ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "tired",
    definition: "เหนื่อย",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "unusual",
    definition: "ผิดปกติ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "usually",
    definition: "โดยปกติ",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "week",
    definition: "สัปดาห์",
    book: "English File",
    level: "elementary",
    unit: "File 3 More Words to Learn",
  },
  {
    word: "buy a newspaper",
    definition: "ซื้อหนังสือพิมพ์",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "call a taxi",
    definition: "เรียกแท็กซี่",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "come here",
    definition: "มาที่นี่",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "dance the tango",
    definition: "เต้นแทงโก้",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "draw a picture",
    definition: "วาดภาพ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "find some money",
    definition: "หาเงิน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "give her a present",
    definition: "ให้ของขวัญแก่เธอ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "hear a noise",
    definition: "ได้ยินเสียง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "help someone",
    definition: "ช่วยใครสักคน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "look for your keys",
    definition: "มองหากุญแจของคุณ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "meet a friend",
    definition: "พบเพื่อน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "paint a picture",
    definition: "วาดภาพ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "play chess",
    definition: "เล่นหมากรุก",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "ride a bike",
    definition: "ขี่จักรยาน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "run a race",
    definition: "วิ่งแข่ง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "see a film",
    definition: "ดูหนัง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "sing a song",
    definition: "ร้องเพลง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "swim every day",
    definition: "ว่ายน้ำทุกวัน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "take photos",
    definition: "ถ่ายรูป",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "take your umbrella",
    definition: "ใช้ร่มของคุณ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "talk to a friend",
    definition: "พูดคุยกับเพื่อน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "tell me a secret",
    definition: "บอกความลับให้ฉันหน่อย",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "travel by plane",
    definition: "เดินทางโดยเครื่องบิน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "turn on the tv",
    definition: "เปิดทีวี",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "use a computer",
    definition: "ใช้คอมพิวเตอร์",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "wait for a bus",
    definition: "รอรถบัส",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "walk home",
    definition: "เดินกลับบ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More verb phrases",
  },
  {
    word: "advertisement",
    definition: "โฆษณา",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "channel",
    definition: "ช่อง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "concert",
    definition: "คอนเสิร์ต",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "creative",
    definition: "ความคิดสร้างสรรค์",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "decide",
    definition: "ตัดสินใจ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "die",
    definition: "ตาย",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "download",
    definition: "ดาวน์โหลด",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "everything",
    definition: "ทุกอย่าง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "follow",
    definition: "ติดตาม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "forget",
    definition: "ลืม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "group",
    definition: "กลุ่ม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "head",
    definition: "ศีรษะ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "hobby",
    definition: "งานอดิเรก",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "impossible",
    definition: "เป็นไปไม่ได้",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "in the end",
    definition: "ในที่สุด",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "interesting",
    definition: "น่าสนใจ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "life",
    definition: "ชีวิต",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "lyrics",
    definition: "เนื้อเพลง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "map",
    definition: "แผนที่",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "need",
    definition: "ความต้องการ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "physical",
    definition: "ทางกายภาพ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "possibly",
    definition: "อาจจะ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "practical",
    definition: "ใช้ได้จริง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "programme",
    definition: "โปรแกรม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "sell",
    definition: "ขาย",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "shop",
    definition: "ร้านค้า",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "similar",
    definition: "คล้ายกัน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "slowly",
    definition: "ช้า",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "some",
    definition: "บาง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "star",
    definition: "ดาว",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "stay",
    definition: "อยู่",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "still",
    definition: "นิ่ง",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "story",
    definition: "เรื่องราว",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "survey",
    definition: "สำรวจ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "today",
    definition: "วันนี้",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "tonight",
    definition: "คืนนี้",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "toys",
    definition: "ของเล่น",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "try on",
    definition: "ลองบน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "what kind of",
    definition: "แบบไหน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "without",
    definition: "ปราศจาก",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "boil",
    definition: "ต้ม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "can",
    definition: "สามารถ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "dvd",
    definition: "ดีวีดี",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "fall in love",
    definition: "ตกหลุมรัก",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "get angry",
    definition: "โกรธ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "get married",
    definition: "แต่งงานกัน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "go out together",
    definition: "ออกไปด้วยกัน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "guitar",
    definition: "กีตาร์",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "hate",
    definition: "เกลียด",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "intelligent",
    definition: "ฉลาด",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "jacket",
    definition: "เสื้อแจ็กเกต",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "love affair",
    definition: "เรื่องรัก ๆ ใคร่ ๆ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "musical instrument",
    definition: "เครื่องดนตรี",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "present",
    definition: "ปัจจุบัน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "shirt",
    definition: "เสื้อ",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "spectator sport",
    definition: "กีฬาผู้ชม",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "sport",
    definition: "กีฬา",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "sweater",
    definition: "เสื้อกันหนาว",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "take-away food",
    definition: "อาหารนำกลับบ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "trousers",
    definition: "กางเกงขายาว",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "waste of time",
    definition: "เสียเวลา",
    book: "English File",
    level: "elementary",
    unit: "File 4 More Words to Learn",
  },
  {
    word: "get a newspaper",
    definition: "รับหนังสือพิมพ์",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "get a taxi",
    definition: "เรียกแท็กซี่",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "get an e-mail",
    definition: "รับอีเมล",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "get dressed",
    definition: "แต่งตัว",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "get up",
    definition: "ลุกขึ้น",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go away for a week",
    definition: "ออกไปหนึ่งสัปดาห์",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go by bus",
    definition: "ไปโดยรถบัส",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go for a walk",
    definition: "ไปเดินเล่น",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go out on friday",
    definition: "ออกไปในวันศุกร์",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go shopping",
    definition: "ไปช้อปปิ้ง",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go to a restaurant",
    definition: "ไปร้านอาหาร",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go to bed late",
    definition: "เข้านอนดึก",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go to church",
    definition: "ไปโบสถ์",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go to mosque",
    definition: "ไปมัสยิด",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "go to the beach",
    definition: "ไปที่ชายหาด",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "have a car",
    definition: "มีรถยนต์",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "have a drink",
    definition: "ดื่ม",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "have a good time",
    definition: "มีช่วงเวลาที่ดี",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "have breakfast",
    definition: "รับประทานอาหารเช้า",
    book: "English File",
    level: "elementary",
    unit: "Go, have, get",
  },
  {
    word: "against",
    definition: "ขัดต่อ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "already",
    definition: "เรียบร้อยแล้ว",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "arrive",
    definition: "มาถึง",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "asleep",
    definition: "นอนหลับ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "battle",
    definition: "การต่อสู้",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "book a ticket",
    definition: "จองตั๋ว",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "cake",
    definition: "เค้ก",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "country house",
    definition: "บ้านในชนบท",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "dead",
    definition: "ตาย",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "dress",
    definition: "ชุด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "especially",
    definition: "โดยเฉพาะ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "everybody",
    definition: "ทุกคน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "fashions",
    definition: "แฟชั่น",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "flight",
    definition: "เที่ยวบิน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "great",
    definition: "ยอดเยี่ยม",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "happen",
    definition: "เกิดขึ้น",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "journey",
    definition: "การเดินทาง",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "land",
    definition: "ที่ดิน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "on the left",
    definition: "ทางด้านซ้าย",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "on the right",
    definition: "ทางด้านขวา",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "library",
    definition: "ห้องสมุด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "literature",
    definition: "วรรณกรรม",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "lucky",
    definition: "โชคดี",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "make up",
    definition: "แต่งหน้า",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "millionaire",
    definition: "เศรษฐี",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "murder",
    definition: "ฆาตกรรม",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "nobody",
    definition: "ไม่มีใคร",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "open",
    definition: "เปิด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "closed",
    definition: "ปิด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "pay for",
    definition: "จ่ายสำหรับ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "show",
    definition: "แสดง",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "so",
    definition: "ดังนั้น",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "soldier",
    definition: "ทหาร",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "somebody",
    definition: "ใครบางคน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "statue",
    definition: "รูปปั้น",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "teenager",
    definition: "วัยรุ่น",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "village",
    definition: "หมู่บ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "war",
    definition: "สงคราม",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "wine",
    definition: "ไวน์",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "world",
    definition: "โลก",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "worried",
    definition: "กังวล",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "battery",
    definition: "แบตเตอรี่",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "check in",
    definition: "เช็คอิน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "composer",
    definition: "นักแต่งเพลง",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "film",
    definition: "ฟิล์ม",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "leader",
    definition: "ผู้นำ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "leave",
    definition: "ออกจาก",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "mug",
    definition: "แก้ว",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "painter",
    definition: "จิตรกร",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "pianist",
    definition: "นักเปียโน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "politician",
    definition: "นักการเมือง",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "postcard",
    definition: "โปสการ์ด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "sailor",
    definition: "กะลาสีเรือ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "scientist",
    definition: "นักวิทยาศาสตร์",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "t-shirt",
    definition: "เสื้อยืด",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "wait",
    definition: "รอ",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "writer",
    definition: "นักเขียน",
    book: "English File",
    level: "elementary",
    unit: "File 5 More Words to Learn",
  },
  {
    word: "armchair",
    definition: "เก้าอี้นวม",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "bath",
    definition: "อาบน้ำ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "bathroom",
    definition: "ห้องน้ำ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "bed",
    definition: "เตียง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "bedroom",
    definition: "ห้องนอน",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "carpet",
    definition: "พรม",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "central heating",
    definition: "เครื่องทำความร้อนกลาง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "clock",
    definition: "นาฬิกา",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "cooker",
    definition: "หม้อหุงข้าว",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "cupboard",
    definition: "ตู้",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "desk",
    definition: "โต๊ะ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "dining room",
    definition: "ห้องรับประทานอาหาร",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "fireplace",
    definition: "เตาผิง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "floor",
    definition: "พื้น",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "fridge",
    definition: "ตู้เย็น",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "garage",
    definition: "โรงรถ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "garden",
    definition: "สวน",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "hall",
    definition: "ห้องโถง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "kitchen",
    definition: "ครัว",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "lamp",
    definition: "โคมไฟ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "light",
    definition: "แสงสว่าง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "living room",
    definition: "ห้องนั่งเล่น",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "mirror",
    definition: "กระจกเงา",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "picture",
    definition: "รูปภาพ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "plant",
    definition: "ปลูก",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "shelves",
    definition: "ชั้นวาง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "shower",
    definition: "อาบน้ำ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "sofa",
    definition: "โซฟา",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "stairs",
    definition: "บันได",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "study",
    definition: "ศึกษา",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "toilet",
    definition: "ห้องน้ำ",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "wall",
    definition: "กำแพง",
    book: "English File",
    level: "elementary",
    unit: "Flats and houses",
  },
  {
    word: "art gallery",
    definition: "หอศิลป์",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "bank",
    definition: "ธนาคาร",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "bridge",
    definition: "สะพาน",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "bus station",
    definition: "สถานีขนส่ง",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "castle",
    definition: "ปราสาท",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "chemist’s",
    definition: "นักเคมี",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "church",
    definition: "คริสตจักร",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "cinema",
    definition: "โรงหนัง",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "department store",
    definition: "ห้างสรรพสินค้า",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "hospital",
    definition: "โรงพยาบาล",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "market",
    definition: "ตลาด",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "mosque",
    definition: "มัสยิด",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "museum",
    definition: "พิพิธภัณฑ์",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "park",
    definition: "สวน",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "pharmacy",
    definition: "ร้านขายยา",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "police station",
    definition: "สถานีตำรวจ",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "post office",
    definition: "ที่ทำการไปรษณีย์",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "railway station",
    definition: "สถานีรถไฟ",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "river",
    definition: "แม่น้ำ",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "road",
    definition: "ถนน",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "school",
    definition: "โรงเรียน",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "shopping centre",
    definition: "ศูนย์การค้า",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "sports centre",
    definition: "ศูนย์กีฬา",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "square",
    definition: "สี่เหลี่ยม",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "street",
    definition: "ถนน",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "supermarket",
    definition: "ซูเปอร์มาร์เก็ต",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "theatre",
    definition: "โรงภาพยนตร์",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "town hall",
    definition: "ศาลากลางจังหวัด",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "travel agent’s",
    definition: "ตัวแทนการท่องเที่ยว",
    book: "English File",
    level: "elementary",
    unit: "Town and city",
  },
  {
    word: "argue",
    definition: "โต้แย้ง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "baby",
    definition: "ที่รัก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "bark",
    definition: "เห่า",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "believe",
    definition: "เชื่อ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "building",
    definition: "อาคาร",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "century",
    definition: "ศตวรรษ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "choose",
    definition: "เลือก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "cry",
    definition: "ร้องไห้",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "draw",
    definition: "วาด",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "each",
    definition: "แต่ละ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "estate agent",
    definition: "ตัวแทนอสังหาริมทรัพย์",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "flag",
    definition: "ธง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "friendly",
    definition: "เป็นกันเอง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "frightened",
    definition: "ตระหนก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "ghost",
    definition: "ผี",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "go back",
    definition: "กลับไป",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "guest",
    definition: "แขก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "large",
    definition: "ใหญ่",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "local",
    definition: "ท้องถิ่น",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "move",
    definition: "เคลื่อนไหว",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "neighbour",
    definition: "เพื่อนบ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "nervous",
    definition: "ประหม่า",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "noisy",
    definition: "เสียงดัง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "original",
    definition: "ต้นฉบับ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "paintings",
    definition: "ภาพวาด",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "passenger",
    definition: "ผู้โดยสาร",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "plants",
    definition: "พืช",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "price",
    definition: "ราคา",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "priest",
    definition: "นักบวช",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "quiet",
    definition: "เงียบ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "rent",
    definition: "เช่า",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "ship",
    definition: "เรือ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "spend the night",
    definition: "ใช้เวลาทั้งคืน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "strange",
    definition: "แปลก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "through",
    definition: "ผ่าน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "trip",
    definition: "การเดินทาง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "underground station",
    definition: "สถานีรถไฟใต้ดิน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "view",
    definition: "ดู",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "violin",
    definition: "ไวโอลิน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "what a pity",
    definition: "ช่างน่าเสียดาย",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "available",
    definition: "มีอยู่",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "behind",
    definition: "ด้านหลัง",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "cemetery",
    definition: "สุสาน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "corner",
    definition: "มุม",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "daily",
    definition: "รายวัน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "horror film",
    definition: "หนังสยองขวัญ",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "in advance",
    definition: "ล่วงหน้า",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "next to",
    definition: "ถัดจาก",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "opposite",
    definition: "ตรงข้าม",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "over",
    definition: "เกิน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "queue",
    definition: "คิว",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "remote control",
    definition: "การควบคุมระยะไกล",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "roundabout",
    definition: "วงเวียน",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "straight on",
    definition: "ตรงไป",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "traffic lights",
    definition: "สัญญาณไฟจราจร",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "under",
    definition: "ภายใต้",
    book: "English File",
    level: "elementary",
    unit: "File 6 More Words to Learn",
  },
  {
    word: "apples",
    definition: "แอปเปิ้ล",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "bananas",
    definition: "กล้วย",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "biscuits",
    definition: "บิสกิต",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "bread",
    definition: "ขนมปัง",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "butter",
    definition: "เนย",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "cake",
    definition: "เค้ก",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "carrots",
    definition: "แครอท",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "cereal",
    definition: "ซีเรียล",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "cheese",
    definition: "ชีส",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "chips",
    definition: "ชิป",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "chocolate",
    definition: "ช็อคโกแลต",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "coffee",
    definition: "กาแฟ",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "crisps",
    definition: "ขนมกรุบกรอบ",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "eggs",
    definition: "ไข่",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "fish",
    definition: "ปลา",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "fruit salad",
    definition: "สลัดผลไม้",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "grapes",
    definition: "องุ่น",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "ice cream",
    definition: "ไอศครีม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "jam",
    definition: "แยม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "ketchup",
    definition: "ซอสมะเขือเทศ",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "lettuce",
    definition: "ผักกาดหอม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "meat",
    definition: "เนื้อ",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "milk",
    definition: "น้ำนม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "mushrooms",
    definition: "เห็ด",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "olive oil",
    definition: "น้ำมันมะกอก",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "onions",
    definition: "หัวหอม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "orange juice",
    definition: "น้ำส้ม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "oranges",
    definition: "ส้ม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "pasta",
    definition: "พาสต้า",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "peas",
    definition: "ถั่ว",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "pineapple",
    definition: "สัปปะรด",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "potatoes",
    definition: "มันฝรั่ง",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "rice",
    definition: "ข้าว",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "salad",
    definition: "สลัด",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "sandwiches",
    definition: "แซนด์วิช",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "sugar",
    definition: "น้ำตาล",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "sweets",
    definition: "ขนม",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "tea",
    definition: "ชา",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "toast",
    definition: "ขนมปังปิ้ง",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "tomatoes",
    definition: "มะเขือเทศ",
    book: "English File",
    level: "elementary",
    unit: "Food",
  },
  {
    word: "a bottle of",
    definition: "ขวดของ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "agree",
    definition: "เห็นด้วย",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "basket",
    definition: "ตะกร้า",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "boat",
    definition: "เรือ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "campsite",
    definition: "ที่ตั้งแคมป์",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "card",
    definition: "การ์ด",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "countable",
    definition: "นับได้",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "couple",
    definition: "คู่",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "disaster",
    definition: "ภัยพิบัติ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "dish",
    definition: "จาน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "for example",
    definition: "ตัวอย่างเช่น",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "go camping",
    definition: "ไปตั้งแคมป์",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "heart",
    definition: "หัวใจ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "ideal",
    definition: "ในอุดมคติ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "ingredients",
    definition: "วัตถุดิบ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "like",
    definition: "ชอบ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "litres",
    definition: "ลิตร",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "lose",
    definition: "สูญเสีย",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "luxury",
    definition: "หรูหรา",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "maybe",
    definition: "อาจจะ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "missing",
    definition: "หายไป",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "moment",
    definition: "ช่วงเวลา",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "more or less",
    definition: "ไม่มากก็น้อย",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "nightlife",
    definition: "สถานบันเทิงยามค่ำคืน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "note",
    definition: "บันทึก",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "of course",
    definition: "แน่นอน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "plan",
    definition: "วางแผน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "probably",
    definition: "อาจจะ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "put",
    definition: "ใส่",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "ring",
    definition: "แหวน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "see the sights",
    definition: "ดูสถานที่ท่องเที่ยว",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "soon",
    definition: "เร็วๆ นี้",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "spaghetti",
    definition: "ปาเก็ตตี้",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "sure",
    definition: "แน่นอน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "surprise",
    definition: "เซอร์ไพรส์",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "tap",
    definition: "แตะ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "uncountable",
    definition: "นับไม่ได้",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "voice",
    definition: "เสียง",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "at least",
    definition: "อย่างน้อย",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "contain",
    definition: "บรรจุ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "dessert",
    definition: "ขนม",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "experiment",
    definition: "การทดลอง",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "fresh",
    definition: "สด",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "home-made",
    definition: "ทำที่บ้าน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "in fact",
    definition: "ในความเป็นจริง",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "main course",
    definition: "อาหารจานหลัก",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "mineral water",
    definition: "น้ำแร่",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "myth",
    definition: "ตำนาน",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "recently",
    definition: "ล่าสุด",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "roast",
    definition: "ย่าง",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "sauce",
    definition: "ซอส",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "soup",
    definition: "ซุป",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "starter",
    definition: "เริ่มต้น",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "sweat",
    definition: "เหงื่อ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "weather",
    definition: "สภาพอากาศ",
    book: "English File",
    level: "elementary",
    unit: "File 7 More Words to Learn",
  },
  {
    word: "abroad",
    definition: "ต่างประเทศ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "adult",
    definition: "ผู้ใหญ่",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "adventure",
    definition: "การผจญภัย",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "air conditioning",
    definition: "เครื่องปรับอากาศ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "almost",
    definition: "เกือบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "beer",
    definition: "เบียร์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "boring",
    definition: "น่าเบื่อ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "capital",
    definition: "เมืองหลวง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "chef",
    definition: "พ่อครัว",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "climate",
    definition: "ภูมิอากาศ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "crime",
    definition: "อาชญากรรม",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "crowded",
    definition: "แออัด",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "dreams",
    definition: "ความฝัน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "dress",
    definition: "ชุด",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "elegant",
    definition: "สง่างาม",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "everywhere",
    definition: "ทุกที่",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "experience",
    definition: "ประสบการณ์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "geography",
    definition: "ภูมิศาสตร์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "height",
    definition: "ความสูง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "imagine",
    definition: "จินตนาการ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "including",
    definition: "รวมทั้ง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "make jokes",
    definition: "ทำเรื่องตลก",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "jump",
    definition: "กระโดด",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "last",
    definition: "ล่าสุด",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "mars",
    definition: "ดาวอังคาร",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "mosquitoes",
    definition: "ยุง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "oxygen",
    definition: "ออกซิเจน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "personality",
    definition: "บุคลิกภาพ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "polite",
    definition: "สุภาพ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sharks",
    definition: "ฉลาม",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "shy",
    definition: "อาย",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "social life",
    definition: "ชีวิตทางสังคม",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "steal",
    definition: "ขโมย",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "suddenly",
    definition: "กะทันหัน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "surprisingly",
    definition: "น่าแปลกใจ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "the earth",
    definition: "โลก",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "tigers",
    definition: "เสือ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "weight",
    definition: "น้ำหนัก",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "aggressive",
    definition: "ก้าวร้าว",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "altitude",
    definition: "ระดับความสูง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "bill",
    definition: "ใบแจ้งหนี้",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "breathe",
    definition: "หายใจ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "careful",
    definition: "ระมัดระวัง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "check out",
    definition: "ตรวจสอบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "cloudy",
    definition: "เมฆมาก",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "common",
    definition: "ทั่วไป",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "complete",
    definition: "สมบูรณ์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "cotton",
    definition: "ฝ้าย",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "exciting",
    definition: "น่าตื่นเต้น",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "freezer",
    definition: "ตู้แช่แข็ง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "generous",
    definition: "ใจกว้าง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "hit",
    definition: "ตี",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "luggage",
    definition: "กระเป๋าเดินทาง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "rain",
    definition: "ฝน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "reservation",
    definition: "การจอง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "roof",
    definition: "หลังคา",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sea level",
    definition: "ระดับน้ำทะเล",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "serious",
    definition: "จริงจัง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sign",
    definition: "เข้าสู่ระบบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "snow",
    definition: "หิมะ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "stylish",
    definition: "มีสไตล์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sunny",
    definition: "แดดจัด",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "useful",
    definition: "มีประโยชน์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "windy",
    definition: "ลมแรง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "autograph",
    definition: "ลายเซ็นต์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "back row",
    definition: "แถวหลัง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "based on",
    definition: "ขึ้นอยู่กับ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "bestseller",
    definition: "หนังสือขายดี",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "continent",
    definition: "ทวีป",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "exactly",
    definition: "อย่างแน่นอน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "jealous",
    definition: "อิจฉา",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "miles",
    definition: "ไมล์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "prefer",
    definition: "ชอบมากกว่า",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "ring",
    definition: "แหวน",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "somewhere",
    definition: "ที่ไหนสักแห่ง",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "soundtrack",
    definition: "เพลงประกอบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "spa",
    definition: "สปา",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "sports event",
    definition: "การแข่งขันกีฬา",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "version",
    definition: "รุ่น",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "brochure",
    definition: "โบรชัวร์",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "fun",
    definition: "สนุก",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "reply",
    definition: "ตอบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
  {
    word: "silence",
    definition: "ความเงียบ",
    book: "English File",
    level: "elementary",
    unit: "File 8 More Words to Learn",
  },
];
