import "./Wordle.css";
import WordleApp from "./WordleGame";

export const WordlePage = () => {
  return (
    <div className="wordle-container">
      <WordleApp />
    </div>
  );
};
