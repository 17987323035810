import { translation } from "../../utils/translation";

export const Philosophy = () => {
  return (
    <div className="philosophy-container">
      <p>{translation.tr_my_philosophy.en}</p>
      <h2>{translation.tr_get_students_talking.en}</h2>
    </div>
  );
};
