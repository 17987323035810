import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export const Match = () => {
  // const json = {
  //   uid: "1",
  //   title: "Company Facts",
  //   exercises: [
  //     {
  //       exercise_id: "1",
  //       type: "match",
  //       line: "block",
  //       title: "Match company facts",
  //       instructions: "Match the statements with the correct answers.",
  //       questions: [
  //         {
  //           question: "Our annual revenue is _____. And we're based  _____",
  //           answers: [
  //             { id: 1, text: "€300 million" },
  //             { id: 2, text: "in the north of Italy" },
  //           ],
  //         },

  //         {
  //           question: "We operate _____",
  //           answers: [{ id: 3, text: "in many different countries" }],
  //         },

  //         {
  //           question: "We produce _____",
  //           answers: [{ id: 4, text: "mobile phones" }],
  //         },

  //         {
  //           question: "We specialize _____",
  //           answers: [{ id: 5, text: "in phones for children under 12" }],
  //         },

  //         {
  //           question: "We provide _____",
  //           answers: [{ id: 6, text: "phone services" }],
  //         },
  //       ],
  //     },
  //   ],
  // };

  const json = {
    uid: "2",
    title: "Complete the Email",
    exercises: [
      {
        exercise_id: "1",
        type: "match",
        line: "inline",
        title: "Complete the email",
        instructions: "Complete the email with the words from the list below",
        questions: [
          {
            question:
              "Hi Abdul, \n Here's the information about us: \nThe name of the company _____ IT-express.",
            answers: [{ id: 1, text: "is" }],
          },
          {
            question:
              "I _____ the CEO, and my four colleagues _____ the technicians.",
            answers: [
              { id: 2, text: "am" },
              { id: 3, text: "are" },
            ],
          },
          {
            question: "Pascale is _____ personal assistant.",
            answers: [{ id: 4, text: "our" }],
          },
          {
            question:
              "_____ office is in Tunis, but the customers _____ in Spain.",
            answers: [
              { id: 5, text: "Our" },
              { id: 6, text: "are" },
            ],
          },
          {
            question:
              "Yolanda _____ the new Sales Rep. She _____ Spanish, but _____ French and English are very good.",
            answers: [
              { id: 7, text: "is" },
              { id: 8, text: "is" },
              { id: 9, text: "her" },
            ],
          },
          {
            question:
              "Send me _____ phone number so we can meet. \n Regards, \n Kashyar",
            answers: [{ id: 10, text: "your" }],
          },
        ],
      },
    ],
  };

  const [toggleShowResults, setShowResults] = useState(false);
  const [toggleShowAnswers, setShowAnswers] = useState(false);
  const [options, setOptions] = useState<{ id: number; text: string }[]>([]);
  const [selected, setSelected] = useState<number>(-1);
  const [correctAnswers, setCorrectAnswers] = useState<string[][]>([]);
  const [answers, setAnswers] = useState<string[][]>([]);
  const exercise = json.exercises[0];

  const handleSelectOption = (opt: number) => {
    if (selected === opt) {
      setSelected(-1);
    } else {
      setSelected(opt);
    }
  };

  const handleSetAnswer = (i: number, j: number) => {
    let tempAnswers = answers;
    if (answers[i][j] !== "") {
      let tempOptions = options;
      tempOptions.push({
        id: Math.floor(Math.random() * 100000),
        text: answers[i][j],
      });
      setOptions(tempOptions);
    }
    tempAnswers[i][j] =
      options.find((option) => option.id === selected)?.text || "";
    setAnswers(tempAnswers);
    setOptions(options.filter((opt) => opt.id !== selected));
    setSelected(-1);
  };

  const handleShowAnswers = () => {
    setAnswers(correctAnswers);
    setOptions([]);
    setShowAnswers(true);
  };

  const handleReset = () => {
    let tempOptions: { id: number; text: string }[] = [];

    exercise.questions.map((question) => {
      question.answers.map((answer) => {
        tempOptions.push(answer);
      });
    });

    setCorrectAnswers(
      exercise.questions.map((question) => {
        return question.answers.map((option) => option.text);
      })
    );

    // let tempAnswers = tempOptions.map((option) => "");
    setAnswers(
      exercise.questions.map((question) => {
        return question.answers.map(() => "");
      })
    );

    let randomizedOptions = [];

    while (tempOptions.length > 0) {
      let randomIndex = Math.floor(Math.random() * tempOptions.length);
      randomizedOptions.push(tempOptions[randomIndex]);
      tempOptions = tempOptions.filter(
        (option) => option !== tempOptions[randomIndex]
      );
    }
    setOptions(randomizedOptions);
  };
  useEffect(() => {
    handleReset();
  }, []);

  return (
    <div className="exam-container match-container">
      <div className="exam-header">
        <div className="div-exam-title">
          <h3>{json.title} -</h3>
        </div>
        <div className="exercise-id">
          <h3>
            {" "}
            {exercise.title} ({exercise.exercise_id}/{json.exercises.length})
          </h3>
        </div>
      </div>

      <div className="exam-body">
        <div className="instructions-submit">
          <div className="div-instructions">
            <strong>Instructions: </strong>
            {exercise.instructions}
          </div>
          {toggleShowAnswers || toggleShowResults ? (
            <button
              onClick={() => {
                setShowResults(false);
                handleReset();
                setShowAnswers(false);
              }}
            >
              Try again
            </button>
          ) : !answers.flat().includes("") &&
            !toggleShowResults &&
            !toggleShowAnswers ? (
            <button
              onClick={() => {
                setShowResults(true);
              }}
            >
              Submit
            </button>
          ) : (
            <button
              onClick={() => {
                handleShowAnswers();
              }}
            >
              Show Answers
            </button>
          )}
        </div>
        <div className="match-div-options">
          {options.map((option) => (
            <div
              className={
                selected === option.id
                  ? "match-option option-selected"
                  : "match-option"
              }
              key={"option-" + option.id}
              onClick={() => handleSelectOption(option.id)}
            >
              {option.text}
            </div>
          ))}
        </div>
        <ol className="div-match-questions">
          {exercise.questions.map((question, i) => (
            <div
              className="match-question"
              key={"questions-" + question.question.replaceAll(" ", "-")}
            >
              ({i + 1})
              {question.question.split("\n").map((line, p) => {
                return (
                  <div className="match-question-parts">
                    {line.split("_____").map((part, j) => {
                      return (
                        <>
                          <div
                            className="match-question-part"
                            key={line + "part-" + j}
                          >
                            {part}
                          </div>

                          {j < line.split("_____").length - 1 && (
                            <div
                              className={
                                answers && answers[i] && answers[i][j] === ""
                                  ? "answer-box-container answer-box-container-empty"
                                  : "answer-box-container"
                              }
                              onClick={() => {
                                handleSetAnswer(i, j);
                              }}
                            >
                              {answers && answers[i] && answers[i][j] === ""
                                ? "__________"
                                : answers && answers[i] && answers[i][j]
                                ? answers[i][j]
                                : "***"}
                            </div>
                          )}

                          {toggleShowResults && answers[i][j] !== "" && (
                            <div className="div-check-cross">
                              {answers[i][j] === correctAnswers[i][j] ? (
                                <FaCheck fill="#009900" />
                              ) : (
                                <ImCross fill="#990000" />
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}

                    {p < question.question.split("\n").length - 1 && <br />}
                  </div>
                );
              })}
              {question.question.includes("\n") ||
                (exercise.line === "block" && <br />)}
            </div>
          ))}
        </ol>
      </div>
    </div>
  );
};
